import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {OpportunityWatchTable} from './components/OpportunityWatchTable'
import {OpportunityWatchAdd} from './components/OpportunityWatchAdd'
import {OpportunityWatchDetail} from './components/OpportunityWatchDetail'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Activity} from '../generalActivityLogs/components/Activity'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Todo Activities',
    path: '/detail/:id',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OpportunityWatch: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path={['/:workspaceid/opportunity-watch', '/opportunity-watch']}>
          <OpportunityWatchTable className='mb-5 mb-xl-8' />
        </Route>
        <Route exact path={['/:workspaceid/opportunity-watch/add', '/opportunity-watch/add']}>
          {({match, history}) => (
            <OpportunityWatchAdd
              // workSpaceId={match && match.params.workspaceid}
              onHide={() => {
                // history.push(`/${match && match.params.workspaceid}/opportunity-watch`)
                history.push(`/opportunity-watch`)
              }}
            />
          )}
        </Route>
        <Route
          exact
          path={['/:workspaceid/opportunity-watch/:id/edit', '/opportunity-watch/:id/edit']}
        >
          {({match, history}) => (
            <OpportunityWatchAdd
              // workSpaceId={match && match.params.workspaceid }
              id={match && match.params.id}
              onHide={() => {
                // history.push(`/${match && match.params.workspaceid}/opportunity-watch`)
                history.push(`/opportunity-watch`)
              }}
            />
          )}
        </Route>
        <Route path='/opportunity-watch/:id'>
          <OpportunityWatchDetail />
        </Route>
      </Switch>
    </>
  )
}

export {OpportunityWatch}
