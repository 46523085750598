/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
type Props = {}

export const Unauthorized: FC<Props> = () => {
  return (
    <div className='card'>
      <div className='scroll-y pe-5 h-200px h-lg-550px m-auto py-5 mb-0 pb-0 d-flex align-items-center justify-content-center'>
        <div>
          <h1 className='text-center'>Unauthorized!</h1>
          <p className='text-center'>You are not allowed to chat!</p>
        </div>
      </div>
    </div>
  )
}
