/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useRef, useEffect, useState, useCallback } from 'react'
import axios from 'axios'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { UpdateAuthModel } from '../../modules/auth/models/UpdateAuthModel'
import * as auth from '../../modules/auth/redux/AuthRedux'
import { RootState } from '../../../setup'
import '../../../_metronic/assets/sass/components/prof-icon.scss'
import { Link } from 'react-router-dom'
import { getUserByToken } from '../../modules/auth/redux/AuthCRUD'

type Props = {
  onRefChange: any
  rowDimension: any
  setRow1Dimension: any
  defaultSetting: any
  setDefaultSetting: any
  //setToggleView: any
}

const API_URL = process.env.REACT_APP_API_URL
const USERS_SETTINGS = `${API_URL}/users/settings`
const USER_DATA = `${API_URL}/users`
let userSettings: any;

export const DashboardSettingsPopup: React.FC<Props> = ({
  onRefChange,
  rowDimension,
  setRow1Dimension,
  defaultSetting,
  setDefaultSetting,
  //setToggleView,
}) => {
  const authorData: UpdateAuthModel = useSelector<RootState>(
    ({ auth }) => auth,
    shallowEqual
    ) as UpdateAuthModel 
  const dispatch = useDispatch()
  const menuRef = useRef<HTMLDivElement>(null);
  const [disable, setIsDisable]: any = useState(false)
  const [isChecked, setIsChecked]: any = useState(false);
  const [isApply, setIsApply] = useState(false);
  useEffect(()=>{
    if (authorData.user.settings) {
      userSettings = JSON.parse(JSON.stringify(authorData?.user?.settings))
    }
  }, []);
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
    dataWithIconSize = JSON.parse(JSON.stringify(authorData))
    dataWithIconSize.user.settings.fullWidth = event.target.checked
    dispatch(auth.actions.setUser(dataWithIconSize))
  }
  

    useEffect(() => {
      setIsChecked(authorData.user.settings.fullWidth)
    }, [])

    let dataWithIconSize: any
  const sendSettings = () => {
    setIsDisable(true)
    setTimeout(() => {
      axios
        .patch(USERS_SETTINGS, {
          settings: { iconSize: rowDimension , 
                      fullWidth: isChecked,
                    },
        })
        .then((data: any) => {
          setDefaultSetting(true)
          setIsApply(true)
          let updatedUser = JSON.parse(JSON.stringify(data.data))
          userSettings = updatedUser.settings
          setIsChecked(userSettings.fullWidth);
          setRow1Dimension(+userSettings.iconSize)
          dataWithIconSize = JSON.parse(JSON.stringify(authorData))
          dataWithIconSize.user.settings.iconSize = +userSettings.iconSize
          dataWithIconSize.user.settings.fullWidth = userSettings.fullWidth
          // userSettings = dataWithIconSize.user.settings
          dispatch(auth.actions.setUser(dataWithIconSize))
          setIsDisable(false)
          setTimeout(() => {
            setDefaultSetting(false)
          }, 1000)
        })
        .catch((error) => console.log('error', error))
    }, 10)
  }

  const clearSetting = () => {
    setDefaultSetting(true)
    setIsChecked(false);
    // rowDimension = 63
    setRow1Dimension(63)
    dataWithIconSize = JSON.parse(JSON.stringify(authorData))
    dataWithIconSize.user.settings.fullWidth = false
    dispatch(auth.actions.setUser(dataWithIconSize))
    setTimeout(() => {
      setDefaultSetting(false)
    }, 0)
  }
const handleClose=()=>{
  setDefaultSetting(true)
  setIsChecked(userSettings.fullWidth);
  setRow1Dimension(+userSettings.iconSize)
  rowDimension = +userSettings.iconSize
  dataWithIconSize = JSON.parse(JSON.stringify(authorData))
  dataWithIconSize.user.settings.fullWidth = userSettings.fullWidth
  dataWithIconSize.user.settings.iconSize = userSettings.iconSize
  dispatch(auth.actions.setUser(dataWithIconSize))
  setTimeout(() => {
    setDefaultSetting(false)
  }, 1000)
}

useEffect(() => {
  const target = menuRef.current;
  const observer : any = new MutationObserver((mutations: any) => {
    const classList = mutations[0].target.classList;
    if (classList.contains('show')) {
      // console.log('Modal is now visible');
    } else {
      // if(isApply=== true){
      //   console.log("Zain")
      //   setTimeout(() => {
      //     handleClose();
      //     console.log('Modal is now hidden');
      //   }, 500);
      // }else{
      //   handleClose()
      //   console.log("Zain")

      // }
      setTimeout(() => {
        handleClose();
        
      }, 200);
    }
  });

  observer.observe(target, { attributes: true });

  return () => observer.disconnect();
}, []);

// useEffect(() => {
//   function handleClickOutside(event: any) {
//     if (menuRef?.current?.classList.contains('menu-sub-dropdown') === false && menuRef?.current?.classList.contains('show') === false && !menuRef.current.contains(event.target)) {
//       console.log("closing")
//       handleClose();
//     }
//   }
// if(menuRef?.current){
// console.log("🚀 ~ file: DashboardSettingsPopup.tsx:122 ~ useEffect ~ menuRef?.current:", menuRef?.current)

//   document.addEventListener('click', handleClickOutside);
// }
//   return () => {
//     document.removeEventListener('click', handleClickOutside);
//   };
// }, [menuRef]);

  return (
    <div
      ref={menuRef}  
      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px dropdown_dialog border-right-top-bottom z-index-78'
      data-kt-menu='true'
    >
      <div className='px-7 py-5 ms-3 d-flex justify-content-between'>
        <div className='fs-6 text-dark fw-500'>Layout Settings</div>

        <div className='btn btn-active-light p-0'>
          {' '}
          <i className='fa fa-times' aria-hidden='true' data-kt-menu-dismiss='true' onClick={handleClose}></i>
        </div>

        {/* <i className="fa-light fa-xmark"></i> */}
      </div>
      <div className='separator border-gray-200'></div>
      <div
        className='px-7 ms-3 py-5 filters'
        data-kt-user-table-filter='form'
        style={{ marginBottom: '15px' }}
      >
        <>
          <div className='row mx-auto'>
            <div className='col-lg-12 mt-2 ps-1'>
              <h4 className='fs-7 fw-500' title='Icons'>
                Icon Size
              </h4>
            </div>
          </div>
          <div className='row mx-auto mt-5 icon_ui'>
            {' '}
            <div
              title={'Icon size'}
              id='slider'
              ref={onRefChange}
              className='noUi-sm mt-5 me-5'
              style={{ cursor: 'pointer' }}
            ></div>
          </div>

          {/* Full Width Controller */}
          <div className='row mx-auto mt-10'>
            <div className="col-sm-7 p-0 pe-5 form-check form-switch d-flex align-items-center justify-content-between">
              <label className="form-check-label fs-7 fw-500 text-black" title='Width' htmlFor="widthToggle">Full Width View</label>
              <input className="form-check-input" type="checkbox" role="switch" id="widthToggle" style={{height:'1rem', width:'1.75rem'}} onChange={handleCheckboxChange} value={isChecked} checked={isChecked}/>
            </div>
          </div>
          <div className='row mx-auto mt-10'>
              <Link to='/account/cover-image' className='menu-link px-0 fs-7 fw-500 text-black link_hover'>
                Change Cover Image
             </Link>
          </div>
          


          <div className='d-flex justify-content-end mt-16 popup_setting'>
            <button
              type='button'
              className='btn btn-sm btn-white btn-active-light-primary me-2'
              // data-kt-menu-dismiss='true'
              title='Clear'
              onClick={() => clearSetting()}
              disabled={disable}
            >
              Clear
            </button>

            {/* <div className='col-lg-6 ms-lg-5 ps-3 mt-3'> */}
            <button
              type='button'
              className='btn btn-primary fs-7 fw-bold py-2 px-7'
              title='Apply'
              onClick={() => sendSettings()}
              disabled={disable}
              data-kt-menu-dismiss='true'
            >
              Apply
            </button>
          </div>
          {/* </div> */}

          {/* <div className='row mx-auto'>
            <div className='col-lg-6 ms-lg-5 ps-3 mt-3'>
              <button
                type='button'
                className='btn  btn-primary fs-7 fw-bold px-6'
                title='Apply'
                onClick={() => sendSettings()}
                disabled={disable}
              >
                Apply
              </button>
            </div>
          </div> */}
          <div className='row mx-auto'>
            <div className='col-12 d-flex justify-content-end'></div>
          </div>
        </>
      </div>
    </div>
  )
}
