import React, {useEffect, useRef, useState} from 'react'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as actions from '../redux/ReleaseNoteAction'
import Editor from '../../../Common/Editor/Editor'

interface ICreateAccount {
  title: string
  description: string
  version: string
}

const inits: ICreateAccount = {
  title: '',
  description: '',
  version: '',
}

interface Props {
  onHide: () => void
  id?: string | null
}

export const ReleaseNoteAdd: React.FC<Props> = ({id, onHide}) => {
  const dispatch = useDispatch()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)

  const releaseNotesValidationSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .label('title')
      .min(1, 'Title must contain more than 3 characters'),
    version: Yup.string()
      .required('Version is required')
      .label('version')
      .min(1, 'Version must contain 1 character'),
    description: Yup.string().nullable(true),
  })

  const {ReleaseNoteDetail}: any = useSelector<RootState>(
    (state) => ({
      userInfo: state.auth.user,
      ReleaseNoteDetail: state.releasenote.ReleaseNoteDetail,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (ReleaseNoteDetail && id) {
      setInitValues({
        title: ReleaseNoteDetail?.title,
        description: ReleaseNoteDetail?.description,
        version: ReleaseNoteDetail?.version,
      })
    }
  }, [ReleaseNoteDetail])

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchReleaseNoteById(id))
    }
  }, [])

  const crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Release Notes', url: `/release-notes`},
    {
      name: id ? 'Edit Release Notes' : 'Add Release Notes',
      url: `/release-notes/add`,
    },
  ]

  return (
    <div className='page-content mh-80'>
      <div className='px-12'>
        <div className="row">
          <div className="col-sm-6">
            <h2 className='fw-500 fs-2 m-0 py-5'>
              {id ? 'Edit Release Notes' : 'Create Release Notes'}
            </h2>
          </div>
        </div>
      </div>
      <Breadcrumbs crumbs={crumbs} />
      <div className='page-body py-lg-10 px-lg-10 overflow_height_scroll'>
        <div
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={releaseNotesValidationSchema}
              initialValues={id ? initValues : inits}
              enableReinitialize
              onSubmit={(values: any) => {
                if (id) {
                  dispatch(actions.UpdateReleaseNote(values, id))
                  onHide()
                } else {
                  dispatch(actions.createReleaseNote(values))
                  onHide()
                }
              }}
            >
              {({values, setFieldValue}) => (
                <Form className='form' noValidate id='kt_modal_create_app_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      <div className='fv-row mb-5'>
                        <label className='fs-6 fw-bold form-label mb-2 required'>
                          <span>Title</span>
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-lg  form-control-solid'
                          name='title'
                          placeholder='Title'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='title' />
                        </div>
                      </div>
                      <div className='fv-row mb-5'>
                        <label className='fs-6 fw-bold form-label mb-2 required'>
                          <span>Version</span>
                        </label>
                        <Field
                          type='text'
                          className='form-control form-control-lg  form-control-solid'
                          name='version'
                          placeholder='Version'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='version' />
                        </div>
                      </div>
                      <div className='fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className=''>Description</span>
                        </label>
                        <Field
                          name='description'
                          className='form-control form-control-lg form-control-solid'
                          type='text'
                          placeholder='Description'
                        >
                          {({field, form, meta}: any) => (
                            <Editor
                              value={values?.description || ''}
                              field='description'
                              setFieldValue={setFieldValue}
                            />
                          )}
                        </Field>
                        <div className='text-danger'>
                          <ErrorMessage name='description' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div> </div>
                    <div className='me-2'>
                      <button
                        type='button'
                        className='btn cancel_button fs-7 fw-bolder me-3'
                        onClick={() => onHide()}
                        title='Cancel'
                      >
                        Cancel
                      </button>
                      <button
                        type='submit'
                        className='btn btn-sm btn-blue fs-7'
                        title={id ? 'Update' : 'Create'}
                      >
                        <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}
