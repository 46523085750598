import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/release-notes.scss'

type Props = {
  index: number
  releaseNote: {
    _id: string
    title: string
    version: string
    createdAt: string
    description: string
  }
  deleteReleaseNote: Function
}

export const GridItem: React.FC<Props> = ({index, releaseNote, deleteReleaseNote}) => {
  return (
    <div className='col-12 col-lg-6'>
      <div className='drag-drop-card card mb-5 border-card-grey'>
      <div className='d-flex flex-row border-0 justify-content-between'>
        <div className='card-title' title={releaseNote?.title}>
          {releaseNote?.title}
        </div>
        <Can I='read' this='release-notes'>
          <Link
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
            title='View'
            to={`/release-notes/${releaseNote?._id}`}
          >
            <KTSVG path='/media/icons/view.svg' className='svg-icon-4' />
          </Link>
        </Can>
      </div>
      <div className='d-flex list-box mb-5 flex-column flex-sm-row'>
        {releaseNote?.version && (
          <div
            className='text versionContent mb-5 mb-sm-0'
            title={releaseNote?.version}
            style={{marginRight: '50px'}}
          >
            <span className='fw-bolder pe-2 subheading mb-5'>Version </span>
            {releaseNote?.version}
          </div>
        )}
        {releaseNote?.createdAt && (
          <div
            className='text createdAtContent'
            title={
              releaseNote?.createdAt ? moment(releaseNote?.createdAt).format('MM/DD/YYYY') : ''
            }
          >
            <span className='fw-bolder pe-2 subheading'>Release Date </span>
            {releaseNote?.createdAt ? moment(releaseNote?.createdAt).format('MM/DD/YYYY') : ''}
          </div>
        )}
      </div>
      {releaseNote?.description && (
        <span className='d-flex flex-column align-items-start'>
          <span className='fw-bolder subheading mb-5'>Description</span>
          <div
            title={'Release Notes Descriptions'}
            className='d-flex flex-column description'
            dangerouslySetInnerHTML={{__html: releaseNote?.description}}
          />
        </span>
      )}
    </div>
    </div>
  )
}
