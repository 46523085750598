import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const NOTIFICATION_URL = `${API_URL}/notifications`

export function getNotificationList({
  search,
  page,
  orderby,
  sortby,
  createdAt,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  createdAt: string
}) {
  return axios.get(NOTIFICATION_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      createdAt,
    },
  })
}
export function deleteNotification(_id: string) {
  return axios.delete(`${NOTIFICATION_URL}/${_id}`)
}
export function updateNotificationList() {
  return axios.patch(`${NOTIFICATION_URL}/markAsSeen`)
}

export function updateNotificationAsSeen(_id: string) {
  return axios.patch(`${NOTIFICATION_URL}/markAsSeen/${_id}`)
}
