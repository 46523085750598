import {createSlice} from '@reduxjs/toolkit'

interface entities {
  app: boolean
  email: boolean
  list: boolean
  todo: boolean
  discussion: boolean
  documents: boolean
}

// Define a type for the slice state
interface WorkSpaceNotificationState {
  data?: entities
}

// Define the initial state using that type
const workSpaceNotificationInitialState: WorkSpaceNotificationState = {
  data: {
    app: false,
    email: false,
    list: false,
    todo: false,
    discussion: false,
    documents: false,
  },
}

export const WorkSpaceNotificationSlice = createSlice({
  name: 'WorkSpaceNotifications',
  initialState: workSpaceNotificationInitialState,
  reducers: {
    notificationFetched: (state, action) => {
      state.data = action.payload
    },
  },
})
