import clsx from 'clsx'
import React, {FC} from 'react'
import {getImageURL, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import {RootState} from '../../../../setup'
import {HeaderNotificationsMenu} from '../../../partials/layout/header-menus/HeaderNotificationsMenu'
import '../../../../_metronic/assets/sass/components/top-bar.scss'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-30px',
  toolbarButtonHeightClass = 'w-30px h-30px w-md-40px h-md-40px',
  toolbarButtonIconSizeClass = 'svg-icon-1'

const Topbar: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  const {notificationData}: any = useSelector<RootState>(
    (state) => ({
      notificationData: state.notification.entities,
    }),
    shallowEqual
  ) as Notification

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* NOTIFICATIONS */}
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          {/* begin::Menu- wrapper */}
          <div
            className={clsx(
              'btn btn-icon btn-active-light-primary position-relative',
              toolbarButtonHeightClass
            )}
            title={notificationData?.unSeenCount > 0 ? `${notificationData?.unSeenCount} New ` : ''}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            {/* <div className='display_mobile_none'>
              <KTSVG
                path='/media/icons/duotune/general/icon(4).svg'
                className={toolbarButtonIconSizeClass}
              />
            </div> */}
            <KTSVG
                path='/media/icons/duotune/general/icon(4).svg'
                className={toolbarButtonIconSizeClass}
              />
            {/* <div className='display_desktop_none'>
              <KTSVG
                path='/media/icons/notification-mobile.svg'
                
              />
            </div> */}
            {notificationData?.unSeenCount > 0 && (
              <span className='bullet dot_ui bullet-dot bg-success h-8px w-8px position-absolute translate-middle top-0 start-50 animation-blink'></span>
            )}
          </div>
          <HeaderNotificationsMenu />
          {/* end::Menu wrapper */}
        </div>

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            title='Profile'
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='profileImg'
              src={getImageURL(user?.image?.[0]?.thumbnailUrl, accessToken)}
              alt={user?.fullName}
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
