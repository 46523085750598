import {
  HeightRule,
  ImageRun,
  Paragraph,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx'
import {constants} from './Constants'
import {docxTable, indentSpace, showImage, docxBorder, blob} from './DocxFunctions'

export const skillsTools = (skills: any, templateName: string) => {
  const tableRows: any = []
  for (let i = 0; i < skills.length; i = i + 2) {
    tableRows.push(
      new TableRow({
        height: {value: 400, rule: HeightRule.EXACT},
        children: [
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                indent: indentSpace(),
                children: [
                  new TextRun({
                    text: skills[i]?.name,
                    font: {
                      name: constants.CONTENT_FONT_FAMILY,
                    },
                    size: 20,
                  }),
                ],
              }),
            ],

            columnSpan: 2,
            borders: docxBorder(),
            width: {
              type: WidthType.PERCENTAGE,
              size: 25,
            },
          }),
          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                children: Array(skills[i]?.rating)
                  .fill('')
                  .map(function (x, i) {
                    return new ImageRun({
                      data: blob(
                        templateName === 'nextbridge'
                          ? '/media/talent/next-progress-bar.png'
                          : '/media/talent/vteam-progress-bar.png'
                      ),
                      transformation: {
                        width: 23,
                        height: 13,
                      },
                    })
                  }),
              }),
            ],
            borders: docxBorder(),
            width: {
              type: WidthType.PERCENTAGE,
              size: 25,
            },
          }),

          new TableCell({
            verticalAlign: VerticalAlign.CENTER,
            children: [
              new Paragraph({
                indent: indentSpace(),
                children: [
                  new TextRun({
                    text: skills[i + 1]?.name,
                    size: 20,
                    font: {
                      name: constants.CONTENT_FONT_FAMILY,
                    },
                  }),
                ],
              }),
            ],

            borders: docxBorder(),
            width: {
              type: WidthType.PERCENTAGE,
              size: 25,
            },
          }),
          ...(skills[i + 1]?.name
            ? [
                new TableCell({
                  verticalAlign: VerticalAlign.CENTER,
                  children: [
                    new Paragraph({
                      children: Array(skills[i + 1]?.rating)
                        .fill('')
                        .map(function (x, i) {
                          return new ImageRun({
                            data: blob(
                              templateName === 'nextbridge'
                                ? '/media/talent/next-progress-bar.png'
                                : '/media/talent/vteam-progress-bar.png'
                            ),
                            transformation: {
                              width: 23,
                              height: 13,
                            },
                          })
                        }),
                    }),
                  ],

                  borders: docxBorder(),
                  width: {
                    type: WidthType.PERCENTAGE,
                    size: 25,
                  },
                }),
              ]
            : []),
        ],
      })
    )
  }

  tableRows.unshift(
    new TableRow({
      children: [
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          children: [showImage('/media/talent/docx-setting-iocn.png', 24, 24)],
          width: {
            size: 6,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                line: 400,
              },
              children: [
                new TextRun({
                  text: constants.Titles.SKILLS_TOOLS,
                  color: constants.Colors.NERO,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  size: 24,
                }),
              ],
            }),
          ],
          columnSpan: 3,
          borders: docxBorder(),
        }),
        new TableCell({
          children: [],
          borders: docxBorder(),
        }),
      ],
    })
  )

  return docxTable(tableRows)
}
