import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {Board} from './components/Board'
import {Summary} from './components/Summary'

const BoardPage: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path={['/project/:id/board', '/:workspaceid/project/:id/board']}>
          {({history, match}: {history: any; match: any}) => {
            return <Board />
          }}
        </Route>
        <Route path={['/project/:id/board/summary', '/:workspaceid/project/:id/board/summary']}>
          {({history, match}: {history: any; match: any}) => {
            return <Summary />
          }}
        </Route>
      </Switch>
    </>
  )
}

export {BoardPage}
