import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const PROJECT = `${API_URL}/projects`

export function createProject(name: string, workspace: string, type: string) {
  return axios.post(`${PROJECT}`, {name, workspace, type})
}
export function getAllProjects(
  page: string,
  sortby: string,
  orderby: string,
  search: string,
  workspace: string,
  createdAt: string
) {
  return axios.get(`${PROJECT}`, {
    params: {
      page,
      sortby,
      orderby,
      search,
      workspace,
      createdAt,
    },
  })
}

export function getProjectById(id: string) {
  return axios.get(`${PROJECT}/${id}`)
}

export function updateProjectById(id: string, name: string, workspace: string, type: string) {
  return axios.patch(`${PROJECT}/${id}`, {name, workspace, type})
}

export function deleteProject(id: string) {
  return axios.delete(`${PROJECT}/${id}`)
}
