import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const AVENGERS_INITIATIVES_URL = `${API_URL}/avengers/initiatives`
const WORKSPACE_URL = `${API_URL}/workspace/assignees`

export function getWorkspaceMembers({
  search,
  page,
  sortby,
  orderby,
  currentStatus,
  updateFrequency,
  activity,
  workspace,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  currentStatus: number
  updateFrequency: number
  activity: number
  workspace: string
}) {
  return axios.get(AVENGERS_INITIATIVES_URL, {
    params: {
      search,
      page,
      sortby,
      orderby,
      currentStatus,
      updateFrequency,
      activity,
      workspace,
    },
  })
}
export function WorkspaceMemberAdd(
  title: string,
  description: string,
  currentStatus: number,
  updateFrequency: number,
  activity: number,
  assignee: [],
  whoWillUpdate: [],
  workspace: string
) {
  return axios.post(AVENGERS_INITIATIVES_URL, {
    title,
    description,
    currentStatus,
    updateFrequency,
    activity,
    assignee,
    whoWillUpdate,
    workspace,
  })
}
export function updateWorkspaceMember(
  _id: string,
  title: string,
  description: string,
  currentStatus: number,
  updateFrequency: number,
  activity: number,
  assignee: [],
  whoWillUpdate: [],
  workspace: string
) {
  return axios.patch(`${AVENGERS_INITIATIVES_URL}/${_id}`, {
    _id,
    title,
    description,
    currentStatus,
    updateFrequency,
    activity,
    assignee,
    whoWillUpdate,
    workspace,
  })
}

export function removeWorkspaceMember(_id: string) {
  return axios.delete(`${AVENGERS_INITIATIVES_URL}/${_id}`)
}

export function fetchAssignees({workspaceid}: {workspaceid: any}) {
  return axios.get(`${WORKSPACE_URL}/${workspaceid}`)
}

export function fetchWorkspaceMember(_id: string, workspaceid: string) {
  return axios.get(`${AVENGERS_INITIATIVES_URL}/${_id}/${workspaceid}`)
}
