/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as actions from '../redux/ApprovalsActions'
import {RootState} from '../../../../setup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'

interface ICreateAccount {
  title: string
  description: string
  assignee: any
}

const inits: ICreateAccount = {
  title: '',
  description: '',
  assignee: [],
}
interface Option {
  id: string
  label: string
  value: string
}

interface Props {
  onHide: () => void
  id?: string | null
}

const ApprovalEdit: FC<Props> = ({onHide, id}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const {workspaceid}: {workspaceid: string} = useParams()
  const LIST_USERS = `${API_URL}/approvals/assignees`
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const dispatch = useDispatch<any>()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [response, setResponse] = useState<boolean>(false)
  const [toggleMember, setToggleMember] = useState<boolean>(false)

  const {spaceDetail, error, userInfo}: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.approvals.approvalsDetail,
      error: state.approvals.error,
      userInfo: state.auth.user,
    }),
    shallowEqual
  )

  const [assigneesOptions, setAssigneesOptions] = useState([])
  const [selectedFeatures, setSelectedFeatures] = useState<String[]>([])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const {spaceData}: any = useSelector<RootState>(
    (state) => ({
      spaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  const fetchAssigneesData = async () => {
    let assigneeOptions: any = []
    const usersData = await axios.get(`${LIST_USERS}/${workspaceid}`)

    usersData?.data?.data[0]?.assignee?.map((userData: any) => {
      assigneeOptions.push({
        value: userData._id,
        label: userInfo._id === userData._id ? userData.fullName + ' ' + '(Me)' : userData.fullName,
        id: userData._id,
      })
    })
    setAssigneesOptions(assigneeOptions)
  }

  const animatedComponents = makeAnimated()
  useEffect(() => {
    if (spaceDetail) {
      setToggleMember(spaceDetail?.spaceType === 1)
      setInitValues({
        title: spaceDetail?.title,
        description: spaceDetail?.description,
        assignee: spaceDetail?.assignee?.map((assignee: any) => {
          return {
            value: assignee._id,
            label:
              userInfo._id === assignee._id ? assignee.fullName + ' ' + '(Me)' : assignee.fullName,
            id: assignee._id,
          }
        }),
      })
    }
  }, [spaceDetail])

  useEffect(() => {
    fetchAssigneesData()
    setSelectedFeatures([])
    if (id) {
      dispatch(actions.fetchApproval(id))
    }
    dispatch(actions.removeErrorMessage(''))
  }, [dispatch])

  const createUpdateApprovalsSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .label('title')
      .min(1, 'Title must contain more than 3 characters'),
    assignee: Yup.array().required('Assignee is required'),
    description: Yup.string().required('Description is required'),
  })

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const handleChange = (value: any, setFieldValue: any) => {
    setFieldValue('assignee', value)
  }
  let crumbs
  if (spaceData) {
    crumbs = [
      {name: 'Dashboard', url: '/dashboard'},
      {name: 'Spaces', url: '/spaces'},
      {name: `${spaceData?.name}`, url: `/${spaceData?._id}/features`},
      {name: 'Approvals', url: `/${spaceData?._id}/approvals`},
      {name: id ? 'Edit Approval' : 'Create Approval', url: `/${spaceData?._id}/approvals`},
    ]
  }
  return (
    <div className='modal-content'>
      <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 pt-5' />
      <div className='modal-header'>
        <h2>{id ? 'Edit Approval' : 'Create Approval'}</h2>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            <Formik
              validationSchema={createUpdateApprovalsSchema}
              initialValues={id ? initValues : inits}
              enableReinitialize
              onSubmit={(values: any) => {
                values.assignee = values.assignee.map((assign: any) => {
                  return assign.id
                })

                if (id) {
                  values._id = id
                  values.workSpace = workspaceid
                  setResponse(true)
                  dispatch(actions.updateApproval(values))
                    .then((response: any) => {
                      onHide()
                    })
                    .catch((error: string) => {
                      setResponse(false)
                    })
                } else {
                  setResponse(true)
                  values.workSpace = workspaceid
                  dispatch(actions.createApproval(values))
                    .then((response: any) => {
                      onHide()
                    })
                    .catch((error: string) => {
                      setResponse(false)
                    })
                }
              }}
            >
              {({values, setFieldValue}) => (
                <Form className='form' noValidate id='kt_modal_create_app_form'>
                  <div className='current' data-kt-stepper-element='content'>
                    <div className='w-100'>
                      {error && (
                        <div className='mb-lg-15 alert alert-danger'>
                          <div className='alert-text font-weight-bold'>{error}</div>
                        </div>
                      )}
                      <div className='fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Title</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='title'
                          placeholder='Title'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='title' />
                        </div>
                      </div>
                      <div className='row fv-row'>
                        <div className='col-12 multisel'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Assignee</span>
                          </label>
                          <Select
                            options={assigneesOptions}
                            placeholder='Select'
                            name='assignee'
                            className='multi-select-container'
                            classNamePrefix='multi-select'
                            onChange={(e: any) => {
                              handleChange(e, setFieldValue)
                            }}
                            value={values.assignee}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                          />

                          <div className='text-danger'>
                            <ErrorMessage name='assignee' />
                          </div>
                        </div>
                      </div>

                      <div className='fv-row mb-5'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Description</span>
                        </label>

                        <Field
                          className='form-control form-control-lg form-control-solid'
                          name='description'
                          placeholder='Add description'
                          as='textarea'
                          rows='5'
                        />

                        <div className='text-danger'>
                          <ErrorMessage name='description' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-stack pt-10'>
                    <div> </div>
                    <div className='me-2'>
                      <button
                        type='button'
                        className='btn cancel_button fs-7 fw-bolder  me-3'
                        onClick={onHide}
                        title='Cancel'
                      >
                        Cancel
                      </button>
                      <button
                        disabled={response}
                        type='submit'
                        className='btn btn-sm btn-blue fs-7'
                        title='Submit'
                      >
                        <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ApprovalEdit}
