import {FC, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {ProjectData} from './ProjectData'
import * as projectActions from '../redux/ProjectActions'
import {Pagination} from '../../../pagination/pagination'
import {Loading} from '../../../../_metronic/layout/Loading'

type Props = {}

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}
const Project: FC<Props> = () => {
  let crumbs
  const dispatch: any = useDispatch()
  const {workspaceid}: {workspaceid: string} = useParams()
  const {currentSpace, loading}: any = useSelector<RootState>(
    (state) => ({
      state,
      currentSpace: state.project.currentSpace,

      loading: state.project.loading,
    }),
    shallowEqual
  )

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.project,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (workspaceid) {
      dispatch(projectActions.fetchWorkspace(workspaceid))
    }

    dispatch(projectActions.getAllProjects('', '', '', '', workspaceid ? workspaceid : '', ''))
  }, [])
  // crumbs = workspaceid
  //   ? [
  //       {name: 'Spaces', url: '/dashboard'},
  //       {name: `${currentSpace?.name}`, url: `/${currentSpace?._id}/features`},
  //       {name: 'Project', url: `/${currentSpace?._id}`},
  //     ]
  //   : [
  //       {name: 'Spaces', url: '/dashboard'},
  //       {name: 'Project', url: `/project`},
  //     ]

  const handlePageClick = (event: any) => {
    dispatch(
      projectActions.getAllProjects(
        event.selected + 1,
        '',
        '',
        '',
        workspaceid ? workspaceid : '',
        ''
      )
    )
  }

  if (loading) {
    return <Loading />
  }
  return (
    <>
      {/* {crumbs && <Breadcrumbs crumbs={crumbs} />} */}
      <div className='mh-80 tabs-main card legend-card-main rounded-bottom rounded-0'>
        <ProjectData />
        <Pagination paginationData={paginationData} fetchData={handlePageClick} />
      </div>
    </>
  )
}

export {Project}
