export const convertTemplateToPlainText = (
  temp: string,
  from: string,
  fromId: string,
  workSpace: string,
  list: string,
  todo: string,
  opportunityWatch: string,
  avengersInitiative: string,
  discussion: string,
  auth: any
) => {
  let splits = temp.split(' ')

  if (splits.includes('{from}')) {
    temp = temp.replaceAll(
      '{from}',
      auth?._id === fromId
        ? `<strong>${'You'}</strong>`
        : `<strong>${from?.charAt(0)?.toUpperCase() + from?.slice(1)}</strong>`
    )
  }
  if (splits.includes('{internal-initiatives}')) {
    temp = temp.replaceAll('{internal-initiatives}', `<strong>${avengersInitiative}</strong>`)
  }
  if (splits.includes('you')) {
    temp = temp.replaceAll('you', auth?._id === fromId ? 'yourself' : 'you')
  }
  if (splits.includes('{updater}')) {
    temp = temp.replaceAll('{updater}', 'as a Updater')
  }
  if (splits.includes('{assignee}')) {
    temp = temp.replaceAll('{assignee}', 'as a assignee')
  }
  if (splits.includes('{workSpace}')) {
    temp = temp.replaceAll('{workSpace}', `<strong>${workSpace}</strong>`)
  }
  if (splits.includes('{member}')) {
    temp = temp.replaceAll('{member}', 'as a member')
  }
  if (splits.includes('{maintainer}')) {
    temp = temp.replaceAll('{maintainer}', 'as a maintainer')
  }
  if (splits.includes('{teamMember}')) {
    temp = temp.replaceAll('{teamMember}', 'as a team member')
  }
  if (splits.includes('{list}')) {
    temp = temp.replaceAll('{list}', `<strong>${list}</strong>`)
  }
  if (splits.includes('{todo}')) {
    temp = temp.replaceAll('{todo}', `<strong>${todo}</strong>`)
  }
  if (splits.includes('{opportunityWatch}')) {
    temp = temp.replaceAll('{opportunityWatch}', `<strong>${opportunityWatch}</strong>`)
  }
  if (splits.includes('{Chat}')) {
    temp = temp.replaceAll('{Chat}', `<strong>${discussion} discussion</strong>`)
  }
  if (splits.includes('{added}')) {
    temp = temp.replaceAll('{added}', `Members to <strong>${discussion} discussion</strong>`)
  }

  return `<span>${temp}</span>`
}

export const makeRouter = (itemType: Array<any>, item: any) => {
  if (itemType.includes('AvengersInitiative')) return '/internal-initiatives'
  else if (itemType.includes('OpportunityWatch')) return '/opportunity-watch'
  else if (itemType.includes('Todo')) {
    return `/${item?.workSpace?._id}/todo/${item?.todo?._id}`
  } else if (itemType.includes('List')) {
    return `/${item?.workSpace?._id}/list/${item?.list?._id}`
  } else if (itemType.includes('Chat') && itemType.includes('Workspace')) {
    return `/${item?.workSpace?._id}/discussion#${item?.discussion?._id}`
  } else if (itemType.includes('Chat')) {
    return `/discussion#${item?.discussion?._id}`
  } else if (itemType.includes('Workspace')) {
    return `/${item?.workSpace?._id}/features`
  }
}

export const replaceNotificationType = (type: string) => {
  // const key =
  //   type.includes('Workspace') && type.includes('Chat') ? type[type.length - 1] : 'AppChat'
  const typeOfNotifications: any = {
    Workspace: `Space`,
    AvengersInitiative: `Internal Initiatives`,
    OpportunityWatch: `Opportunity Watch`,
    Chat: `Space Discussion `,
    AppChat: `Discussion `,
  }

  return typeOfNotifications[type] ?? type
}
