import {
  AlignmentType,
  ExternalHyperlink,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx'
import {constants} from './Constants'
import {docxTable, indentSpace, showImage, docxBorder, addLineBreak} from './DocxFunctions'

export const projectHeader = () => {
  return docxTable(
    [
      new TableRow({
        children: [
          new TableCell({
            children: [showImage('/media/talent/project-summary-icon.png', 22, 22)],
            width: {
              size: 6,
              type: WidthType.PERCENTAGE,
            },
            borders: docxBorder(),
          }),

          new TableCell({
            children: [
              new Paragraph({
                indent: indentSpace(),
                spacing: {
                  line: 400,
                },
                children: [
                  new TextRun({
                    text: constants.Titles.PROJECTS_SUMMARY,
                    size: 24,
                    font: {
                      name: constants.CONTENT_FONT_FAMILY,
                    },
                  }),
                ],
              }),
            ],
            borders: docxBorder(),
            columnSpan: 2,
          }),

          new TableCell({
            children: [],
            borders: docxBorder(constants.Colors.GRAY_BORDER, 0, 10, 0, 0),
          }),
        ],
      }),
    ],
    constants.Colors.GRAY_BORDER,
    0,
    10,
    0,
    0
  )
}

export const projects = (project: any) => {
  const tableRows: any = []

  tableRows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              alignment: AlignmentType.RIGHT,
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              children: [
                new TextRun({
                  text: project.name,
                  bold: true,
                  size: 20,
                  color: constants.Colors.BUNKER,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 30,
            type: WidthType.PERCENTAGE,
          },
          columnSpan: 2,
          borders: docxBorder(),
        }),

        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  children: addLineBreak(project.description.split('\n')),
                }),
              ],
            }),
          ],
          width: {
            size: 70,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              children: [
                new TextRun({
                  text: constants.Titles.TOOLS_TECHNOLOGIES,
                  color: constants.Colors.BUNKER,
                  font: {name: constants.CONTENT_FONT_FAMILY},
                  size: 20,
                }),
              ],
              alignment: AlignmentType.RIGHT,
            }),
          ],
          width: {
            size: 30,
            type: WidthType.PERCENTAGE,
          },
          columnSpan: 2,
          borders: docxBorder(),
        }),

        new TableCell({
          children: [
            new Paragraph({
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              indent: indentSpace(),
              alignment: AlignmentType.JUSTIFIED,
              children: [
                new TextRun({
                  text: project.technologies,
                  color: constants.Colors.BUNKER,
                  font: {name: constants.CONTENT_FONT_FAMILY},
                  size: 20,
                }),
              ],
            }),
          ],
          width: {
            size: 70,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              alignment: AlignmentType.RIGHT,
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              children: [
                new TextRun({
                  text: constants.Titles.ROLE_AND_RESPONSIBILITIES,
                  size: 20,
                  color: constants.Colors.BUNKER,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 30,
            type: WidthType.PERCENTAGE,
          },
          columnSpan: 2,
          borders: docxBorder(),
        }),

        new TableCell({
          children: [
            new Paragraph({
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              indent: indentSpace(),
              alignment: AlignmentType.JUSTIFIED,
              children: [
                new TextRun({
                  text: project.responsibility,
                  size: 20,
                  color: constants.Colors.BUNKER,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 70,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              indent: indentSpace(),
              children: [
                new TextRun({
                  text: constants.Titles.URL,
                  size: 20,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  color: constants.Colors.BUNKER,
                }),
              ],
              alignment: AlignmentType.RIGHT,
            }),
          ],
          width: {
            size: 30,
            type: WidthType.PERCENTAGE,
          },
          columnSpan: 2,
          borders: docxBorder(),
        }),

        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                after: 50,
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              alignment: AlignmentType.JUSTIFIED,
              children: [
                new ExternalHyperlink({
                  children: [
                    new TextRun({
                      text: `${project.url}`,
                      style: 'Hyperlink',
                      size: 20,
                      font: {
                        name: constants.CONTENT_FONT_FAMILY,
                      },
                    }),
                  ],
                  link: `${project.url}\n`,
                }),
              ],
            }),
          ],
          width: {
            size: 70,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(constants.Colors.GRAY_BORDER, 0, 10, 0, 0),
        }),
      ],
    })
  )

  return [
    new Table({
      rows: tableRows,
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: docxBorder(constants.Colors.GRAY_BORDER, 0, 10, 0, 0),
    }),
    new Paragraph({
      text: '',
      spacing: {
        after: 70,
      },
    }),
  ]
}
