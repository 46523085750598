import React, {useState} from 'react'
import {TalentTable} from './components/TalentTable'
import {TalentEdit} from './components/TalentEdit'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import Ability from '../../Common/RBAC/Ability'
import TalentSuccess from './components/Success'
import {projects} from './components/filesDownload/docx/partials/Projects'

const Talent: React.FC = () => {
  const [newTalentId, setNewTalentId] = useState(null)
  const history: any = useHistory()
  const query = new URLSearchParams(history?.location?.search)
  const widgetId = query.get('widget_id') || ''

  return (
    <>
      <Switch>
        {Ability.can('list', 'talent') && (
          <Route exact path='/talent'>
            <TalentTable className='mb-5 mb-xl-8' />
          </Route>
        )}
        <Route exact path='/talent/success'>
          <TalentSuccess></TalentSuccess>
        </Route>

        <Route exact path='/talent/add'>
          {({history, match}) => {
            return (
              <TalentEdit
                onHide={() => {
                  history.push(`/talent`)
                }}
              />
            )
          }}
        </Route>

        <Route exact path={['/talent/:id/edit', '/talent/:id']}>
          {({history, match}) => (
            <>
              <TalentEdit
                id={match && match.params.id}
                onHide={() => {
                  history.push({
                    pathname: widgetId ? `/talent/success` : `/talent`,
                    state: {success: true, id: match?.params?.id},
                    search: history.location.search,
                  })
                }}
              />
            </>
          )}
        </Route>
        <Redirect to='/dashboard' />
      </Switch>
    </>
  )
}

export {Talent}
