import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  name: string
  members: string[]
}

// Define a type for the slice state
interface TeamsState {
  teamDetail?: entities
  totalCount?: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
}

// Define the initial state using that type
const initialState: TeamsState = {
  totalCount: 0,
  entities: [{_id: '', name: '', members: []}],
  start: 1,
  end: 10,
  limitPerPage: 10,
}

export const teamsSlice = createSlice({
  name: 'teams',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    teamsFetched: (state, action: PayloadAction<TeamsState>) => {
      const {totalCount, entities, start, end} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    teamsCreated: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.entities.push(action.payload.entities)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    teamsUpdate: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    teamFetched: (state, action) => {
      const {data} = action.payload
      state.teamDetail = data
    },
  },
})
