import {FC, useRef, useEffect, useState, useContext} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {updateAbility} from '../../../Common/RBAC/Ability'
import {AbilityContext} from '../../../Common/RBAC/Can'
import {useHistory} from 'react-router-dom'

const mapState = (state: RootState) => ({auth: state.auth})
const connector = connect(mapState, auth.actions)
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux> = (props) => {
  const didRequest = useRef(false)
  const ability = useContext(AbilityContext)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const user:any = useSelector<RootState>((state) => state.auth.user, shallowEqual)
  const history = useHistory()
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current && !window.location.search.includes('widget')) {
          await getUserByToken().then((settings:any)=>{
            dispatch(auth.actions.fulfillUser({...user, settings}))
          }).catch((err) => {
            if (err.response.status === 403 && err.response.statusText === 'Forbidden') {
              window.location.href = process.env.REACT_APP_ADMIN_URL + '/admin/login'
            } else {
              history.push('/login')
            }
          })
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (accessToken) {
      requestUser()
      updateAbility(ability, user)
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
