import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {OrganizationState} from '../organizationModel'

// Define the initial state using that type
const initialState: OrganizationState = {
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
  actionsLoading: false,
  listLoading: true,
  chartsLoading: true,
  organizationDetail: {
    _id: '',
    name: '',
    descriptions: '',
    slug: '',
    default: false,
    createdBy: {
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
      fullName: '',
      id: '',
    },
    createdAt: '',
    updatedAt: '',
  },
  chartDetail: {},
  savedRolesDetails: {},
  allUsers: [],
  allUserRoles: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const organizationSlice = createSlice({
  name: 'organization',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    startChartCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.chartsLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    organizationsFetched: (state, action: PayloadAction<OrganizationState>) => {
      const {totalCount, entities, start, end} = action.payload
      state.chartsLoading = true
      state.listLoading = false
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    organizationChartsFetched: (state, action: PayloadAction<OrganizationState>) => {
      const {totalCount, entities, start, end} = action.payload
      state.chartsLoading = false
      state.listLoading = true
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    organizationFetched: (state, action) => {
      const {entities} = action.payload
      state.organizationDetail = entities
    },
    chartFetched: (state, action) => {
      const {entities} = action.payload
      state.chartDetail = entities
      state.listLoading = false
    },
    chartSaved: (state, action) => {
      const {data} = action.payload
      state.savedRolesDetails = data
    },
    usersFetched: (state, action) => {
      const {data} = action.payload
      state.allUsers = data
    },
    usersRolesFetched: (state, action) => {
      const {data} = action.payload
      state.allUserRoles = data
    },
  },
})
