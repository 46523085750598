import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const SETTINGS_URL = `${API_URL}/settings`

export function updateSettings(
  name: string,
  value: string
) {
  return axios.patch(`${SETTINGS_URL}/updateSettings`, {
    name,
    value
  })
}

export function getSettings({
  search,
  page,
  orderby,
  sortby,
  createdAt,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  createdAt: string
}) {
  return axios.get(SETTINGS_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      createdAt,
    },
  })
}
