import React, {lazy, Suspense} from 'react'
// import {TaskTable} from './components/TaskTable'
import {Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../../_metronic/partials'
import {TaskAdd} from '../Task/components/TaskAdd'
import {TaskDataHeader} from './components/TaskDataHeader'
import {Activity} from '../generalActivityLogs/components/Activity'

const TaskTable = lazy(() => import('./components/TaskTable'))

const Task: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/:workspaceid/project/:projectid/task'>
        <Suspense fallback={<FallbackView />}>
          <TaskTable className='mb-5 mb-xl-8' />
        </Suspense>
      </Route>
      <Route exact path='/:workspaceid/project/:projectid/task/add'>
        {({history, match}) => {
          return (
            <TaskAdd
              onHide={() => {
                history.push(`/${match?.params?.workspaceid}/project/${match?.params?.projectid}/task`)
              }}
            />
          )
        }}
      </Route>
      <Route exact path='/:workspaceid/project/:projectid/task/:id/edit'>
        {({history, match}) => (
          <TaskAdd
            id={match && match.params.id}
            onHide={() => {
              history.push(`/${match?.params?.workspaceid}/project/${match?.params?.projectid}/task`)
            }}
            itemType='Task'
          />
        )}
      </Route>
      <Route exact path='/:workspaceid/project/:projectid/task/:id'>
        <TaskDataHeader />
      </Route>
    </Switch>
  )
}

export default Task
