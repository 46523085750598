import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {ProjectActions} from './ProjectActions'
import * as projectActions from '../redux/ProjectActions'
import moment from 'moment'
import {Link, useParams} from 'react-router-dom'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Templates} from './ProjectTemplates'

const ProjectList: FC = () => {
  const {workspaceid}: {workspaceid: string} = useParams()
  const dispatch: any = useDispatch()
  const {allProjects, loading, loginUser}: any = useSelector<RootState>(
    (state) => ({
      state,
      allProjects: state.project.allProjects,
      loading: state.project.loading,
      loginUser: state.auth.user,
    }),
    shallowEqual
  )

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      dispatch(
        projectActions.getAllProjects(
          '',
          event.target.abbr,
          'asc',
          '',
          workspaceid ? workspaceid : '',
          ''
        )
      )

      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      dispatch(
        projectActions.getAllProjects(
          '',
          event.target.abbr,
          'desc',
          '',
          workspaceid ? workspaceid : '',
          ''
        )
      )
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  if (loading) {
    return <Loading />
  }
  return (
    <>
      {allProjects.length > 0 && !loading ? (
        <table className='project-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          <thead>
            <tr className='fw-bolder text-muted'>
              <th
                className=''
                style={{cursor: 'pointer', minWidth: '230px'}}
                onClick={sortColumn}
                abbr='name'
                id='project-name-head'
              >
                Title
              </th>

              <th className='' style={{cursor: 'pointer', minWidth: '230px'}}>
                Template
              </th>
              <th
                style={{cursor: 'pointer', minWidth: '180px'}}
                abbr='currentStatus'
                id='project-currentStatus-head'
              >
                Created By
              </th>
              <th
                className='min-w-120px'
                onClick={sortColumn}
                abbr='createdAt'
                id='project-createdAt-head'
              >
                Created Date
              </th>

              <th
                className=''
                style={{cursor: 'pointer', minWidth: '130px'}}
                onClick={sortColumn}
                abbr='key'
                id='project-key-head'
              >
                Key
              </th>

              <th style={{minWidth: '130px'}} className='min-w-100px text-start'>
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {allProjects?.map((list: any, i: number) => {
              return (
                <tr key={i}>
                  <td data-label='Title'>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <span 
                          className='projectTitle'
                            title='acac'
                            style={{
                              display: 'block',
                              width: '230px',
                              minWidth: '230px',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <Link
                              to={
                                workspaceid
                                  ? `/${workspaceid}/project/${list._id}/board`
                                  : `/project/${list._id}/board`
                              }
                              className=''
                              title={list?.name}
                            >
                              {list?.name}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td data-label='Template'>
                    <div className='align-items-center' title='Ahmad,ali'>
                      {Templates.map((temp: any) => {
                        if (temp.key === list?.type) {
                          return temp.name
                        }
                      })}
                    </div>
                  </td>
                  <td data-label='Created By' title='Running'>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        {list?.createdBy?.fullName}
                      </div>
                    </div>
                  </td>
                  <td data-label='Created Date'>
                    <div className='align-items-center' title='Ahmad,ali'>
                      {moment(list?.createdAt).format('MM/DD/YYYY')}
                    </div>
                  </td>
                  <td data-label='key' title='-'>
                    <div className='align-items-center projectTitle'>{list?.key}</div>
                  </td>

                  <td data-label='Actions'>
                    {(list?.workspace?.maintainers?.includes(loginUser?._id) ||
                      list?.workspace?.owner === loginUser?._id ||
                      list?.createdBy?._id === loginUser?._id) && (
                      <ProjectActions _id={list?._id} />
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <NotFound />
      )}
    </>
  )
}

export {ProjectList}
