import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import * as actions from '../redux/organizationActions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {Pagination} from '../../../pagination/pagination'
import {Link, useParams} from 'react-router-dom'
import {Search} from '../../../../_metronic/layout/search/Search'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {ChartDelete} from './chartDelete'

type Props = {
  onHide: () => void
}

const ChartTable: React.FC<Props> = ({onHide}) => {
  const dispatch = useDispatch()

  const [showDialogBox, setShowDialogBox] = useState(false)
  const [chartsList, setChartsList] = useState<any>([])
  const [chartId, setChartId] = useState('')
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: 'createdAt',
  })

  const {listLoading, organizationCharts, paginationData, organizationDetail}: any =
    useSelector<RootState>(
      (state) => ({
        listLoading: state.organization.chartsLoading,
        organizationCharts: state.organization.entities,
        paginationData: state.organization,
        organizationDetail: state.organization.organizationDetail,
      }),
      shallowEqual
    ) as any

  useEffect(() => {
    if (organizationCharts?.length > 0) {
      setChartsList(organizationCharts)
    } else {
      setChartsList([])
    }
  }, [organizationCharts])

  const {id}: {id: string} = useParams()

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchOrganization({id}))
    }
  }, [id])

  const deleteOrganization = (id: string) => async (event: any) => {
    setChartId(id)
    setShowDialogBox(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`chart-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && chartsList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && chartsList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }

    dispatch(
      actions.fetchUserCharts({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
      })
    )
  }, [sortValues])

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchUserCharts({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
      })
    )
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchUserCharts({
        search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
      })
    )
  }

  let crumbs

  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    // {name: 'Organizations', url: '/organization'},
    // {name: `${organizationDetail?.name}`, url: '/organization'},
    {name: `Groups`, url: ``},
  ]

  return (
    <>
      <ChartDelete
        show={showDialogBox}
        handleClose={() => {
          setShowDialogBox(false)
        }}
        id={chartId}
        companyId={id}
      />
      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
        {!listLoading && (
          <div className='px-6 px-sm-12'>
            <div className='row'>
              <div className='col-sm-6'>
                <h2 className='fw-500 fs-2 m-0 py-5'>{`${organizationDetail?.name} Groups`}</h2>
              </div>
              <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
                <Search handleSearch={handleSearch} />
              </div>
            </div>
          </div>
        )}
        <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          <Link className='bg-lighter me-10' to={`/groups/add`}>
            <span
              className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
              style={{whiteSpace: 'nowrap'}}
            >
              <KTSVG path='' className='svg-icon-3' />
              <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
              <span className='link_button'> Create Group</span>
            </span>
          </Link>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <>
          <div className='overflow_height_scroll'>
            <div className='card-body d-flex flex-column py-3 px-10'>
              {/* begin::Table container */}

              {!listLoading && chartsList?.length > 0 ? (
                <div className='table-responsive'>
                  {/* begin::Table */}

                  <table className='chart-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th
                          className=''
                          style={{cursor: 'pointer', minWidth: '230px'}}
                          onClick={sortColumn}
                          abbr='name'
                          id='chart-name-head'
                        >
                          Name
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='organization'
                          id='chart-organization-head'
                        >
                          Organization
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='descriptions'
                          id='chart-descriptions-head'
                        >
                          Created By
                        </th>
                        <th
                          className='min-w-120px table-sort-desc'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='createdAt'
                          id='chart-createdAt-head'
                        >
                          Created Date
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer', minWidth: '125px'}}
                          onClick={sortColumn}
                          abbr='updatedAt'
                          id='chart-updatedAt-head'
                        >
                          Updated Date
                        </th>

                        <th className='min-w-100px'>Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {chartsList.map((chart: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td data-label='name' title={chart?.name}>
                              <Link to={`/groups/${chart?._id}/hierarchy`}>
                                <span
                                  title={chart?.name}
                                  style={{
                                    display: 'block',
                                    width: '230px',
                                    minWidth: '230px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {chart?.name}
                                </span>
                              </Link>
                            </td>

                            <td data-label='company' title={chart?.company?.name}>
                              <div
                                style={{
                                  // width: '230px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                                className='d-flex align-items-center'
                              >
                                <div className='d-flex justify-content-start flex-column'>
                                  {chart?.company?.name}
                                </div>
                              </div>
                            </td>
                            <td
                              data-label='descriptions'
                              title={chart.createdBy.firstName + ' ' + chart.createdBy.lastName}
                            >
                              <div
                                style={{
                                  // width: '230px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                                className='d-flex align-items-center'
                              >
                                <div className='d-flex justify-content-start flex-column'>
                                  {chart.createdBy.firstName + ' ' + chart.createdBy.lastName}
                                </div>
                              </div>
                            </td>
                            <td
                              data-label='createdDate'
                              title={
                                chart?.createdAt
                                  ? moment(chart.createdAt).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {chart?.createdAt ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(chart.createdAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center '>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td
                              data-label='updateDate'
                              title={
                                chart.createdAt ? moment(chart.createdAt).format('MM/DD/YYYY') : '-'
                              }
                            >
                              {chart?.updatedAt ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(chart.updatedAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td data-label='Actions' className='action_btns'>
                              <div className='flex-shrink-0'>
                                <Link
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                  title='View'
                                  to={`/groups/${chart?._id}/hierarchy`}
                                >
                                  <KTSVG path='/media/icons/view.svg' className='svg-icon-4' />
                                </Link>
                                <Link
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                  to={`/groups/${chart._id}/edit`}
                                  title='Edit'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-3'
                                  />
                                </Link>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_delete_app'
                                  onClick={deleteOrganization(chart._id)}
                                  title='Delete'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : !listLoading && chartsList?.length <= 0 ? (
                <NotFound />
              ) : (
                <Loading />
              )}

              {/* end::Table container */}
            </div>
          </div>

          <Pagination paginationData={paginationData} fetchData={handlePagination} />
        </>
      </div>
    </>
  )
}

export {ChartTable}
