/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import '../../../_metronic/assets/sass/components/cards.scss'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import {SpacesDropdown} from './SpacesDropdown'

const DashboardWrapper8: FC = () => {
  return (
    <>
      <section className='tabs-main mb-5 pb-5'>
        <div className='container px-0'>
          <div className='card card-custom'>
            <div className='card-body p-0'>
              <div className='tab-content'>
                <div className='tab-pane tab-pane2 pb-5 active rounded'>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4'>
                    <div className='col d-flex justify-content-center mt-0 px-0'>
                      <div className='card-toolbar'>
                        <button
                          type='button'
                          className='btn bg-primary text-white position-relative mt-10 ms-14'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-start'
                          data-kt-menu-flip='top-start'
                        >
                          All Spaces
                        </button>
                        <div
                          className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                          data-kt-menu='true'
                          style={{maxHeight: '345px', overflow: 'auto'}}
                        >
                          <div className='px-7 py-5'>
                            <div className='fs-5 text-dark fw-bolder'>All Spaces</div>
                          </div>
                          <div className='separator border-gray-200'></div>
                          <SpacesDropdown />
                        </div>
                      </div>
                    </div>
                    <div className='col-6 d-flex justify-content-end px-0'>
                      <div
                        className='btn bg-primary text-white position-relative mt-10 me-20'
                        id='kt_drawer_chat_toggle'
                      >
                        All Spaces
                      </div>
                    </div>
                  </div>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4'>
                    <div className='col-12 w-100 row mx-auto p-0'>
                      <div className='col-md-6 d-flex justify-content-start px-0'>
                        <div className='card-toolbar'>
                          {/* begin::Menu */}
                          <button
                            type='button'
                            className='btn bg-primary text-white position-relative mt-10 ms-sm-20 ms-20'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-start'
                            data-kt-menu-flip='top-start'
                          >
                            Spaces
                          </button>
                          <div
                            className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                            data-kt-menu='true'
                            style={{maxHeight: '345px', overflow: 'auto'}}
                          >
                            <SpacesDropdown />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-6 d-flex justify-content-end px-0">
                        <div className='btn bg-primary text-white position-relative mt-10 me-20' id='kt_drawer_chat_toggle'>
                          All Spaces
                        </div>
                      </div> */}
                    </div>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 2'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 2</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 3'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 3</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 4'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 4</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 5'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 5</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 6'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 6</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 7'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 7</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 8'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 8</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 9'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 9</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 10'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 10</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 11'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 11</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 12'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 12</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        id='kt_drawer_chat'
        className='bg-white'
        data-kt-drawer='true'
        data-kt-drawer-name='chat'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'300px', 'md': '400px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_drawer_chat_toggle'
        data-kt-drawer-close='#kt_drawer_chat_close'
      >
        <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
          <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
            <div className='card-title'>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 lh-1'>
                  All Spaces
                </a>
              </div>
            </div>
          </div>
          <SpacesDropdown />
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper8}
