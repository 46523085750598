import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  name: string
  value: string
}

interface SettingsState {
  listLoading: boolean
  totalCount?: number
  SettingsDetail?: entities
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  error?: any
}

const initialState: SettingsState = {
  listLoading: true,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
}

export const SettingsSlice = createSlice({
  name: 'Settings',
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<SettingsState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state) => {
      state.listLoading = true
    },
    SettingsFetched: (state, action: PayloadAction<SettingsState>) => {
      state.listLoading = false
      const {totalCount, entities, start, end} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    SettingUpdate: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity.name === entities.name) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
  },
})
