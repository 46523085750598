/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useLayoutEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Breadcrumbs } from '../../../../pages/Breadcrumbs'
import * as actions from './redux/NotificationActions'
import { workSpaceNotifications } from '../../../../Common/NotificationJsonSetting'
import { RootState } from '../../../../../setup'
import * as workspaceActions from '../../redux/WorkspaceActions'
import { Notifications } from '../../../../Common/notification/Notifications'

type Props = {
  className: string
}
const NotificationSettings: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch()
  const { workspaceid }: { workspaceid: any } = useParams()
  const settings: any = useSelector<RootState>(
    (state) => state.workSpaceNotifications,
    shallowEqual
  )

  const { workSpaceData }: any = useSelector<any>(
    (state) => ({
      workSpaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(workspaceActions.fetchWorkspace(workspaceid))
  }, [])

  let crumbs
  if (workSpaceData) {
    crumbs = [
      { name: 'Spaces', url: '/dashboard' },
      // {name: 'Spaces', url: '/spaces'},
      {
        name: `${workSpaceData?.name}`,
        url: `/${workspaceid}/features`,
      },
      { name: 'Notifications', url: '' },
    ]
  }

  return (
    <>
      <div className={`mh-80 tabs-main card legend-card-main ${className}`}>
        <div className='px-6 px-sm-12'>
          <div className="row">
            <div className="col-sm-6">
              <h2 className='fw-500 fs-2 m-0 py-5'>
                {workSpaceNotifications.heading}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className='mx-12 pt-5 pb-3 d-flex flex-row justify-content-between align-items-center'>
          <h3 className='card-title align-items-start flex-column '>
            <span className='card-label fw-500 fs-2 mb-1'>{workSpaceNotifications.heading}</span>
          </h3>
        </div> */}
        {crumbs && <Breadcrumbs crumbs={crumbs} />}
        <div className="overflow_height_scroll">

          <Notifications
            notificationsSettings={workSpaceNotifications}
            actions={actions}
            actionsID={workspaceid}
            settings={settings}
          />
        </div>
      </div>
    </>
  )
}

export { NotificationSettings }
