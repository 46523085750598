import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {TaskStatuses} from '../../Task/TaskUIHelper'
import moment from 'moment'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import _ from 'lodash'

import * as activityActions from '../../generalActivityLogs/redux/activityLogsAction'
import * as actions from '../../Task/redux/TaskAction'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Attachment} from '../../../Common/attachments/Attachment'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'
import * as boardActions from '../../board/redux/BoardActions'
import {Activity} from '../../generalActivityLogs/components/Activity'

const TaskDataHeader: React.FC = () => {
  const dispatch = useDispatch()
  const {id, workspaceid, projectid}: {id: string; workspaceid: string; projectid: string} =
    useParams()
  const {taskDetail, attachments, listLoading, spaceDetail, currentProject}: any =
    useSelector<RootState>(
      (state) => ({
        taskDetail: state.task.taskDetail,
        listLoading: state.activities.listLoading,
        attachments: state.task.attachments,
        spaceDetail: state.workspace.workspaceDetail,
        currentProject: state.board.currentProject,
      }),
      shallowEqual
    ) as any

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchTask(id))
    }
  }, [id])

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
    dispatch(boardActions.currentProject(projectid))
  }, [dispatch, workspaceid])

  let crumbs
  if (spaceDetail) {
    crumbs = [
      {name: 'Spaces', url: '/dashboard'},
      {name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features`},
      {name: 'Project', url: `/${spaceDetail?._id}/project`},
      {name: `${currentProject?.name}`, url: `/${spaceDetail?._id}/project/${projectid}/board`},
      {name: 'Tasks', url: `/${spaceDetail?._id}/project/${projectid}/task`},
      {name: `${taskDetail?.title}`},
    ]
  }

  return (
    <div className='mh-80 bg-white'>
      <div className='card pb-5  bg-white'>
        {listLoading && <Loading />}
        {!listLoading && (
          <div className='card-body p-0'>
            {taskDetail && (
              <>
                {taskDetail.title && (
                  <div className='px-6 px-sm-12'>
                    <div className='row'>
                      <div className='col-sm-6' title={taskDetail.title}>
                        <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
                          {taskDetail.title}
                        </h2>
                      </div>
                    </div>
                  </div>
                )}
                {!listLoading && crumbs && <Breadcrumbs crumbs={crumbs} containerClass='' />}
                <div className='overflow_height_scroll'>
                  <div className='border_grey_info p-5 mt-10 mx-2 mx-sm-10'>
                    <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 flex-column'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <span
                          className='d-flex align-items-center  me-5 mb-2'
                          title={
                            taskDetail.createdBy &&
                            taskDetail.createdBy?.firstName + ' ' + taskDetail.createdBy?.lastName
                          }
                        >
                          {taskDetail.title}
                        </span>
                        <Can I='update' this='spaces'>
                          <Link
                            to={`/${workspaceid}/project/${projectid}/task/${id}/edit`}
                            title='Edit'
                            className='btn btn-sm btn-light-blue text-blue ms-3'
                          >
                            <img
                              src={toAbsoluteUrl('/media/icons/projects/svgexport-10.svg')}
                              className='me-2 w-25'
                            />
                            Edit
                          </Link>
                        </Can>
                      </div>

                      <div className='d-flex flex-column flex-sm-row'>
                        {taskDetail.createdBy && (
                          <span
                            className='d-flex align-items-center text-gray-400 me-5 mb-2'
                            title={moment(taskDetail.dueDate).format('MM/DD/YYYY')}
                          >
                            <span className='text-danger w-100'>
                              <i className='text-danger text-muted'></i>
                              <span className='ms-2 fw-bolder fs-7 text-muted'>Created By</span>
                              <span className='ms-2 fs-7'>
                                <KTSVG
                                  path='/media/icons/duotune/communication/com006.svg'
                                  className='svg-icon-4 me-1'
                                />
                                {taskDetail.createdBy?.firstName +
                                  ' ' +
                                  taskDetail.createdBy?.lastName}
                              </span>
                            </span>
                          </span>
                        )}

                        {taskDetail.createdAt && (
                          <span
                            className='d-flex align-items-center me-5 mb-2'
                            title={moment(taskDetail.createdAt).format('MM/DD/YYYY')}
                          >
                            <span className='fw-bolder fs-7 text-muted'>Created Date</span>
                            <span className='ms-2 fs-7'>
                              {taskDetail.createdAt
                                ? moment(taskDetail.createdAt).format('MM/DD/YYYY')
                                : ''}
                            </span>
                          </span>
                        )}
                      </div>
                      <div className='d-flex flex-column flex-sm-row'>
                        {taskDetail.assignee?.length > 0 && (
                          <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 align-items-center  me-5 '>
                            <span className='me-1 d-flex'>
                              <span className='fs-7 text-muted'>Assignees &nbsp;</span>
                              <span className='fs-7 ms-1'>
                                {taskDetail.assignee?.length > 0 &&
                                  taskDetail.assignee.map((assignee: any, index: number) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {index === 0
                                          ? `${assignee.firstName} ${assignee.lastName}`
                                          : `, ${assignee.firstName} ${assignee.lastName}`}
                                      </React.Fragment>
                                    )
                                  })}
                              </span>
                            </span>
                          </div>
                        )}
                        {taskDetail.dueDate && (
                          <span
                            className='d-flex align-items-center text-gray-400 me-5 mb-2'
                            title={moment(taskDetail.dueDate).format('MM/DD/YYYY')}
                          >
                            <span className='text-danger w-100'>
                              <i className='far fa-calendar text-danger text-muted'></i>
                              <span className='ms-2 fw-bolder fs-7 text-muted'>Due Date</span>
                              <span className='ms-2 fs-7'>
                                {moment(taskDetail.dueDate).format('MM/DD/YYYY')}
                              </span>
                            </span>
                          </span>
                        )}
                      </div>

                      <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                        <div className='d-flex justify-content-start'>
                          <span
                            className={` me-4 badge badge-light-${
                              taskDetail.status.name === 'To Do'
                                ? 'info'
                                : taskDetail.status.name === 'In Progress'
                                ? 'primary'
                                : 'success'
                            } fs-7 fw-bolder mb-2`}
                            title={taskDetail.status.name}
                          >
                            {taskDetail.status.name}
                          </span>
                          <span
                            className={`h-50 badge badge-light-${
                              taskDetail.priority === 'high'
                                ? 'danger'
                                : taskDetail.priority === 'medium'
                                ? 'success'
                                : 'primary'
                            } fs-7 fw-bolder`}
                            title='Low'
                          >
                            {_.startCase(taskDetail.priority)}
                          </span>
                        </div>
                      </span>
                    </div>
                    {/* <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 align-items-center  me-5 '>
                          {taskDetail.assignee?.length > 0 &&
                            taskDetail.assignee.map((assignee: any, index: number) => {
                              return (
                                <span key={index} className='d-flex align-items-center '>
                                  {index === 0
                                    ? `${assignee.firstName}`
                                    : `, ${assignee.firstName}`}
                                </span>
                              )
                            })}
                        </div> */}
                    {taskDetail.description && (
                      <>
                        <p className='fs-7 text-muted fw-bolder'>Description </p>
                        <div
                          className='d-flex align-items-center mb-2 text-gray-800 me-1'
                          title={taskDetail.description}
                          style={{whiteSpace: 'pre-line'}}
                        >
                          {`${taskDetail.description}`}
                        </div>
                      </>
                    )}

                    {attachments?.length > 0 && (
                      <>
                        <p className='fs-7 text-muted fw-bolder'>Attachments </p>
                        <Attachment
                          attachments={attachments}
                          loadAttachments={actions.loadAttachments}
                          attachmentType='listing'
                          id={id}
                          itemType='task'
                        />
                      </>
                    )}
                  </div>
                  <Activity itemType='Task' activityItemId='' />
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export {TaskDataHeader}
