import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL_V2
const API_URL_Nest = process.env.REACT_APP_API_URL
const ACTIVITY_URL = `${API_URL_Nest}/activities`
const TODO_URL = `${API_URL_Nest}/todos`

export function getActivityList({
  itemId,
  page,
  itemType,
}: {
  itemId: string
  page: number | string
  itemType: string
}) {
  return axios.get(`${ACTIVITY_URL}/${itemId}`, {
    params: {page, itemType},
  })
}
export function addActivityList({
  itemType,
  itemId,
  description,
  attachment,
}: {
  itemType: string
  itemId: string
  description: string
  attachment: Array<string>
}) {
  return axios.post(ACTIVITY_URL, {
    item: itemId,
    description,
    attachmentPaths: attachment,
    itemType: itemType,
  })
}
export function updateActivityList({
  itemId,
  _id,
  description,
  attachments,
}: {
  itemId: string
  _id: string
  description: string
  attachments: Array<string>
  item: string
}) {
  return axios.patch(`${ACTIVITY_URL}/${_id}`, {
    item: itemId,
    _id,
    description,
    attachmentPaths: attachments,
  })
}
export function deleteActivityList(id: string) {
  return axios.delete(`${ACTIVITY_URL}/${id}`)
}
export function uploadSingleFile(data: any) {
  return axios({
    method: 'post',
    url: `${ACTIVITY_URL}/upload`,
    data: data,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}
export function fetchSingleTodo(_id: string) {
  return axios.get(`${TODO_URL}/${_id}`)
}
