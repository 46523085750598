import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const RELEASE_NOTE_URL = `${API_URL}/release-note`
export function getReleaseNoteList({
  search,
  page,
  orderby,
  sortby,
  createdAt,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  createdAt: string
}) {
  return axios.get(RELEASE_NOTE_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      createdAt,
    },
  })
}
export function addReleaseNoteList(title: string, description: string, version: string) {
  return axios.post(RELEASE_NOTE_URL, {
    title,
    description,
    version,
  })
}
export function updateReleaseNoteList(
  _id: string,
  title: string,
  description: string,
  version: string
) {
  return axios.patch(`${RELEASE_NOTE_URL}/${_id}`, {
    _id,
    title,
    description,
    version,
  })
}

export function deleteReleaseNoteList(_id: string) {
  return axios.delete(`${RELEASE_NOTE_URL}/${_id}`)
}

export function fetchReleaseNoteById(_id: string) {
  return axios.get(`${RELEASE_NOTE_URL}/${_id}`)
}
