import * as serverRequest from './DiscussionCrud'
import {discussionsSlice} from './DiscussionSlice'
import {AppDispatch} from '../../../../setup/redux/Store'

const {actions} = discussionsSlice

export const getAllUsers = () => (dispatch: AppDispatch) => {
  dispatch(actions.startCall())
  return serverRequest
    .getAllUsers()
    .then((res) => {
      if (res.status) dispatch(actions.discussionsFetched({chat: res.data}))
      dispatch(actions.unauthorizedForChat({unauthorizedForChat: {}}))
    })
    .catch((err) => {
      dispatch(actions.unauthorizedForChat({unauthorizedForChat: err.response.data}))
    })
}

export const createChatIFNotExist =
  (workSpace: string, userId: string, chatType: string) => (dispatch: AppDispatch) => {
    return serverRequest.createChatIFNotExist(workSpace, userId, chatType).then((res) => {
      dispatch(actions.currentUserChatId({chatId: res.data._id}))
      return serverRequest.getAllMessages(res.data._id).then((res) => {
        dispatch(actions.singleUserMessages({chat: res.data}))
      })
    })
  }

export const getAllChat = (workspaceid: string, chatType: string) => (dispatch: AppDispatch) => {
  return serverRequest.getAllGroupChat(workspaceid, chatType).then((res) => {
    dispatch(actions.allChat({chat: res.data}))
  })
}

export const createGroup =
  (chatName: string, users: any, workSpace: string, chatType: string) =>
  (dispatch: AppDispatch) => {
    return serverRequest
      .createGroup(chatName, users, workSpace, chatType)
      .then((res) => {
        if (res) {
          dispatch(actions.currentUserChatId({chatId: res.data._id}))
          return serverRequest.getAllMessages(res.data._id).then((res) => {
            dispatch(actions.singleUserMessages({chat: res.data ? res.data : []}))
            return res.data
          })
        }
      })
      .catch((err) => {
        dispatch(actions.unauthorizedForChat({unauthorizedForChat: err.response.data}))
      })
  }

export const updatedGroup =
  (chatId: string, chatName: string, users: any) => (dispatch: AppDispatch) => {
    return serverRequest.updatedGroup(chatId, chatName, users).catch((err) => {
      dispatch(actions.unauthorizedForChat({unauthorizedForChat: err.response.data}))
    })
  }

export const deleteChannel = (chatId: string) => (dispatch: AppDispatch) => {
  return serverRequest.deleteChannel(chatId).catch((err) => {
    dispatch(actions.unauthorizedForChat({unauthorizedForChat: err.response.data}))
  })
}

export const getAllMessages = (chatId: string) => (dispatch: AppDispatch) => {
  return serverRequest
    .getAllMessages(chatId)
    .then((res) => {
      if (res) dispatch(actions.singleUserMessages({chat: res.data ? res.data : []}))
    })
    .catch((err) => {
      dispatch(actions.unauthorizedForChat({unauthorizedForChat: err.response.data}))
    })
}

export const sendMessage =
  (chat: string, workSpace: string, content: string) => (dispatch: AppDispatch) => {
    return serverRequest.sendMessage(chat, workSpace, content)
  }

export const messageScenedUserIds = (chatId: string) => (dispatch: AppDispatch) => {
  return serverRequest.messageScenedUserIds(chatId).catch((err) => {
    dispatch(actions.unauthorizedForChat({unauthorizedForChat: err.response.data}))
  })
}

export const currentChatType = (groupChat: any) => (dispatch: AppDispatch) => {
  dispatch(actions.currentChatType({groupChat}))
}

export const currentUserChatId = (chatId: string) => (dispatch: AppDispatch) => {
  dispatch(actions.currentUserChatId({chatId}))
}

export const allChat = (newChats: any) => (dispatch: AppDispatch) => {
  dispatch(actions.allChat({chat: newChats}))
}
export const discussionsFetched = (users: any) => (dispatch: AppDispatch) => {
  dispatch(actions.discussionsFetched({chat: users}))
}

export const unauthorizedForChat = (error: any) => (dispatch: AppDispatch) => {
  dispatch(actions.unauthorizedForChat({unauthorizedForChat: error}))
}

export const editChat = (editChat: any) => (dispatch: AppDispatch) => {
  dispatch(actions.editChat({editChat}))
}

export const getChannelUsers = (chatId: string) => (dispatch: AppDispatch) => {
  return serverRequest.getChannelUsers(chatId)
}
export const chatIdByNotification = (chatId: string) => (dispatch: AppDispatch) => {
  dispatch(actions.chatIdByNotification({chatIdByNotification: chatId}))
}

export const groupCreated = (groupCreated: boolean) => (dispatch: AppDispatch) => {
  dispatch(actions.groupCreated({groupCreated: groupCreated}))
}

export const showMemberPopup = (showMemberPopup: boolean) => (dispatch: AppDispatch) => {
  dispatch(actions.showMemberPopup({showMemberPopup: showMemberPopup}))
}

export const creatingChannel = (creatingChannel: boolean) => (dispatch: AppDispatch) => {
  dispatch(actions.creatingChannel({creatingChannel: creatingChannel}))
}
export const memberAdded = (memberAdded: boolean) => (dispatch: AppDispatch) => {
  dispatch(actions.memberAdded({memberAdded}))
}
