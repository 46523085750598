/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { KTSVG } from '../../../../_metronic/helpers/components/KTSVG'
import { Loading } from '../../../../_metronic/layout/Loading'
import { NotFound } from '../../../../_metronic/layout/NotFound'
import { Search } from '../../../../_metronic/layout/search/Search'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import '../../Todo/components/todo.scss'
import * as actions from '../redux/workspaceMembersActions'

type Props = {
  className: string
}
const WorkspaceMembersTable: React.FC<Props> = ({ className }) => {
  const { workspaceid }: { workspaceid: any } = useParams()
  const dispatch = useDispatch()
  const [membersData, setMembersData] = useState<any>()
  const { workspaceMembersData, isLoading, auth }: any = useSelector<any>(
    (state) => ({
      workspaceMembersData: state.workspaceMembers.assignees,
      isLoading: state.workspaceMembers.listLoading,
      auth: state.auth.user,
    }),
    shallowEqual
    )
  useEffect(() => {
    dispatch(
      actions.fetchAssignees({
        workspaceid: workspaceid,
      })
    )
    if (workspaceMembersData[0]) {
      setMembersData(workspaceMembersData[0])
    }
  }, [])

  useEffect(() => {
    setMembersData(workspaceMembersData[0])
  }, [workspaceMembersData])

  let crumbs
  if (workspaceMembersData.length > 0) {
    crumbs = [
      { name: 'Spaces', url: '/dashboard' },
      { name: `${workspaceMembersData[0]?.name}`, url: `/${workspaceMembersData[0]?._id}/features` },
      { name: 'Members', url: '' },
    ]
  }

  const isAuthorized = () => {
    if (auth._id === workspaceMembersData[0]?.owner) {
      return true
    } else if (workspaceMembersData[0]?.maintainers?.some((e: any) => e._id === auth._id)) {
      return true
    } if(workspaceMembersData[0]?.group?.createdBy === auth._id){
      return true
    }else {
      return false
    }
  }

  const handleSearch = (search: string) => {
    let filteredData = workspaceMembersData[0]?.users?.filter((user: any) => {
      return user?.fullName?.match(new RegExp(search, 'i'))
    })
    let finalData = { ...workspaceMembersData[0], users: filteredData }
    setMembersData(finalData)
  }

  return (
    <>
      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
        {isLoading && <Loading />}
        {!isLoading && (
          <div className='px-6 px-sm-12'>
            <div className="row">
              <div className="col-sm-6">
                <h2 className='fw-500 fs-2 m-0 py-5'>
                Members
                </h2>
              </div>
              <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
                <Search handleSearch={handleSearch}/>
              </div>
            </div>
          </div>
        )}

        <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          {isAuthorized() && (
            <Link className='bg-lighter me-10' to={`/space/${workspaceMembersData[0]?._id}/edit`}>
              <span
                className='btn btn-sm bg-transparent text-blue pe-5 d-flex align-items-center'
                style={{ whiteSpace: 'nowrap' }}
              >
                <i className='fa fa-pencil-alt text-blue link_button' aria-hidden='true'></i>
                <span className='link_button'> Edit Space</span>
              </span>
            </Link>
          )}
        </div>

        <div className="overflow_height_scroll">
          {!isLoading && membersData?.users?.length > 0 && (
            <>
              <div className='card-body d-flex flex-column py-3 px-10'>
                <div className='table-responsive'>
                  <table className='todo-table table table-row-dashed table_border_lite table-row-gray-300 align-middle gs-0 gy-4 membersTable'>
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th className='' abbr='name' id='workspaceMembers-name-head'>
                          Name
                        </th>

                        <th className='' abbr='email' id='workspaceMembers-email-head'>
                          Email
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {membersData?.users?.map((workspaceMembers: any, index: number) => {
                        return (
                          <tr key={index} style={{ lineHeight: '25px' }}>
                            <td data-label='Name'>
                              {/* <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                  </div>
                                </div>
                              </div> */}
                              <div
                                title={workspaceMembers.fullName}

                                style={{
                                  display: 'block',
                                  width: '230px',
                                  minWidth: '230px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  fontSize: '12px'

                                }}
                              >
                                {membersData?.owner === workspaceMembers._id && index === 0
                                  ? workspaceMembers.fullName + ' (Creator)'
                                  : membersData?.maintainers?.some(
                                    (e: any) => e._id === workspaceMembers._id
                                  )
                                    ? workspaceMembers.fullName + ' (Maintainer)'
                                    : workspaceMembers.fullName}
                              </div>
                            </td>

                            <td data-label='Email'>
                              {/* <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                  </div>
                                </div>
                              </div> */}
                              <div
                                title={workspaceMembers.email}
                                style={{
                                  display: 'block',
                                  width: '230px',
                                  minWidth: '230px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  fontSize: '12px'
                                }}
                              >
                                {workspaceMembers.email}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
          
          {!isLoading && membersData?.teams?.length > 0 && (
            <>
              {membersData?.teams?.map((team: any, index: number) => (
                <React.Fragment key={index}>
                  <div className='d-flex flex-row px-10'>
                    <h3 className='card-title align-items-start flex-column '>
                      <span className='text-dark fw-bolder text-hover-primary fs-6'>
                        {team?.name + ' (Team)'}
                      </span>
                    </h3>
                    {auth._id === membersData?.owner && (
                      <Link
                        className='card-title align-items-start flex-column ps-3'
                        to={`/account/teams/${team._id}/edit`}
                      >
                        <i className='fa fa-pen' title='Edit Team'></i>
                      </Link>
                    )}
                  </div>
                  <div className='card-body d-flex flex-column py-3 px-10'>
                    <div className='table-responsive'>
                      <table className='todo-table table table-row-dashed  table_border_lite table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          <tr className='fw-bolder text-muted'>
                            <th className='' abbr='name' id='workspaceMembers-name-head'>
                              Name
                            </th>

                            <th className='' abbr='email' id='workspaceMembers-email-head'>
                              Email
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {team?.members?.map((teamMembers: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td data-label='Name'>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span
                                          title={teamMembers.fullName}
                                          style={{
                                            display: 'block',
                                            width: '230px',
                                            minWidth: '230px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {teamMembers.fullName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Email'>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span
                                          title={teamMembers.email}
                                          style={{
                                            display: 'block',
                                            width: '230px',
                                            minWidth: '230px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {teamMembers.email}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}

          {!isLoading && membersData?.group?.length > 0 && (
            <>
              {membersData?.group?.map((team: any, index: number) => (
                <React.Fragment key={index}>
                  <div className='d-flex flex-row px-10 justify-content-between'>
                    <h3 className='card-title align-items-start flex-column '>
                      <span className='text-dark fw-bolder text-hover-primary fs-6'>
                        {team?.name + ' (Group)'}
                      </span>
                    {auth._id === membersData?.owner && (
                      <Link
                        className='card-title align-items-start flex-column ps-3'
                        to={`/organization/${team?.company}/group/${team?._id}/edit`}
                      >
                        <i className='fa fa-pen' title='Edit Group'></i>
                      </Link>
                    )}
                    </h3>
                   {isAuthorized() &&
                    <Link className='' to={`/groups/${team?._id}/hierarchy`}>
                      <span
                        className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
                        style={{whiteSpace: 'nowrap'}}
                      >
                        <KTSVG path='' className='svg-icon-3' />
                        <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
                        <span className='link_button'> Add Members</span>
                      </span>
                    </Link>
                    }
                  </div>
                  <div className='card-body d-flex flex-column py-3 px-10'>
                    <div className='table-responsive'>
                      <table className='todo-table table table-row-dashed  table_border_lite table-row-gray-300 align-middle gs-0 gy-4'>
                        <thead>
                          {team?.group.length > 0 ? 
                          <tr className='fw-bolder text-muted'>
                            <th className='' abbr='name' id='workspaceMembers-name-head'>
                              Name
                            </th>

                            <th className='' abbr='email' id='workspaceMembers-email-head'>
                              Email
                            </th>
                          </tr>: 
                          <tr className='fw-bolder text-muted text-center'>
                              <td>
                                No Members Added!
                              </td>
                          </tr>
                          }
                        </thead>
                        <tbody>
                          {team?.group?.map((teamMembers: any, index: number) => {
                            return (
                              <tr key={index}>
                                <td data-label='Name'>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span
                                          title={teamMembers?.user?.fullName}
                                          style={{
                                            display: 'block',
                                            width: '230px',
                                            minWidth: '230px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {teamMembers?.user?.fullName}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td data-label='Email'>
                                  <div className='d-flex align-items-center'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex justify-content-start flex-column'>
                                        <span
                                          title={teamMembers?.user?.email}
                                          style={{
                                            display: 'block',
                                            width: '230px',
                                            minWidth: '230px',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                          }}
                                        >
                                          {teamMembers?.user?.email}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </>
          )}
        </div>
        {!isLoading && membersData?.teams?.length <= 0 && membersData?.users?.length <= 0 && membersData?.group?.length <= 0 && (
          <NotFound />
        )}
      </div>
    </>
  )
}

export { WorkspaceMembersTable }
