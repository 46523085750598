import {FC} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import * as actions from '../../redux/BoardActions'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../../../setup'

const SearchBox: FC = () => {
  const dispatch = useDispatch()
  const {filter}: any = useSelector<RootState>(
    (state) => ({
      filter: state.board.filter,
    }),
    shallowEqual
  )

  const search = (e: any) => {
    // let filteredColumns: any
    // if (e.target.value) {
    //   filteredColumns = columns.map((column: any) => {
    //     const columnData = JSON.parse(JSON.stringify(column))
    //     columnData.task = column.task.filter((tsk: any) =>
    //       tsk.title.toLowerCase().includes(e.target.value.toLowerCase())
    //     )
    //     return columnData
    //   })
    // }
    // filteredColumns = filteredColumns || columns

    dispatch(actions.filterProject({...filter, search: e.target.value}))
  }

  return (
    <>
    {/*<div
      data-kt-search-element='form'
      // className='todo-bar-search-from position-relative px-3 boder-1 rounded'
      // autoComplete='off'
      title={'Search'}
      // style={{width: '290px'}}
    >

    <div className="input-group col-sm-2 p-1 me-1">
      <input
        type='text'
        className='w-50 form-control form_control_space  bd-right-none py-1'
        placeholder='Search'
        aria-label="Recipient's username"
        aria-describedby='basic-addon2'
        onChange={search}
      />
      <span className="input-group-text bd-left-none bg-white py-1 px-3 z-indx-0" id="basic-addon2"> <button className="btn bg-white btn-focus-white p-0 m-0" style={{cursor: 'unset'}}> <span className="svg-icon "><svg id="svgexport-7" xmlns="http://www.w3.org/2000/svg" width="19.803" height="19.303" viewBox="0 0 19.803 19.303" className="mh-50px" color=""><rect id="Rectangle_974" data-name="Rectangle 974" width="8.155" height="2" rx="1" transform="translate(14.037 12.122) rotate(45)" fill="#a1a5b7" opacity="0.5"></rect><path id="Path_4816" data-name="Path 4816" d="M11,19a8,8,0,1,1,8-8A7.967,7.967,0,0,1,11,19ZM11,5a5.815,5.815,0,0,0-6,6,5.815,5.815,0,0,0,6,6,5.815,5.815,0,0,0,6-6A5.815,5.815,0,0,0,11,5Z" transform="translate(-3 -3)" fill="#a1a5b7"></path></svg></span></button></span> 
    </div>



      /~ <KTSVG
        path='/media/icons/duotune/general/gen021.svg'
        className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
      />

      <input
        type='text'
        className='form-control form-control-flush py-2 ps-10'
        name='search'
        placeholder='Search...'
        data-kt-search-element='input'
        onChange={search}
      />

      <span
        className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
        data-kt-search-element='clear'
      >
        <KTSVG
          path='/media/icons/duotune/arrows/arr061.svg'
          className='svg-icon-2 svg-icon-lg-1 me-0'
        />
      </span> ~/
    </div>*/}

    <div className={`input-group w-100 w-sm-50 me-2`}>
      <input
        type='text'
        className='form-control form_control_space  bd-right-none py-1'
        placeholder='Search'
        aria-label="Recipient's username"
        aria-describedby='basic-addon2'
        title='Search'
        onChange={search}
      />
      <span
        className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
        id='basic-addon2'
      >
        <span className='p-0 m-0'>
          <span>
            <KTSVG path='/media/icons/projects/svgexport-7.svg' />
          </span>
        </span>
      </span>
    </div>
    </>
  )
}

export {SearchBox}
