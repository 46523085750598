import React from 'react'
import {OrganizationTable} from './components/organizationTable'
import {Redirect, Route, Switch} from 'react-router-dom'
import {OrganizationEdit} from './components/organizationEdit'
import {OrganizationCharts} from './components/organizationCharts'
import {ChartAdd} from './components/chartAdd'
import {ChartTree} from './components/ChartTree'
import Ability from '../../Common/RBAC/Ability'
import {ChartTable} from './components/ChartTable'

const Organization: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/organization/create'>
          {({history, match}) => {
            return (
              <OrganizationEdit
                onHide={() => {
                  history.push(`/organization`)
                }}
              />
            )
          }}
        </Route>
        {Ability.can('list', 'organization') && (
          <Route exact path='/organization'>
            <OrganizationTable className='mb-5 mb-xl-8' />
          </Route>
        )}

        <Route exact path='/organization/:id/edit'>
          {({history, match}) => (
            <OrganizationEdit
              id={match && match.params.id}
              onHide={() => {
                history.push(`/organization`)
              }}
            />
          )}
        </Route>

        <Route exact path='/groups'>
          {({history, match}) => (
            <ChartTable
              onHide={() => {
                history.push(`/groups`)
              }}
            />
          )}
        </Route>

        <Route exact path='/organization/:id/groups'>
          {({history, match}) => (
            <OrganizationCharts
              onHide={() => {
                history.push(`/organization`)
              }}
            />
          )}
        </Route>

        <Route path={['/organization/:id/group/add', '/groups/add']}>
          {() => {
            return <ChartAdd />
          }}
        </Route>

        <Route path={['/organization/group/:id/hierarchy', '/groups/:id/hierarchy']}>
          {() => {
            return <ChartTree />
          }}
        </Route>

        <Route exact path={['/organization/:id/group/:chartId/edit', '/groups/:chartId/edit']}>
          {({history, match}) => <ChartAdd />}
        </Route>
        <Redirect to='/dashboard' />
      </Switch>
    </>
  )
}

export {Organization}
