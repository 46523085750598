import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {Can} from '../../../Common/RBAC/Can'
import Editor from '../../../Common/Editor/Editor'
import SimpleDropZone from './DropZone'
import {useParams, Link} from 'react-router-dom'
import './style.css'

interface Props {
  values: any
  setFieldValue: any
  resetFields: any
  resetForm: any
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const CommentForm: React.FC<Props> = ({
  values,
  setFieldValue,
  resetFields,
  resetForm,
  show,
  setShow,
}) => {
  return (
    <Can I='update' this='spaces'>
      {/* <Can I='update' this='opportunity-watch'> */}
      <Form className='Form'>
        <div className='fv-row'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='fs-7 mt-6'>Comments</span>
          </label>

          <Field
            className='form-control form-control-lg form-control-solid'
            name='comment'
            placeholder='Write a comment'
            id='editComment'
            type='text'
            height='20px'
          >
            {({field, form, meta}: any) => (
              <Editor value={values?.comment || ''} field='comment' setFieldValue={setFieldValue} />
            )}
          </Field>

          <div className='text-danger'>
            <ErrorMessage name='comment' />
          </div>
        </div>
        <div className='dropzone-main d-flex'>
          <button
            className='btn btn-sm btn-icon btn-active-light-primary me-1 mt-4'
            type='button'
            title='Upload File'
            onClick={() => {
              setShow((show: any) => !show)
            }}
          >
            <i className='bi bi-paperclip fs-3'></i>
          </button>
        </div>
        {show && <SimpleDropZone />}

        <div className='card-footer d-flex justify-content-end pt-6 px-9 pb-0'>
          {/* <button
            type='button'
            className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
            onClick={() => {
              resetFields()
              resetForm()
            }}
            title='Cancel'
          >
            Cancel
          </button> */}

          <button type='submit' className='btn btn-blue  fs-7' title={'Comment'}>
            {'Comment'}
          </button>
        </div>
      </Form>
      {/* </Can> */}
    </Can>
  )
}
