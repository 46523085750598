import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import clsx from 'clsx'
import moment from 'moment'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {Pagination} from '../../../pagination/pagination'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import * as actions from '../redux/listing/AllNotificationAction'
import {
  convertTemplateToPlainText,
  makeRouter,
  replaceNotificationType,
} from '../../../../app/Common/notification/CommonFunctions'
import * as discussionActions from '../../../../app/modules/workspace/components/chat/redux/DiscussionActions'
import * as recentNotificationactions from '../../../../app/modules/notifications/redux/NotificationAction'

import _default from 'nouislider'
import {NotificationDelete} from './NotificationDelete'

type Props = {
  className: string
}

type Notification = {
  end: number
  limitPerPage: number
  message?: string
  start?: number
  totalCount?: number
  unSeenCount?: number
  data: Array<object>
}

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

export const NotificationTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const [notificationList, setNotificationList]: any = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')

  const {notificationData}: any = useSelector<RootState>(
    (state) => ({
      notificationData: state.notificationListing.entities,
      auth: state.auth,
    }),
    shallowEqual
  ) as Notification

  const isLoading: boolean = useSelector<RootState>(
    (state) => state.notificationListing.listLoading,
    shallowEqual
  ) as boolean

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.notificationListing.entities,
    shallowEqual
  ) as PaginationModel

  const {auth}: any = useSelector<RootState>(
    (state) => ({
      auth: state.auth?.user,
    }),
    shallowEqual
  ) as any

  useEffect(() => {
    if (notificationData?.data?.length > 0) {
      setNotificationList(notificationData)
    } else {
      setNotificationList([])
    }
  }, [notificationData])

  useEffect(() => {
    dispatch(
      actions.fetchNotification({
        search: '',
        page: '',
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }, [])
  console.log(notificationList, 'listttt')
  const handlePagination = (event: any) => {
    setCurrentPage(event.selected + 1)
    dispatch(
      actions.fetchNotification({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }
  const renderSingleNotification = (
    template: string,
    from: string,
    fromId: string,
    workSpace: string,
    list: string,
    todo: string,
    opportunityWatch: string,
    discussion: string,
    avengersInitiative: string
  ) => {
    return (
      <div
        className='text-gray-800 fs-7 fw-normal mt-2 menu-style-note w_300px'
        dangerouslySetInnerHTML={{
          __html: convertTemplateToPlainText(
            template,
            from,
            fromId,
            workSpace,
            list,
            todo,
            opportunityWatch,
            avengersInitiative,
            discussion,
            auth
          ),
        }}
      ></div>
    )
  }

  const onClickReadAll = () => {
    dispatch(recentNotificationactions.UpdateNotification())
    dispatch(actions.UpdateNotification(currentPage))
  }
  const onClickDelete = (id: any) => {
    setItemToDelete(id)
    setShowDeleteDialogue(true)
  }

  let crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Notifications', url: ``},
  ]

  return (
    <>
      <NotificationDelete
        show={showDeleteDialogue}
        handleClose={() => {
          setShowDeleteDialogue(false)
        }}
        _id={itemToDelete}
        page={currentPage}
      />
      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <div className='px-6 px-sm-12'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h2 className='fw-500 fs-2 m-0 py-5'>Notifications</h2>
                </div>
              </div>
            </div>

            {/* <div className='card-header border-0 px-10'>
              <h2 className='fw-500 fs-2 m-0 py-5'>Notifications</h2>
              <div
                className='card-toolbar mt-0 pt-2'
                data-bs-toggle='tooltip'
                data-bs-placement='top'
                data-bs-trigger='hover'
                title='Changes Notes Listing View'
              ></div>
            </div> */}

            <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
              {crumbs && <Breadcrumbs crumbs={crumbs} />}
              {notificationData?.unSeenCount !== 0 && (
                <a className='bg-lighter me-15' onClick={onClickReadAll}>
                  <span
                    className='btn btn-sm p-0 bg-transparent text-blue btn_notification d-flex align-items-center'
                    style={{whiteSpace: 'nowrap'}}
                  >
                    <div className='block'>
                      <div className='border_notification'>
                        {' '}
                        <i className='fa fa-check link_button' aria-hidden='true'></i>
                      </div>
                    </div>
                    <span className='link_button'>Mark all as Read</span>
                  </span>
                </a>
              )}
            </div>
          </>
        )}
        {!isLoading && notificationList?.data?.length > 0 ? (
          <>
            <div className='overflow_height_scroll'>
              {' '}
              <div className='card-body d-flex flex-column py-3 px-10'>
                <div className='table-responsive'>
                  <table className='table_solid table  align-middle gs-0 gy-4'>
                    <tbody>
                      {notificationList?.data?.map((notification: any, index: number) => {
                        return (
                          <tr
                            className={!notification.isSeen ? 'active_notification' : ''}
                            key={index}
                          >
                            <td>
                              <div className='d-flex justify-content-between'>
                                {' '}
                                <Link
                                  key={`alert${index}`}
                                  className='d-flex flex-stack py-2 px-2 my-1 '
                                  to={makeRouter(notification.itemType, notification.item)}
                                  onClick={() => {
                                    if (notification.itemType.includes('Chat')) {
                                      dispatch(
                                        discussionActions.chatIdByNotification(
                                          notification.item.discussion._id
                                        )
                                      )
                                    }
                                    if (!notification.isSeen)
                                      dispatch(
                                        actions.UpdateANotification(notification._id, currentPage)
                                      )
                                  }}
                                >
                                  <div className='d-flex align-items-center'>
                                    <div title='Profile' className={'cursor-pointer symbol pe-3'}>
                                      <img
                                        className='rounded-circle'
                                        src={toAbsoluteUrl('/media/avatars/blank.png')}
                                        alt='profile_picture'
                                        style={{height: 40, width: 40}}
                                      />
                                    </div>

                                    <div
                                      className='mb-0 me-2 ms-3 d-flex align-items-end'
                                      style={{lineHeight: 1.2}}
                                    >
                                      {renderSingleNotification(
                                        notification?.template,
                                        notification?.from?.fullName,
                                        notification?.from?._id,
                                        notification?.item?.workSpace?.name,
                                        notification?.item?.list?.title,
                                        notification?.item?.todo?.title,
                                        notification?.item?.opportunityWatch?.lead,
                                        notification?.item?.discussion?.chatName,
                                        notification?.item?.avengersInitiative?.title
                                      )}
                                      <span className='text-gray-800 fs-7 ms-2 fw-normal'>
                                        {notification.itemType[0] == 'Chat'
                                          ? replaceNotificationType('AppChat')
                                          : replaceNotificationType(
                                              notification.itemType[
                                                notification.itemType.length - 1
                                              ]
                                            )}
                                      </span>
                                    </div>
                                  </div>
                                </Link>
                                <div className='d-flex justif-content-between align-items-center'>
                                  <div className='d-flex justify-content-between align-items-center'>
                                    <span
                                      className={clsx(
                                        'badge fs-8 me-20',
                                        `${
                                          !notification.isSeen
                                            ? 'badge-light-primary'
                                            : 'badge-white fw-normal'
                                        }`
                                      )}
                                    >
                                      {moment(notification.createdAt).fromNow()}
                                    </span>
                                  </div>
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary  bg-light-blue-actions btn-sm '
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_delete_app'
                                    onClick={() => onClickDelete(notification._id)}
                                    title='Delete'
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <Pagination paginationData={paginationData} fetchData={handlePagination} />
          </>
        ) : !isLoading && notificationList?.length <= 0 ? (
          <NotFound />
        ) : (
          <Loading />
        )}
      </div>
    </>
  )
}
