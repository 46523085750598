/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
// import {PageTitle} from '../../../_metronic/layout/core'
import {Cards} from '../../modules/Cards/Cards'
const DashboardWrapper: FC = () => {
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle> */}
      <Cards />
    </>
  )
}

export {DashboardWrapper}
