/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {RootState} from '../../../setup'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Loading} from '../../../_metronic/layout/Loading'
import {WorkspaceSort} from '../../../_metronic/layout/sort/WorkspaceSort'
import {Can} from '../../Common/RBAC/Can'
import {getNewSpaceMembers} from '../UIHelper'
import '../../../_metronic/assets/sass/components/new-spaces.scss'

type Props = {
  workspaces: any
  toggleView: any
  sortValues: any
  setSortValues: any
  setIsFiltered: any
  deleteWorkspace: any
  apiSettings: any
}

const NewSpacesCardView: FC<Props> = ({
  workspaces,
  toggleView,
  sortValues,
  setSortValues,
  setIsFiltered,
  deleteWorkspace,
  apiSettings,
}) => {
  const [viewMoreCount, setViewMoreCount] = useState(0)
  const [isViewLess, setIsViewLess] = useState<boolean>(false)
  const noOfSpaces = apiSettings.find((obj: any) => {
    return obj.name === 'noOfSpaces'
  })
  useEffect(() => {
    setViewMoreCount(+noOfSpaces?.value)
  }, [])

  const {workspaceCount, auth, listLoading, accessToken}: any = useSelector<RootState>(
    (state) => ({
      workspaceCount: state.workspace.totalCount,
      auth: state.auth.user,
      listLoading: state.workspace.listLoading,
      accessToken: state.auth.accessToken,
    }),
    shallowEqual
  )

  const cards = 12 - (workspaceCount + 1) > 0 ? Array(12 - (workspaceCount + 1))?.fill(2) : Array(0)

  const isAuthorized = (spaceDetail: any) => {
    if (auth._id === spaceDetail?.owner?._id) {
      return true
    } else if (spaceDetail?.maintainers?.some((e: any) => e === auth._id)) {
      return true
    } else {
      return false
    }
  }

  return (
    <>
      {!listLoading && (
        <div className='row mx-auto'>
          <div className='mt-15'></div>
        </div>
      )}

      {toggleView && (
        <WorkspaceSort
          sortValues={sortValues}
          setSortValues={setSortValues}
          setIsFiltered={setIsFiltered}
        />
      )}

      {listLoading && <Loading />}
      {!listLoading && (
        <div className='row  drag-drop-card-main  margins-dashboard  px-2  g-4 pb-0'>
          <div className='col-lg-3 col-md-6 mt-0 h-auto' title='Create Space'>
            <Can I='create' this='spaces'>
              <Link to='/space/add' className='card h-100'>
                <div
                  className='drag-drop-card card_create card mb-5 justify-content-evenly h-100'
                  style={{border: '1px solid #EEEEEE'}}
                >
                  <div className='card-header border-0 justify-content-center'>
                    <div className='card-title d-flex flex-column align-items-center'>

                    <div className='create_icon d-flex justify-content-center align-items-center'><img src="/media/icons/projects/add-icon.svg"/></div>
                      <h1
                        style={{
                          color: '#0368CD',
                          fontSize: '12px',
                          marginTop: '12px',
                        }}
                        title='Create Space'
                      >
                        Create Space
                      </h1>
                      {/* <i className='fa fa-plus ps-3 fs-3' style={{color: '#999'}}></i> */}
                    </div>
                  </div>
                </div>
              </Link>
            </Can>
          </div>

          {workspaces?.length > 0 &&
            workspaces?.map((workSpace: any, index: number) => {
              const workSpaceMembers = getNewSpaceMembers(
                workSpace?.assignee,
                workSpace?.teams,
                workSpace?.maintainers,
                workSpace?.owner,
                accessToken
              )
              if (index < viewMoreCount) {
                return (
                  <div className='col-xl-3 col-lg-3 col-md-6 mt-0' key={index}>
                    <Link to={`/${workSpace?._id}/features`}>
                    <div
                      className='drag-drop-card card mb-5  px-2 py-0 justify-content-evenly card_spaces'
                      style={{border: '1px solid #EEEEEE'}}
                    >
                      <div className='card-header p-4 border-0 d-block'>
                        <div
                          className='card-title d-flex flex-column'
                          style={{width: 'calc(100% - 20px)'}}
                        >
                          <Link to={`/${workSpace?._id}/features`}>
                            <p className='card-label spaces-members fs-6' title={workSpace.name}>
                              {workSpace.name}
                            </p>
                          </Link>
                          <p className='fs-9 mt-1 mb-2 text-muted'>
                            Created on {moment(workSpace.createdAt).format('DD MMM, YYYY')}
                          </p>
                        </div>

                        <div className='d-flex justify-content-between w-100'>
                          <div className='d-flex flex-column' title={workSpace.spaceKey}>
                            <Link to={`/${workSpace?._id}/features`}>
                              <p className='fs-7 text-blue fw-500 mb-1 masonry-title-memeber'>{workSpace.spaceKey}</p>
                            </Link>
                            <p className='fs-9 mb-0 text-muted '>Space Key</p>
                          </div>
                          <div className='d-flex flex-column ms-6'>
                            <p className='fs-7 fw-500 mb-1 masonry-title-memeber' title={workSpace?.owner?.fullName}>{workSpace?.owner?.fullName}</p>
                            <p className='fs-9 mb-0 text-muted'>Created By</p>
                          </div>
                        </div>

                        <div className='d-flex justify-content-between w-100 align-items-end'>
                          <div className='d-flex px-1 mt-4 spaces_card_img'>
                            <Link to={`/${workSpace?._id}/members`}>
                              <div
                                className='card-header d-flex p-0'
                                id='kt_chat_messenger_header'
                                title='Members'
                              >
                                <div className='card-title'>
                                  <div className='symbol-group'>
                                    {workSpaceMembers?.map((user: any, index: number, row: any) => {

                                      if (index < 12) {
                                        return row.length > 5 && index == 11 ? (
                                          <div
                                            className='symbol symbol-35px symbol-circle symbol_service'
                                            key={index}
                                          >
                                            <img
                                              className='symbol-label fs-8 fw-bolder'
                                              src={
                                                user.hasOwnProperty('image') && user?.image !== ''
                                                  ? user.image
                                                  : toAbsoluteUrl('/media/avatars/blank.png')
                                              }
                                              alt={user?.fullName}
                                              title={user?.firstName}
                                            />
                                            <span>
                                              {row?.length > 12 ? '+' + (row?.length - 12) : ''}
                                            </span>
                                          </div>
                                        ) : (
                                          <div
                                            className='symbol symbol-35px symbol-circle'
                                            key={index}
                                          >
                                            <img
                                              src={
                                                user.hasOwnProperty('image') && user?.image !== ''
                                                  ? user.image
                                                  : toAbsoluteUrl('/media/avatars/blank.png')
                                              }
                                              alt={user?.fullName}
                                              title={user?.firstName}
                                            />
                                          </div>
                                        )
                                       
                                      }
                                      
                                      // return(

                                      // <div
                                      //     className='symbol symbol-35px symbol-circle'
                                      //     key={index}
                                      //   >
                                      //     <img
                                      //       src={
                                      //         user.hasOwnProperty('image') && user?.image !== ''
                                      //           ? user.image
                                      //           : toAbsoluteUrl('/media/avatars/blank.png')
                                      //       }
                                      //       alt={user?.fullName}
                                      //       title={user?.firstName}
                                      //     />
                                      //   </div>
                                      // )
                                    })}
                                  </div>
                                </div>
                                <div className='card-toolbar'>
                                  <div className='me-n3'>
                                    <div
                                      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                                      data-kt-menu='true'
                                    >
                                      <div className='px-7 py-5'>
                                        <div className='fs-5 text-dark fw-bolder'>
                                          Filter Options
                                        </div>
                                      </div>
                                      <div className='separator border-gray-200'></div>
                                      <div className='px-7 py-5'>
                                        <div className='mb-10'>
                                          <label className='form-label fw-bold'>Status:</label>
                                          <div>
                                            <select
                                              className='form-select form-select-solid'
                                              data-kt-select2='true'
                                              data-placeholder='Select option'
                                              data-allow-clear='true'
                                            >
                                              <option></option>
                                              <option value='1'>Approved</option>
                                              <option value='2'>Pending</option>
                                              <option value='3'>In Process</option>
                                              <option value='4'>Rejected</option>
                                            </select>
                                          </div>
                                        </div>
                                        <div className='mb-10'>
                                          <label className='form-label fw-bold'>Member Type:</label>
                                          <div className='d-flex'>
                                            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='1'
                                              />
                                              <span className='form-check-label'>Author</span>
                                            </label>
                                            <label className='form-check form-check-sm form-check-custom form-check-solid'>
                                              <input
                                                className='form-check-input'
                                                type='checkbox'
                                                value='2'
                                              />
                                              <span className='form-check-label'>Customer</span>
                                            </label>
                                          </div>
                                        </div>
                                        <div className='mb-10'>
                                          <label className='form-label fw-bold'>
                                            Notifications:
                                          </label>
                                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                                            <input
                                              className='form-check-input'
                                              type='checkbox'
                                              name='notifications'
                                              value=''
                                            />
                                            <label className='form-check-label'>Enabled</label>
                                          </div>
                                        </div>
                                        <div className='d-flex justify-content-end'>
                                          <button
                                            type='reset'
                                            className='btn btn-sm btn-light btn-active-light-primary me-2'
                                            data-kt-menu-dismiss='true'
                                          >
                                            Reset
                                          </button>
                                          <button
                                            type='submit'
                                            className='btn btn-sm btn-primary'
                                            data-kt-menu-dismiss='true'
                                          >
                                            Apply
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>

                          <div className='card-toolbar icons_card_bar'>
                            <span className='btn btn-icon btn-hover-light-primary h-100  draggable-handle justify-content-end'>
                              {/* <i className='fa fa-cog'></i> */}
                              <Can I='update' this='spaces'>
                                {isAuthorized(workSpace) ? (
                                  <Link to={`space/${workSpace?._id}/edit`}>
                                    <i className='fa fa-pen m-0' title='Edit'></i>
                                  </Link>
                                ) : null}
                              </Can>
                              <Can I='delete' this='spaces'>
                                {!workSpace.default && auth._id === workSpace?.owner?._id && (
                                  <i
                                    className={`fa fa-trash ms-3`}
                                    title='Delete'
                                    onClick={deleteWorkspace(workSpace?._id)}
                                  ></i>
                                )}
                              </Can>
                            </span>
                          </div>
                        </div>
                      </div>

                      {/* <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
              <div className='d-flex w-100 justify-content-between spaces-key-date'>
                <span className='d-flex align-items-center spaces-key'>
                  <b className='pe-2 d-inline-flex'>Space Key:</b>
                  <Link
                    className='spaces-members'
                    to={`/${workSpace._id}/features`}
                    style={{fontSize: '12px', lineHeight: '21px'}}
                    title={workSpace.spaceKey}
                  >
                    {workSpace.spaceKey}
                  </Link>
                </span>
                <span
                  className='d-flex align-items-center date'>
                  <b className='pe-2'>
                    Date:
                  </b>
                  <span>
                    {moment(workSpace.createdAt).format('DD MMMM, YYYY')}
                  </span>
                </span>
              </div>
              <div className='d-flex w-100 justify-content-between spaces-key-date'>
                <span className='d-flex align-items-center spaces-key'>
                  <b className='pe-2 d-inline-flex'>Created By:</b>
                  <span
                    className='spaces-members'
                    style={{fontSize: '12px', lineHeight: '21px'}}
                    title={workSpace?.owner?.fullName}
                  >
                    {workSpace?.owner?.fullName}
                  </span>
                </span>
                <span
                  className='d-flex align-items-center date'
                  style={{fontSize: '12px'}}
                  title='Members'
                >
                  <b className='pe-2' style={{minWidth: '74px'}}>
                    Members:
                  </b>
                  <Link to={`/${workSpace._id}/members`} className=''>
                    <i
                      className='fa fa-users fs-6 m-0 me-1 text-primary'
                      title='Member'
                    ></i>
                    <span>
                      {getMembers(
                        workSpace?.assignee,
                        workSpace?.teams,
                        workSpace?.maintainers,
                        workSpace?.owner
                      )}
                    </span>
                  </Link>
                </span>
              </div>
              <Can I='read' this='spaces'>
                <Link
                  to={`/${workSpace._id}/features`}
                  className='btn btn-primary font-weight-bold py-1 px-4 w-auto mt-2'
                  title='Go to Space'
                >
                  Go to Space
                </Link>
              </Can>
            </div> */}
                    </div>
                    </Link>
                  </div>
                )
              }
            })}

          {(workspaces?.length - viewMoreCount > 0 || noOfSpaces?.value < workspaces?.length) && (
            <div className='col-lg-3 col-md-6 mt-0 h-auto'>
                  <div className='card h-100'>
              {isViewLess ? (
                <div
                  className='drag-drop-card bg-hover-grey card_create card mb-5 justify-content-evenly h-100'
                  title='View Less'
                  style={{
                    border: '1px solid rgb(238, 238, 238) !important',
                    background: '#F5F5F7',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setViewMoreCount(noOfSpaces?.value)
                    setIsViewLess(false)
                  }}
                >
                  <div className='card-header border-0 justify-content-center'>
                    <div className='card-title d-flex flex-column align-items-center justify-content-center'>
                      <a className='color-grey-dark mb-1 text-center'>
                        {' '}
                        <KTSVG path={'/media/icons/projects/upward.svg'} noClass={true} />
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className='drag-drop-card card_create bg-hover-grey card mb-5 justify-content-evenly h-100'
                  title='View All'
                  style={{
                    border: '1px solid rgb(238, 238, 238) !important',
                    background: '#F5F5F7',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setViewMoreCount(workspaces?.length)
                    setIsViewLess(true)
                  }}
                >
                  <div className='card-header border-0 justify-content-center'>
                    <div className='card-title d-flex flex-column align-items-center justify-content-center'>
                      <span>
                        {' '}
                        <h4 className='text-center fw-500' style={{color: '#A1A5B7'}}>
                          {workspaces.length - viewMoreCount}+
                        </h4>
                      </span>
                      <span className='color-grey-dark mb-1' style={{color: '#A1A5B7'}}>
                        {' '}
                        View All
                      </span>
                    </div>
                  </div>
                </div>
               
              )}
            </div>
            </div>
          )}
          </div>
       
      )}
      
    </>
  )
}

export {NewSpacesCardView}
