/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import '../../../../_metronic/assets/sass/components/cards.scss'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import * as actions from '../redux/avengersInitiativesActions'
import {RootState} from '../../../../setup'
import {useParams} from 'react-router-dom'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {updateFrequency, currentStatus, activity} from '../AvengersInitiativesUIHelper'
import axios from 'axios'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'
import {showUserNames, showUserTeamNames} from '../../workspace/WorkspaceUIHelper'

interface ICreateAccount {
  title: string
  description: string
  currentStatus: number
  updateFrequency: number
  activity: number
  assignee: []
  whoWillUpdate: []
  teams: []
  whoWillUpdateTeam: []
}

const inits: ICreateAccount = {
  title: '',
  description: '',
  currentStatus: 0,
  updateFrequency: 0,
  activity: 0,
  assignee: [],
  whoWillUpdate: [],
  teams: [],
  whoWillUpdateTeam: [],
}

interface Props {
  onHide: () => void
  id?: string | null
}

const API_URL = process.env.REACT_APP_API_URL
const LIST_USERS = `${API_URL}/users`
const LIST_TEAMS = `${API_URL}/teams`

const AvengersInitiativesAdd: FC<Props> = ({onHide, id}) => {
  const {workspaceid}: {workspaceid: any} = useParams()

  const {userInfo, userData}: any = useSelector<RootState>(
    (state) => ({userInfo: state.auth.user, userData: state.avengersInitiatives.entities}),
    shallowEqual
  )

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)
  const [calledBy, setCalledBy] = useState('')
  const [popupTitle, setPopupTitle] = useState('')
  const [assigneeAndTeams, setassigneeAndTeams]: any = useState()
  const [usersId, setUsersId] = useState([])
  const [teamsId, setTeamsId] = useState([])
  const [assigneesId, setAssigneesId] = useState([])
  const [assigneeTeamsId, setAssigneeTeamsId] = useState([])
  const [whoWillUpdateId, setWhoWillUpdateId] = useState([])
  const [whoWillUpdateTeamsId, setWhoWillUpdateTeamsId] = useState([])

  const dispatch: any = useDispatch()

  const {avengersDetail}: any = useSelector<RootState>(
    (state) => ({
      avengersDetail: state.avengersInitiatives.avengersDetail,
    }),
    shallowEqual
  )

  const createAppSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .label('title')
      .min(1, 'Title must contain more than 2 digits'),
    description: Yup.string().nullable(true),
    currentStatus: Yup.number()
      .required('Status is required')
      .min(1, 'Status is required')
      .nullable(true),
    updateFrequency: Yup.number().min(1, 'Update Frequency is required').nullable(true),
    activity: Yup.number().min(1, 'Activity is required').nullable(true),
  })

  const getAssigneesData = async () => {
    const usersData: any = await axios.get(LIST_USERS)
    const teams = await axios.get(LIST_TEAMS)

    setassigneeAndTeams({
      assignee: usersData?.data,
      teams: teams?.data?.data,
    })
  }

  useEffect(() => {
    getAssigneesData()
  }, [])
  useEffect(() => {
    if (id) {
      dispatch(actions.fetchAvengersInitiative(id, workspaceid))
    }
  }, [])

  useEffect(() => {
    if (id) {
      setAssigneesId(avengersDetail?.assignee?.map((assignee: any) => assignee._id))
      setAssigneeTeamsId(avengersDetail?.teams.map((team: any) => team._id))
      setWhoWillUpdateId(avengersDetail?.whoWillUpdate.map((user: any) => user._id))
      setWhoWillUpdateTeamsId(avengersDetail?.whoWillUpdateTeam.map((team: any) => team._id))
    }
  }, [id, avengersDetail])

  const initialValuesOnEdit = {
    title: avengersDetail?.title,
    description: avengersDetail?.description,
    currentStatus: avengersDetail?.currentStatus,
    updateFrequency: avengersDetail?.updateFrequency,
    activity: avengersDetail?.activity,

    assignee: avengersDetail?.assignee.map((assignee: any) => {
      return {
        value: assignee._id,
        label: userInfo._id === assignee._id ? assignee.fullName + ' ' + '(Me)' : assignee.fullName,
        id: assignee._id,
        isTeam: false,
      }
    }),
    teams: avengersDetail?.teams?.map((team: any) => {
      return {
        value: team._id,
        label: team.name + ' ' + '(Team)',
        id: team._id,
        isTeam: true,
      }
    }),

    whoWillUpdate: avengersDetail?.whoWillUpdate.map((WWU: any) => {
      return {
        value: WWU?._id,
        label: userInfo?._id === WWU?._id ? WWU?.fullName + ' ' + '(Me)' : WWU?.fullName,
        id: WWU?._id,
        isTeam: false,
      }
    }),
    whoWillUpdateTeam: avengersDetail?.whoWillUpdateTeam.map((WWU: any) => {
      return {
        value: WWU?._id,
        label: WWU.name + ' ' + '(Team)',
        id: WWU?._id,
        isTeam: true,
      }
    }),
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  useEffect(() => {
    if (calledBy === 'internalInitiativeAssignee') {
      setAssigneesId(usersId)
      setAssigneeTeamsId(teamsId)
    } else if (calledBy === 'whoWillUpdate') {
      setWhoWillUpdateId(usersId)
      setWhoWillUpdateTeamsId(teamsId)
    }
  }, [usersId, teamsId])

  let crumbs
  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: `Internal Initiatives`, url: `/internal-initiatives`},
    {
      name: id ? 'Edit Internal Initiative' : 'Create Internal Initiative',
      url: `/internal-initiatives/${id ? 'Edit' : 'Add'}`,
    },
  ]

  return (
    <>
      <AssigneesList
        show={showAssigneesDialog}
        handleClose={() => {
          setShowAssigneesDialog(false)
        }}
        assignee={assigneeAndTeams?.assignee}
        teams={assigneeAndTeams?.teams}
        calledBy={calledBy}
        setAssigneesId={setUsersId}
        assigneesId={usersId}
        teamsId={teamsId}
        setTeamsId={setTeamsId}
        id={id}
        title={popupTitle}
      />
      <div className='mh-80 page-content'>
        {/* <div className='page-header mx-3'>
          <h2>{id ? 'Edit Internal Initiative' : 'Create Internal Initiative'}</h2>
        </div> */}
        <div className='px-6 px-sm-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5'>
                {id ? 'Edit Internal Initiative' : 'Create Internal Initiative'}
              </h2>
            </div>
          </div>
        </div>
        <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />

        <div className='overflow_height_scroll'>
          <div className='page-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  validationSchema={createAppSchema}
                  initialValues={id ? initialValuesOnEdit : inits}
                  enableReinitialize
                  onSubmit={(values: any) => {
                    values.teams = assigneeTeamsId
                    values.assignee = assigneesId
                    values.whoWillUpdateTeam = whoWillUpdateTeamsId
                    values.whoWillUpdate = whoWillUpdateId

                    if (id) {
                      values._id = id
                      values.workspace = workspaceid
                      dispatch(actions.UpdateAvengersInitiativess(values)).then((response: any) => {
                        onHide()
                      })
                    } else {
                      values.workspace = workspaceid
                      dispatch(actions.createAvengersInitiativess(values, workspaceid)).then(
                        (response: any) => {
                          onHide()
                        }
                      )
                    }
                  }}
                >
                  {({setFieldValue, values}) => (
                    <Form
                      className='form form_opportunity_form'
                      noValidate
                      id='kt_modal_create_app_form'
                    >
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          <div className='fv-row mb-5'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span className='required'>Title</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='title'
                              placeholder='Title'
                              style={{minHeight: '41px', maxHeight: '41px'}}
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='title' />
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-4 fv-row mb-5'>
                              <label className='fs-6 fw-bold mb-2'>
                                <span className='required'>Status</span>
                              </label>

                              <div className='position-relative'>
                                <Field
                                  as='select'
                                  name='currentStatus'
                                  className='form-select form-select-solid'
                                  style={{minHeight: '41px', maxHeight: '41px'}}
                                >
                                  <option value='0'>Select</option>
                                  {currentStatus.map((status: any, index: number) => (
                                    <option key={index} value={status.value}>
                                      {status.name}
                                    </option>
                                  ))}
                                </Field>
                                <div className='text-danger'>
                                  <ErrorMessage name='currentStatus' />
                                </div>
                              </div>
                            </div>

                            <div className='col-md-4 fv-row mb-5'>
                              <label className='fs-6 fw-bold mb-2'>
                                <span className='required'>Update Frequency</span>
                              </label>
                              <div className='row fv-row'>
                                <div className='col-12'>
                                  <Field
                                    as='select'
                                    name='updateFrequency'
                                    className='form-select form-select-solid'
                                    style={{minHeight: '41px', maxHeight: '41px'}}
                                  >
                                    <option value='0'>Select</option>
                                    {updateFrequency.map((frequency: any, index: number) => (
                                      <option key={index} value={frequency.value}>
                                        {frequency.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className='text-danger'>
                                    <ErrorMessage name='updateFrequency' />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-md-4 fv-row mb-5'>
                              <label className='fs-6 fw-bold mb-2'>
                                <span className='required'>Activity</span>
                              </label>
                              <div className='row fv-row'>
                                <div className='col-12'>
                                  <Field
                                    as='select'
                                    name='activity'
                                    className='form-select form-select-solid'
                                    style={{minHeight: '41px', maxHeight: '41px'}}
                                  >
                                    <option value='0'>Select</option>
                                    {activity.map((activity: any, index: number) => (
                                      <option key={index} value={activity.value}>
                                        {activity.name}
                                      </option>
                                    ))}
                                  </Field>
                                  <div className='text-danger'>
                                    <ErrorMessage name='activity' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row'>
                            <div className='col-md-6 fv-row mb-5'>
                              <label className='fs-6 fw-bold mb-2 d-flex'>
                                <span>Assignee</span>{' '}
                                {assigneesId?.length + assigneeTeamsId?.length > 0 ? (
                                  <span className='text-style-count ms-2'>
                                    {assigneesId?.length + assigneeTeamsId?.length}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </label>

                              {/* <div className='row fv-row'>
                              <div className='col-12 multisel'>
                                <div className='multisel-custom-select align-items-center'>
                                  <div
                                    style={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {assigneesId?.length > 0 && (
                                      <span
                                        className='d-inline-flex align-items-center border border-primary border-runded-4 px-2 fs-8 m-2'
                                        style={{borderRadius: '4px'}}
                                        title={showUserNames(
                                          assigneesId,
                                          assigneeAndTeams?.assignee
                                        )}
                                      >
                                        Users
                                        <span className='badge ms-2 bg-primary py-1 px-2 fs-9 m-1'>
                                          {assigneesId?.length}
                                        </span>
                                      </span>
                                    )}
                                    {assigneeTeamsId?.length > 0 && (
                                      <span
                                        className='d-inline-flex align-items-center border border-primary border-runded-4 px-2 fs-8 m-2'
                                        style={{borderRadius: '4px'}}
                                        title={showUserTeamNames(
                                          assigneeTeamsId,
                                          assigneeAndTeams?.teams
                                        )}
                                      >
                                        Teams
                                        <span className='badge ms-2 bg-primary py-1 px-2 fs-9 m-1'>
                                          {assigneeTeamsId?.length}
                                        </span>
                                      </span>
                                    )}
                                  </div>

                                  <button
                                    title='Add'
                                    type='button'
                                    className='btn btn-sm btn-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_create_app'
                                    id='kt_modal_assignees_list_app'
                                    onClick={() => {
                                      setUsersId(assigneesId)
                                      setTeamsId(assigneeTeamsId)
                                      setCalledBy('internalInitiativeAssignee')
                                      setPopupTitle('Assignee')
                                      setShowAssigneesDialog(true)
                                    }}
                                  >
                                    add
                                  </button>
                                </div>
                                <div className='text-danger'>
                                  <ErrorMessage name='assignee' />
                                </div>
                              </div>
                            </div> */}

                              <div className='row fv-row'>
                                <div className='col-12 multisel'>
                                  <div className='d-flex justify-content-center align-items-center bg-lightest'>
                                    <div
                                      style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {assigneesId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserNames(
                                            assigneesId,
                                            assigneeAndTeams?.assignee
                                          )}
                                        >
                                          Users
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {assigneesId?.length}
                                          </span>
                                        </span>
                                      )}
                                      {assigneeTeamsId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserTeamNames(
                                            assigneeTeamsId,
                                            assigneeAndTeams?.teams
                                          )}
                                        >
                                          Teams
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {assigneeTeamsId?.length}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                    <a
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_create_app'
                                      id='kt_modal_assignees_list_app'
                                      onClick={() => {
                                        setUsersId(assigneesId)
                                        setTeamsId(assigneeTeamsId)
                                        setCalledBy('internalInitiativeAssignee')
                                        setPopupTitle('Assignee')
                                        setShowAssigneesDialog(true)
                                      }}
                                    >
                                      <i
                                        className='fa fa-plus-circle text-blue'
                                        aria-hidden='true'
                                      ></i>
                                      <button
                                        title='Add'
                                        type='button'
                                        className='btn btn-sm  bg-transparent text-primary fw-500 p-2 '
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        id='kt_modal_assignees_list_app'
                                      >
                                        add
                                      </button>
                                    </a>
                                  </div>
                                  <div className='text-danger'>
                                    <ErrorMessage name='assignee' />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-md-6 fv-row mb-5'>
                              <label className='fs-6 fw-bold mb-2 d-flex'>
                                <span>Who will update</span>{' '}
                                {whoWillUpdateId?.length + whoWillUpdateTeamsId?.length > 0 ? (
                                  <span className='text-style-count ms-2'>
                                    {whoWillUpdateId?.length + whoWillUpdateTeamsId?.length}
                                  </span>
                                ) : (
                                  ''
                                )}
                              </label>

                              {/* <div className='row fv-row'>
                              <div className='col-12 multisel'>
                                <div className='multisel-custom-select align-items-center'>
                                  <div
                                    style={{
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                      overflow: 'hidden',
                                    }}
                                  >
                                    {whoWillUpdateId?.length > 0 && (
                                      <span
                                        className='d-inline-flex align-items-center border border-primary border-runded-4 px-2 fs-8 m-2'
                                        style={{borderRadius: '4px'}}
                                        title={showUserNames(
                                          whoWillUpdateId,
                                          assigneeAndTeams?.assignee
                                        )}
                                      >
                                        Users
                                        <span className='badge ms-2 bg-primary py-1 px-2 fs-9 m-1'>
                                          {whoWillUpdateId?.length}
                                        </span>
                                      </span>
                                    )}
                                    {whoWillUpdateTeamsId?.length > 0 && (
                                      <span
                                        className='d-inline-flex align-items-center border border-primary border-runded-4 px-2 fs-8 m-2'
                                        style={{borderRadius: '4px'}}
                                        title={showUserTeamNames(
                                          whoWillUpdateTeamsId,
                                          assigneeAndTeams?.teams
                                        )}
                                      >
                                        Teams
                                        <span className='badge ms-2 bg-primary py-1 px-2 fs-9 m-1'>
                                          {whoWillUpdateTeamsId?.length}
                                        </span>
                                      </span>
                                    )}
                                  </div>

                                  <button
                                    title='Add'
                                    type='button'
                                    className='btn btn-sm btn-primary'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_create_app'
                                    id='kt_modal_assignees_list_app'
                                    onClick={() => {
                                      setUsersId(whoWillUpdateId)
                                      setTeamsId(whoWillUpdateTeamsId)
                                      setCalledBy('whoWillUpdate')
                                      setPopupTitle('Who will update')
                                      setShowAssigneesDialog(true)
                                    }}
                                  >
                                    add
                                  </button>
                                </div>
                                <div className='text-danger'>
                                  <ErrorMessage name='assignee' />
                                </div>
                              </div>
                            </div> */}

                              <div className='row fv-row'>
                                <div className='col-12 multisel'>
                                  <div className='d-flex justify-content-center align-items-center bg-lightest'>
                                    <div
                                      style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {whoWillUpdateId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserNames(
                                            whoWillUpdateId,
                                            assigneeAndTeams?.assignee
                                          )}
                                        >
                                          Users
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {whoWillUpdateId?.length}
                                          </span>
                                        </span>
                                      )}
                                      {whoWillUpdateTeamsId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserTeamNames(
                                            whoWillUpdateTeamsId,
                                            assigneeAndTeams?.teams
                                          )}
                                        >
                                          Teams
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {whoWillUpdateTeamsId?.length}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                    <a
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_create_app'
                                      id='kt_modal_assignees_list_app'
                                      onClick={() => {
                                        setUsersId(whoWillUpdateId)
                                        setTeamsId(whoWillUpdateTeamsId)
                                        setCalledBy('whoWillUpdate')
                                        setPopupTitle('Who will update')
                                        setShowAssigneesDialog(true)
                                      }}
                                    >
                                      <i
                                        className='fa fa-plus-circle text-blue'
                                        aria-hidden='true'
                                      ></i>
                                      <button
                                        title='Add'
                                        type='button'
                                        className='btn btn-sm  bg-transparent text-primary fw-500 p-2 '
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        id='kt_modal_assignees_list_app'
                                      >
                                        add
                                      </button>
                                    </a>
                                  </div>
                                  <div className='text-danger'>
                                    <ErrorMessage name='assignee' />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='fv-row'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span>Description</span>
                            </label>

                            <Field
                              className='form-control form-control-lg form-control-solid'
                              name='description'
                              placeholder='Description'
                              as='textarea'
                              rows='5'
                            />

                            <div className='text-danger'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-stack pt-10'>
                        <div> </div>
                        <div className='me-2'>
                          <button
                            type='button'
                            className='btn btn-sm fw-bolder   me-3 fs-7 cancel_button'
                            onClick={onHide}
                            title='Cancel'
                          >
                            Cancel
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm btn-primary btn-blue  fs-7 '
                            title={id ? 'Update' : 'Create'}
                          >
                            <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // </Modal>
  )
}

export {AvengersInitiativesAdd}
