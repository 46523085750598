import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const WORKSPACE_URL = `${API_URL}/list`

export function getLists({
  search,
  page,
  sortby,
  orderby,
  workspace,
}: {
  search: string
  page: number | string
  sortby: string
  orderby: string
  workspace: string
}) {
  return axios.get(`${WORKSPACE_URL}/${workspace}`, {params: {search, page, sortby, orderby}})
}
export function createList(title: string, description: string, assignee: [], workspace: string) {
  return axios.post(`${WORKSPACE_URL}`, {title, description, assignee, workspace})
}
export function updateList(
  _id: string,
  title: string,
  description: string,
  assignee: [],
  workspace: string
) {
  return axios.patch(`${WORKSPACE_URL}/${_id}`, {
    title,
    description,
    assignee,
    workspace,
  })
}

export function deleteList(_id: string, workspace: string) {
  return axios.delete(`${WORKSPACE_URL}/${_id}`, {params: {workspace}})
}

export function fetchList(_id: string, workspaceid: string) {
  return axios.get(`${WORKSPACE_URL}/${_id}/${workspaceid}`)
}
