import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const DISCUSSION_URL = `${API_URL}/discussions`
const MESSAGE_URL = `${API_URL}/message`

export function getAllWorkspaceUsers(workSpaceId: string) {
  return axios.get(`${DISCUSSION_URL}?workSpace=${workSpaceId}`)
}

export function createChatIFNotExist(workSpace: string, userId: string, chatType: string) {
  return axios.post(`${DISCUSSION_URL}`, {workSpace, userId, chatType})
}

export function createGroup(chatName: string, users: any, workSpace: string, chatType: string) {
  return axios.post(`${DISCUSSION_URL}/group`, {chatName, users, workSpace, chatType})
}

export function getAllGroupChat(workSpaceId: string, chatType: string) {
  return axios.get(`${DISCUSSION_URL}/allchat`, {params: {workSpaceId, chatType}})
}

export function getChannelUsers(chatId: string) {
  return axios.get(`${DISCUSSION_URL}/group/${chatId}`)
}

export function updatedGroup(chatId: string, chatName: string, users: Array<string>) {
  return axios.put(`${DISCUSSION_URL}/updategroup`, {
    chatId: chatId,
    chatName: chatName,
    users: users,
  })
}

export function deleteChannel(chatId: string) {
  return axios.delete(`${DISCUSSION_URL}/${chatId}`)
}

export function getAllMessages(chatId: string) {
  return axios.get(`${MESSAGE_URL}/${chatId}`)
}

export function sendMessage(chat: string, workSpace: string, content: string) {
  return axios.post(`${MESSAGE_URL}`, {chat, workSpace, content})
}

export function messageScenedUserIds(chatId: string) {
  return axios.put(`${MESSAGE_URL}/${chatId}`)
}
