/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { toAbsoluteUrl } from '../helpers'

const NotFound: FC = () => {
  return (
    <div className='no-item-found'>
      <div className='d-flex align-items-center flex-column'>
        <img src={toAbsoluteUrl('/media/icons/todo.png')} className='img-fluid p-3' alt='icon' />
        <p className='fs-1 text-700 text-center text-primary w-100 fs-800'>No Item Found</p>
      </div>
    </div>
  )
}

export {NotFound}
