import axios from 'axios'
import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {RootState} from '../../../setup'
import {UserModel} from '../auth/models/UserModel'

const API_URL = process.env.REACT_APP_API_URL
const VERIFY_INVITE = `${API_URL}/user-invitation/verify`

const VerifyUserInvitation: React.FC = () => {
  const search = window.location.search
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const _id = params.get('id')

  const history = useHistory()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  if (user) {
    axios.patch(VERIFY_INVITE, {code: code, _id: _id}).then((response) => {
      if (response.data.status === true) {
        history.push('/dashboard')
      } else {
        history.push('/account/my-profile')
      }
    })
  } else {
    history.push(`/login?code=${code}&id=${_id}`)
  }

  return <></>
}

export {VerifyUserInvitation}
