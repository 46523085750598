import React from 'react'
import {WorkspaceMembersTable} from './components/workspaceMembersTable'
import {Route, Switch} from 'react-router-dom'

const WorkspaceMembers: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/:workspaceid/members'>
          <WorkspaceMembersTable className='mb-5 mb-xl-8' />
        </Route>
      </Switch>
    </>
  )
}

export {WorkspaceMembers}
