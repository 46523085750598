/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch, Link} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {LdapLogin} from './components/LdapLogin'
import {ResetPassword} from './components/ResetPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {TalentPublic} from '../talent/talentPublicPage'

export function AuthPage() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <div
      className='Login-page d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      // style={{
      //   backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.jpg')})`,
      // }}
    >
      {/* begin::Content */}
      <div className='d-flex flex-centerr position-x-center flex-column flex-column-fluid p-10 pt-11 pb-lg-20'>
        {/* begin::Logo */}
        <Link to='/login' className='mb-10 w-auto mx-auto'>
          {/* <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-4.png')}
            className='h-45px d-flex position-x-center mx-auto'
          /> */}
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/logo-4.svg')}
            className='h-75px d-flex position-x-center mx-auto'
          />
        </Link>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        {window.location.pathname.includes('talent') &&
        !window.location.search.includes('widget') ? (
          <Switch>
            <Route path='/talent' component={TalentPublic} />
          </Switch>
        ) : (
          <div className='w-lg-500px bg-white rounded  p-10 p-lg-15 mx-auto box-shadow-light'>
            <Switch>
              <Route path='/login' component={Login} />
              <Route path='/registration' component={Registration} />
              <Route path='/forgot-password' component={ForgotPassword} />
              <Route exact path='/auth/reset/password/:token' component={ResetPassword} />
              <Redirect from='/groupcaptain' exact={true} to='/login' />
              <Redirect
                to={{
                  pathname: '/login',
                  state: {
                    prevPath: window.location.pathname,
                  },
                }}
              />
            </Switch>
          </div>
        )}

        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
  )
}
