/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Search } from '../../../_metronic/layout/search/Search'
import { getNewSpaceMembers } from '../UIHelper'
import { HeaderBreadcrumbsTest2 } from './HeaderBreadcrumbsTest2'
import { SpaceDetail } from './SpaceDetail'

const HeaderBreadcrumbsTest: FC = () => {
  const { isLoading, settings, user, dashSettings }: any = useSelector<any>(
    (state) => ({
      isLoading: state.workspace.listLoading,
      settings: state.auth.user.settings,
      user: state.auth.user,
      dashSettings: state.settings.entities,
    }),
    shallowEqual
  )
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  return (
    <>
      <div className={`tabs-main card legend-card-main`}>
        <div className='px-12'>
          {/* begin::Header */}
          <div className="row">
            <div className="col-sm-6">
              <h2 className='fw-500 fs-2 m-0 py-5'>
                Testing Header
              </h2>
            </div>

            {/* new space sarchbar + icons start */}

            <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
              <HeaderBreadcrumbsTest2 />
              <button
                className='btn btn-sm btn-icon btn-active-light-primary p-2' title='Icon View'>
                <a>
                  <KTSVG path='/media/icons/projects/blocks.svg' />
                </a>
              </button>

              <button className='btn btn-sm btn-icon btn-active-light-primary p-2' title='Tile View'>
                <a>
                  <KTSVG path='/media/icons/projects/gen010.svg' />
                </a>
              </button>

              <button type='button' className='btn btn-clean btn-sm btn-icon btn-active-light-primary p-2' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end' title='Filters'>
                <a>
                  <KTSVG path='/media/icons/projects/filter.svg' />
                </a>
              </button>
              <button type='button' className='btn btn-clean btn-sm btn-icon btn-active-light-primary p-2' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end' title='Settings'>
                <KTSVG path='/media/icons/duotune/coding/cod001.svg'
                  className='svg-icon-2 m-0 p-2' />
              </button>
            </div>

            {/* new space sarchbar + icons end */}


            {/* space detail start */}

            {/* <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center flex-wrap flex-sm-nowrap">


              <div className=''>
                <span className='text-muted fs-7 mb-0 me-2' style={{ whiteSpace: 'nowrap' }}>
                  Created By
                </span>

                <span className='fs-7  mb-0 me-2' style={{ whiteSpace: 'nowrap' }}>
                  Addan
                </span>

                <span className='text-muted fs-7 mb-0 me-2' style={{ whiteSpace: 'nowrap' }}>
                  06 Jan, 2023
                </span>
              </div>

              <div className='d-flex align-items-center'>
                <span className='fs-7  mb-0 me-6' style={{ whiteSpace: 'nowrap' }}>
                  Members
                </span>

                <div className='d-flex px-1 spaces_card_img'>
                  <div className='symbol-group'>

                    <div className='symbol symbol-35px symbol-circle symbol_service'>
                      <img className='symbol-label fs-8 fw-bolder'
                        src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />
                    </div>
                    <div className='symbol symbol-35px symbol-circle symbol_service'>
                      <img className='symbol-label fs-8 fw-bolder'
                        src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />

                    </div>
                    <div className='symbol symbol-35px symbol-circle symbol_service'>
                      <img
                        className='symbol-label fs-8 fw-bolder'
                        src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />

                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* space detail end */}

          </div>
        </div>
        {/* end::Header */}
      </div>
    </>
  )
}

export { HeaderBreadcrumbsTest }
