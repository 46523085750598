import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import * as actions from '../redux/DocumentsAction'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
import moment from 'moment'
import {Pagination} from '../../../pagination/pagination'
import {Search} from '../../../../_metronic/layout/search/Search'
import {Link, useLocation, useParams} from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Can} from '../../../Common/RBAC/Can'
import {DocumentDeleteDialog} from './DocumentDeleteDialog'
import {AttachmentListing} from './attachmentListing'
import {RenameFile} from './RenameFile'
import axios from 'axios'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'

const filesExtensions = ['csv', 'pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx']

type Props = {
  className: string
}
interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}
export interface DocumentsModel {
  sharedWith: any
  _id: string
  name: string
  createdAt: string
  updatedAt: string
  document: {
    originalName: string
    _id: string
  }
  parent: string
  grandParent: string
  attachments: any[]
  type: string
  createdBy: {
    fullName: string
    _id: string
  }
}

const API_URL = process.env.REACT_APP_API_URL
const LIST_USERS = `${API_URL}/todos/assignees`
const LIST_USERS_APPS = `${API_URL}/users`

const DocumentTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()
  const location: any = useLocation()

  const {documentsData, listLoading, spaceDetail, documentDetail, accessToken, user}: any =
    useSelector<RootState>(
      (state) => ({
        documentsData: state.documents.entities,
        listLoading: state.documents.listLoading,
        spaceDetail: state.workspace.workspaceDetail,
        documentDetail: state.documents.documentDetail,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
      }),
      shallowEqual
    ) as DocumentsModel

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.documents,
    shallowEqual
  ) as PaginationModel

  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showCreateFolder, setShowCreateFolder] = useState(false)
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: 'createdAt',
    NotFound: '',
  })
  const [idToDelete, setIdToDelete] = useState('')
  const [createFolder, setCreateFolder] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [showRenameFile, setShowRenameFile] = useState(false)
  const [idRename, setIdRename] = useState('')

  //To share docs with other users
  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)
  const [allMembers, setAllMembers]: any = useState()
  const [documentId, setdocumentId] = useState('')
  const [assigneesId, setAssigneesId] = useState<any>([])
  const [calledBy, setCalledBy] = useState('')

  const {workspaceid}: {workspaceid: string} = useParams()
  const {folderid}: {folderid: string} = useParams()

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  useEffect(() => {
    if (folderid) {
      dispatch(actions.fetchDocument(folderid))
    }
  }, [folderid])

  const fetchAssigneesDataAsFeature = async () => {
    const assigneeData = await axios.get(`${LIST_USERS}/${workspaceid}`)
    setAllMembers(assigneeData?.data[0]?.assignee)
    setCalledBy('documentFeature')
  }
  const getAssigneesDataAsApp = async () => {
    const usersData: any = await axios.get(LIST_USERS_APPS)
    setAllMembers(usersData?.data)
    setCalledBy('documentApp')
  }
  useEffect(() => {
    if (workspaceid) {
      fetchAssigneesDataAsFeature()
    } else {
      getAssigneesDataAsApp()
    }
  }, [])

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`todo-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && documentsData?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && documentsData?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchDocuments({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workspace: workspaceid,
        parent: folderid,
        grandParent: location?.state?.grandParent,
      })
    )
  }, [sortValues, folderid])

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        NotFound: event.target.abbr,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        NotFound: event.target.abbr,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchDocuments({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workspace: workspaceid,
        parent: folderid,
        grandParent: location?.state?.grandParent,
      })
    )
  }
  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchDocuments({
        search: search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workspace: workspaceid,
        parent: folderid,
        grandParent: location?.state?.grandParent,
      })
    )
  }
  useEffect(() => {
    if (documentsData?.length > 0) {
      setShowTable(true)
    } else {
      setShowTable(false)
    }
  }, [documentsData])

  let crumbs
  crumbs =
    workspaceid && !folderid
      ? [
          {name: 'Spaces', url: '/dashboard'},
          {name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features`},
          {name: 'Documents', url: `/${workspaceid}/documents`},
        ]
      : workspaceid && folderid
      ? [
          {name: 'Spaces', url: '/dashboard'},
          {name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features`},
          {name: 'Documents', url: `/${workspaceid}/documents`},
          {name: `${documentDetail?.name}`, url: `/${folderid}/documents`},
        ]
      : !workspaceid && !folderid
      ? [
          {name: 'Dashboard', url: '/dashboard'},
          {name: 'Documents', url: `/documents`},
        ]
      : [
          {name: 'Dashboard', url: '/dashboard'},
          {name: 'Documents', url: `/documents`},
          {name: `${documentDetail?.name}`, url: `/${folderid}/documents`},
        ]

  return (
    <>
      <DocumentDeleteDialog
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={idToDelete}
      />
      <RenameFile
        documentsData={documentsData}
        workspaceid={workspaceid}
        folderid={folderid}
        setShowTable={setShowTable}
        setCreateFolder={setCreateFolder}
        id={idRename}
        setIdRename={setIdRename}
        accessToken={accessToken}
        show={showRenameFile}
        handleClose={() => {
          setShowRenameFile(false)
        }}
      />
      {
        <AssigneesList
          show={showAssigneesDialog}
          handleClose={() => {
            setShowAssigneesDialog(false)
          }}
          calledBy={calledBy}
          setAssigneesId={setAssigneesId}
          assigneesId={assigneesId}
          id={documentId}
          assignee={allMembers}
          teams={[]}
          title={'Share File/Folder'}
        />
      }
      <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
        {/*<div className='card-header border-0 ps-12'>
          /~ begin::Header ~/
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-500 fs-2 mb-1'>
              {folderid ? documentDetail?.name : 'Documents'}
            </span>
          </h3>
          <Search handleSearch={handleSearch} />
          /~ <Can I='create' this={workspaceid ? 'spaces' : 'documents'}>
            <div
              className='card-toolbar mt-0 pt-2 me-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Add Folder'
            >
              <button
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => {
                  setShowTable(true)
                  setCreateFolder(true)
                }}
              >
                <KTSVG path='/media/icons/duotune/files/fil013.svg' className='svg-icon-2' />
                Add Folder
              </button>
            </div>
          </Can>
          <Can I='create' this={workspaceid ? 'spaces' : 'documents'}>
            <div
              className='card-toolbar mt-0 pt-2'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Upload Document'
            >
              <Link
                to={
                  !workspaceid && !folderid
                    ? `/documents/add`
                    : workspaceid && !folderid
                    ? `/${workspaceid}/documents/add`
                    : !workspaceid && folderid
                    ? `/documents/${folderid}/add`
                    : `/${workspaceid}/documents/${folderid}/add`
                }
                className='btn btn-sm btn-light-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' />
                Upload Document
              </Link>
            </div>
          </Can> ~/
        </div>*/}
        <div className='px-6 px-sm-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
                {folderid ? documentDetail?.name : 'Documents'}
              </h2>
            </div>
            <div className='col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center'>
              <Search handleSearch={handleSearch} />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}

          <div
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_delete_app'
            id='kt_toolbar_primary_button'
            onClick={() => {
              setShowTable(true)
              setCreateFolder(true)
              setShowRenameFile(true)
            }}
          >
            <span
              className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
              style={{whiteSpace: 'nowrap'}}
            >
              <KTSVG path='' className='svg-icon-3' />
              <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
              <span className='link_button'> Create Folder</span>
            </span>
          </div>
          <Link
            className='me-sm-10'
            to={
              !workspaceid && !folderid
                ? `/documents/add`
                : workspaceid && !folderid
                ? `/${workspaceid}/documents/add`
                : !workspaceid && folderid
                ? `/documents/${folderid}/add`
                : `/${workspaceid}/documents/${folderid}/add`
            }
          >
            <span
              className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
              style={{whiteSpace: 'nowrap'}}
            >
              <KTSVG
                path='/media/icons/duotune/files/fil018.svg'
                className='svg-icon-3 link_button'
                svgClassName={'header_icon active'}
              />
              {/* <i className='fa fa-plus-circle ' aria-hidden='true' style={{color: '#0368CD'}}></i> */}
              <span className='link_button'> Upload Document</span>
            </span>
            {/* <KTSVG path='/media/icons/duotune/files/fil018.svg' className='svg-icon-3' />
            Upload Document */}
          </Link>
        </div>

        {/* end::Header */}
        {/* begin::Body */}

        <div className='overflow_height_scroll'>
          <div className='card-body d-flex flex-column py-3'>
            {/* begin::Table container */}
            {showTable && !listLoading && (
              <div className='table-responsive'>
                {/* begin::Table */}

                <table className='todo-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bolder text-muted'>
                      <th
                        className=''
                        style={{cursor: 'pointer', minWidth: '100px'}}
                        onClick={sortColumn}
                        abbr='name'
                        id='list-name-head'
                      >
                        Name
                      </th>
                      <th
                        className='min-w-120px text-nowrap'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='createdBy'
                        id='list-createdBy-head'
                      >
                        Created By
                      </th>
                      <th
                        className='min-w-120px text-nowrap'
                        style={{cursor: 'pointer'}}
                        onClick={sortColumn}
                        abbr='createdAt'
                        id='list-createdAt-head'
                      >
                        Created Date
                      </th>

                      <th className='min-w-100px text-start text-nowrap'>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {createFolder && (
                    <tr>
                      <td>
                        {/~ <RenameFile
                          documentsData={documentsData}
                          workspaceid={workspaceid}
                          folderid={folderid}
                          setShowTable={setShowTable}
                          setCreateFolder={setCreateFolder}
                          id={null}
                          setIdRename={setIdRename}
                          accessToken={accessToken}
                        /> ~/}
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}*/}
                    {/* <img src='https://focus3.com/wp-content/uploads/2020/01/10_things_that_require_courage-1.jpg' /> */}
                    {documentsData.map((document: DocumentsModel, index: number) => {
                      return (
                        <tr key={index}>
                          <td data-label='Title'>
                            {/* <Link className='d-flex' to={`/list/${list?._id}`}> */}
                            {showTable && (
                              <div
                                className='line-clamp'
                                style={{width: '100%'}}
                                title={document?.name}
                              >
                                {document?.type !== 'file' && (
                                  <Link
                                    to={
                                      workspaceid
                                        ? `/${workspaceid}/documents/${document._id}`
                                        : `/documents/${document._id}`
                                    }
                                    replace
                                  >
                                    {/* <img
                                        src={'/media/icons/files/folder.png'}
                                        style={{height: '40px', width: '40px'}}
                                        className='me-2'
                                      /> */}
                                    <KTSVG
                                      path='/media/icons/duotune/files/fil012.svg'
                                      className='svg-icon-1 me-2 mt-3'
                                    />
                                  </Link>
                                )}
                                {document?.type === 'file' ? (
                                  <AttachmentListing
                                    attachments={document?.attachments}
                                    attachmentType='listing'
                                    id={document?._id}
                                    itemType='documents'
                                    calledThrough={'filname'}
                                    name={document?.name}
                                    type={document?.attachments[0].type}
                                  />
                                ) : (
                                  <Link
                                    to={{
                                      pathname: workspaceid
                                        ? `/${workspaceid}/documents/${document._id}`
                                        : `/documents/${document._id}`,
                                      state: {
                                        grandParent: document.grandParent || '',
                                      },
                                    }}
                                    replace
                                  >
                                    {document?.name}
                                  </Link>
                                )}
                              </div>
                            )}
                            {/* <div className='d-flex align-items-center justify-content-start flex-column'>
                            </div> */}
                            {/* </Link> */}
                          </td>

                          <td data-label='Created By' title={document?.createdBy?.fullName}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {document?.createdBy?.fullName}
                              </div>
                            </div>
                          </td>

                          <td
                            data-label='Created Date'
                            title={moment(document?.createdAt).format('MM/DD/YYYY')}
                          >
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {moment(document?.createdAt).format('MM/DD/YYYY')}
                              </div>
                            </div>
                          </td>

                          <td data-label='Actions'>
                            <div className='d-flex justify-content-start flex-shrink-0'>
                              {/* <Can I='update' this='spaces'>
                          <Link
                            to={`#`}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            title='Edit'
                          >
                            <KTSVG
                              path='/media/icons/duotune/art/art005.svg'
                              className='svg-icon-3'
                            />
                          </Link>
                        </Can> */}
                              {document?.type === 'file' && (
                                <AttachmentListing
                                  attachments={document?.attachments}
                                  attachmentType='listing'
                                  id={document?._id}
                                  itemType='documents'
                                  calledThrough={'icon'}
                                  name={document?.name}
                                  type={document?.attachments[0].type}
                                />
                              )}
                              {document?.type === 'folder' && (
                                <Link
                                  to={
                                    workspaceid
                                      ? `/${workspaceid}/documents/${document._id}`
                                      : `/documents/${document._id}`
                                  }
                                  replace
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-1 me-2 bg-light-blue-actions'
                                  title='View Folder'
                                >
                                  <KTSVG path='/media/icons/view.svg' className='svg-icon-3' />
                                </Link>
                              )}

                              <Can I='update' this={workspaceid ? 'spaces' : 'documents'}>
                                {user._id === document.createdBy._id && (
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-1 me-2 bg-light-blue-actions'
                                    title='Rename'
                                    onClick={() => {
                                      setIdRename(document?._id)
                                      setShowRenameFile(true)
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/art/art005.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                )}
                              </Can>
                              <Can I='delete' this={workspaceid ? 'spaces' : 'documents'}>
                                {user._id === document.createdBy._id && (
                                  <button
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2 bg-light-blue-actions'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_delete_app'
                                    id='kt_toolbar_primary_button'
                                    title='Delete'
                                    onClick={() => {
                                      setIdToDelete(document?._id)
                                      setShowDeleteDialog(true)
                                    }}
                                  >
                                    <KTSVG
                                      path='/media/icons/duotune/general/gen027.svg'
                                      className='svg-icon-3'
                                    />
                                  </button>
                                )}
                              </Can>
                              {user._id === document.createdBy._id && (
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-1 bg-light-blue-actions '
                                  title='Share'
                                  onClick={() => {
                                    setdocumentId(document?._id)
                                    setAssigneesId(
                                      document?.sharedWith?.map((data: any) => {
                                        return data._id
                                      })
                                    )
                                    setShowAssigneesDialog(true)
                                  }}
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/coding/cod007.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>

                  {/* end::Table head */}
                  {/* begin::Table body */}

                  {/* end::Table body */}
                </table>

                {/* end::Table */}
              </div>
            )}
            {documentsData?.length <= 0 && !listLoading && !createFolder && <NotFound />}
            {listLoading && <Loading />}
            {/* end::Table container */}
          </div>
        </div>

        <Pagination paginationData={paginationData} fetchData={handlePagination} />

        {/* begin::Body */}
      </div>
    </>
  )
}

export {DocumentTable}
