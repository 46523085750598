import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id: string
  todo: string
  type: string
  description: string
  assigneeChanged: string
  createdBy: string
}

// Define a type for the slice state
interface ActivityLogsState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount?: number
  entities: [entities]
  start: number
  end: number
  limitPerPage: number
  todoDetail?: any
  attachments?: any
}

// Define the initial state using that type
const initialState: ActivityLogsState = {
  actionsLoading: false,
  listLoading: false,
  entities: [{_id: '', todo: '', type: '', description: '', assigneeChanged: '', createdBy: ''}],
  todoDetail: null,
  start: 1,
  end: 10,
  limitPerPage: 10,
  totalCount: 0,
  attachments: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const generalActivityLogsSlice = createSlice({
  name: 'activities',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    activityLogsFetched: (state, action: PayloadAction<ActivityLogsState>) => {
      const {entities, totalCount, start, end, limitPerPage} = action.payload
      state.listLoading = false
      if (entities && Array.isArray(entities)) {
        const currentPage = start / limitPerPage
        if (currentPage > 1) {
          state.entities.push(...entities)
        } else {
          state.entities = entities
        }
        state.totalCount = totalCount
        state.start = start
        state.end = end
      }
    },
    activityLogsCreated: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)
      state.entities.push(action.payload.entities)
    },
    imageUploaded: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)

      state.attachments.push({path: action.payload.path, url: action.payload.url})
    },
    loadAttachments: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.attachments = action.payload
      } else {
        state.attachments.push(action.payload)
      }
    },
    removeAttachments: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)
      state.attachments = action.payload.attachments
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    activityUpdated: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    singleTodoFetched: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)
      state.listLoading = false
      state.todoDetail = action.payload.todo
    },
  },
})
