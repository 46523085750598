/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import '../../../../_metronic/assets/sass/components/cards.scss'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import * as actions from '../redux/organizationActions'
import {RootState} from '../../../../setup'
import {CreateChart} from '../organizationModel'
import {useHistory, useParams} from 'react-router-dom'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'

const inits: CreateChart = {
  company: '',
  name: '',
}

const ChartAdd: FC<any> = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const dispatch: any = useDispatch()

  const {id, chartId}: {id: string; chartId: string} = useParams()
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    if (chartId) {
      dispatch(actions.fetchOrganizationChart({chartId}))
    }
  }, [chartId])

  useEffect(() => {
    dispatch(actions.getAllCompanies()).then((companies: any) => {
      const allCompanies = companies.reduce((manageCompany: any, company: any) => {
        manageCompany.push({value: company?._id, label: company.name})
        return manageCompany
      }, [])

      setCompanies(allCompanies)
    })
  }, [])

  const {chartDetail}: any = useSelector<RootState>(
    (state) => ({
      chartDetail: state.organization.chartDetail,
    }),
    shallowEqual
  ) as any

  const userValidationSchema = {
    company: Yup.string().required('Organization is required'),
    name: Yup.string().required('Name is required').label('name'),
    // .min(2, 'Name must contain more than 1 digits'),
  }
  const createAppSchema = Yup.object().shape(userValidationSchema)

  const initialValuesOnEdit = {
    company: chartDetail?.company?._id,
    name: chartDetail?.name,
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const history = useHistory()

  const crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    // {name: 'Organizations', url: '/organization'},
    // { name: `${organizationDetail?.name}`, url: `/organization/${organizationDetail?._id}/groups` },
    {name: `Groups`, url: `/groups`},
    {
      name: chartId ? 'Edit Group' : 'Create Group',
      url: ``,
    },
  ]

  return (
    <div className='mh-80 page-content'>
      <div className='px-6 px-sm-12'>
        <div className='row'>
          <div className='col-sm-6'>
            <h2 className='fw-500 fs-2 m-0 py-5'>{chartId ? 'Edit Group' : 'Create Group'}</h2>
          </div>
        </div>
      </div>
      <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />

      <div className='overflow_height_scroll'>
        <div className='page-body py-lg-10 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={createAppSchema}
                initialValues={chartId ? {...inits, ...initialValuesOnEdit} : inits}
                enableReinitialize
                onSubmit={(values: any) => {
                  if (chartId) {
                    values.id = chartId
                    dispatch(actions.updateOrganizationChart(values)).then(history.goBack)
                  } else {
                    dispatch(actions.createOrganizationChart(values)).then(history.goBack)
                  }
                }}
              >
                {({setFieldValue, values, errors}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Organization</span>
                          </label>

                          <select
                            className='form-select form-select-solid'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            onChange={(e) => setFieldValue('company', e.target.value)}
                            value={values.company}
                            name='company'
                          >
                            <option value=''>Select</option>
                            {companies.map((company: any, index: number) => {
                              return (
                                <option value={company.value} key={index}>
                                  {company.label}
                                </option>
                              )
                            })}
                          </select>
                          <div className='text-danger'>
                            <ErrorMessage name='company' />
                          </div>
                        </div>

                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Name</span>
                          </label>
                          <Field
                            type='text'
                            name='name'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Name'
                            style={{minHeight: '41px', maxHeight: '41px'}}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bolder me-3 fs-7 cancel_button'
                          onClick={history.goBack}
                          title='Cancel'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary btn-blue fs-7'
                          title={chartId ? 'Update' : 'Create'}
                        >
                          <span className='indicator-label'>{chartId ? 'Update' : 'Create'}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ChartAdd}
