import {ErrorMessage, Field} from 'formik'
import React, {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
import {experiences, ratings} from '../talentUIHelper'
// import styles from './s'

interface Props {
  values: any
  arrayHelpers: any
  saveData: boolean
  widgetId: string
  editFieldPermissions: any
  minSkills?: number
}

export const SkillsTable: FC<Props> = ({
  values,
  saveData,
  arrayHelpers,
  widgetId,
  editFieldPermissions,
  minSkills,
}) => {
  const {widgetSettings}: any = useSelector<RootState>(
    (state) => ({
      widgetSettings: state.talent.widgetSettings,
    }),
    shallowEqual
  )

  const mouseEnter = (e: any) => {
    e.target.style.fill = widgetSettings.buttonHoverColor
  }

  return (
    <table className='table table-responsive talentTable'>
      <tbody>
        {values?.skills?.length > 0 &&
          values.skills.map((skill: any, index: any) => (
            <React.Fragment key={index}>
              <tr>
                <td data-label='Skill' className='col-md-4' title='Skill'>
                  <div className='d-flex flex-column'>
                    <Field
                      name={`skills.[${index}].name`}
                      placeholder='Skill Name e.g PHP'
                      className='form-control form-control-md form-control-solid'
                      style={
                        widgetId
                          ? {
                              color: `${widgetSettings?.textColor}`,
                              background: widgetSettings.fieldBg,
                              borderColor: widgetSettings.borderColor,
                            }
                          : {}
                      }
                      readOnly={!editFieldPermissions()}
                    />

                    <div className='text-danger'>
                      {saveData && <ErrorMessage name={`skills.[${index}].name`} />}
                    </div>
                  </div>
                </td>

                <td data-label='Rating' className='col-md-3' title='Rating'>
                  <div className='d-flex flex-column '>
                    {/* <Field
                      type="number"
                      name={`skills.[${index}].rating`}
                      placeholder='Rating'
                      className='form-control form-control-md form-control-solid'
                    /> */}
                    <>
                      <Field
                        as='select'
                        name={`skills.[${index}].rating`}
                        className='form-select form-select-md form-select-solid'
                        style={
                          widgetId
                            ? {
                                minHeight: '41px',
                                maxHeight: '41px',
                                color: `${widgetSettings?.textColor}`,
                                background: widgetSettings.fieldBg,
                                borderColor: widgetSettings.borderColor,
                              }
                            : {}
                        }
                        disabled={!editFieldPermissions()}
                      >
                        <option value=''>Select rating</option>
                        {ratings.map((rating: number, i: any) => {
                          return (
                            <option key={i} value={rating}>
                              {rating}
                            </option>
                          )
                        })}
                      </Field>
                      <div className='text-danger'>
                        {saveData && <ErrorMessage name={`skills.[${index}].rating`} />}
                      </div>
                    </>
                  </div>
                </td>

                <td data-label='Experience' className='col-md-3' title='Experience'>
                  <div className='d-flex flex-column '>
                    <>
                      <Field
                        as='select'
                        name={`skills.[${index}].experience`}
                        className='form-select form-select-md form-select-solid'
                        style={
                          widgetId
                            ? {
                                minHeight: '41px',
                                maxHeight: '41px',
                                color: `${widgetSettings?.textColor}`,
                                background: widgetSettings.fieldBg,
                                borderColor: widgetSettings.borderColor,
                              }
                            : {}
                        }
                        disabled={!editFieldPermissions()}
                      >
                        <option value=''>Select experience</option>
                        {experiences.map((exp: any, i: any) => {
                          return (
                            <option key={i} value={exp.label}>
                              {exp.label}
                            </option>
                          )
                        })}
                      </Field>
                      <div className='text-danger'>
                        {saveData && <ErrorMessage name={`skills.[${index}].experience`} />}
                      </div>
                    </>
                  </div>
                </td>

                <td className='col-md-2'>
                  <div className='d-flex flex-row '>
                    {editFieldPermissions() && (
                      <button
                        type='button'
                        className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3 `}
                        onClick={() => arrayHelpers.remove(index)}
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Remove Item'
                        disabled={
                          minSkills ? index < minSkills : values.skills.length > 1 ? false : true
                        }
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon svg-icon-3'
                        />
                      </button>
                    )}
                    {values.skills.length === index + 1 && editFieldPermissions() && (
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        title='Add New Item'
                        onClick={() =>
                          arrayHelpers.push({
                            name: '',
                            rating: '',
                            experience: '',
                          })
                        }
                        disabled={!editFieldPermissions()}
                      >
                        <KTSVG
                          path='/media/icons/duotune/arrows/arr013.svg'
                          className='svg-icon  svg-icon-3'
                        />
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            </React.Fragment>
          ))}
      </tbody>
    </table>
  )
}
