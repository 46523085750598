/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import throttle from 'lodash/throttle'

/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// import {MAIN_APP_SIDEBAR_ID} from '../../constants/global'
const MAIN_APP_SIDEBAR_ID = 'kanban-main'

/*------------------------------------------------*/
/* CONSTANTS
/*------------------------------------------------*/
const OFFSET = 50
const PX_DIFF = 5
const PX_DIFF2 = 100

/*------------------------------------------------*/
/* GLOBAL VARIABLES
/*------------------------------------------------*/
let scrollIncrementRightLeft = 0
let scrollIncrementUpDown = 0
let isScrolling = false
let sidebarElement = null

/*------------------------------------------------*/
/* METHODS
/*------------------------------------------------*/

/**
 * Scroll right in the sidebar.
 */
const goRight = () => {
  scrollIncrementRightLeft = PX_DIFF + scrollIncrementRightLeft
  sidebarElement.scrollLeft = scrollIncrementRightLeft

  if (isScrolling && scrollIncrementRightLeft >= 0) {
    window.requestAnimationFrame(goRight)
  }
}

/**
 * Scroll left in the sidebar.
 */
const goLeft = () => {
  scrollIncrementRightLeft = scrollIncrementRightLeft - PX_DIFF
  sidebarElement.scrollLeft = scrollIncrementRightLeft
  // && scrollIncrement >= 0

  if (isScrolling && scrollIncrementRightLeft > 0) {
    window.requestAnimationFrame(goLeft)
  }
}

const onDragOver = (event) => {
  const clientRect = sidebarElement.getBoundingClientRect()
  if (
    (sidebarElement.scrollLeft >= 0 &&
      sidebarElement.scrollLeft <
        sidebarElement.parentNode.parentNode.parentNode.offsetWidth + 100) ||
    scrollIncrementRightLeft === 0
  ) {
    scrollIncrementRightLeft = sidebarElement.scrollLeft
  }

  const isMouseOnLeft =
    scrollIncrementRightLeft > 0 && 600 < sidebarElement.offsetWidth - event.clientX

  const isMouseOnRight =
    scrollIncrementRightLeft >= 0 &&
    scrollIncrementRightLeft <= sidebarElement.offsetWidth &&
    OFFSET > sidebarElement.offsetWidth - event.clientX

  if (!isScrolling && (isMouseOnLeft || isMouseOnRight)) {
    isScrolling = true

    if (isMouseOnRight) {
      window.requestAnimationFrame(goRight)
    } else if (isMouseOnLeft) {
      window.requestAnimationFrame(goLeft)
    }
  } else if (!isMouseOnRight && !isMouseOnLeft) {
    isScrolling = false
  }
}

/**
 * The "throttle" method prevents executing the same function SO MANY times.
 */
const throttleOnDragOver = throttle(onDragOver, 300)

// IMPORTANT: CALL THIS METHOD IN: beginDrag!!!
const addEventListenerForSidebar = () => {
  sidebarElement = document.getElementById(MAIN_APP_SIDEBAR_ID)
  sidebarElement.addEventListener('dragover', throttleOnDragOver)
}

// IMPORTANT: CALL THIS METHOD IN: endDrag!!!
const removeEventListenerForSidebar = () => {
  sidebarElement.removeEventListener('dragover', throttleOnDragOver)
  isScrolling = false
}

/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default {
  addEventListenerForSidebar,
  removeEventListenerForSidebar,
}
