import * as serverRequest from './OpportunityWatchCRUD'
import {opportunityWatchSlice, callTypes} from './OpportunityWatchSlice'
import {AppDispatch} from '../../../../setup/redux/Store'

const {actions} = opportunityWatchSlice

export const fetchOpportunityWatch =
  ({
    search,
    page,
    sortby,
    orderby,
    status,
    createdAt,
    activeDate,
  }: // assignee,
  // teams,
  // workSpace,
  {
    search: string
    page: number | string
    sortby: string
    orderby: string
    status: string
    createdAt: string
    activeDate: string
    // assignee: any
    // teams: any
    // workSpace: string | null
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest
      .getOpportunityWatchList({
        search,
        page,
        sortby,
        orderby,
        status,
        createdAt,
        activeDate,
        // assignee,
        // teams,
        // workSpace,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.opportunityWatchFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }
export const createOpportunityWatch = (opportunityWatchToCreate: any) => (dispatch: any) => {
  const {technology, plan, status, activeDate, lead, attachmentPaths} = opportunityWatchToCreate
  return serverRequest
    .addOpportunityWatchList({technology, plan, status, activeDate, lead, attachmentPaths})
    .then((response) => {
      const {data, totalCount}: {data: any; totalCount: any} = response.data
      dispatch(actions.opportunityWatchCreated({totalCount, entities: data}))
      serverRequest
        .getOpportunityWatchList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          status: '',
          createdAt: '',
          activeDate: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.opportunityWatchFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
}
export const UpdateOpportunityWatch = (OpportunityWatchToCreate: any) => (dispatch: any) => {
  const {id, technology, plan, status, activeDate, lead, attachmentPaths} = OpportunityWatchToCreate

  return serverRequest
    .updateOpportunityWatchList({id, technology, plan, status, activeDate, lead, attachmentPaths})
    .then((response) => {
      serverRequest
        .getOpportunityWatchList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          status: '',
          createdAt: '',
          activeDate: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.opportunityWatchFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
}

export const deleteOpportunityWatch =
  (_id: string, workspaceid: string | null) => (dispatch: any) => {
    return serverRequest.deleteOpportunityWatchList(_id, workspaceid).then((response) => {
      serverRequest
        .getOpportunityWatchList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          status: '',
          createdAt: '',
          activeDate: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.opportunityWatchFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
  }

export const fetchOpportunityWatchById = (_id: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return serverRequest
    .fetchOpportunityWatchById(_id)
    .then((response) => {
      const {data} = response
      dispatch(actions.loadAttachments(data.attachments))
      dispatch(actions.opportunityWatchFetchedById({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch opportunity watch"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const loadAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}

export const removeAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}
