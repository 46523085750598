import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  name: string
  description: string
  slug: string
  default: boolean
  owner: string
  type: number
}

// Define a type for the slice state
interface listState {
  listLoading: boolean
  actionsLoading: boolean
  listDetail?: entities
  totalCount: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  page: number
  error?: any
}

// Define the initial state using that type
const initialState: listState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 11,
  limitPerPage: 10,
  page: 1,
  error: '',
}
export const callTypes = {
  list: 'list',
  action: 'action',
}

export const listSlice = createSlice({
  name: 'list',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<listState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      }
    },
    listsFetched: (state, action: PayloadAction<listState>) => {
      const {page, totalCount, entities, start, end} = action.payload
      state.listLoading = false
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
      state.page = page
    },
    listCreated: (state, action) => {
      state.actionsLoading = false
      const {data} = action.payload

      state.entities.push(data)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    listUpdated: (state, action) => {
      state.actionsLoading = false
      const {data} = action.payload
      if (state && state.entities.length > 0) {
        state.entities = state.entities.map((entity: entities) => {
          if (entity._id === data._id) {
            return data
          }
          return entity
        })
      }
    },
    // deleteList
    listDeleted: (state, action) => {
      state.entities = state.entities.filter((el) => el._id !== action.payload._id)
    },
    listFetched: (state, action) => {
      const {data} = action.payload

      state.listDetail = data
    },
  },
})
