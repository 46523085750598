import * as serverRequest from './ReleaseNoteCRUD'
import {ReleaseNoteSlice} from './ReleaseNoteSlice'
import {AppDispatch} from '../../../../setup/redux/Store'

const {actions} = ReleaseNoteSlice

export const fetchReleaseNote =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    createdAt: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall())
    }
    return serverRequest
      .getReleaseNoteList({
        search,
        page,
        sortby,
        orderby,
        createdAt,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.ReleaseNoteFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  }
export const createReleaseNote = (ReleaseNoteToCreate: any) => (dispatch: any) => {
  return serverRequest
    .addReleaseNoteList(
      ReleaseNoteToCreate.title,
      ReleaseNoteToCreate.description,
      ReleaseNoteToCreate.version
    )
    .then((response) => {
      const {data, totalCount}: {data: any; totalCount: any} = response.data
      dispatch(actions.ReleaseNoteCreated({totalCount, entities: data}))
      serverRequest
        .getReleaseNoteList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          createdAt: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.ReleaseNoteFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
            })
          )
        })
    })
}
export const UpdateReleaseNote = (ReleaseNoteToCreate: any, id: any) => (dispatch: any) => {
  return serverRequest
    .updateReleaseNoteList(
      id,
      ReleaseNoteToCreate.title,
      ReleaseNoteToCreate.description,
      ReleaseNoteToCreate.version
    )
    .then((response) => {
      serverRequest
        .getReleaseNoteList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          createdAt: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.ReleaseNoteFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
            })
          )
        })
    })
}

export const deleteReleaseNote = (_id: string) => (dispatch: any) => {
  return serverRequest.deleteReleaseNoteList(_id).then((response) => {
    serverRequest
      .getReleaseNoteList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.ReleaseNoteFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}

export const fetchReleaseNoteById = (_id: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall())
  return serverRequest
    .fetchReleaseNoteById(_id)
    .then((response) => {
      const {data} = response
      dispatch(actions.ReleaseNoteFetchedById({data, listLoading: false}))
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch release note"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}
