import * as serverRequest from './WorkspaceCrud'
import {callTypes, workspaceSlice} from './WorkspaceSlice'
import {AppDispatch} from '../../../../setup/redux/Store'
const {actions} = workspaceSlice

export const fetchWorkspaces =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
    spaceType,
    assignee,
    maintainer,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    createdAt: string
    spaceType: number | string
    assignee: any
    maintainer: any
  }) =>
  (dispatch: AppDispatch) => {
    if (
      search === '' &&
      orderby !== '' &&
      sortby === '' &&
      createdAt === '' &&
      spaceType === '' &&
      assignee === '' &&
      maintainer === ''
    ) {
      dispatch(actions.startCall({callType: callTypes.list}))
    }

    return serverRequest
      .getAllWorkspaces({search, page, sortby, orderby, createdAt, spaceType, assignee, maintainer})
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(
          actions.workspacesFetched({
            page,
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }
export const createWorkspace = (workspaceToCreate: any) => (dispatch: AppDispatch) => {
  const {
    name,
    spaceType,
    spaceKey,
    description,
    assignee,
    maintainers,
    teams,
    features,
    maintainerTeams,
    company,
    group,
  } = workspaceToCreate
  return serverRequest
    .createWorkspace({
      name,
      spaceType,
      spaceKey,
      description,
      assignee,
      maintainers,
      teams,
      features,
      maintainerTeams,
      company,
      group,
    })
    .then((response) => {
      serverRequest
        .getAllWorkspaces({
          search: '',
          page: 0,
          sortby: '',
          orderby: '',
          createdAt: '',
          spaceType: '',
          assignee: [],
          maintainer: '',
        })
        .then((response) => {
          const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

          dispatch(
            actions.workspacesFetched({
              page,
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
    .catch((error) => {
      error.clientMessage = "Can't create workspaces"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}
export const updateWorkspace = (workspaceToUpdate: any) => (dispatch: AppDispatch) => {
  const {
    _id,
    name,
    spaceType,
    description,
    assignee,
    maintainers,
    teams,
    features,
    workSpace,
    maintainerTeams,
    company,
    group,
  } = workspaceToUpdate
  return serverRequest
    .updateWorkspace({
      _id,
      name,
      spaceType,
      description,
      assignee,
      maintainers,
      teams,
      maintainerTeams,
      features,
      workSpace,
      company,
      group,
    })

    .then((response) => {
      const {data} = response
      dispatch(actions.workspaceUpdated({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update workspaces"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const deleteWorkspace = (_id: string) => (dispatch: AppDispatch) => {
  return serverRequest.deleteWorkspace(_id).then((response) => {
    serverRequest
      .getAllWorkspaces({
        search: '',
        page: 0,
        sortby: '',
        orderby: '',
        createdAt: '',
        spaceType: '',
        assignee: [],
        maintainer: '',
      })
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(
          actions.workspacesFetched({
            page,
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  })
}

export const fetchWorkspace = (_id: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return serverRequest
    .fetchWorkspace(_id)
    .then((response) => {
      const {data} = response

      dispatch(actions.workspaceFetched({data}))
      return data
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch workspace"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const makekWorkSpaceEmpty = (data: any) => (dispatch: AppDispatch) => {
  dispatch(actions.workspaceFetched({data}))
}

export const removeErrorMessage = (error: any) => (dispatch: AppDispatch) => {
  dispatch(actions.catchError(error))
}
export const getAllCompanies = () => () => {
  return serverRequest.getAllCompanies().then((response) => {
    return response.data
  })
}
