import React, {useEffect, useState} from 'react'
import {Formik, Form} from 'formik'
import GCDropZone from '../GCDropzone/GCDropZone'
import '../../../_metronic/assets/sass/components/comments.scss'

interface Props {
  loadAttachments: any
  attachments: any
  itemType: string
}
const AttachmentUpload: React.FC<Props> = ({loadAttachments, attachments, itemType}) => {
  const [show, setShow] = useState(true)

  // useEffect(() => {
  //   if (itemType === 'document') {
  //     setShow(true)
  //   }
  // }, [itemType])
  return (
    <>
      <div className='card w-100'>
        <div className='collapse show'>
          <Formik initialValues={{}} onSubmit={(values: any, {resetForm}) => {}}>
            {() => (
              <Form className='Form'>
                {/* {itemType !== 'document' && (
                  <div className='dropzone-main d-flex justify-content-center'>
                    <button
                      className='btn btn-sm   bg-transparent  d-flex text-blue text-center align-items-center'
                      type='button'
                      title='Upload File'
                      onClick={() => {
                        setShow((show) => !show)
                      }}
                    >
                      <i
                        className='fa fa-plus-circle '
                        aria-hidden='true'
                        style={{color: '#0368CD'}}
                      ></i>
                      Upload
                    </button>
                  </div>
                )} */}
                {show && <GCDropZone loadAttachments={loadAttachments} attachments={attachments} />}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export {AttachmentUpload}
