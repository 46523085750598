import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'


export function BoardDropdown() {
  return (
    <div className='py-3'>
      <div className="card card-custom card-stretch gutter-b">
        <div className='card-header border-0 min-h-auto'>
        <div className="d-flex justify-content-center border-bottom w-100 pb-3">
          <span className='mt-1 fs-7 text-center' style={{fontWeight: '300'}}>switch view </span>
        </div>
          <h3 className='card-header border-0 px-0 pt-4 justify-content-center'>
            <span className='card-label fw-bolder text-center text-dark fs-4 mb-2'>Upgrade to get more views</span>
            <span className='mt-1 fs-7 text-center' style={{fontWeight: '300'}}>View key timelines, assignments, data, and more directly from your Trello board with Trello Premium.</span>
          </h3>
        </div>
      </div>
      <div className='card-body pt-5'>
        <div className='d-flex align-items-center mb-7'>
          <div className='symbol symbol-50px me-5'>
            {/* <span className='symbol-label bg-light-success'> */}
            <img className="_37celvfjR8Sinf" alt="Timeline view" src="./media/icons/timeline.svg" />
            {/* </span> */}
          </div>
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
              Timeline
            </a>
            <span className='text-muted fs-8'>Visually plan time and workload.</span>
          </div>
        </div>
        <div className='d-flex align-items-center mb-7'>
          <div className='symbol symbol-50px me-5'>
            <img className="_37celvfjR8Sinf" alt="Timeline view" src="./media/icons/table.svg" />
          </div>
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
              Table
            </a>
            <span className='text-muted fs-8'>Display cards in rows and columns, like a spreadsheet.</span>
          </div>
        </div>
        <div className='d-flex align-items-center mb-7'>
          <div className='symbol symbol-50px me-5'>
            <img className="_37celvfjR8Sinf" alt="Timeline view" src="./media/icons/calendar.svg" />
          </div>
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
              calendar
            </a>
            <span className='text-muted fs-8'>See cards with dates on a calendar.</span>
          </div>
        </div>
        <div className='d-flex align-items-center mb-7'>
          <div className='symbol symbol-50px me-5'>
            <img className="_37celvfjR8Sinf" alt="Timeline view" src="./media/icons/dashboard.svg" />
          </div>
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
              Dashboard
            </a>
            <span className='text-muted fs-8'>Generate charts, visuals, and metrics for your work.</span>
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <div className='symbol symbol-50px me-5'>
            <img className="_37celvfjR8Sinf" alt="Timeline view" src="./media/icons/map.svg" />
          </div>
          <div className='d-flex flex-column'>
            <a href='#' className='text-dark text-hover-primary fs-6 fw-bolder'>
              Map
            </a>
            <span className='text-muted fs-8'>Visualize card locations on an interactive map.</span>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <span className='mt-8 fs-7 text-center'>To upgrade, contact a Workspace admin.</span>
        </div>
        <div className="d-flex justify-content-center">
          <a href="#" className='text-dark text-hover-primary fs-7 fw-bolder'>Learn more about Group Captain Premium</a>
        </div>
      </div>
    </div>
  )
}
