import * as serverRequest from './TaskCRUD'
import {taskSlice, callTypes} from './TaskSlice'
import * as activityAction from '../../generalActivityLogs/redux/activityLogsAction'

const {actions} = taskSlice

export const fetchTasks =
  ({
    search,
    page,
    sortby,
    orderby,
    status,
    priority,
    createdAt,
    dueDate,
    assignee,
    workSpace,
    project,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    status: string
    priority: string
    createdAt: string
    dueDate: string
    assignee: any
    workSpace: string
    project: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest
      .getTasksList({
        search,
        page,
        sortby,
        orderby,
        status,
        priority,
        createdAt,
        dueDate,
        assignee,
        workSpace,
        project,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data

        dispatch(
          actions.tasksFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }
export const createTasks = (taskToCreate: any, workspaceid: any) => (dispatch: any) => {
  const {
    title,
    description,
    status,
    priority,
    dueDate,
    label,
    assignee,
    attachmentPaths,
    project,
    workSpace,
  } = taskToCreate
  return serverRequest
    .addTaskList({
      title,
      description,
      status,
      priority,
      dueDate,
      label,
      assignee,
      attachmentPaths,
      project,
      workSpace,
    })
    .then((response) => {
      const {data, totalCount}: {data: any; totalCount: any} = response.data
      dispatch(actions.taskCreated({totalCount, entities: data}))
      // if (data.attachmentPaths.length > 0) {
      //   serverRequest.addTaskAttachmentData(data)
      // }

      dispatch(actions.startCall({callType: callTypes.action}))
      serverRequest
        .getTasksList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          status: '',
          priority: '',
          createdAt: '',
          dueDate: '',
          assignee: [],
          workSpace: workspaceid,
          project: project,
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.tasksFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
}
export const UpdateTasks =
  (taskToUpdate: any, workspaceid: any, projectid: any) => (dispatch: any) => {
    const {_id, title, description, status, priority, dueDate, label, assignee, attachmentPaths} =
      taskToUpdate
    return serverRequest
      .updateTaskList({
        _id,
        title,
        description,
        status,
        priority,
        dueDate,
        label,
        assignee,
        attachmentPaths,
        workSpace: workspaceid,
      })
      .then((response) => {
        const {data} = response?.data

        dispatch(actions.taskUpdated({entities: data}))
        // if (data.attachmentPaths.length > 0) {
        //   serverRequest.updateTaskAttachmentData(data)
        // } else {
        //   serverRequest.deleteAttachment(_id)
        // }

        serverRequest
          .getTasksList({
            search: '',
            page: '',
            orderby: '',
            sortby: '',
            status: '',
            priority: '',
            createdAt: '',
            dueDate: '',
            assignee: [],
            workSpace: workspaceid,
            project: projectid,
          })
          .then((response) => {
            dispatch(actions.startCall({callType: callTypes.action}))
            const {data, totalCount, start, end, limitPerPage} = response.data
            dispatch(
              actions.tasksFetched({
                totalCount,
                start,
                end,
                limitPerPage,
                entities: data,
                listLoading: false,
                actionsLoading: false,
              })
            )

            dispatch(activityAction.fetchSingleTodo(taskToUpdate._id))
            dispatch(
              activityAction.fetchActivites({
                itemId: taskToUpdate._id,
                page: '',
                itemType: 'Task',
              })
            )
          })
      })
  }

export const UpdateTaskSpecific =
  (taskToUpdate: any, workspaceid: any, projectid: any) => (dispatch: any) => {
    const {_id} = taskToUpdate
    return serverRequest.updateSpecificTaskList({
      _id,
      updateTask: taskToUpdate,
      workSpace: workspaceid,
    })
  }

export const deleteTask = (_id: string, workspaceid: any, projectid: any) => (dispatch: any) => {
  return serverRequest.deleteTaskList(_id).then((response) => {
    serverRequest
      .getTasksList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        status: '',
        priority: '',
        createdAt: '',
        dueDate: '',
        assignee: [],
        workSpace: workspaceid,
        project: projectid,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.tasksFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  })
}

export const markTaskAsDone =
  (_id: string, workspaceid: any, projectid: any) => (dispatch: any) => {
    return serverRequest.markTaskAsDone(_id, projectid).then((response) => {
      serverRequest
        .getTasksList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          status: '',
          priority: '',
          createdAt: '',
          dueDate: '',
          assignee: [],
          workSpace: workspaceid,
          project: projectid,
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.tasksFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
  }
export const markTaskAsPinned =
  (_id: string, workspaceid: any, pinned: boolean) => (dispatch: any) => {
    return serverRequest.markTaskAsPinned(_id, pinned).then((response) => {
      serverRequest
        .getTasksList({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          status: '',
          priority: '',
          createdAt: '',
          dueDate: '',
          assignee: [],
          workSpace: workspaceid,
          project: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.tasksFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
    })
  }

export const fetchTask = (_id: string) => (dispatch: any) => {
  return serverRequest.fetchTask(_id).then((response) => {
    const {data} = response?.data

    dispatch(actions.loadAttachments(data.attachments))

    dispatch(
      actions.taskFetched({
        entities: data,
      })
    )
  })
}

export const loadAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}
export const removeTaskDetail = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
  dispatch(actions.taskFetched(data))
}
export const removeAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}

export const createTaskActivites =
  ({
    description,
    taskId,
    type,
    itemType,
  }: {
    description: string
    taskId: string | undefined | null
    type: string
    itemType: string | undefined | null
  }) =>
  (dispatch: any) => {
    return serverRequest.addTaskActivityList({
      item: taskId,
      description: description,
      type,
      itemType,
    })
  }

export const fetchBoardColumns = (project: string, workSpace: string) => (dispatch: any) => {
  return serverRequest.getBoardColumns({project, workSpace}).then((response) => {
    const {data} = response.data
    return data
  })
}
