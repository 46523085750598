import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
export const CREATED_WORkSPACENOTIFICATIONSETTINGS_URL = `${API_URL}/workspace-notification`
export const UPDATE_NOTIFICATION_URL = `${API_URL}/workspace-notification`
export const GET_UPDATED_WORkSPACENOTIFICATIONSETTINGS_URL = `${API_URL}/workspace-notification`

export function getUpdatedWorkSpaceNotificationSettings(workSpaceId: any) {
  return axios.get(`${GET_UPDATED_WORkSPACENOTIFICATIONSETTINGS_URL}/${workSpaceId}`)
}

export function updateWorkSpaceNotificationSettings(workSpaceId: any, notificationSettings: any) {
  return axios.patch(`${UPDATE_NOTIFICATION_URL}/${workSpaceId}`, {notificationSettings})
}
