import React from 'react'
import {AvengersInitiativesTable} from './components/avengersInitiativesTable'
import {Route, Switch} from 'react-router-dom'
import {AvengersInitiativesAdd} from '../avengersInitiatives/components/avengersInitiativesAdd'

const AvengersInitiatives: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path='/internal-initiatives/add'>
          {({history, match}) => {
            return (
              <AvengersInitiativesAdd
                onHide={() => {
                  history.push(`/internal-initiatives`)
                }}
              />
            )
          }}
        </Route>
        <Route exact path='/internal-initiatives'>
          <AvengersInitiativesTable className='mb-5 mb-xl-8' />
        </Route>
        {/* <Route path='/:workspaceid/avengers/initiatives/:id'>
          <ActivityLogs />
        </Route> */}
        <Route exact path='/internal-initiatives/:id/edit'>
          {({history, match}) => (
            <AvengersInitiativesAdd
              id={match && match.params.id}
              onHide={() => {
                history.push(`/internal-initiatives`)
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  )
}

export {AvengersInitiatives}
