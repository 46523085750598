import {FC, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as projectActions from '../redux/ProjectActions'
import {CreateProjectForm} from './CreateProjectForm'

type Props = {
  onHide?: any
}

const CreateProject: FC<Props> = ({onHide}) => {
  let crumbs
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const {currentSpace}: any = useSelector<RootState>(
    (state) => ({
      state,
      currentSpace: state.project.currentSpace,
    }),
    shallowEqual
  )
  const dispatch: any = useDispatch()
  useEffect(() => {
    if (workspaceid) {
      dispatch(projectActions.fetchWorkspace(workspaceid))
    }
  }, [])
  crumbs = workspaceid
    ? [
        {name: 'Spaces', url: '/dashboard'},
        {name: `${currentSpace?.name}`, url: `/${currentSpace?._id}/features`},
        {name: 'Project', url: `/${currentSpace?._id}/Project`},
        {name: `${id ? 'Edit' : 'Create'}  Project`, url: `/${currentSpace?._id}/Project`},
      ]
    : [
        {name: 'Spaces', url: '/dashboard'},
        {name: 'Project', url: `/Project`},
      ]

  return (
    <>
      <div className='page-content rounded-bottom rounded-0'>
        <div className='px-6 px-sm-12'>
          <div className="row">
            <div className="col-sm-6">
              <h2 className='fw-500 fs-2 m-0 py-5'>
              {id ? 'Edit' : 'Create'} Project
              </h2>
            </div>
          </div>
        </div>
        {/* <div className='page-header ps-12 pt-5 pb-3'>
          <h3 className='fs-2 fw-500'>{id ? 'Edit' : 'Create'} Project</h3>
        </div> */}
        {crumbs && <Breadcrumbs crumbs={crumbs} />}
        <div className='page-body py-lg-10 px-lg-10'>
          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <CreateProjectForm onHide={onHide} />
          </div>
        </div>
      </div>
    </>
  )
}

export {CreateProject}
