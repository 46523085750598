/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as actions from '../redux/listActions'
import {RootState} from '../../../../setup'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import {useParams} from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import Editor from '../../../Common/Editor/Editor'
import {showUserNames} from '../../workspace/WorkspaceUIHelper'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'

interface ICreateAccount {
  title: string
  description: string
  assignee: any
}

const inits: ICreateAccount = {
  title: '',
  description: '',
  assignee: [],
}
interface Option {
  id: string
  label: string
  value: string
}

interface Props {
  onHide: () => void
  id?: string | null
}

const API_URL = process.env.REACT_APP_API_URL
const LIST_USERS1 = `${API_URL}/users`
const LIST_TEAMS = `${API_URL}/teams`

const ListEdit: FC<Props> = ({onHide, id}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const {workspaceid}: {workspaceid: string} = useParams()
  const LIST_USERS = `${API_URL}/list/assignees`
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const dispatch = useDispatch<any>()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [response, setResponse] = useState<boolean>(false)
  const [toggleMember, setToggleMember] = useState<boolean>(false)

  const {spaceDetail, error, userInfo}: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.list.listDetail,
      error: state.list.error,
      userInfo: state.auth.user,
    }),
    shallowEqual
  )

  // const [assigneesOptions, setAssigneesOptions] = useState([])
  const [selectedFeatures, setSelectedFeatures] = useState<String[]>([])

  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)
  const [calledBy, setCalledBy] = useState('')
  const [popupTitle, setPopupTitle] = useState('')
  const [assigneeAndTeams, setassigneeAndTeams]: any = useState()
  const [usersId, setUsersId] = useState([])
  const [assigneesId, setAssigneesId] = useState([])

  const getAssigneesData = async () => {
    const usersData = await axios.get(`${LIST_USERS}/${workspaceid}`)
    const assignees: any = []
    usersData?.data?.data[0]?.assignee?.map((userData: any) => {
      assignees.push(userData)
    })
    setassigneeAndTeams({
      assignee: assignees,
    })
  }

  useEffect(() => {
    getAssigneesData()
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const {spaceData}: any = useSelector<RootState>(
    (state) => ({
      spaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  // const fetchAssigneesData = async () => {
  //   let assigneeOptions: any = []
  //   const usersData = await axios.get(`${LIST_USERS}/${workspaceid}`)

  //   usersData?.data?.data[0]?.assignee?.map((userData: any) => {
  //     assigneeOptions.push({
  //       value: userData._id,
  //       label: userInfo._id === userData._id ? userData.fullName + ' ' + '(Me)' : userData.fullName,
  //       id: userData._id,
  //     })
  //   })
  //   console.log(assigneeOptions, 'old list')
  //   setAssigneesOptions(assigneeOptions)
  // }

  const animatedComponents = makeAnimated()
  useEffect(() => {
    if (spaceDetail) {
      setToggleMember(spaceDetail?.spaceType === 1)
      setInitValues({
        title: spaceDetail?.title,
        description: spaceDetail?.description,
        assignee: spaceDetail?.assignee?.map((assignee: any) => {
          return {
            value: assignee._id,
            label:
              userInfo._id === assignee._id ? assignee.fullName + ' ' + '(Me)' : assignee.fullName,
            id: assignee._id,
          }
        }),
      })
    }
  }, [spaceDetail])

  useEffect(() => {
    if (id) {
      setAssigneesId(spaceDetail?.assignee?.map((assignee: any) => assignee._id))
    }
  }, [id, spaceDetail])

  useEffect(() => {
    if (calledBy === 'list') {
      setAssigneesId(usersId)
    }
  }, [usersId])

  useEffect(() => {
    // fetchAssigneesData()
    setSelectedFeatures([])
    if (id) {
      dispatch(actions.fetchList(id, workspaceid))
    }
    dispatch(actions.removeErrorMessage(''))
  }, [dispatch])

  const createUpdatelistsSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .label('title')
      .min(1, 'Title must contain more than 3 characters'),
  })

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const handleChange = (value: any, setFieldValue: any) => {
    setFieldValue('assignee', value)
  }
  let crumbs
  if (spaceData) {
    crumbs = [
      {name: 'Spaces', url: '/dashboard'},
      {name: `${spaceData?.name}`, url: `/${spaceData?._id}/features`},
      {name: 'List', url: `/${spaceData?._id}/list`},
      {name: id ? 'Edit List' : 'Create List', url: `/${spaceData?._id}/list`},
    ]
  }
  return (
    <>
      <AssigneesList
        show={showAssigneesDialog}
        handleClose={() => {
          setShowAssigneesDialog(false)
        }}
        assignee={assigneeAndTeams?.assignee}
        teams={[]}
        calledBy={calledBy}
        setAssigneesId={setUsersId}
        assigneesId={usersId}
        id={id}
        title={popupTitle}
      />
      <div className='mh-80 page-content'>
        <div className='px-6 px-sm-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
                {id ? 'Edit List' : 'Create List'}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className='page-header mx-5'>
          <h2 className='fw-500 fs-2'>{id ? 'Edit List' : 'Create List'}</h2>
        </div> */}
        <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 ' />
        <div className='overflow_height_scroll'>
          <div className='page-body py-lg-10 px-lg-10'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
              id='kt_modal_create_app_stepper'
            >
              <div className='flex-row-fluid py-lg-5 px-lg-15'>
                <Formik
                  validationSchema={createUpdatelistsSchema}
                  initialValues={id ? initValues : inits}
                  enableReinitialize
                  onSubmit={(values: any) => {
                    // values.assignee = values.assignee.map((assign: any) => {
                    //   return assign.id
                    // })

                    values.assignee = assigneesId

                    if (id) {
                      values._id = id
                      values.workspace = workspaceid
                      setResponse(true)
                      dispatch(actions.updateList(values))
                        .then((response: any) => {
                          onHide()
                        })
                        .catch((error: string) => {
                          setResponse(false)
                        })
                    } else {
                      setResponse(true)
                      values.workspace = workspaceid
                      dispatch(actions.createList(values))
                        .then((response: any) => {
                          onHide()
                        })
                        .catch((error: string) => {
                          setResponse(false)
                        })
                    }
                  }}
                >
                  {({values, setFieldValue}) => (
                    <Form className='form form_bg_light' noValidate id='kt_modal_create_app_form'>
                      <div className='current' data-kt-stepper-element='content'>
                        <div className='w-100'>
                          {error && (
                            <div className='mb-lg-15 alert alert-danger'>
                              <div className='alert-text font-weight-bold'>{error}</div>
                            </div>
                          )}
                          <div className='fv-row mb-5'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span className='required'>Title</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='title'
                              placeholder='Title'
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='title' />
                            </div>
                          </div>
                          <div className='row fv-row mb-5'>
                            <div className='col-12 multisel'>
                              <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                                <span>Assignee</span>
                              </label>
                              {/* <Select
                              options={assigneesOptions}
                              placeholder='Select'
                              name='assignee'
                              className='multi-select-container'
                              classNamePrefix='multi-select'
                              onChange={(e: any) => {
                                handleChange(e, setFieldValue)
                              }}
                              value={values.assignee}
                              closeMenuOnSelect={false}
                              components={animatedComponents}
                              isMulti
                            /> */}

                              <div className='row fv-row'>
                                <div className='col-12 multisel'>
                                  <div className='d-flex justify-content-center align-items-center bg-lightest'>
                                    <div
                                      style={{
                                        display: '-webkit-box',
                                        WebkitLineClamp: '1',
                                        WebkitBoxOrient: 'vertical',
                                        overflow: 'hidden',
                                      }}
                                    >
                                      {assigneesId?.length > 0 && (
                                        <span
                                          className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserNames(
                                            assigneesId,
                                            assigneeAndTeams?.assignee
                                          )}
                                        >
                                          Users
                                          <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                            {assigneesId?.length}
                                          </span>
                                        </span>
                                      )}
                                    </div>
                                    <a
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_create_app'
                                      id='kt_modal_assignees_list_app'
                                      onClick={() => {
                                        setUsersId(assigneesId)
                                        setCalledBy('list')
                                        setPopupTitle('Assignee')
                                        setShowAssigneesDialog(true)
                                      }}
                                    >
                                      <i
                                        className='fa fa-plus-circle text-blue'
                                        aria-hidden='true'
                                      ></i>
                                      <button
                                        title='Add'
                                        type='button'
                                        className='btn btn-sm  bg-transparent text-primary fw-500 p-2 '
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        id='kt_modal_assignees_list_app'
                                      >
                                        add
                                      </button>
                                    </a>
                                  </div>
                                  <div className='text-danger'>
                                    <ErrorMessage name='assignee' />
                                  </div>
                                </div>
                              </div>

                              <div className='text-danger'>
                                <ErrorMessage name='assignee' />
                              </div>
                            </div>
                          </div>

                          <div className='fv-row'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              <span>Description</span>
                            </label>

                            <Field
                              name='description'
                              className='form-control form-control-lg form-control-solid'
                              type='text'
                              placeholder='Description'
                            >
                              {({field, form, meta}: any) => (
                                <Editor
                                  value={values?.description || ''}
                                  field='description'
                                  setFieldValue={setFieldValue}
                                />
                              )}
                            </Field>

                            <div className='text-danger'>
                              <ErrorMessage name='description' />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex flex-stack pt-10'>
                        <div> </div>
                        <div className='me-2'>
                          <button
                            type='button'
                            className='btn btn-sm  fw-bolder  cancel_button me-3 fs-7'
                            onClick={onHide}
                            title='Cancel'
                          >
                            Cancel
                          </button>
                          <button
                            disabled={response}
                            type='submit'
                            className='btn btn-sm  btn-blue fs-7'
                            title={id ? 'Update' : 'Create'}
                          >
                            <span className='indicator-label fs-7'>{id ? 'Update' : 'Create'}</span>
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ListEdit}
