import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface BoardState {
  error?: any
  currentSpace?: any
  singleBoard?: object
  currentProject?: object
  projectDetails?: any
  allColumnsByBoardId?: []
  currentSpaceUsers?: []
  singleTask?: object
  columnByProId?: []
  boardColumns?: []
  boardTasks?: []
  loading?: boolean
  boardTasksActivities?: object
  summaryActivities?: object
  summaryActivitiesData?: []
  filter?: {search: string; users: string[]}
}

// Define the initial state using that type
const initialState: BoardState = {
  error: '',
  currentSpace: {},
  singleBoard: {},
  currentProject: {},
  projectDetails: {},
  allColumnsByBoardId: [],
  currentSpaceUsers: [],
  singleTask: {},
  columnByProId: [],
  boardColumns: [],
  boardTasks: [],
  loading: true,
  boardTasksActivities: {},
  summaryActivities: {},
  summaryActivitiesData: [],
  filter: {search: '', users: []},
}

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    startCall: (state) => {
      state.loading = true
    },
    catchError: (state, action: PayloadAction<BoardState>) => {
      state.error = `${action.payload}`
    },

    currentSpaceName: (state, action: PayloadAction<BoardState>) => {
      const {currentSpace, loading}: any = action.payload
      state.currentSpace = currentSpace
      state.loading = loading
    },
    singleBoard: (state, action: PayloadAction<BoardState>) => {
      const {singleBoard}: any = action.payload
      state.singleBoard = singleBoard
    },
    currentProject: (state, action: PayloadAction<BoardState>) => {
      const {currentProject, loading}: any = action.payload
      state.currentProject = currentProject
      state.projectDetails = currentProject
      state.loading = loading
    },
    updateCurrentProject: (state, action: PayloadAction<BoardState>) => {
      const {currentProject, loading}: any = action.payload
      state.currentProject = currentProject
      if (!currentProject.filter && !state.filter?.search && state?.filter?.users?.length !== 0) {
        state.projectDetails = currentProject
      }
      state.loading = loading
    },
    filterProject: (state, action: PayloadAction<BoardState>) => {
      const {filter, loading}: any = action.payload
      if (!filter.search && filter.users.length === 0) {
        state.currentProject = state.projectDetails
      }
      if (filter.search || filter.users.length > 0) {
        let filteredColumns = state?.projectDetails?.board?.columns.map((column: any) => {
          const columnData = JSON.parse(JSON.stringify(column))
          columnData.task = column.task.filter((tsk: any) => {
            if (
              (!filter.search || tsk.title.toLowerCase().includes(filter.search.toLowerCase())) &&
              (filter.users.length === 0 ||
                tsk.assignee.some((assigne: any) => filter.users.includes(assigne._id)))
            ) {
              return tsk
            }
          })
          return columnData
        })
        state.currentProject = {
          ...state.projectDetails,
          board: {...state.projectDetails.board, columns: filteredColumns},
        }
        // filteredColumns = filteredColumns || columns
      }

      state.filter = filter
      state.loading = loading
    },
    currentSpaceUsers: (state, action: PayloadAction<any>) => {
      const {currentSpaceUsers}: any = action.payload
      state.currentSpaceUsers = currentSpaceUsers
    },
    singleTask: (state, action: PayloadAction<BoardState>) => {
      const {singleTask}: any = action.payload
      state.singleTask = singleTask
    },
    columnByProId: (state, action: PayloadAction<BoardState>) => {
      const {columnByProId}: any = action.payload
      state.columnByProId = columnByProId
    },
    boardColumns: (state, action: PayloadAction<BoardState>) => {
      const {boardColumns}: any = action.payload
      state.boardColumns = boardColumns
    },
    boardTasks: (state, action: PayloadAction<BoardState>) => {
      const {boardTasks}: any = action.payload
      state.boardTasks = boardTasks
    },
    boardTasksActivities: (state, action: PayloadAction<BoardState>) => {
      const {boardTasksActivities}: any = action.payload
      state.boardTasksActivities = boardTasksActivities
    },
    boardTaskSummaryActivities: (state: any, action: PayloadAction<BoardState>) => {
      const {summaryActivities}: any = action.payload
      state.summaryActivities = summaryActivities
      const {summaryActivitiesData}: any = action.payload
      if (summaryActivities?.page > 1) {
        state.summaryActivitiesData.push(...summaryActivitiesData)
      } else {
        state.summaryActivitiesData = summaryActivitiesData
      }
    },
  },
})
