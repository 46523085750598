import React from 'react'
import {Link} from 'react-router-dom'
import ReactTooltip from "react-tooltip";
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'


export function BoardMembersRoles() {
  return (
    <div className='py-3'>
      <div className="card card-custom card-stretch gutter-b">
        <div className='card-header border-0 min-h-auto'>
          <div className="d-flex justify-content-center border-bottom w-100 pb-3">
            <span className='mt-1 fs-7 text-center text-hover-primary fw-bolder' style={{fontWeight: '300'}}>Board Members</span>
          </div>
        </div>
      </div>
      <div className='card-body pt-5'>
        <div className='d-flex flex-column mb-2'>
          <div className="kanban-header-facepile flex-column">
            <span className="text-muted fs-7 text-hover-primary fw-bolder mb-2">Workspace Members</span>
            <ul className=''>
              <li>
                <Link to="/" data-tip data-for="userTip1">
                  <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/60742c87f67f794749a987e4/182003c4fdd39730e266c400c4562b8b/30.png" alt="Sobia Yaseen (sobiayaseen2)" title="Sobia Yaseen (sobiayaseen2)" />
                </Link>
                <ReactTooltip id="userTip1" place="top" effect="solid">
                  Sobia Yaseen (sobiayaseen2)
                </ReactTooltip>
              </li>
              <li>
                <Link to="/" data-tip data-for="userTip2">
                  <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/5baa1fb489aa5034705706cb/8cddeb7c4a1196de74e31782806454b9/30.png" alt="UsmanSyednxb (UsmanSyed7)" title="UsmanSyednxb (UsmanSyed7)" />
                </Link>
                <ReactTooltip id="userTip2" place="top" effect="solid">
                  UsmanSyednxb (UsmanSye7)
                </ReactTooltip>
              </li>
              <li>
                <Link to="/" data-tip data-for="userTip3">
                  <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/620b60cd319f57274dcd54d1/97404896844a81eb235a295ebe1e2c2e/30.png" alt="Arsal Khalid (ArsalKhalid1)" title="Arsal Khalid (ArsalKhalid1)" />
                </Link>
                <ReactTooltip id="userTip3" place="top" effect="solid">
                  Arsal Khalid (ArsalKhalid1)
                </ReactTooltip>
              </li>
            </ul>
          </div>
        </div>
        <div className='d-flex flex-column mb-3'>
          <div className="kanban-header-facepile flex-column">
            <span className="text-muted fs-7 text-hover-primary fw-bolder mb-2">Guests</span>
            <ul className=''>
              <li className='d-flex me-1'>
                                  <Link to="/" data-tip data-for="userTip4">
                                    <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/615ae310929aa08f0d684de4/c2c159c1644f7a24f666aefb4cf60767/30.png" alt="Fariha Liaqat (farihaliaqat1)" title="Fariha Liaqat (farihaliaqat1)" />
                                  </Link>
                                  <ReactTooltip id="userTip4" place="top" effect="solid">
                                    Arsal Khalid (ArsalKhalid1)
                                  </ReactTooltip>
              </li>
              <li className='d-flex me-1'>
                <Link to="/" data-tip data-for="userTip5">
                  <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/55718d69fa56fa735348e20b/2e6e78d02009eca3268881c7d90f455a/30.png" alt="Mubbashir (mubbashir2)" title="Mubbashir (mubbashir2)" />
                </Link>
                <ReactTooltip id="userTip5" place="top" effect="solid">
                  Mubbashir (mubbashir2)
                </ReactTooltip>
                
              </li>
              
              <li className='d-flex me-1'>
                <Link to="/" data-tip data-for="userTip5">
                  <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/60ec13a02cb61e5e1739c5f6/597d13daf40afd1e11926530085efcdf/30.png" alt="quratul.ain (quratulain63)" title="quratul.ain (quratulain63)" />
                </Link>
                <ReactTooltip id="userTip5" place="top" effect="solid">
                  quratul.ain (quratulain63)
                </ReactTooltip>
              </li>
              
              <li className='d-flex me-1'>
                <Link to="/" data-tip data-for="userTip5">
                  <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/625d37010f69fb57652230b0/0f1e966f23e86bd97e67dd61b39db2ad/30.png" alt="zawwar (zawwar4)" title="zawwar (zawwar4)" />
                </Link>
                <ReactTooltip id="userTip5" place="top" effect="solid">
                zawwar (zawwar4)
                </ReactTooltip>
                
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
