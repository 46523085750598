import React, {useEffect, useState, FC, useRef} from 'react'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {useSelector, shallowEqual} from 'react-redux'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import {RootState} from '../../../setup'
import {useParams} from 'react-router-dom'

interface SortingValues {
  sortby: string
  orderby: string
}
type Props = {
  setIsFiltered: any
  setSortValues: any
  sortValues: SortingValues
}
const API_URL = process.env.REACT_APP_API_URL
const LIST_USERS = `${API_URL}/todos/assignees`

const TodoListSorting: FC<Props> = ({setSortValues, sortValues, setIsFiltered}) => {
  const {workspaceid}: {workspaceid: any} = useParams()

  const animatedComponents = makeAnimated()

  const [orderby, setOrderBy] = useState<string>(sortValues.orderby)
  const [sortby, setSortBy] = useState<string>(sortValues.sortby)
  const [status, setStatus] = useState<string>('')
  const [priority, setPriority] = useState<string>('')
  const [createdDate, setCreatedDate] = useState<string>('')
  const [dueDate, setDueDate] = useState<string>('')
  const [assignee, setAssignee] = useState([])
  const [rerender, setRerender] = useState(false)
  const [assignees, setAssignees] = useState<any>([])

  const selectRef = useRef<any>()

  const userInfo: any = useSelector<RootState>((state) => state.auth.user, shallowEqual)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    setOrderBy(sortValues.orderby)
    setSortBy(sortValues.sortby)
  }, [sortValues])

  const resetData = () => {
    if (selectRef && selectRef.current) {
      selectRef.current.clearValue()

      setRerender(rerender ? false : true)
    }

    setSortBy('')
    setOrderBy('desc')
    setCreatedDate('')
    setDueDate('')
    setStatus('')
    setPriority('')
    setAssignee([])
  }

  const filterData = (e: any) => {
    if (
      orderby === 'desc' &&
      sortby === '' &&
      status === '' &&
      priority === '' &&
      createdDate === '' &&
      dueDate === '' &&
      assignee.length <= 0
    ) {
      setIsFiltered(false)
    } else {
      setIsFiltered(true)
    }
    setSortValues({
      orderby: orderby,
      sortby: sortby,
      status: status,
      priority: priority,
      createdAt: createdDate,
      dueDate: dueDate,
      assignee: assignee,
    })
  }

  const fetchAssigneesData = async () => {
    const assigneeData = await axios.get(`${LIST_USERS}/${workspaceid}`)

    setAssignees(
      assigneeData?.data[0]?.assignee.map((assigne: any) => {
        return {
          value: assigne._id,
          label: userInfo._id === assigne._id ? assigne.fullName + ' ' + '(Me)' : assigne.fullName,
          id: assigne._id,
        }
      })
    )
  }

  useEffect(() => {
    fetchAssigneesData()
  }, [])
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown w-250px w-md-300px dropdown_dialog'
        data-kt-menu='true'
      >
        <div className='px-7 py-5 d-flex justify-content-between'>
          <div className='fs-5 text-dark fw-bolder'>Search Filters</div>

          <div className='btn btn-active-light p-0'>
            {' '}
            <i className='fa fa-times' aria-hidden='true' data-kt-menu-dismiss='true'></i>
          </div>

          {/* <i className="fa-light fa-xmark"></i> */}
        </div>

        <div className='separator border-gray-200'></div>
        <div className='px-7 pt-0 py-7 overflow-auto'>
          <div className=''>
         
            <div className='my-5'>
               <label className="form-label f-15px fw-bold mt-10 mb-3">Space Type</label>
              <label className='mb-3 f-13px'>Status</label>
              <select
                className='form-select form-select-solid dashboard_select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='status'
                data-hide-search='true'
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              >
                <option value=''></option>
                <option value='0'>Todo</option>
                <option value='1'>In Progress</option>
                <option value='2'>Done</option>
              </select>
            </div>

            <div className='my-5'>
              <label className='mb-3 f-13px'>Priority</label>
              <select
                className='form-select form-select-solid dashboard_select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='priority'
                data-hide-search='true'
                onChange={(e) => setPriority(e.target.value)}
                value={priority}
              >
                <option value=''></option>
                <option value='0'>Low</option>
                <option value='1'>Normal</option>
                <option value='2'>High</option>
              </select>
            </div>

            <div className='my-5'>
              <label className='mb-3 f-13px'>Due Date</label>
              <input
                type='date'
                name='dueDate'
                value={dueDate}
                className='form-select form-select-solid date-field'
                onChange={(event) => {
                  setDueDate(event.target.value)
                }}
              />
            </div>
            <div className='my-5'>
              <label className='mb-3 f-13px'>Created Date</label>
              <input
                type='date'
                name='createdAt'
                value={createdDate}
                className='form-select form-select-solid date-field'
                onChange={(event) => {
                  setCreatedDate(event.target.value)
                }}
              />
            </div>

            <div className='my-5'>
              <div className='mb-5 multisel'>
                <label className='mb-3 f-13px'>Assignee</label>

                <Select
                  ref={selectRef}
                  placeholder='Select'
                  name='assignee'
                  className='multi-select-container'
                  classNamePrefix='multi-select'
                  onChange={(e: any) => {
                    const assignees = e.map((assign: any) => {
                      return assign.id
                    })

                    setAssignee(assignees)
                  }}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  // components={animatedComponents}
                  isMulti
                  options={assignees}
                />
              </div>
            </div>
            <div className='my-5'>
              <label className='mb-3 f-13px'>Sort By</label>
              <select
                className='form-select form-select-solid dashboard_select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='orderby'
                data-hide-search='true'
                onChange={(e) => setSortBy(e.target.value)}
                value={sortby}
              >
                <option value=''></option>
                <option value='title'>Title</option>
                <option value='dueDate'>Due Date</option>
                <option value='priority'>Priority</option>
                <option value='status'>Status</option>
                <option value='createdBy'>Created By</option>
                <option value='createdAt'>Created Date</option>
              </select>
            </div>
            <div className='my-5'>
              <label className='mb-3 f-13px'>Order By</label>
              <select
                className='form-select form-select-solid dashboard_select'
                data-kt-select2='true'
                data-placeholder='Select option'
                data-allow-clear='true'
                data-kt-user-table-filter='two-step'
                data-hide-search='true'
                onChange={(e) => setOrderBy(e.target.value)}
                value={orderby}
              >
                <option value={'asc'}>Asc</option>
                <option value='desc'>Desc</option>
              </select>
            </div>
          </div>

          <div className='d-flex justify-content-end popup_setting'>
            <button
              type='button'
              onClick={resetData}
              className='btn btn-sm btn-white btn-active-light-primary me-2'
            >
              Clear
            </button>

            <button
              type='button'
              onClick={filterData}
              className='btn btn-sm btn-primary'
              data-kt-menu-dismiss='true'
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export {TodoListSorting}
