import React from 'react'
import {TalentEdit} from './components/TalentEdit'
import {Redirect, Route, Switch} from 'react-router-dom'
import TalentSuccess from './components/Success'

const TalentPublic: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/talent/success' component={TalentSuccess} />
        <Route exact path='/talent/add'>
          {({history, match}) => {
            return (
              <TalentEdit
                onHide={() => {
                  history.push({
                    pathname: `/talent/success`,
                    state: {success: true},
                    search: history.location.search,
                  })
                }}
              />
            )
          }}
        </Route>
        <Route exact path='/talent/:id'>
          {({history, match}) => (
            <TalentEdit
              id={match && match.params.id}
              onHide={() => {
                history.push({
                  pathname: `/talent/success`,
                  state: {success: true, id: match?.params?.id},
                  search: history.location.search,
                })
              }}
            />
          )}
        </Route>
        <Route exact path='/talent/:id/edit'>
          {({history, match}) => (
            <TalentEdit
              id={match && match.params.id}
              onHide={() => {
                history.push({
                  pathname: `/talent/success`,
                  state: {success: true, id: match?.params?.id},
                  search: history.location.search,
                })
              }}
            />
          )}
        </Route>

        <Redirect to={`/talent/add${window.location.search}`} />
      </Switch>
    </>
  )
}

export {TalentPublic}
