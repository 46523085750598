import {createSlice, PayloadAction} from '@reduxjs/toolkit'

// Define a type for the slice state
interface PmsState {
  loading?: boolean
  error?: any
  currentSpace?: any
  allProjects?: any
  start?: number
  end?: number
  limitPerPage?: number
  totalCount?: number
  currentProject?: object
  // page: number
}

// Define the initial state using that type
const initialState: PmsState = {
  loading: true,
  error: '',
  currentSpace: {},
  currentProject: {},
  allProjects: [],
  totalCount: 0,
  start: 1,
  end: 10,
  limitPerPage: 10,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<PmsState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state) => {
      state.loading = true
    },
    currentSpaceName: (state, action: PayloadAction<PmsState>) => {
      const {currentSpace}: any = action.payload
      state.currentSpace = currentSpace
    },
    currentProject: (state, action: PayloadAction<PmsState>) => {
      const {currentProject}: any = action.payload
      state.currentProject = currentProject
    },

    allProjects: (state, action: PayloadAction<PmsState>) => {
      const {totalCount, allProjects, start, end, loading}: any = action.payload
      state.allProjects = allProjects
      state.totalCount = totalCount
      state.start = start
      state.end = end
      state.loading = loading
    },
  },
})
