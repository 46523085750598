import * as serverRequest from './NotificationCRUD'
import {NotificationSlice} from './NotificationSlice'

const {actions} = NotificationSlice

export const fetchNotification =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    createdAt: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall())
    }
    return serverRequest
      .getNotificationList({
        search,
        page,
        sortby,
        orderby,
        createdAt,
      })
      .then((response) => {
        const {data} = response.data

        dispatch(
          actions.NotificationFetched({
            entities: data,
            listLoading: false,
          })
        )
      })
  }
export const deleteNotification = (_id: string) => (dispatch: any) => {
  return serverRequest.deleteNotification(_id).then((response) => {
    serverRequest
      .getNotificationList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data} = response.data
        dispatch(
          actions.NotificationFetched({
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}
export const UpdateNotification = () => (dispatch: any) => {
  return serverRequest.updateNotificationList().then((response) => {
    serverRequest
      .getNotificationList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data} = response.data
        dispatch(
          actions.NotificationFetched({
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}

export const UpdateANotification = (id: any) => (dispatch: any) => {
  return serverRequest.updateNotificationAsSeen(id).then((response) => {
    const {data} = response.data
    dispatch(
      actions.UpdateANotification({
        entities: data,
      })
    )
    serverRequest
      .getNotificationList({
        search: '',
        page: '',
        orderby: '',
        sortby: '',
        createdAt: '',
      })
      .then((response) => {
        const {data} = response.data
        dispatch(
          actions.NotificationFetched({
            entities: data,
            listLoading: false,
          })
        )
      })
  })
}
