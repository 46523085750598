import {Paragraph, TableCell, TableRow, TextRun, WidthType} from 'docx'
import {constants} from './Constants'
import {docxTable, indentSpace, docxBorder as docxBorder, linesBreak} from './DocxFunctions'

export const personalInfo = (res: any, templateName: string) => {
  const tableRows: any = []
  tableRows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              children: [
                new TextRun({
                  text: res.callingName ? res.callingName : res.name,
                  color: constants.Colors.BLACK,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  size: 44,
                }),
              ],
            }),
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                line: constants.PARAGRAPH_LINE_HEIGHT,
              },
              children: [
                new TextRun({
                  text: res.jobTitle ? res.jobTitle : constants.Titles.DESIGNATION,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  size: 20,
                  color: constants.Colors.NERO,
                }),
              ],
            }),
          ],
          columnSpan: 3,
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              spacing: {
                after: 50,
              },
              indent: indentSpace(),
              children: [
                new TextRun({
                  text: res?.experienceInMonth ? res.year + '.' + res?.experienceInMonth : res.year,
                  size: 28,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
                new TextRun({
                  text: constants.Titles.YEARS_OF_EXPERIENCE,
                  color: constants.Colors.SHADE_GRAY,
                  size: 18,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 35,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(constants.Colors.GRAY_BORDER, 0, 10, 0, 0),
        }),
        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                line: 250,
              },
              children: [
                new TextRun({
                  text:
                    templateName === 'nextbridge'
                      ? constants.Titles.NEXTBRIDGE_SALARY
                      : constants.Titles.VTEAMS_SALARY,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  size: 28,
                }),
                new TextRun({
                  text: constants.Titles.PER_MONTH,
                  color: constants.Colors.SHADE_GRAY,
                  size: 18,

                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
            new Paragraph({
              spacing: {
                after: 10,
              },
              indent: indentSpace(),
              children: [
                new TextRun({
                  text: constants.Titles.TIME,
                  color: constants.Colors.SHADE_GRAY,
                  size: 16,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 40,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(constants.Colors.GRAY_BORDER, 0, 10, 0, 0),
        }),
        new TableCell({
          children: [],
          width: {
            size: 25,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(constants.Colors.GRAY_BORDER, 0, 10, 0, 0),
        }),
      ],
    })
  )
  return docxTable(tableRows, constants.Colors.GRAY_BORDER, 0, 10, 0, 0)
}
