import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id: string
  title: string
  description: string
  createdBy: String
}

// Define a type for the slice state
interface NotificationState {
  listLoading: boolean
  totalCount?: number
  NotificationDetail?: entities
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  error?: any
}

// Define the initial state using that type
const initialState: NotificationState = {
  listLoading: true,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
}

export const allNotificationSlice = createSlice({
  name: 'allNotification',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<NotificationState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state) => {
      state.listLoading = true
    },
    NotificationFetched: (state, action: PayloadAction<NotificationState>) => {
      state.listLoading = false
      const {totalCount, entities, start, end} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    NotificationUpdate: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    UpdateANotification: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities = state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return entities
          }
          return entity
        })
      }
    },
  },
})
