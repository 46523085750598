import React, { useEffect, useState } from 'react'
import SVG from 'react-inlinesvg'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'
import {toAbsoluteUrl} from '../AssetHelpers'
type Props = {
  className?: string
  path: string
  color?: string
  svgClassName?: string
  noClass?: boolean
}

const KTSVG: React.FC<Props> = ({
  className = '',
  path,
  color = '',
  svgClassName = 'mh-50px',
  noClass = false,
}) => {

  if (!noClass) {
    return (
      <span className={`svg-icon ${className}`}>
        <SVG src={toAbsoluteUrl(path)} className={svgClassName} color={color} />
      </span>
    )
  } else {
    return <SVG src={toAbsoluteUrl(path)} />
  }
}

export {KTSVG}
