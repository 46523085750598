import {FC, useEffect, useRef} from 'react'

type Props = {
  setShowActions?: any
  columnId?: string
  name: string
  setShowPopup: any
  setChangeName: any
}

const ColumnActions: FC<Props> = ({
  setShowActions,
  columnId,
  name,
  setShowPopup,

  setChangeName,
}) => {
  const columnActionRef: any = useRef(null)

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (columnActionRef.current && !columnActionRef.current.contains(event.target)) {
        setShowActions(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [columnActionRef])

  const openDeleteColumnActions = () => {
    setShowPopup(true)
    setShowActions(false)
  }
  return (
    <>
      <div
        ref={columnActionRef}
        className='end-0 show position-absolute menu menu-sub menu-sub-dropdown w-200px w-md-200px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold fs-base w-175px'
        data-kt-menu='true'
      >
        <div className='separator border-gray-200'></div>
        <div className='px-3'>
          <div className='menu-item px-3 my-0'>
            <div className='menu-link px-3'>
              <span
                onClick={(e) => openDeleteColumnActions()}
                title='Delete Column'
                className='menu-title'
              >
                Delete Column
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {ColumnActions}
