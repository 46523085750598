import * as serverRequest from './ProjectCrud'
import {projectSlice} from './ProjectSlice'
import {AppDispatch} from '../../../../setup/redux/Store'
import * as workSpaceRequest from '../../workspace/redux/WorkspaceCrud'

const {actions} = projectSlice

interface ProjectDataItem {
  name: string
  type: string
  space: string
}
export const fetchWorkspace = (workSpace: string) => (dispatch: AppDispatch) => {
  return workSpaceRequest.fetchWorkspace(workSpace).then((res: any) => {
    dispatch(actions.currentSpaceName({currentSpace: res.data}))
  })
}

export const createProject = (projectData: ProjectDataItem) => () => {
  return serverRequest
    .createProject(projectData.name, projectData.space, projectData.type)
    .then((res: any) => {
      return res
    })
}

export const getAllProjects =
  (
    page: string,
    sortby: string,
    orderby: string,
    search: string,
    workSpace: string,
    createdAt: string
  ) =>
  (dispatch: AppDispatch) => {
    return serverRequest
      .getAllProjects(page, sortby, orderby, search, workSpace, createdAt)
      .then((res: any) => {
        const {data, totalCount, start, end, limitPerPage} = res.data
        dispatch(
          actions.allProjects({
            totalCount,
            start,
            end,
            limitPerPage,
            allProjects: data,
            loading: false,
          })
        )
      })
  }

export const getProjectById = (id: string) => (dispatch: AppDispatch) => {
  return serverRequest.getProjectById(id).then((res: any) => {
    dispatch(actions.currentProject({currentProject: res.data.data}))
    return res
  })
}

export const updateProjectById = (id: string, projectData: ProjectDataItem) => () => {
  return serverRequest
    .updateProjectById(id, projectData.name, projectData.space, projectData.type)
    .then((res: any) => {
      return res
    })
}

export const deleteProject = (id: string) => () => {
  return serverRequest.deleteProject(id).then((res: any) => {
    if (res.data.acknowledged) {
      return res
    }
  })
}
