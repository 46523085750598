import React, {useEffect} from 'react'

import Dropzone from 'react-dropzone-uploader'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {KTSVG} from '../../../_metronic/helpers'
import {RootState} from '../../../setup'
import 'react-dropzone-uploader/dist/styles.css'
import './style.css'

interface Props {
  loadAttachments: any
  attachments: any
}

const GCDropZone: React.FC<Props> = ({loadAttachments, attachments}) => {
  const dispatch = useDispatch()

  const auth: any = useSelector<RootState>((state) => state.auth, shallowEqual) as any
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  useEffect(() => {
    if (attachments?.length === 0) {
      const btn: any = document.getElementsByClassName('dzu-submitButton')[0]
      if (btn) {
        btn.click()
      }
    }
  }, [attachments])
  // Payload data and url to upload files
  //
  // https://httpbin.org/post
  const getUploadParams = ({meta}: any) => {
    return {
      url: `${process.env.REACT_APP_API_URL}/attachments/upload`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  }
  const Preview = ({meta, fileWithMeta}: any) => {
    const {name, percent, status} = meta

    return (
      <>
        <span style={{alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica'}}>
          {name}, {Math.round(percent)}%, {status}
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
            onClick={() => {
              fileWithMeta.remove()
              if (status === 'done' && fileWithMeta?.xhr?.responseText) {
                const response = JSON.parse(fileWithMeta?.xhr?.responseText)
                dispatch(loadAttachments(attachments.filter((a: any) => a.url !== response.url)))
              }
            }}
            title='Delete'
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
          </button>
        </span>
      </>
    )
  }

  // Return the current status of files being uploaded
  const handleChangeStatus = ({meta, file, xhr, remove}: any, status: any) => {
    if (status === 'done') {
      const responseObject = JSON.parse(xhr.responseText)

      dispatch(
        loadAttachments({
          path: responseObject.path,
          url: responseObject.url,
          originalName: responseObject.originalName ? responseObject.originalName : '',
          uploaded: false,
        })
      )
    }
  }

  // Return array of uploaded files after submit button is clicked
  const handleSubmit = (files: any, allFiles: any) => {
    allFiles.forEach((f: any) => f.remove())
  }

  return (
    <div className='gcdropzone'>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        // accept="image/*"
        // accept=".png, .jpg, .jpeg, .pdf, .doc, .txt"
        accept='*'
        PreviewComponent={Preview}
        maxSizeBytes={10000000}
        inputWithFilesContent={(files, extra) => 
          extra.reject
            ? 'Only Image, pdf and docs files are allowed!'
            : <span className='btn btn-sm bg-transparent text-blue pe-5 d-flex align-items-center'> <i className="fa fa-plus-circle text-blue" aria-hidden="true"></i><span className='fs-7 text-blue'>Upload</span></span>
        }
        inputContent={(files, extra) =>
          extra.reject
            ? 'Only Image, pdf and docs files are allowed!'
            :<span> <i className="fa fa-plus-circle text-blue" aria-hidden="true"></i><span className='ms-2 fs-7 text-blue'>Upload</span></span>
        }
        styles={{
          // dropzone: {  maxHeight: 200},
          dropzoneReject: {borderColor: '#F19373', backgroundColor: '#F1BDAB'},
          inputLabel: (files, extra) => (extra.reject ? {color: '#A02800'} : {})
        }}
        classNames={{
          inputLabelWithFiles: ''
        }}
      />
    </div>
  )
}

export default GCDropZone
