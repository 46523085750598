/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import moment from 'moment'
import '../../../../_metronic/assets/sass/components/cards.scss'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import * as actions from '../redux/TaskAction'
import {RootState} from '../../../../setup'
import {useParams} from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {Attachment} from '../../../Common/attachments/Attachment'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'
import axios from 'axios'
import {showUserNames} from '../../workspace/WorkspaceUIHelper'
import * as boardActions from '../../board/redux/BoardActions'
import Select from 'react-select'

interface ICreateAccount {
  title: string
  description: string
  status: string
  priority: string
  dueDate: Date | String | null
  assignee: []
  label: []
}

const inits: ICreateAccount = {
  title: '',
  description: '',
  status: '',
  priority: 'low',
  dueDate: '',
  assignee: [],
  label: [],
}

interface Props {
  onHide: () => void
  id?: string | null
  itemType?: string
}

const TaskAdd: FC<Props> = ({onHide, id, itemType}) => {
  const dispatch: any = useDispatch()

  const {workspaceid, projectid}: {workspaceid: string; projectid: string} = useParams()
  const {userInfo, taskDetail, currentProject}: any = useSelector<RootState>(
    (state) => ({
      userInfo: state.auth.user,
      taskDetail: state.task.taskDetail,
      currentProject: state.board.currentProject,
    }),
    shallowEqual
  )

  const today = new Date(Date.now() - 86400000)

  const API_URL = process.env.REACT_APP_API_URL
  const LIST_USERS = `${API_URL}/todos/assignees`

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [initValues] = useState<ICreateAccount>(inits)
  const [assigneesId, setAssigneesId] = useState<any>([])
  const [imagePaths, setImagePaths]: any = useState([])
  const [boardColumns, setBoardColumns]: any = useState<[]>([])
  const [taskLabel, setTaskLabel] = useState<any>([])
  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)
  const [allMembers, setAllMembers]: any = useState()
  let [allMembersAndTeams, setAllMembersAndTeams]: any = useState()
  const {attachments, spaceData}: any = useSelector<RootState>(
    (state) => ({attachments: state.task.attachments, spaceData: state.workspace.workspaceDetail}),
    shallowEqual
  ) as any

  useEffect(() => {
    if (Array.isArray(attachments)) {
      setImagePaths(
        attachments.map((attachment: any) => {
          const staticURL = attachment?.path?.split('/')[0]
          if (staticURL === 'public') {
            return attachment.path
          }
          return attachment.type ? attachment.path + '.' + attachment.type : attachment.path
        })
      )
    }
  }, [attachments])

  const fetchAssigneesData = async () => {
    const assigneeData = await axios.get(`${LIST_USERS}/${workspaceid}`)
    setAllMembers(assigneeData?.data[0]?.assignee)
  }

  useEffect(() => {
    fetchAssigneesData()
  }, [])

  useEffect(() => {
    if (spaceData) {
      setAllMembersAndTeams({assignee: allMembers, teams: spaceData?.teams})
    }
  }, [spaceData, allMembers])

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchTask(id))
    } else {
      dispatch(actions.removeTaskDetail([]))
    }
    dispatch(actions.fetchBoardColumns(projectid, workspaceid)).then((res: any) => {
      setBoardColumns(res)
    })
  }, [])
  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
    dispatch(boardActions.currentProject(projectid))
  }, [dispatch])

  useEffect(() => {
    if (id) {
      setAssigneesId(taskDetail?.assignee.map((assignee: any) => assignee._id))
      setTaskLabel(
        taskLabels.filter((option: any) => taskDetail?.label?.indexOf(option.value) >= 0)
      )
    }
  }, [id, taskDetail])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const createAppSchema = Yup.object().shape({
    title: Yup.string()
      .required('Title is required')
      .label('title')
      .min(1, 'Title must contain more than 2 digits'),
    description: Yup.string().nullable(true),
    status: Yup.string().nullable(true),
    priority: Yup.string().nullable(true),
    dueDate: taskDetail?._id
      ? Yup.date().nullable(true)
      : Yup.date().min(today, 'Date must be today or later than today.').nullable(true),
  })

  const initialValuesonEdit = {
    title: taskDetail?.title,
    description: taskDetail?.description,
    status: taskDetail?.status._id,
    priority: taskDetail?.priority,
    dueDate: taskDetail?.dueDate ? moment(taskDetail?.dueDate).format('YYYY-MM-DD') : null,
    assignee: taskDetail?.assignee.map((assignee: any) => {
      return {
        value: assignee._id,
        label: userInfo._id === assignee._id ? assignee.fullName + ' ' + '(Me)' : assignee.fullName,
        id: assignee._id,
      }
    }),
    label: taskDetail?.label,
  }

  const [taskLabels, settaskLabels] = useState([])

  const {settings}: any = useSelector<RootState>(
    (state) => ({
      settings: state.settings.entities,
    }),
    shallowEqual
  )

  useEffect(() => {
    const labelsObject = settings.find((data: any) => data.name === 'taskLabels')
    const taskLabelsArray = labelsObject?.value.split('\n')
    const labels: any = []
    taskLabelsArray?.forEach((task: any) => {
      labels.push({
        value: task.toLowerCase(),
        label: task.charAt(0).toUpperCase() + task.slice(1),
        color: '#ABFF8B',
      })
    })
    settaskLabels(labels)
  }, [settings])

  const handleUpdateTask = (data: any) => {
    let updatedTask = {}
    let actualTask = JSON.parse(JSON.stringify(taskDetail))
    actualTask[data.field] = data.value
    updatedTask = actualTask

    // Update singleTask redux
    dispatch(boardActions.updatedSingleTask(updatedTask))
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  let crumbs: any
  // if (spaceData) {
  //   crumbs = [
  //     {name: 'Dashboard', url: '/dashboard'},
  //     {name: 'Spaces', url: '/spaces'},
  //     {name: `${spaceData?.name}`, url: `/${spaceData?._id}/features`},
  //     {name: 'Task', url: `/${spaceData?._id}/task`},
  //     {name: id ? 'Edit Task' : 'Add Task', url: `/${spaceData?._id}/task`},
  //   ]
  // }
  crumbs = workspaceid
    ? [
        // {name: 'Dashboard', url: '/dashboard'},
        {name: 'Spaces', url: '/dashboard'},
        {name: `${spaceData?.name}`, url: `/${spaceData?._id}/features`},
        {name: 'Project', url: `/${spaceData?._id}/project`},
        {name: `${currentProject?.name}`, url: `/${spaceData?._id}/project/${projectid}/board`},
        {name: 'Tasks', url: `/${spaceData?._id}/project/${projectid}/task`},
        {name: id ? 'Edit Task' : 'Create Task'},
      ]
    : [
        {name: 'Dashboard', url: '/dashboard'},
        {name: 'Project', url: `/project`},
      ]

  return (
    <>
      <AssigneesList
        show={showAssigneesDialog}
        handleClose={() => {
          setShowAssigneesDialog(false)
        }}
        calledBy={'task'}
        setAssigneesId={setAssigneesId}
        assigneesId={assigneesId}
        id={id}
        assignee={allMembersAndTeams?.assignee}
        teams={[]}
        title={'Assignees'}
      />

      <div className='mh-80 page-content'>
        <div className='px-6 px-sm-12'>
          <div className="row">
            <div className="col-sm-6">
              <h2 className='fw-500 fs-2 m-0 py-5'>
                {id ? 'Edit Task' : 'Create Task'}
              </h2>
            </div>
          </div>
        </div>
        {/* <div className='page-header mx-3'>
          <h2>{id ? 'Edit Task' : 'Create Task'}</h2>
        </div> */}
        {crumbs?.length && <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />}

        <div className="overflow_height_scroll">
        <div className='page-body py-lg-10 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={createAppSchema}
                enableReinitialize
                initialValues={taskDetail ? initialValuesonEdit : initValues}
                onSubmit={(values: any) => {
                  values = JSON.parse(JSON.stringify(values))
                  values.assignee = assigneesId
                  if (id) {
                    values._id = id
                    values.attachmentPaths = imagePaths
                    values.label = taskLabel.map((label: any) => label.value)
                    dispatch(actions.UpdateTasks(values, workspaceid, projectid))
                    onHide()
                  } else {
                    if (imagePaths?.length > 0) {
                      values.attachmentPaths = imagePaths
                    }

                    values.label = taskLabel.map((label: any) => label.value)
                    values.workSpace = workspaceid
                    values.project = projectid

                    dispatch(actions.createTasks(values, workspaceid))

                    onHide()
                  }
                }}
              >
                {({setFieldValue, values}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Title</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='title'
                            placeholder='Add title'
                            style={{minHeight: '41px', maxHeight: '41px'}}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='title' />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-4 fv-row mb-5'>
                            <label className='fs-6 fw-bold mb-2'>
                              <span>Status</span>
                            </label>
                            <div className='row fv-row'>
                              <div className='col-12'>
                                <Field
                                  as='select'
                                  name='status'
                                  className='form-select form-select-solid'
                                  style={{minHeight: '41px', maxHeight: '41px'}}
                                >
                                  {/* <option value=''>Select an option</option> */}
                                  {boardColumns.map((status: any, i: any) => {
                                    return (
                                      <option key={i} value={status?._id}>
                                        {status?.name}
                                      </option>
                                    )
                                  })}
                                </Field>
                                <div className='text-danger'>
                                  <ErrorMessage name='status' />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-4 fv-row mb-5'>
                            <label className='fs-6 fw-bold mb-2'>
                              <span>Priority</span>
                            </label>

                            <div className='position-relative'>
                              <Field
                                as='select'
                                name='priority'
                                className='form-select form-select-solid'
                                style={{minHeight: '41px', maxHeight: '41px'}}
                              >
                                <option value={'low'}>Low</option>
                                <option value={'medium'}>Medium</option>
                                <option value={'high'}>High</option>
                              </Field>
                              <div className='text-danger'>
                                <ErrorMessage name='priority' />
                              </div>
                            </div>
                          </div>
                          <div className='col-md-4 fv-row mb-5'>
                            <label className='fs-6 fw-bold mb-2'>
                              <span>Due date</span>
                            </label>

                            <div className='row fv-row'>
                              <div className='col-12'>
                                <Field
                                  type='date'
                                  name='dueDate'
                                  min={moment().format('YYYY-MM-DD')}
                                  className='form-select form-select-solid date-field'
                                />

                                <div className='text-danger'>
                                  <ErrorMessage name='dueDate' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-6 fv-row mb-5'>
                            <label className='fs-6 fw-bold mb-2'>
                              <span>Assignee</span>
                            </label>

                            <div className='row fv-row'>
                              <div className='col-12 multisel'>
                                <div className='d-flex justify-content-center bg-lightest'>
                                  <div>
                                    {assigneesId?.length > 0 && (
                                      <>
                                        <span
                                          className='d-inline-flex tags_ui align-items-center  border-runded-4 px-2 fs-8 m-2'
                                          style={{borderRadius: '4px'}}
                                          title={showUserNames(
                                            assigneesId,
                                            allMembersAndTeams?.assignee
                                          )}
                                        >
                                          Users
                                          <span className='badge ms-2 bg-transparent text-danger py-1 px-2 fs-9 m-1'>
                                            {assigneesId?.length}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                  </div>
                                  <button
                                    title='Add'
                                    type='button'
                                    className='btn text-blue fw-500 fs-6 btn-sm bg-transparent'
                                    data-bs-toggle='modal'
                                    data-bs-target='#kt_modal_create_app'
                                    id='kt_modal_assignees_list_app'
                                    onClick={() => {
                                      setShowAssigneesDialog(true)
                                    }}
                                  >
                                    <i
                                      className='fa fa-plus-circle text-blue'
                                      aria-hidden='true'
                                    ></i>
                                    add
                                  </button>
                                </div>
                                <div className='text-danger'>
                                  <ErrorMessage name='assignee' />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-6 fv-row mb-5'>
                            <label className='fs-6 fw-bold mb-2'>
                              <span>Label</span>
                            </label>
                            <div className='row fv-row'>
                              <div className='col-12'>
                                <Select
                                  className='multi-select-container'
                                  name='label'
                                  defaultValue={taskLabels.filter(
                                    (option: any) => taskDetail?.label?.indexOf(option.value) >= 0
                                  )}
                                  value={taskLabel}
                                  onChange={(e: any) => {
                                    setTaskLabel(e)
                                  }}
                                  placeholder='Select labels'
                                  options={taskLabels}
                                  isMulti={true}
                                  isClearable={false}
                                />
                                <div className='text-danger'>
                                  <ErrorMessage name='status' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='fv-row'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span>Description</span>
                          </label>

                          <Field
                            className='form-control form-control-lg form-control-solid'
                            name='description'
                            placeholder='Add description'
                            as='textarea'
                            rows='5'
                          />

                          <div className='text-danger'>
                            <ErrorMessage name='description' />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Attachment
                      attachments={attachments}
                      loadAttachments={actions.loadAttachments}
                      attachmentType='attachmentUpload'
                      id={id}
                      itemType='task'
                    />

                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn cancel_button fs-7 fw-bolder  me-3'
                          onClick={onHide}
                          title='Cancel'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-sm btn-blue fs-7'
                          title={id ? 'Update' : 'Create'}
                        >
                          <span className='indicator-label fs-7'>{id ? 'Update' : 'Create'}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
    // </Modal>
  )
}

export {TaskAdd}
