/* eslint-disable jsx-a11y/anchor-is-valid */

import {FC, useEffect, useState} from 'react'
import {shallowEqual, useSelector, useDispatch} from 'react-redux'
import {RootState} from '../../../setup'
import {getImageURL, KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {ChatInner} from '../../../_metronic/partials'
import {socket} from '../../../_metronic/partials/layout/header-menus/HeaderUserMenu'
import moment from 'moment'
import {Breadcrumbs} from '../../pages/Breadcrumbs'
import {Loading} from '../../../_metronic/layout/Loading'
import {GroupActions} from './GroupActions'
import {Unauthorized} from './Unauthorized'
import {useLocation, useParams} from 'react-router-dom'
import {CreateChannel} from './CreateChannel'
import '../../../_metronic/assets/sass/components/create-channel.scss'
type Props = {
  discussionActions?: any
  workspaceid?: any
  chatType?: any
}
const Private: FC<Props> = ({discussionActions, workspaceid, chatType}) => {
  const dispatch = useDispatch()
  const [userName, setUserName] = useState<any>()
  const [showGroupPopup, setShowGroupPopup] = useState<any>()
  const [allUserChat, setAllUserChat] = useState<any>([])
  const [allChatDuplicateUsers, setAllChatDuplicateUsers] = useState<any>([])
  const [findIndex, setFindIndex] = useState<any>(null)
  const [isGroupAdmin, setIsGroupAdmin] = useState<any>('')
  const [allGroupChatUserName, setAllGroupChatUserName] = useState<any>('')
  const [isGeneralChannel, setIsGeneralChannel] = useState<any>('')
  const [oldChatName, setOldChatName] = useState<string>('')
  let crumbs
  const {
    isLoading,
    allWorkspaceUsers,
    allChat,
    loginUser,
    chatId,
    currentSpace,
    groupChat,
    unauthorizedForChat,
    chatIdByNotification,
    editChat,
    creatingChannel,
    groupCreated,
    accessToken,
    memberAdded,
  }: any = useSelector<RootState>(
    (state) => ({
      state,
      allWorkspaceUsers: state.discussions.chat,
      allChat: state.discussions.allChat,
      loginUser: state.auth.user,
      chatId: state.discussions.chatId,
      currentSpace: state.discussions.currentSpaceName,
      workSpaceData: state.workspace.workspaceDetail,
      messages: state.discussions.messages,
      isLoading: state.discussions.loading,
      groupChat: state.discussions.groupChat,
      unauthorizedForChat: state.discussions.unauthorizedForChat,
      chatIdByNotification: state.discussions.chatIdByNotification,
      editChat: state.discussions.editChat,
      creatingChannel: state.discussions.creatingChannel,
      groupCreated: state.discussions.groupCreated,
      accessToken: state.auth.accessToken,
      memberAdded: state.discussions.memberAdded,
    }),
    shallowEqual
  )

  let discussionId = useLocation()

  useEffect(() => {
    setTimeout(() => {
      document.getElementById(chatIdByNotification)?.click()
    }, 600)
  }, [chatIdByNotification, workspaceid])
  useEffect(() => {
    dispatch(discussionActions.getAllChat(workspaceid, chatType))
    if (workspaceid) {
      dispatch(discussionActions.getAllWorkspaceUsers(workspaceid))
      dispatch(discussionActions.fetchWorkspace(workspaceid))
    } else {
      dispatch(discussionActions.getAllUsers())
    }
    dispatch(discussionActions.currentChatType(''))
    window.onload = function () {
      setTimeout(() => {
        document.getElementById(discussionId.hash.substring(1))?.click()
      }, 100)
    }
    return () => {
      dispatch(discussionActions.currentUserChatId(''))
    }
  }, [workspaceid])

  useEffect(() => {
    dispatch(discussionActions.creatingChannel(false))
    dispatch(discussionActions.getAllChat(workspaceid, chatType))
    if (workspaceid) {
      dispatch(discussionActions.getAllWorkspaceUsers(workspaceid))
      dispatch(discussionActions.fetchWorkspace(workspaceid))
    } else {
      dispatch(discussionActions.getAllUsers())
    }
    dispatch(discussionActions.currentChatType(''))
    window.onload = function () {
      setTimeout(() => {
        document.getElementById(discussionId.hash.substring(1))?.click()
      }, 100)
    }
    return () => {
      dispatch(discussionActions.currentUserChatId(''))
      dispatch(discussionActions.creatingChannel(false))
    }
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on('getUserStatus', (user: any) => {
        if (user?._id) {
          const newChats = allChat.map((chat: any) => {
            if (!chat.isGroupChat) {
              const chatCopy = JSON.parse(JSON.stringify(chat))
              chatCopy.users = chat.users.map((singleChatUser: any) =>
                singleChatUser._id === user._id ? user : singleChatUser
              )
              return chatCopy
            }
            return chat
          })
          if (newChats.length > 0) {
            dispatch(discussionActions.allChat(newChats))
          }

          const users = allWorkspaceUsers.map((spaceUser: any) =>
            spaceUser._id === user._id ? user : spaceUser
          )
          dispatch(discussionActions.discussionsFetched(users))
        }
      })
    }
    return () => {
      socket.removeListener('getUserStatus')
    }
  }, [allChat, allWorkspaceUsers])

  useEffect(() => {
    socket.on('userAddeed', (data: any) => {
      let users: any = []
      data.filter((user: any) => {
        users.push(user._id)
      })

      if (users.includes(loginUser._id)) {
        dispatch(discussionActions.getAllChat(workspaceid, chatType))
        users = []
      }
    })
    return () => {
      socket.removeListener('userAddeed')
    }
  }, [memberAdded === true])

  useEffect(() => {
    const getChat = allChat.filter((singleChat: any) => {
      return singleChat._id === chatId
    })[0]

    if (getChat && getChat.isGroupChat) {
      setUserName(getChat?.chatName)
      setAllGroupChatUserName(getChat.users)
    }
    return () => {
      socket.removeListener('getUserStatus')
    }
  }, [allChat])

  const handleClick = async (event: any, chat: any, index: any) => {
    dispatch(discussionActions.groupCreated(false))
    setShowGroupPopup(false)
    if (chat.count > 0) {
      dispatch(discussionActions.messageScenedUserIds(chat._id))
      dispatch(discussionActions.getAllChat(workspaceid, chatType))
    }
    setFindIndex(chat._id)

    dispatch(discussionActions.currentChatType(chat.isGroupChat ? chat.isGroupChat : false))

    setIsGeneralChannel(chat.generalChanel)
    if (chat.isGroupChat) {
      setIsGroupAdmin(chat.groupAdmin._id)
      setAllGroupChatUserName(JSON.parse(JSON.stringify(chat.users)))
    } else {
      setAllGroupChatUserName([])
    }
    if (!chat.hasOwnProperty('email')) {
      if (chat.isGroupChat) {
        setUserName(chat.chatName)
      } else {
        chat.users.map((name: any) => {
          if (name._id !== loginUser._id) {
            setUserName(name.fullName)
          }
        })
      }
      dispatch(discussionActions.currentUserChatId(chat._id))
      dispatch(discussionActions.getAllMessages(chat._id))
    } else {
      const workSpace = workspaceid
      const userId = chat._id

      dispatch(discussionActions.createChatIFNotExist(workSpace, userId, chatType))

      setUserName(chat.fullName)
    }
  }

  const searchChat = (e: any) => {
    const searchKeyword = e.target.value

    if (searchKeyword !== '') {
      const results = allChatDuplicateUsers.filter((user: any) => {
        if (user?.isGroupChat === true) {
          return user?.chatName.toLowerCase()?.includes(searchKeyword?.toLowerCase())
        } else if (user?.isGroupChat === false) {
          if (user?.users?.[1]?._id != loginUser?._id) {
            if (user?.users?.[1].firstName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())) {
              return user?.users?.[1].firstName
                ?.toLowerCase()
                ?.includes(searchKeyword?.toLowerCase())
            } else {
              return user?.users?.[1].lastName
                ?.toLowerCase()
                ?.includes(searchKeyword?.toLowerCase())
            }
          } else {
            if (user?.users?.[0].firstName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())) {
              return user?.users?.[0].firstName
                ?.toLowerCase()
                ?.includes(searchKeyword?.toLowerCase())
            } else {
              return user?.users?.[1].lastName
                ?.toLowerCase()
                ?.includes(searchKeyword?.toLowerCase())
            }
          }
        } else {
          if (user?.firstName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())) {
            return user?.firstName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
          } else {
            return user?.lastName?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
          }
        }
      })

      setAllUserChat(results)
    } else if (searchKeyword === '') {
      combineLoginChatUser()
      const filterUsers = allWorkspaceUsers.filter((user: any, i: any) => {
        if (!allChatUsersIds.includes(user._id)) {
          return user
        }
      })

      const allChats = [...allChat, ...filterUsers]
      setAllChatDuplicateUsers(allChats)
      setAllUserChat(allChats)
    }
  }

  const allChatUsersIds: any = []

  const combineLoginChatUser = () => {
    allChat.map((chat: any) => {
      if (!chat.isGroupChat) {
        return chat?.users?.map((user: any) => {
          if (user._id != loginUser._id) {
            allChatUsersIds.push(user._id)
          }
        })
      }
    })
  }

  useEffect(() => {
    const users: any = []

    currentSpace?.group?.[0]?.group.forEach((chartRole: any) => {
      users.push(chartRole.user)
    })
    combineLoginChatUser()
    const filterUsers = allWorkspaceUsers?.filter((user: any, i: any) => {
      if (!allChatUsersIds.includes(user._id)) {
        return user
      }
    })

    const allChats = [...allChat, ...filterUsers, ...users]
    setAllChatDuplicateUsers(allChats)
    setAllUserChat(allChats)
  }, [allWorkspaceUsers, allChat, currentSpace])

  crumbs = workspaceid
    ? [
        {name: 'Spaces', url: '/dashboard'},
        {name: `${currentSpace?.name}`, url: `/${currentSpace?._id}/features`},
        {name: 'Discussion', url: `/${currentSpace?._id}`},
      ]
    : [
        {name: 'Dashboard', url: '/dashboard'},
        {name: 'Discussion', url: `/Discussion`},
      ]

  useEffect(() => {
    if (editChat) {
      dispatch(discussionActions.getChannelUsers(chatId))
        .then((res: any) => {
          setOldChatName(res.data.chatName)
        })
        .catch((err: any) => {
          dispatch(discussionActions.unauthorizedForChat(err.response.data))
        })
    }
  }, [editChat])

  const toggleCreateChannel = () => {
    setShowGroupPopup(!showGroupPopup)
    if (!showGroupPopup) {
      dispatch(discussionActions.creatingChannel(true))
    } else {
      dispatch(discussionActions.creatingChannel(false))
    }
  }
  return (
    <>
      {unauthorizedForChat.statusCode === 403 && <Unauthorized />}
      {unauthorizedForChat.statusCode !== 403 && isLoading && <Loading />}
      {unauthorizedForChat.statusCode !== 403 && !isLoading && (
        <>
          {/* <div className='d-flex flex-column flex-lg-row bg-white flex-wrap rounded chat-module-box'> */}
          <div className='mh-80 page-content height_adjust_mobile'>
            {/* <div className='flex-column flex-lg-row-auto w-xl-400px w-md-100 w-100 mb-10 mb-lg-0'>
              <div className='card card-flush left-bar'>
                <div className='card-header d-block ps-12 pt-3 pb-2' id='kt_chat_contacts_header'>
                  <h3 className='card-title d-flex justify-content-between align-items-center'>
                    <span className='card-label fw-500 fs-2 mb-1'>
                      {workspaceid && `${currentSpace.name} `}Discussion
                    </span>
                  </h3>
                </div>
              </div>
            </div> */}
            <div className='px-6 px-sm-12'>
              <div className='row'>
                <div className='col-sm-6'>
                  <h2 className='fw-500 fs-2 m-0 py-5'>
                    {workspaceid && `${currentSpace.name} `}Discussion
                  </h2>
                </div>
              </div>
            </div>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}

            <div className='row px-3'>
              <div className='col-sm-4'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='card-header d-block' id='kt_chat_contacts_header'>
                      <div className='card-title d-flex justify-content-between align-items-center my-4'>
                        <form className='w-100 position-relative' autoComplete='off'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen021.svg'
                            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                          />

                          <input
                            title='Search by name'
                            type='text'
                            className='form-control form-control-solid px-15 bg-transparent border-chat-grey'
                            name='search'
                            placeholder='Search by name...'
                            onChange={(e) => {
                              searchChat(e)
                            }}
                          />
                        </form>

                        <button
                          title='Create Channel'
                          type='button'
                          className='mb-3 btn bg-transparent position-relative py-2 px-0 fw-bolder fs-7 d-flex align-items-center'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                          id='kt_modal_group'
                          onClick={() => {
                            toggleCreateChannel()
                          }}
                        >
                          <div className='icon me-2'></div>
                          {/* <i className='fa fa-users fs-2' aria-hidden='true'></i> */}
                          <img src={toAbsoluteUrl('/media/icons/svgexport-10 (1).svg')} />
                        </button>
                      </div>
                      {editChat || showGroupPopup || creatingChannel ? (
                        <CreateChannel
                          discussionActions={discussionActions}
                          workspaceid={workspaceid}
                          setShowGroupPopup={setShowGroupPopup}
                          setIsGeneralChannel={setIsGeneralChannel}
                          removeSelectedChat={setFindIndex}
                          setIsGroupAdmin={setIsGroupAdmin}
                          oldChatName={oldChatName}
                          chatType={chatType}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className='row chats'>
                  <div className='col-sm-12'>
                    <div className='card-body pt-5 px-0 pb-0' id='kt_chat_contacts_body'>
                      <ul
                        className={`scroll-y  height_overlay  mb-3 w-100 p-0 border border-white ${
                          creatingChannel ? 'd-none' : ''
                        }`}
                      >
                        {allUserChat.length > 0 ? (
                          allUserChat.map((chat: any, index: any) => {
                            // eslint-disable-next-line no-lone-blocks

                            {
                              return (
                                chat?._id !== loginUser?._id && (
                                  <li
                                    onClick={(event) => handleClick(event, chat, index)}
                                    className={`d-flex cursor-pointer px-4 w-100 ${
                                      findIndex === chat._id || chatId === chat._id
                                        ? 'active bg-light-primary'
                                        : ''
                                    }`}
                                    key={chat._id}
                                    id={chat._id}
                                    title={
                                      chat?.isGroupChat === true
                                        ? chat?.chatName
                                        : chat?.isGroupChat === false
                                        ? chat?.users?.[1]?._id === loginUser?._id
                                          ? chat?.users?.[0]?.fullName
                                          : chat?.users?.[1]?.fullName
                                        : chat?.fullName
                                    }
                                  >
                                    <div className='d-flex justify-content-between align-items-center py-4 rounded-0 border-bottom w-100'>
                                      <div className='d-flex align-items-end'>
                                        <div className='symbol symbol-45px symbol-circle'>
                                          {chat?.isGroupChat === false ? (
                                            <img
                                              alt={chat?.chatName}
                                              src={getImageURL(
                                                chat?.isGroupChat === false &&
                                                  chat?.users?.[0]?._id !== loginUser?._id
                                                  ? chat.users?.[1]?.image?.[0]?.thumbnailUrl
                                                  : chat.users?.[1]?.image?.[0]?.thumbnailUrl,

                                                accessToken
                                              )}
                                            />
                                          ) : chat?.isGroupChat !== true ? (
                                            <img
                                              alt={chat?.chatName}
                                              src={getImageURL(
                                                chat?.image?.[0]?.thumbnailUrl,

                                                accessToken
                                              )}
                                            />
                                          ) : null}
                                        </div>

                                        {chat?.isGroupChat === false &&
                                          chat?.users?.[0]?._id !== loginUser?._id &&
                                          chat?.users?.[0]?.socketIds?.length > 0 && (
                                            <div
                                              className='symbol-badge bg-success start-100 top-100 mb-2 border-4 h-10px w-10px ms-n2 mt-n2 rounded-circle'
                                              style={{zIndex: 1}}
                                            ></div>
                                          )}
                                        {chat?.isGroupChat === false &&
                                          chat?.users?.[1]?._id !== loginUser?._id &&
                                          chat?.users?.[1]?.socketIds?.length > 0 && (
                                            <div
                                              className='symbol-badge bg-success start-100 top-100 mb-2 border-4 h-10px w-10px ms-n2 mt-n2 rounded-circle'
                                              style={{zIndex: 1}}
                                            ></div>
                                          )}

                                        {chat._id !== loginUser._id &&
                                          chat?.socketIds?.length > 0 && (
                                            <div
                                              className='symbol-badge bg-success start-100 top-100 mb-2 border-4 h-10px w-10px ms-n2 mt-n2 rounded-circle'
                                              style={{zIndex: 1}}
                                            ></div>
                                          )}
                                      </div>
                                      <div className='d-flex align-items-start justify-content-center w-100 flex-column ms-5 ms-5'>
                                        <span
                                          className={`fs-6 text-gray-900  ${
                                            chat.count > 0 ? 'fw-bolder' : ''
                                          }`}
                                        >
                                          {chat?.isGroupChat === true
                                            ? chat?.chatName !== '#General'
                                              ? '# ' + chat?.chatName
                                              : chat?.chatName
                                            : chat?.isGroupChat === false
                                            ? chat?.users?.[1]?._id === loginUser?._id
                                              ? chat?.users?.[0]?.fullName
                                              : chat?.users?.[1]?.fullName
                                            : chat?.fullName}
                                        </span>

                                        <span
                                          className='fs-7 fw-bold text-gray-400'
                                          style={{
                                            display: '-webkit-box',
                                            maxWidth: '120px',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {chat?.latestMessage && chat?.latestMessage?.content}
                                        </span>
                                      </div>

                                      {chat?.latestMessage || chat.isGroupChat ? (
                                        <div className='d-flex flex-grow-2 w-50 flex-column align-items-end ms-2'>
                                          {chat.count > 0 ? (
                                            <span
                                              className='badge badge-sm badge-circle badge-primary fs-9'
                                              style={{
                                                minWidth: '16px',
                                                height: '16px',
                                                width: '16px',
                                              }}
                                            >
                                              {chat.count}
                                            </span>
                                          ) : null}
                                          <span className='text-muted fs-7 mb-1'>
                                            {moment(chat?.updatedAt).fromNow()}
                                          </span>
                                        </div>
                                      ) : null}
                                    </div>
                                  </li>
                                )
                              )
                            }
                          })
                        ) : (
                          <h4 className='text-center'>No Discussion Found!</h4>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-sm-8 p-0'>
                <div className='card h-100' id='kt_chat_messenger'>
                  <div
                    className='card-header d-flex align-items-center py-3 px-0'
                    id='kt_chat_messenger_header'
                  >
                    <div className='d-flex justify-content-evenly flex-wrap' style={{width: '89%'}}>
                      <div className='card-title d-flex align-items-center px-5 py-3 mx-2 flex-grow-1'>
                        <div className='d-flex justify-content-center flex-column me-3'>
                          {userName ? (
                            <>
                              <span className='fs-4  text-gray-900 me-1 lh-1'>
                                <span> {groupChat && !showGroupPopup && '# '}</span>
                                <span className='ms-4'> {!showGroupPopup && userName}</span>
                              </span>
                            </>
                          ) : null}
                        </div>
                      </div>
                      {/* && allGroupChatUserName.length > 1 */}
                      {groupChat === true && !creatingChannel && (
                        <div className='symbol-group symbol-hover symbol-position px-0'>
                          <button
                            title='Add Members'
                            type='button'
                            className='btn btn-sm bg-transparent text-primary fw-500 p-2 me-3'
                            onClick={() => dispatch(discussionActions.showMemberPopup(true))}
                          >
                            <i className='fa fa-plus-circle text-blue' aria-hidden='true'></i>
                            {allGroupChatUserName.length < 2 && 'Add Members'}
                          </button>
                          {allGroupChatUserName.length > 1 &&
                            allGroupChatUserName
                              ?.slice(0, 4)
                              ?.reverse()
                              ?.map((user: any, i: any) => {
                                if (user?._id === loginUser?._id) {
                                  return (
                                    <div className='symbol symbol-30px symbol-circle' key={i}>
                                      <img
                                        alt='Pic'
                                        title='You'
                                        src={getImageURL(
                                          loginUser?.image?.[0]?.thumbnailUrl,
                                          accessToken
                                        )}
                                      />
                                    </div>
                                  )
                                } else {
                                  return (
                                    <div className='symbol symbol-30px symbol-circle' key={i}>
                                      <img
                                        alt='Pic'
                                        title={user?.fullName}
                                        src={getImageURL(
                                          user?.image?.[0]?.thumbnailUrl,
                                          accessToken
                                        )}
                                      />
                                    </div>
                                  )
                                }
                              })}

                          {allGroupChatUserName.length > 6 && (
                            <a href='#' className='symbol symbol-35px symbol-circle'>
                              <span
                                className='symbol-label fs-8 fw-bold'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                title='more users'
                              >
                                +{allGroupChatUserName.length}
                              </span>
                            </a>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <button
                        type='button'
                        title='Actions'
                        className={`btn btn-sm ${
                          groupChat === true &&
                          isGroupAdmin === loginUser._id &&
                          !showGroupPopup &&
                          isGeneralChannel != true
                            ? ''
                            : 'd-none'
                        }`}
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                      >
                        <img src='/media/icons/group-1668.svg' />
                        {/* <i className='fa fa-ellipsis-v fs-2'></i> */}
                      </button>

                      <GroupActions
                        discussionActions={discussionActions}
                        workspaceid={workspaceid}
                        setShowGroupPopup={setShowGroupPopup}
                        chatType={chatType}
                      />
                    </div>
                  </div>
                  {!creatingChannel && (
                    <ChatInner
                      workspaceid={workspaceid}
                      discussionActions={discussionActions}
                      showGroupPopup={showGroupPopup}
                      setUserName={setUserName}
                      chatType={chatType}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {Private}
