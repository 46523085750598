import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const ORGANIZATION_ADMIN_URL = `${API_URL}/admin/companies`
const ORGANIZATION_URL = `${API_URL}/users/companies`
const USER_URL = `${API_URL}/users`
const ROLE_URL = `${API_URL}/admin/permissions/user-allowed-roles`
const ALL_COMPANIES_AND_GROUPS = `${API_URL}/users/companies/groups`

export function getOrganization({
  search,
  page,
  sortby,
  orderby,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
}) {
  return axios.get(ORGANIZATION_ADMIN_URL, {
    params: {
      search,
      page,
      sortby,
      orderby,
    },
  })
}

export function getOrganizationCharts({
  id,
  search,
  page,
  sortby,
  orderby,
}: {
  id: string
  search: string
  page: number | string
  orderby: string
  sortby: string
}) {
  return axios.get(`${ORGANIZATION_URL}/${id}/charts`, {
    params: {
      search,
      page,
      sortby,
      orderby,
    },
  })
}

export function getUserCharts({
  search,
  page,
  sortby,
  orderby,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
}) {
  return axios.get(`${ORGANIZATION_URL}/charts`, {
    params: {
      search,
      page,
      sortby,
      orderby,
    },
  })
}

export function getUsers() {
  return axios.get(USER_URL)
}

export function getRoles() {
  return axios.get(`${ROLE_URL}`)
}

export function fetchChartRoles({id}: {id: string}) {
  return axios.get(`${ORGANIZATION_URL}/chart/${id}/roles`, {})
}

export function saveChartRoles({id}: {id: string}, {roles}: {roles: any}) {
  return axios.post(`${ORGANIZATION_URL}/chart/role/${id}`, {roles})
}

export function deleteChartRoles({id}: {id: string}, {roles}: {roles: any}) {
  return axios.patch(`${ORGANIZATION_URL}/chart/role/${id}`, {roles})
}

export function createOrganization({
  name,
  descriptions,
  defaultValue,
}: {
  name: string
  descriptions: string
  defaultValue: boolean
}) {
  return axios.post(ORGANIZATION_URL, {
    name,
    descriptions,
    default: defaultValue,
  })
}
export function createOrganizationChart({name, company}: {name: string; company: string}) {
  return axios.post(`${ORGANIZATION_URL}/chart`, {
    name,
    company,
  })
}
export function updateOrganizationChart({
  id,
  name,
  company,
}: {
  id: string
  name: string
  company: string
}) {
  return axios.patch(`${ORGANIZATION_URL}/chart/${id}`, {
    name,
    company,
  })
}
export function updateOrganization({
  id,
  name,
  descriptions,
  defaultValue,
}: {
  id: string
  name: string
  descriptions: string
  defaultValue: boolean
}) {
  return axios.patch(`${ORGANIZATION_URL}/${id}`, {
    name,
    descriptions,
    default: defaultValue,
  })
}

export function deleteOrganization({id}: {id: string | undefined | null}) {
  return axios.delete(`${ORGANIZATION_URL}/${id}`)
}

export function deleteOrganizationChart({id}: {id: string | undefined | null}) {
  return axios.delete(`${ORGANIZATION_URL}/${id}/chart`)
}

export function Organization({id}: {id: string}) {
  return axios.get(`${ORGANIZATION_URL}/${id}`)
}
export function fetchOrganizationChart({chartId}: {chartId: string}) {
  return axios.get(`${ORGANIZATION_URL}/chart/${chartId}`)
}
export function getAllCompanies() {
  return axios.get(`${ALL_COMPANIES_AND_GROUPS}`)
}
