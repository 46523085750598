/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {CreateWorkspace} from './CreateWorkspace'
import {WorkspaceDelete} from './WorkspaceDeleteDialog'
import * as actions from '../redux/WorkspaceActions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {Pagination} from '../../../pagination/pagination'
import {TodoListSorting} from '../../../../_metronic/layout/sort/Sort'
import {Search} from '../../../../_metronic/layout/search/Search'
import './workspace.scss'
import {Can} from '../../../Common/RBAC/Can'

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

const WorkspaceTable: React.FC = () => {
  const dispatch = useDispatch()
  const [showWorkspaceDialog, setShowWorkspaceDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [workspaces, setWorkspaces] = useState([{}])
  const [spaceDetail, setSpaceDetail] = useState()
  const [fetchRecords, setFetchRecords] = useState(false)
  const [delWorkspace, setDeleteWorkspace] = useState('')
  const [toggleView, setToggleView] = useState(false)
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: '',
    createdAt: '',
    spaceType: '',
    assignee: '',
    maintainer: '',
  })
  const [isFiltered, setIsFiltered] = useState(false)

  const {workspaceData, workspaceDetail, error}: any = useSelector<RootState>(
    (state) => ({
      workspaceData: state.workspace.entities,
      workspaceDetail: state.workspace.workspaceDetail,
      error: state.workspace.error,
    }),
    shallowEqual
  )

  const userInfo: any = useSelector<RootState>((state) => state.auth.user, shallowEqual)

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.workspace,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (Array.isArray(workspaceData)) {
      setWorkspaces(workspaceData)
    }
  }, [workspaceData])

  useEffect(() => {
    if (workspaceDetail) {
      setSpaceDetail(workspaceDetail)
    }
  }, [workspaceDetail])
  const fetchWorkspace = (_id: string) => async (event: any) => {
    dispatch(actions.fetchWorkspace(_id))

    setShowWorkspaceDialog(true)
  }
  const deleteWorkspace = (_id: string) => async (event: any) => {
    setDeleteWorkspace(_id)
    setShowDeleteDialog(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`space-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && workspaces?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && workspaces?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchWorkspaces({
        search: '',
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        spaceType: sortValues.spaceType,
        createdAt: sortValues.createdAt,
        assignee: sortValues.assignee,
        maintainer: sortValues.maintainer,
      })
    )
  }, [sortValues, fetchRecords])

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        spaceType: sortValues.spaceType,
        createdAt: sortValues.createdAt,
        assignee: sortValues.assignee,
        maintainer: sortValues.maintainer,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        spaceType: sortValues.spaceType,
        createdAt: sortValues.createdAt,
        assignee: sortValues.assignee,
        maintainer: sortValues.maintainer,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchWorkspaces({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        createdAt: sortValues.createdAt,
        spaceType: sortValues.spaceType,
        assignee: sortValues.assignee,
        maintainer: sortValues.maintainer,
      })
    )
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchWorkspaces({
        search: search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        createdAt: sortValues.createdAt,
        spaceType: sortValues.spaceType,
        assignee: sortValues.assignee,
        maintainer: sortValues.maintainer,
      })
    )
  }

  return (
    <>
      <CreateWorkspace
        show={showWorkspaceDialog}
        spaceDetail={spaceDetail}
        handleClose={() => {
          setShowWorkspaceDialog(false)
          dispatch(actions.makekWorkSpaceEmpty([{}]))
          dispatch(actions.removeErrorMessage(''))
        }}
        error={error}
        setFetchRecords={setFetchRecords}
        fetchReocords={fetchRecords}
      />

      <WorkspaceDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={delWorkspace}
        setFetchRecords={setFetchRecords}
        fetchReocords={fetchRecords}
      />

      <div className={`card legend-card-main `}>
        <div className='card-header border-0'>
          {/* begin::Header */}
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Spaces</span>
          </h3>
          <Search handleSearch={handleSearch} />

          <div
            className='card-toolbar mt-0 pt-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Create a workspace'
          >
            <span
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              onClick={() => {
                setSpaceDetail(undefined)

                setShowWorkspaceDialog(true)
              }}
            >
              <KTSVG path='' className='svg-icon-3' />
              Create a space
            </span>
          </div>
        </div>
        {toggleView && (
          <TodoListSorting
            sortValues={sortValues}
            setSortValues={setSortValues}
            setIsFiltered={setIsFiltered}
          />
        )}
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body d-flex flex-column py-3'>
          {/* begin::Table container */}

          {workspaces?.length > 0 ? (
            <div className='table-responsive'>
              {/* begin::Table */}

              <table className='todo-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th
                      className=''
                      style={{cursor: 'pointer', minWidth: '230px'}}
                      onClick={sortColumn}
                      abbr='name'
                      id='space-name-head'
                    >
                      Name
                    </th>

                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='spaceKey'
                      id='space-spaceKey-head'
                    >
                      Key
                    </th>
                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='spaceType'
                      id='space-spaceType-head'
                    >
                      Type
                    </th>
                    <th
                      className='min-w-120px'
                      // style={{cursor: 'pointer'}}
                      // onClick={sortColumn}
                      abbr='description'
                      id='space-description-head'
                    >
                      Description
                    </th>
                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='createdAt'
                      id='space-createdAt-head'
                    >
                      Created Date
                    </th>

                    <th className='min-w-100px text-center'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {workspaces?.length > 0 &&
                    workspaces?.map((workspace: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td data-label='Title'>
                            {/* <Link className='d-flex' to={`/workspace/${workspace?._id}`}> */}
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span
                                  title={workspace?.name}
                                  style={{
                                    display: 'block',
                                    width: '230px',
                                    minWidth: '230px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {workspace?.name}
                                </span>
                              </div>
                            </div>
                            {/* </Link> */}
                          </td>

                          <td data-label='Space Key' title={workspace.spaceKey}>
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {workspace.spaceKey}
                              </div>
                            </div>
                          </td>
                          {workspace.spaceKey ? (
                            <td
                              data-label='Space Type'
                              title={workspace?.spaceType === 0 ? 'Personal Space' : 'Group Space'}
                            >
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {workspace?.spaceType === 0 ? 'Personal Space' : 'Group Space'}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td data-label='Space Type'>
                              <div className='d-flex align-items-center '>
                                <div className='d-flex justify-content-start flex-column'>
                                  {'-'}
                                </div>
                              </div>
                            </td>
                          )}
                          <td data-label='Description' title={workspace?.description}>
                            {workspace?.description ? (
                              <div className='d-flex align-items-center'>
                                <div
                                  className='d-flex justify-content-start flex-column'
                                  style={{
                                    display: 'block',
                                    width: '230px',
                                    minWidth: '230px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {workspace?.description}
                                </div>
                              </div>
                            ) : (
                              <div className='d-flex align-items-center '>
                                <div className='d-flex justify-content-start flex-column'>
                                  {'-'}
                                </div>
                              </div>
                            )}
                          </td>
                          <td
                            data-label='Created Date'
                            title={
                              workspace.createdAt
                                ? moment(workspace.createdAt).format('MM/DD/YYYY')
                                : '-'
                            }
                          >
                            {workspace?.createdAt && (
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {moment(workspace.createdAt).format('MM/DD/YYYY')}
                                </div>
                              </div>
                            )}
                          </td>

                          <td data-label='Actions'>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                onClick={fetchWorkspace(workspace?._id)}
                                title='Edit'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_delete_app'
                                id='kt_toolbar_primary_button'
                                onClick={deleteWorkspace(workspace?._id)}
                                title='Delete'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>

              {/* end::Table */}
            </div>
          ) : (
            <div className='no-item-found'>
              <div className='d-flex align-items-center flex-column'>
                {' '}
                <img src='./media/icons/todo.png' className='img-fluid p-3' alt='icon' />
                <p className='fs-1 text-700 text-center text-primary w-100 fs-800'>No item found</p>
              </div>
            </div>
          )}

          {/* end::Table container */}
        </div>

        <Pagination paginationData={paginationData} fetchData={handlePagination} />

        {/* begin::Body */}
      </div>
    </>
  )
}

export {WorkspaceTable}
