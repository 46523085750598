/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC, useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'
import * as boardActions from '../../redux/BoardActions'

type Props = {}

export const SummaryActivity: FC<Props> = () => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const dispatch: any = useDispatch()
  var page = 1

  const {summaryActivities, summaryActivitiesData}: any = useSelector<RootState>(
    (state) => ({
      summaryActivities: state.board.summaryActivities,
      summaryActivitiesData: state.board.summaryActivitiesData,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (id) dispatch(boardActions.boardSummaryActivity(id, page))
  }, [id])

  const handleLoadMore = (event: any) => {
    dispatch(boardActions.boardSummaryActivity(id, (event += 1)))
  }

  return (
    <>
      <div className='col-lg-3  col-sm-12  margins-responsive'>
        <div className='card activity-card bg-light-grey'>
          <div className='card-body p-0'>
            <div className='p-4'>
              <h5 className='py-3'>Activity</h5>
              <div className='pb-8'>
                <p>Stay up to date with what's happening across the project.</p>

                {summaryActivitiesData &&
                  summaryActivitiesData?.length > 0 &&
                  summaryActivitiesData?.map((activity: any, index: number) => {
                    return (
                      <div className='mt-5' key={index}>
                        <span className='me-3'>
                          <img src={toAbsoluteUrl('/media/icons/projects/bx-right-arrow-circle.svg')} />
                        </span>
                        <span className='font-12px'>{moment(activity?.createdAt).fromNow()}</span>
                        <p className='my-3'>
                          <span className='font-bold-500'>{activity?.createdBy?.fullName}</span>{' '}
                          {activity?.description}{' '}
                          {activity?.description.includes('created') ? '' : 'on'}{' '}
                          <Link to={`/${workspaceid}/project/${id}/task/${activity?.item?._id}`} className='text-blue'>
                            {' '}
                            {activity?.item?.title}
                          </Link>
                        </p>
                      </div>
                    )
                  })}

                {summaryActivities?.end < summaryActivities?.totalCount && (
                  <span
                    className='btn load-more_btn'
                    onClick={(e) => handleLoadMore(summaryActivities?.page)}
                  >
                    Load More
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
