import React, { FC, useRef, useState } from 'react'
import { KTSVG } from '../../helpers'
import '../../../_metronic/assets/sass/components/myspace.scss'
type Props = {
  handleSearch: (search: string) => void
}
const Search: FC<Props> = ({ handleSearch }) => {
  const [menuState] = useState<'main' | 'advanced' | 'preferences'>('main')
  const element = useRef<HTMLDivElement | null>(null)
  const wrapperElement = useRef<HTMLDivElement | null>(null)

  const searchHandler = (event: any) => {
    handleSearch(event.target.value)
  }
  return (
    <>
      {/*<div
        id='kt_header_search'
        className='d-block d-sm-flex align-items-end'
        data-kt-search-keypress='true'
        data-kt-search-min-length='2'
        data-kt-search-enter='enter'
        data-kt-search-layout='menu'
        data-kt-menu-trigger='auto'
        data-kt-menu-overflow='false'
        data-kt-menu-permanent='true'
        data-kt-menu-placement='bottom-end'
        ref={element}
      >
        <div
          className='d-flex align-items-center d-none'
          data-kt-search-element='toggle'
          id='kt_header_search_toggle'
        >
          <div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'></div>
        </div>

        <div
          data-kt-search-element='content'
          className='d-flex flex-center'
          style={{ height: '54px'}}
        >
          <div
            className={`${menuState === 'main' ? '' : 'd-none'}`}
            ref={wrapperElement}
            data-kt-search-element='wrapper'
          >
            <div
              className='d-flex justify-content-end'
            // data-kt-search-element='form'
            // className='todo-bar-search-from position-relative px-3 boder-1 rounded'
            // autoComplete='off'
            // style={{width: '290px'}}
            >
              /~ <input
                type='text'
                className='form-control form-control-flush py-2 ps-10'
                name='search'
                placeholder='Search...'
                data-kt-search-element='input'
                onChange={searchHandler}
              /> ~/

              <div className='input-group col-sm-2 p-1 me-1 small-screen-width w-100'>
                <input
                  type='text'
                  className='w-50 form-control form_control_space  bd-right-none py-1'
                  placeholder='Search'
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                  onChange={searchHandler}
                />
                /~ <input type="text"  className="form-control form_control_space  bd-right-none py-1" placeholder="Search"  onChange={searchHandler} aria-label="Recipient's username" aria-describedby="basic-addon2" value=""/> ~/
                <span
                  className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
                  id='basic-addon2'
                >
                  {' '}
                  <button className='btn bg-white btn-focus-white p-0 m-0'>
                    {' '}
                    <a>
                      {' '}
                      <span className='svg-icon '>
                        <svg
                          id='svgexport-7'
                          xmlns='http://www.w3.org/2000/svg'
                          width='19.803'
                          height='19.303'
                          viewBox='0 0 19.803 19.303'
                          className='mh-50px'
                          color=''
                        >
                          <rect
                            id='Rectangle_974'
                            data-name='Rectangle 974'
                            width='8.155'
                            height='2'
                            rx='1'
                            transform='translate(14.037 12.122) rotate(45)'
                            fill='#a1a5b7'
                            opacity='0.5'
                          ></rect>
                          <path
                            id='Path_4816'
                            data-name='Path 4816'
                            d='M11,19a8,8,0,1,1,8-8A7.967,7.967,0,0,1,11,19ZM11,5a5.815,5.815,0,0,0-6,6,5.815,5.815,0,0,0,6,6,5.815,5.815,0,0,0,6-6A5.815,5.815,0,0,0,11,5Z'
                            transform='translate(-3 -3)'
                            fill='#a1a5b7'
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </button>
                </span>{' '}
              </div>

              /~ <div className='d-flex align-items-center justify-content-end spaces_header'>
                <input
                  type='text'
                  className='w-50 form-control form_control_space  bd-right-none py-1'
                  placeholder='Search'
                  aria-label="Recipient's username"
                  aria-describedby='basic-addon2'
                  onChange={searchHandler}
                />
                <span
                  className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
                  id='basic-addon2'
                >
                  <button className='btn bg-white btn-focus-white p-0 m-0'>
                    {' '}
                    <a>
                      {' '}
                      <span className='svg-icon '>
                        <svg
                          id='svgexport-7'
                          xmlns='http://www.w3.org/2000/svg'
                          width='19.803'
                          height='19.303'
                          viewBox='0 0 19.803 19.303'
                          className='mh-50px'
                          color=''
                        >
                          <rect
                            id='Rectangle_974'
                            data-name='Rectangle 974'
                            width='8.155'
                            height='2'
                            rx='1'
                            transform='translate(14.037 12.122) rotate(45)'
                            fill='#a1a5b7'
                            opacity='0.5'
                          ></rect>
                          <path
                            id='Path_4816'
                            data-name='Path 4816'
                            d='M11,19a8,8,0,1,1,8-8A7.967,7.967,0,0,1,11,19ZM11,5a5.815,5.815,0,0,0-6,6,5.815,5.815,0,0,0,6,6,5.815,5.815,0,0,0,6-6A5.815,5.815,0,0,0,11,5Z'
                            transform='translate(-3 -3)'
                            fill='#a1a5b7'
                          ></path>
                        </svg>
                      </span>
                    </a>
                  </button>
                </span>{' '}
              </div> ~/
              /~ 
              <span
                className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none'
                data-kt-search-element='clear'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon-2 svg-icon-lg-1 me-0'
                />
              </span>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'
              /> ~/
            </div>
          </div>
        </div>
      </div>*/}

      <div className={`input-group w-100 w-sm-50 me-2 ${menuState === 'main' ? '' : 'd-none'}`}>
        <input
          type='text'
          className='form-control form_control_space  bd-right-none py-1'
          placeholder='Search'
          aria-label="Recipient's username"
          aria-describedby='basic-addon2'
          title='Search'
          onChange={searchHandler}
        />
        <span
          className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
          id='basic-addon2'
        >
          <span className='p-0 m-0'>
            <span>
              <KTSVG path='/media/icons/projects/svgexport-7.svg' />
            </span>
          </span>
        </span>
      </div>
    </>
  )
}

export { Search }
