import * as serverRequest from './organizationCRUD'
import {organizationSlice, callTypes} from './organizationSlice'

const {actions} = organizationSlice

export const fetchOrganizations =
  ({
    search,
    page,
    sortby,
    orderby,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest
      .getOrganization({
        search,
        page,
        sortby,
        orderby,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data

        dispatch(
          actions.organizationsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
            chartsLoading: true,
          })
        )
      })
  }

export const fetchOrganizationCharts =
  ({
    id,
    search,
    page,
    sortby,
    orderby,
  }: {
    id: string
    search: string
    page: number | string
    sortby: string
    orderby: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startChartCall({callType: callTypes.list}))
    }
    return serverRequest
      .getOrganizationCharts({
        id,
        search,
        page,
        sortby,
        orderby,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data
        dispatch(
          actions.organizationChartsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
            chartsLoading: false,
          })
        )
      })
  }
export const fetchUserCharts =
  ({
    search,
    page,
    sortby,
    orderby,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startChartCall({callType: callTypes.list}))
    }
    return serverRequest
      .getUserCharts({
        search,
        page,
        sortby,
        orderby,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data
        dispatch(
          actions.organizationChartsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
            chartsLoading: false,
          })
        )
      })
  }

export const fetchChartRoles =
  ({id}: {id: string}) =>
  (dispatch: any) => {
    dispatch(actions.startChartCall({callType: callTypes.list}))
    return serverRequest
      .fetchChartRoles({
        id,
      })
      .then((response) => {
        dispatch(
          actions.chartFetched({
            entities: response?.data,
          })
        )
      })
  }

export const saveChartRoles =
  ({id}: {id: string}, {roles}: {roles: any}) =>
  (dispatch: any) => {
    dispatch(actions.startCall({callType: callTypes.list}))
    return serverRequest.saveChartRoles({id}, {roles}).then((response) => {
      dispatch(
        actions.chartSaved({
          data: {chartID: id, message: 'Roles Successfully Saved'},
        })
      )
    })
  }

export const deleteChartRoles =
  ({id}: {id: string}, {roles}: {roles: any}) =>
  (dispatch: any) => {
    dispatch(actions.startCall({callType: callTypes.list}))
    return serverRequest.deleteChartRoles({id}, {roles}).then((response) => {
      dispatch(
        actions.chartSaved({
          data: {chartID: id, message: 'Roles Deleted Successfully'},
        })
      )
    })
  }

export const createOrganization =
  ({
    name,
    descriptions,
    defaultValue,
  }: {
    name: string
    descriptions: string
    defaultValue: boolean
  }) =>
  (dispatch: any) => {
    return serverRequest.createOrganization({name, descriptions, defaultValue})
  }
export const createOrganizationChart =
  ({name, company}: {name: string; company: string}) =>
  (dispatch: any) => {
    return serverRequest.createOrganizationChart({name, company})
  }
export const updateOrganizationChart =
  ({id, name, company}: {id: string; name: string; company: string}) =>
  (dispatch: any) => {
    return serverRequest.updateOrganizationChart({id, name, company})
  }
export const updateOrganization =
  ({
    id,
    name,
    descriptions,
    defaultValue,
  }: {
    id: string
    name: string
    descriptions: string
    defaultValue: boolean
  }) =>
  (dispatch: any) => {
    return serverRequest.updateOrganization({
      id,
      name,
      descriptions,
      defaultValue,
    })
  }

export const deleteOrganization =
  ({id}: {id: string | undefined | null}) =>
  (dispatch: any) => {
    return serverRequest.deleteOrganization({id}).then((response) => {
      return serverRequest
        .getOrganization({
          search: '',
          page: '',
          sortby: 'createdAt',
          orderby: 'desc',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response?.data

          dispatch(
            actions.organizationsFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
              chartsLoading: true,
            })
          )
        })
    })
  }

export const deleteOrganizationChart = (id: string, companyId: string) => (dispatch: any) => {
  return serverRequest.deleteOrganizationChart({id}).then((response) => {
    return serverRequest
      .getOrganizationCharts({
        id: companyId,
        search: '',
        page: '',
        sortby: 'createdAt',
        orderby: 'desc',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data
        dispatch(
          actions.organizationChartsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
            chartsLoading: false,
          })
        )
      })
  })
}
export const deleteUserChart = (id: string) => (dispatch: any) => {
  return serverRequest.deleteOrganizationChart({id}).then((response) => {
    return serverRequest
      .getUserCharts({
        search: '',
        page: '',
        sortby: 'createdAt',
        orderby: 'desc',
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data
        dispatch(
          actions.organizationChartsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
            chartsLoading: false,
          })
        )
      })
  })
}

export const fetchOrganization =
  ({id}: {id: string}) =>
  (dispatch: any) => {
    return serverRequest.Organization({id}).then((response) => {
      const {data} = response

      dispatch(
        actions.organizationFetched({
          entities: data,
        })
      )
    })
  }

export const fetchOrganizationChart =
  ({chartId}: {chartId: string}) =>
  (dispatch: any) => {
    return serverRequest.fetchOrganizationChart({chartId}).then((response) => {
      dispatch(
        actions.chartFetched({
          entities: response?.data,
        })
      )
    })
  }

export const fetchUsers = () => (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return serverRequest.getUsers().then((response) => {
    const {data} = response
    dispatch(
      actions.usersFetched({
        data: data,
      })
    )
  })
}

export const fetchRoles = () => (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return serverRequest.getRoles().then((response) => {
    const {data} = response
    dispatch(
      actions.usersRolesFetched({
        data: data,
      })
    )
  })
}

export const getAllCompanies = () => () => {
  return serverRequest.getAllCompanies().then((response) => {
    return response.data
  })
}
