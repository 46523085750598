import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id: string
  title: string
  description: string
  createdBy: String
}

// Define a type for the slice state
interface ReleaseNoteState {
  listLoading: boolean
  totalCount?: number
  ReleaseNoteDetail?: entities
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  error?: any
}

// Define the initial state using that type
const initialState: ReleaseNoteState = {
  listLoading: true,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
}

export const ReleaseNoteSlice = createSlice({
  name: 'ReleaseNote',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<ReleaseNoteState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state) => {
      state.listLoading = true
    },
    ReleaseNoteFetched: (state, action: PayloadAction<ReleaseNoteState>) => {
      state.listLoading = false
      const {totalCount, entities, start, end} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    ReleaseNoteCreated: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.entities.push(action.payload.entities)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    ReleaseNoteUpdate: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    ReleaseNoteFetchedById: (state, action) => {
      const {data, listLoading} = action.payload
      state.ReleaseNoteDetail = data
      state.listLoading = listLoading
    },
  },
})
