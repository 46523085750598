/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import {AssigneesList} from '../../Common/assignee-teams/Assignee&TeamList'
import {ChannelDelete} from './ChannelDelete'
type Props = {
  discussionActions?: any
  workspaceid?: any
  setShowGroupPopup?: any
  showGroupPopup?: any
  chatType?: any
}

export const GroupActions: FC<Props> = ({
  discussionActions,
  workspaceid,
  setShowGroupPopup,
  showGroupPopup,
  chatType,
}) => {
  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)
  const [assigneesId, setAssigneesId] = useState([])
  const [teamsId, setTeamsId] = useState([])
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [allUsers, setAllUsers] = useState()
  const dispatch: any = useDispatch()
  const {chat, chatId, loginUser, showMemberPopup}: any = useSelector<RootState>(
    (state) => ({
      chat: state.discussions.chat,
      chatId: state.discussions.chatId,
      loginUser: state.auth.user,
      showMemberPopup: state.discussions.showMemberPopup,
    }),
    shallowEqual
  )

  const getPrevUsers = async () => {
    const alluser = chat.filter((user: any) => user._id != loginUser._id)
    setAllUsers(alluser)
    dispatch(discussionActions.groupCreated(false))
    const res = await dispatch(discussionActions.getChannelUsers(chatId))

    let groupUserIds: any = []
    let alreadySelectedUsers: any = []
    res.data.users.filter((user: any) => {
      groupUserIds.push(user._id)
      if (user._id !== loginUser._id) alreadySelectedUsers.push(user._id)
    })
    setAssigneesId(alreadySelectedUsers)
  }
  const setEditChat = () => {
    dispatch(discussionActions.creatingChannel(true))
    setShowGroupPopup(showGroupPopup)
    dispatch(discussionActions.groupCreated(false))
    dispatch(discussionActions.editChat(true))
  }
  useEffect(() => {
    if (showMemberPopup) {
      getPrevUsers()
      setShowAssigneesDialog(true)
    }
    return () => dispatch(discussionActions.editChat(false))
  }, [showMemberPopup])
  return (
    <>
      <AssigneesList
        show={showAssigneesDialog}
        handleClose={() => {
          dispatch(discussionActions.showMemberPopup(false))
          setShowAssigneesDialog(false)
        }}
        assignee={allUsers}
        teams={[]}
        calledBy={'discussion'}
        setAssigneesId={setAssigneesId}
        assigneesId={assigneesId}
        teamsId={teamsId}
        setTeamsId={[]}
        id={chatId}
        title={'Add Members'}
        discussionActions={discussionActions}
        chatType={chatType}
      />
      <ChannelDelete
        discussionActions={discussionActions}
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
      />
      <div
        className='menu menu-sub menu-sub-dropdown w-200px w-md-200px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold fs-base w-175px styles'
        data-kt-menu='true'
      >
        <div className='separator border-gray-200'></div>
        <div className='px-3 py-5'>
          <div className='menu-item px-3 my-0'>
            <div className='menu-link px-3 py-2'>
              <span
                title='Edit Channel'
                className='menu-title'
                onClick={(e) => {
                  setEditChat()
                }}
              >
                Edit Channel
              </span>
            </div>
          </div>
          <div className='menu-item px-3 my-0'>
            <div className='menu-link px-3 py-2'>
              <span
                onClick={(e) => {
                  setShowDeleteDialog(true)
                }}
                title='Delete Channel'
                className='menu-title'
              >
                Delete Channel
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
