/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from 'react'
import { KTSVG } from '../../../_metronic/helpers'
import { HeaderBreadcrumbsTest } from './HeaderBreadcrumbsTest'

const HeaderBreadcrumbsTest2: FC = () => {
    const [searchValue, setSearchValue] = useState('')
    const [workspaces, setWorkspaces] = useState([{}])
    const [filterWorkspaces, setFilterWorkspaces] = useState([{}])
    const [dashboardIcons, setDashboardIcons]: any = useState()
    const [filterDashboardIcons, setFilterDashboardIcons]: any = useState()
    const searchHandler = (event: any) => {
        // handle search card
        let workspacesFilter = workspaces.filter((search: any) =>
            search?.name?.match(new RegExp(event.target.value, 'i'))
        )
        setFilterWorkspaces(workspacesFilter)

        // handle search tile
        let dashboardIconsFilter = dashboardIcons.filter((search: any) =>
            search?.iconId?.name?.match(new RegExp(event.target.value, 'i'))
        )
        setFilterDashboardIcons(dashboardIconsFilter)
    }
    return (
        <>
            <div className='input-group w-50 me-2'>
                <input
                    type='text'
                    className='form-control form_control_space  bd-right-none py-1'
                    placeholder='Search'
                    aria-label="Recipient's username"
                    aria-describedby='basic-addon2'
                    title='Search Spaces'
                    value={searchValue}
                    onChange={(e: any) => {
                        setSearchValue(e.target.value)
                        searchHandler(e)
                    }}
                />
                <span
                    className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
                    id='basic-addon2'
                >
                    <span className='p-0 m-0'>
                        <span>
                            <KTSVG path='/media/icons/projects/svgexport-7.svg' />
                        </span>
                    </span>
                </span>
            </div>
        </>
    )
}

export { HeaderBreadcrumbsTest2 }