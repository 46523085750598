import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { RootState } from '../../../../setup'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import * as actions from '../redux/OpportunityWatchAction'
import { Attachment } from '../../../Common/attachments/Attachment'
import { Loading } from '../../../../_metronic/layout/Loading'
import { Can } from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/opportunity-watch-add.scss'
import { Activity } from '../../generalActivityLogs/components/Activity'

const OpportunityWatchDetail: React.FC = () => {
  const dispatch = useDispatch()
  const { id }: { id: any; workspaceid: any } = useParams()

  const { opportunityWatchDetail, attachments, listLoading }: any = useSelector<RootState>(
    (state) => ({
      opportunityWatchDetail: state.opportunityWatch.opportunityWatchDetail,
      attachments: state.opportunityWatch.attachments,
      listLoading: state.opportunityWatch.listLoading,
    }),
    shallowEqual
  ) as any

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchOpportunityWatchById(id))
    }
  }, [])

  let crumbs
  if (opportunityWatchDetail) {
    crumbs = [
      { name: 'Dashboard', url: '/dashboard' },
      { name: `Opportunity Watch`, url: `/opportunity-watch` },
      // {name: `${opportunityWatchDetail.lead}`, url: `/opportunity-watch/${id}`},

      { name: `${opportunityWatchDetail.lead}`, url: `/opportunity-watch/${id}` },
    ]
  }

  return (
    <>
      <div className='mh-80 bg-white'>
        <div className='px-6 px-sm-12'>
          <div className="row">
            <div className="col-sm-6">
              <h2 className='fw-500 fs-2 m-0 py-5'>
                Opportunity Watch Detail
              </h2>
            </div>
          </div>
        </div>
        {!listLoading && crumbs && <Breadcrumbs crumbs={crumbs} containerClass='mx-10' />}
        {listLoading && <Loading />}
        {!listLoading && (
          <>
          <div className="overflow_height_scroll">
            <div className='card-body pt-15 pb-2 px-0 mx-2 mx-sm-10'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='flex-grow-1 border_grey_info'>
                  <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                    {opportunityWatchDetail && (
                      <div className='d-flex flex-column w-100'>
                        <div className='d-flex justify-content-between'>
                          {opportunityWatchDetail.lead && (
                            <div
                              className='d-flex align-items-center mb-2 text-gray-800  fs-5 fw-500 me-1'
                              title={opportunityWatchDetail.lead}
                            >
                              {opportunityWatchDetail.lead}
                            </div>
                          )}
                          <div className='d-flex'>
                            <Can I='update' this='opportunity-watch'>
                              <Link to={`/opportunity-watch/${id}/edit`}>
                                <button
                                  title='Edit'
                                  className='d-flex align-items-center btn btn-sm btn-light-blue text-blue ms-3'
                                >
                                  {/* <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3 text-blue'
                                />{' '} */}
                                  <img
                                    src={toAbsoluteUrl('/media/icons/projects/svgexport-10.svg')}
                                    className='me-2 w-25'
                                  />
                                  Edit
                                </button>
                              </Link>
                            </Can>
                          </div>
                        </div>
                        <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 align-items-center  me-5 '>
                          <span className='fs-7 text-muted me-2 mb-2 mb-sm-0'>Created By</span>
                          <span
                            className='d-flex align-items-center fs-7 me-5 mb-2 mb-sm-0'
                            title={
                              opportunityWatchDetail.createdBy &&
                              opportunityWatchDetail.createdBy?.firstName +
                              ' ' +
                              opportunityWatchDetail.createdBy?.lastName
                            }
                          >
                            {/* <KTSVG
                            path='/media/icons/duotune/communication/com006.svg'
                            className='svg-icon-4 me-1'
                          /> */}
                            {opportunityWatchDetail.createdBy &&
                              opportunityWatchDetail.createdBy?.firstName +
                              ' ' +
                              opportunityWatchDetail.createdBy?.lastName}
                          </span>
                          {opportunityWatchDetail.createdAt && (
                            <span
                              className='d-flex align-items-center me-5 mb-2 mb-sm-0'
                              title={moment(opportunityWatchDetail.createdAt).format('MM/DD/YYYY')}
                            >
                              <span className='fw-500 text-muted fs-7'>Created Date</span>
                              <span className='ms-2 fs-7'>
                                {opportunityWatchDetail.createdAt
                                  ? moment(opportunityWatchDetail.createdAt).format('MM/DD/YYYY')
                                  : ''}
                              </span>
                            </span>
                          )}
                          {opportunityWatchDetail.activeDate && (
                            <span
                              className='d-flex align-items-center text-gray-400 me-5 fs-7 mb-2 mb-sm-0'
                              title={moment(opportunityWatchDetail.activeDate).format('MM/DD/YYYY')}
                            >
                              <span className='text-primary w-100'>
                                {/* <i className='far fa-calendar text-primary'></i> */}
                                <span className='fw-500 fs-7 text-muted'>Active Date</span>
                                <span className='ms-2 text-blue'>
                                  {moment(opportunityWatchDetail.activeDate).format('MM/DD/YYYY')}
                                </span>
                              </span>
                            </span>
                          )}
                        </div>
                      </div>
                    )}

                    {/* <div className='d-flex my-4'>
                    <Can I='update' this='opportunity-watch'>
                      <Link to={`/opportunity-watch/${id}/edit`}>
                        <button
                          title='Edit'
                          className='btn btn-sm btn-primary ms-3'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_offer_a_deal'
                        >
                          Edit
                        </button>
                      </Link>
                    </Can>
                  </div> */}
                  </div>
                  {opportunityWatchDetail && (
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 align-items-center  me-5 '>
                        <span className='d-flex align-items-center text-gray-400 mb-2'>
                          {opportunityWatchDetail.status === '1' ? (
                            <span className='badge badge-light-info fw-500 me-5' style={{ padding: '12px 22px' }} title='Declined'>
                              Declined
                            </span>
                          ) : opportunityWatchDetail.status === '2' ? (
                            <span className='badge badge-light-primary me-5' style={{ padding: '12px 22px' }} title='Active'>
                              Active
                            </span>
                          ) : opportunityWatchDetail.status === '3' ? (
                            <span
                              className='badge badge-light-primary fw-500 text-shade-blue me-5'
                              style={{ padding: '12px 22px' }}
                              title='Fulfilled'
                            >
                              Fulfilled
                            </span>
                          ) : opportunityWatchDetail.status === '4' ? (
                            <span
                              className='badge badge-light-danger fw-500 me-5'
                              style={{ padding: '12px 22px' }}
                              title='Fell Off'
                            >
                              Fell Off
                            </span>
                          ) : (
                            ''
                          )}
                        </span>
                        {opportunityWatchDetail.technology && (
                          <span
                            className='d-flex align-items-center me-5 mb-2'
                            title={opportunityWatchDetail.technology}
                          >
                            {/* <span className='fw-bolder'>Technology:</span> */}
                            <span className='badge badge-light'>
                              {opportunityWatchDetail.technology
                                ? opportunityWatchDetail.technology
                                : ''}
                            </span>
                          </span>
                        )}
                      </div>
                      {opportunityWatchDetail.plan && (
                        <div>
                          <p className='text-muted fs-7  mt-3'>Description</p>
                          <p className='text-muted fs-7 mb-1'>{opportunityWatchDetail.plan}</p>
                        </div>
                      )}
                      <div className='mt-8'>
                        {attachments?.length > 0 && (
                          <>
                            <span className='fs-7 text-muted me-2'>Attachments</span>{' '}
                            <Attachment
                              attachments={attachments}
                              loadAttachments={actions.loadAttachments}
                              attachmentType='listing'
                              id={id}
                              itemType='opportunityWatch'
                            />
                          </>
                        )}
                      </div>
                      {/* {opportunityWatchDetail.plan && (
                      <div
                        className='d-flex align-items-center mb-2 text-gray-800 me-1'
                        title={opportunityWatchDetail.plan}
                        style={{whiteSpace: 'pre-line'}}
                      >
                        {`${opportunityWatchDetail.plan}`}
                      </div>
                    )} */}
                    </div>
                  )}
                </div>
              </div>

              {/* <div className='d-flex overflow-auto'>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                <li className='nav-item'> */}
              {/* <span title='Activity' className='me-6 active fw-500'>
              Activity Log
            </span> */}
              {/* </span>
                </li>
              </ul>
            </div> */}
            </div>
            <Activity itemType={'OpportunityWatch'} activityItemId='' />
          </div>
          </>
        )}
      </div>
    </>
  )
}

export { OpportunityWatchDetail }
