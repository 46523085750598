import React, { useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RootState } from '../../../setup'
import { KTSVG } from '../../../_metronic/helpers'
import { modifyDashboardIconsURL } from '../../../_metronic/helpers/modifyDashboardIconsURL'

type Props = {
  rowDimension: any
  dashboardIcon: any
}



const AppsListing: React.FC<Props> = ({rowDimension, dashboardIcon}) => {

  const [dashIconType, setdashIconType] = useState("")

  const { settings}: any = useSelector<RootState>(
    (state) => ({
      settings: state.settings.entities,
    }),
    shallowEqual
  )


  useEffect(()=>{
    const iconType = settings.filter((data : any,index: any)=>{
      return (data.name === "dashMediaIcons")
    })
    setdashIconType(iconType[0]?.value);
  },[settings])


  return (
    <div className='demo-icons'>
      <div className='d-flex col rounded-2'>
        <div className=' card-item mx-4 my-4 rounded-2'>
          <Link to={`/${dashboardIcon?.slug}`}>
            <div
              className='icon'
              style={{
                backgroundColor: dashboardIcon?.backgroundColor,
              }}
              // style={{
              //   // width: rowDimension + 'px',
              //   // height: rowDimension + 'px',
              //   backgroundColor: dashboardIcon?.backgroundColor,
              // }}
            >
              <KTSVG 
                path={modifyDashboardIconsURL(dashIconType, `/${dashboardIcon?.icon}` )} 
                noClass={true} 
                color={dashboardIcon?.color}
                />
            </div>
            <div className='title'>
              <span>
                <h6 className='masonry-title'> {dashboardIcon?.name}</h6>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export { AppsListing }

