/* eslint-disable jsx-a11y/anchor-is-valid */
import { Formik, Form } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../setup'
import { Attachment } from '../../../Common/attachments/Attachment'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import * as actions from '../redux/DocumentsAction'
import { DocumentsModel } from './DocumentsTable'
import '../../../../_metronic/assets/sass/components/document-upload.scss'

interface ICreateAccount { }

const inits: ICreateAccount = {}

interface Props {
  onHide: () => void
}

const DocumentAddDialog: FC<Props> = ({ onHide }) => {
  const { workspaceid }: { workspaceid: string } = useParams()
  const { folderid }: { folderid: string } = useParams()

  const dispatch = useDispatch<any>()

  const { attachments }: any = useSelector<RootState>((state) => ({
    attachments: state.documents.attachments,
  })) as any

  const { spaceDetail, documentsData, documentDetail }: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.workspace.workspaceDetail,
      documentsData: state.documents.entities,
      documentDetail: state.documents.documentDetail,
    }),
    shallowEqual
  ) as DocumentsModel

  const [initValues] = useState<ICreateAccount>(inits)

  useEffect(() => {
    dispatch(actions.removeAttachments([]))
  }, [])

  useEffect(() => {
    if (folderid) {
      dispatch(actions.fetchDocument(folderid))
    }
  }, [folderid])

  let crumbs
  crumbs =
    workspaceid && folderid === 'add'
      ? [
        { name: 'Spaces', url: '/dashboard' },
        { name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features` },
        { name: 'Documents', url: `/${workspaceid}/documents` },
        { name: 'Documents Add', url: `/${workspaceid}/documents/add` },
      ]
      : !workspaceid && folderid && folderid !== 'add'
        ? [
          { name: 'Dashboard', url: '/dashboard' },
          { name: 'Documents', url: `/${folderid}/documents` },
          { name: `${documentDetail?.name}`, url: `/${folderid}/documents` },
          { name: 'Documents Add', url: `/${folderid}/documents/add` },
        ]
        : workspaceid && folderid && folderid !== 'add'
          ? [
            { name: 'Spaces', url: '/dashboard' },
            { name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features` },
            { name: 'Documents', url: `/${workspaceid}/documents` },
            { name: `${documentDetail?.name}`, url: `/${folderid}/documents` },
            { name: 'Documents Add', url: `/${workspaceid}/documents${folderid}/documents/add` },
          ]
          : [
            { name: 'Dashboard', url: '/dashboard' },
            { name: 'Documents', url: `/documents` },
            { name: 'Documents Add', url: `/documents/add` },
          ]

  return (
    <div className='mh-80 page-content'>
      {/* <div className='page-header ps-12 pt-5 pb-3'>
        <h2 className='fw-500 fs-2'>Upload Document</h2>
      </div> */}
      <div className='px-6 px-sm-12'>
        <div className="row">
          <div className="col-sm-6">
            <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
              Upload Document
            </h2>
          </div>
        </div>
      </div>

      {crumbs && <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 ' />}

      <Formik
        enableReinitialize
        initialValues={initValues}
        onSubmit={(values: any) => {
          if (attachments.length > 0) {
            let mainDocument: any = []

            attachments.map((attachment: any, index: number) => {
              mainDocument[index] = {
                name: attachment.originalName,
                path: attachment.path,
                type: 'file',
              }
            })

            values.document = mainDocument
            dispatch(
              actions.createDocuments(
                values,
                workspaceid ? workspaceid : null,
                folderid && folderid !== 'add' ? folderid : null
              )
            )
            onHide()
          }
        }}
      >
        <div className="overflow_height_scroll">
        <Form className='document_upload_form'>
          <div className='page-body py-lg-10 px-lg-10'>
            <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
              <div className='flex-row-fluid px-lg-15'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100'>
                    <div className='fv-row mb-10'>
                      <Attachment
                        attachments={attachments}
                        loadAttachments={actions.loadAttachments}
                        attachmentType='attachmentUpload'
                        id={'id'}
                        itemType='document'
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-stack pt-10'>
                  <div> </div>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-sm cancel_button fw-bolder me-3'
                      onClick={onHide}
                      title='Cancel'
                    >
                      Cancel
                    </button>
                    <button type='submit' className='btn btn-lg btn-blue  fs-7' title={'Submit'}>
                      <span className='indicator-label'>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
        </div>
      </Formik>
    </div>
  )
}

export { DocumentAddDialog }
