import React from 'react'
import {Route, Switch, useParams} from 'react-router-dom'
import {CreateProject} from './components/CreateProject'
import {Project} from './components/Project'

const ProjectPage: React.FC = () => {
  const {workspaceid}: {workspaceid: string} = useParams()

  return (
    <>
      <Switch>
        <Route exact path={['/project', '/:workspaceid/project']}>
          {({history, match}: {history: any; match: any}) => {
            return <Project />
          }}
        </Route>
        <Route
          exact
          path={[
            '/project/add',
            '/:workspaceid/project/add',
            '/project/:id/edit',
            '/:workspaceid/project/:id/edit',
          ]}
        >
          {({history, match}: {history: any; match: any}) => {
            return (
              <CreateProject
                onHide={() => {
                  history.push(workspaceid ? `/${workspaceid}/project` : '/project')
                }}
              />
            )
          }}
        </Route>
      </Switch>
    </>
  )
}

export {ProjectPage}
