import React, { useState, useEffect } from 'react'
import { KTSVG } from '../../../../_metronic/helpers'
import { OrganizationDelete } from './organizationDelete'
import * as actions from '../redux/organizationActions'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RootState } from '../../../../setup'
import moment from 'moment'
import { Pagination } from '../../../pagination/pagination'
import { Link } from 'react-router-dom'
import { Search } from '../../../../_metronic/layout/search/Search'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import { NotFound } from '../../../../_metronic/layout/NotFound'
import { Loading } from '../../../../_metronic/layout/Loading'

type Props = {
  className: string
}

const OrganizationTable: React.FC<Props> = ({ className }) => {
  const dispatch = useDispatch()

  const [showDialogBox, setShowDialogBox] = useState(false)
  const [organizationsList, setOrganizationList] = useState([])
  const [organizationId, setOrganizationId] = useState('')
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: 'createdAt',
  })

  const { listLoading, organizations, paginationData }: any = useSelector<RootState>(
    (state) => ({
      listLoading: state.organization.listLoading,
      organizations: state.organization.entities,
      paginationData: state.organization,
    }),
    shallowEqual
  ) as any

  useEffect(() => {
    if (organizations?.length > 0) {
      setOrganizationList(organizations)
    } else {
      setOrganizationList([])
    }
  }, [organizations])

  const deleteOrganization = (id: string) => async (event: any) => {
    setOrganizationId(id)
    setShowDialogBox(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`organization-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && organizationsList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && organizationsList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }

    dispatch(
      actions.fetchOrganizations({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
      })
    )
  }, [sortValues])

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchOrganizations({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
      })
    )
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchOrganizations({
        search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
      })
    )
  }

  let crumbs

  crumbs = [
    { name: 'Dashboard', url: '/dashboard' },
    { name: 'Organizations', url: `` },
  ]

  return (
    <>
      <OrganizationDelete
        show={showDialogBox}
        handleClose={() => {
          setShowDialogBox(false)
        }}
        id={organizationId}
      />
      <div className={`mh-80 tabs-main card legend-card-main`}>
        {!listLoading && (
          <div className='px-6 px-sm-12'>
            <div className="row">
              <div className="col-sm-6">
                <h2 className='fw-500 fs-2 m-0 py-5'>
                  Organizations
                </h2>
              </div>
              <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
                <Search handleSearch={handleSearch} />
              </div>
            </div>
          </div>
        )}
        <div className='d-flex justify-content-end align-items-center bg-lighter flex-sm-nowrap flex-wrap'>
          {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
          <Link className='bg-lighter pe-10' to={`/organization/create`}>
            <span
              className='btn btn-sm  bg-transparent text-blue pe-5 d-flex align-items-center'
              style={{ whiteSpace: 'nowrap' }}
            >
              <KTSVG path='' className='svg-icon-3' />
              <i className='fa fa-plus-circle link_button' aria-hidden='true'></i>
              <span className='link_button'> Create Organization</span>
            </span>
          </Link>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <>
          <div className="overflow_height_scroll">
            <div className='card-body d-flex flex-column py-3 px-10'>
              {/* begin::Table container */}

              {!listLoading && organizationsList?.length > 0 ? (
                <div className='table-responsive'>
                  {/* begin::Table */}

                  <table className='organization-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                    {/* begin::Table head */}
                    <thead>
                      <tr className='fw-bolder text-muted'>
                        <th
                          className=''
                          style={{ cursor: 'pointer', minWidth: '230px' }}
                          onClick={sortColumn}
                          abbr='name'
                          id='organization-name-head'
                        >
                          Name
                        </th>
                        <th
                          className='min-w-120px'
                          style={{ cursor: 'pointer', minWidth: '230px' }}
                          onClick={sortColumn}
                          abbr='descriptions'
                          id='organization-descriptions-head'
                        >
                          Description
                        </th>
                        <th
                          className='min-w-120px'
                          style={{ cursor: 'pointer', minWidth: '125px' }}
                          onClick={sortColumn}
                          abbr='default'
                          id='organization-default-head'
                        >
                          Default
                        </th>
                        <th
                          className='min-w-120px table-sort-desc'
                          style={{ cursor: 'pointer', minWidth: '125px' }}
                          onClick={sortColumn}
                          abbr='createdAt'
                          id='organization-createdAt-head'
                        >
                          Created Date
                        </th>
                        <th
                          className='min-w-120px'
                          style={{ cursor: 'pointer', minWidth: '125px' }}
                          onClick={sortColumn}
                          abbr='updatedAt'
                          id='organization-updatedAt-head'
                        >
                          Updated Date
                        </th>

                        <th className='min-w-100px'>Actions</th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {organizationsList.map((organization: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td data-label='name' title={organization?.name}>
                              <Link to={`/organization/${organization?._id}/groups`}>
                                <span
                                  title={organization?.name}
                                  style={{
                                    display: 'block',
                                    width: '230px',
                                    minWidth: '230px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {organization?.name}
                                </span>
                              </Link>
                            </td>

                            <td data-label='descriptions' title={organization.descriptions}>
                              <div
                                style={{
                                  width: '200px',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  
                                }}
                              >
                                {organization?.descriptions}
                              </div>
                            </td>
                            <td data-label='default' title={organization.default ? 'Yes' : 'No'}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {organization.default ? 'Yes' : 'No'}
                                </div>
                              </div>
                            </td>
                            <td
                              data-label='Created Date'
                              title={
                                organization?.createdAt
                                  ? moment(organization.createdAt).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {organization?.createdAt ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(organization.createdAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center '>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td
                              data-label='Update Date'
                              title={
                                organization.createdAt
                                  ? moment(organization.createdAt).format('MM/DD/YYYY')
                                  : '-'
                              }
                            >
                              {organization?.updatedAt ? (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {moment(organization.updatedAt).format('MM/DD/YYYY')}
                                  </div>
                                </div>
                              ) : (
                                <div className='d-flex align-items-center'>
                                  <div className='d-flex justify-content-start flex-column'>
                                    {'-'}
                                  </div>
                                </div>
                              )}
                            </td>

                            <td data-label='Actions' className='action_btns'>
                              <div className='flex-shrink-0'>
                                <Link
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                  to={`/organization/${organization._id}/edit`}
                                  title='Edit Organization'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/art/art005.svg'
                                    className='svg-icon-3'
                                  />
                                </Link>
                                <button
                                  className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                  data-bs-toggle='modal'
                                  data-bs-target='#kt_modal_delete_app'
                                  onClick={deleteOrganization(organization._id)}
                                  title='Delete Organization'
                                >
                                  <KTSVG
                                    path='/media/icons/duotune/general/gen027.svg'
                                    className='svg-icon-3'
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : !listLoading && organizationsList?.length <= 0 ? (
                <NotFound />
              ) : (
                <Loading />
              )}

              {/* end::Table container */}
            </div>
          </div>

          <Pagination paginationData={paginationData} fetchData={handlePagination} />
        </>
      </div>
    </>
  )
}

export { OrganizationTable }
