/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef, createRef, useState, useMemo} from 'react'
import {RootState} from '../../../setup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import moment from 'moment'
import {getImageURL} from '../../helpers'
import {MessageDropdown} from './MessageDropdown'
import {socket} from '../layout/header-menus/HeaderUserMenu'
import '../../../_metronic/assets/sass/components/create-channel.scss'

type Props = {
  discussionActions?: any
  showGroupPopup?: boolean
  setUserName?: any
  workspaceid?: any
  chatType?: any
}
const ChatInner: FC<Props> = ({
  discussionActions,
  showGroupPopup,
  setUserName,
  workspaceid,
  chatType,
}) => {
  const [message, setMessage] = useState<string>('')
  const [dropdownIndex, setDropdownIndex] = useState<string>('')
  const [allMessages, setAllMessages] = useState<any>([])
  const [messageDelete, setMessageDelete] = useState<any>(false)
  let messagesEnd: any = useRef<HTMLDivElement>(null)

  const dispatch: any = useDispatch()
  const {accessToken, editChat, chatId, messages, loginUser, groupCreated}: any =
    useSelector<RootState>(
      (state) => ({
        state,
        chatId: state.discussions.chatId,
        messages: state.discussions.messages,
        loginUser: state.auth.user,
        editChat: state.discussions.editChat,
        groupCreated: state.discussions.groupCreated,
        accessToken: state.auth.accessToken,
      }),

      shallowEqual
    )

  const refsById = useMemo(() => {
    const refs: any = {}
    allMessages.forEach((message: any) => {
      refs[message._id] = createRef()
    })
    return refs
  }, [allMessages])

  useEffect(() => {
    setAllMessages(messages)
    setMessage('')

    setTimeout(() => {
      if (messagesEnd.current && !messageDelete) {
        messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight
      }
      if (messageDelete) {
        setMessageDelete(false)
      }
    }, 100)
  }, [chatId, messagesEnd, messages])

  useEffect(() => {
    setMessage('')
  }, [chatId])
  useEffect(() => {
    socket.on('deleteChannel', (chatId: any) => {
      dispatch(discussionActions.getAllChat(workspaceid, chatType))
      dispatch(discussionActions.currentUserChatId(''))
      setUserName('')
    })

    return () => {
      socket.removeListener('deleteChannel')
    }
  }, [])
  useEffect(() => {
    if (editChat) {
      // setShowGroupPopup(true)
      dispatch(discussionActions.getChannelUsers(chatId)).catch((err: any) => {
        dispatch(discussionActions.unauthorizedForChat(err.response.data))
      })
    }
  }, [editChat])
  useEffect(() => {
    socket.on(chatId, (data: any) => {
      dispatch(discussionActions.messageScenedUserIds(chatId))
      const updatedMessages = [...allMessages, data]
      setAllMessages(updatedMessages)
      if (messagesEnd.current && !messageDelete) {
        messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight
      }
      dispatch(discussionActions.getAllChat(workspaceid, chatType))
    })
    if (allMessages.length > 1) {
      dispatch(discussionActions.groupCreated(false))
    }

    return () => {
      socket.removeListener(chatId)
    }
  }, [allMessages])

  useEffect(() => {
    setAllMessages(messages)

    socket.on('messageDeleted', async (data: any) => {
      setMessageDelete(true)
      if (chatId !== '') {
        dispatch(discussionActions.getAllMessages(chatId))
      }
    })

    socket.on('receiveMessageForCount', async (data: any) => {
      if (data.statusCode === 403) {
        dispatch(discussionActions.unauthorizedForChat(data))
        return
      }
      if (data.sender._id !== loginUser._id) {
        dispatch(discussionActions.getAllChat(workspaceid, chatType))
      }
    })

    return () => {
      socket.removeListener('messageDeleted')
      socket.removeListener('receiveMessageForCount')
    }
  }, [messages])
  const sendMessage = async () => {
    socket.emit('createMessage', {
      message: message,
      chat: chatId,
      workSpace: workspaceid,
      loginUser: loginUser._id,
    })

    setMessage('')
    if (messagesEnd.current) {
      messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        dropdownIndex &&
        refsById[dropdownIndex]?.current &&
        !refsById[dropdownIndex]?.current.contains(event.target)
      ) {
        setDropdownIndex('')
      }
    }

    document.addEventListener('click', handleClickOutside, {capture: true})
    return () => {
      document.removeEventListener('click', handleClickOutside, {capture: true})
    }
  }, [dropdownIndex, refsById])

  //
  return chatId && !showGroupPopup ? (
    <>
      <div
        className='card-body d-flex align-items-center flex-column pb-4 chatInner'
        id='kt_chat_messenger_body'
      >
        {groupCreated && (
          <div className='scroll-y me-n5 pe-5 height_overlay mb-1 w-100 d-flex align-items-center justify-content-center flex-column'>
            <h1 className='text-center text-dark-blue fw-500 fs-5'>Start Chat</h1>
            <span className='para-grey'>Channel has been created</span>
          </div>
        )}
        {!groupCreated && (
          <div
            ref={messagesEnd}
            className='scroll-y me-n5 pe-5 height_overlay  mb-1 w-100 chatbox'
            data-kt-element='messages'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
            data-kt-scroll-offset='0px'
          >
            {allMessages?.map((message: any, index: any) => {
              return (
                <div key={index} className=''>
                  {message?.sender?._id !== loginUser?._id ? (
                    message?.messageType !== 'activity' ? (
                      <div key={index} className='d-flex d-flex justify-content-start mb-10 mb-10'>
                        <div className='d-flex flex-column align-items align-items-start'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol  symbol-35px symbol-circle '>
                              <img
                                alt='profile pic'
                                title={message?.sender?.fullName}
                                src={getImageURL(
                                  message?.sender?.image?.[0]?.thumbnailUrl,
                                  accessToken
                                )}
                              />
                            </div>
                            <div className='ms-3'>
                              <span className='fs-7 fw-bold text_color_dis me-1'>
                                {message?.sender?.fullName}
                              </span>
                              <span className='text-muted fs-8 mb-1 ms-3'>
                                {moment(message?.createdAt).fromNow()}
                              </span>
                            </div>
                          </div>
                          {message.messageDelete == 'deleteForEveryOne' ? (
                            <i className='p-5 rounded-border-text bg-light-info text-dark fw-semibold text-start'>
                              Message deleted
                            </i>
                          ) : (
                            <div className='d-flex'>
                              <div
                                className='p-5 rounded-border-text bg-light-info text-dark fw-semibold text-start'
                                style={{
                                  wordBreak: 'break-word',
                                }}
                                data-kt-element='message-text'
                              >
                                {message?.content}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : message.content == 'groupCreated' ? (
                      <>
                        <div className='d-flex d-flex justify-content-center mb-0'>
                          {`${message?.sender?.fullName} created channel "${message?.createdGroupName}"`}
                        </div>
                        <div className='text-muted fs-7 mb-3 text-center'>
                          {moment(message?.createdAt).fromNow()}
                        </div>
                      </>
                    ) : message.content == 'addedNewUsers' ? (
                      <>
                        <div className='d-flex d-flex justify-content-center mb-0'>
                          {`${message?.sender?.fullName} added ${message?.newAddedUsers?.fullName}`}
                        </div>
                        <div className='text-muted fs-7 mb-3 text-center'>
                          {moment(message?.createdAt).fromNow()}
                        </div>
                      </>
                    ) : message.content == 'userRemoved' ? (
                      <>
                        <div className='d-flex d-flex justify-content-center mb-0'>
                          {`${message?.sender?.fullName} Removed ${message?.removedUsers?.fullName} `}
                        </div>
                        <div className='text-muted fs-7 mb-3 text-center'>
                          {moment(message?.createdAt).fromNow()}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='d-flex d-flex justify-content-center mb-0'>
                          {`${message?.sender?.fullName} changed channel name from "${message?.oldGroupName}" to "${message?.currentGroupName}"`}
                        </div>
                        <div className='text-muted fs-7 mb-3 text-center'>
                          {moment(message?.createdAt).fromNow()}
                        </div>
                      </>
                    )
                  ) : message.messageType !== 'activity' ? (
                    // <div>
                    <div
                      key={index}
                      className='d-flex d-flex justify-content-end align-items-start mb-10 mb-10 w-100 h-auto'
                    >
                      <div className='d-flex flex-column align-items align-items-end w-100'>
                        <div className='d-flex align-items-center mb-2 w-100 justify-content-end'>
                          <div className='me-1'>
                            <span className='text-muted fs-8 mb-1 me-3'>
                              {moment(message?.createdAt).fromNow()}
                            </span>

                            {/* <span className='fs-7 text_color_dis  fw-bold  ms-1'>You</span> */}
                          </div>
                          <div className='symbol  symbol-35px symbol-circle '>
                            <img
                              alt='profile pic'
                              title={message?.sender?.fullName}
                              src={getImageURL(
                                message?.sender?.image?.[0]?.thumbnailUrl,
                                accessToken
                              )}
                            />
                          </div>
                        </div>
                        {message.messageDelete == 'deleteForMe' ||
                        message.messageDelete == 'deleteForEveryOne' ? (
                          <i
                            className='position-relative p-5 rounded-border-text bg-light-primary text-dark fw-semibold text-end'
                            style={{
                              wordBreak: 'break-all',
                            }}
                          >
                            Message deleted
                          </i>
                        ) : (
                          <div className='d-flex'>
                            <div
                              ref={refsById[message._id]}
                              className='position-relative p-5  rounded-border-text-light   fw-semibold text-end'
                              style={{
                                wordBreak: 'break-all',
                                marginRight: '44px',
                              }}
                              data-kt-element='message-text'
                            >
                              {dropdownIndex === message._id && (
                                <MessageDropdown
                                  setDropdownIndex={setDropdownIndex}
                                  messageId={message._id}
                                />
                              )}

                              {message?.content}
                            </div>
                            <i
                              className='bi bi-three-dots fs-4 cursor-pointer d-block float-end inner-dots'
                              style={{transform: 'rotateZ(90deg)'}}
                              onClick={() => {
                                setDropdownIndex(message._id)
                              }}
                            ></i>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : // </div>
                  message.content == 'groupCreated' ? (
                    <>
                      <div className='d-flex d-flex justify-content-center mb-0'>
                        {`You created ${message?.createdGroupName} channel `}
                      </div>
                      <div className='text-muted fs-7 mb-3 text-center'>
                        {moment(message?.createdAt).fromNow()}
                      </div>
                    </>
                  ) : message.content == 'addedNewUsers' ? (
                    <>
                      <div className='d-flex d-flex justify-content-center mb-0'>
                        {`You added ${message?.newAddedUsers?.fullName}`}
                      </div>
                      <div className='text-muted fs-7 mb-3 text-center'>
                        {moment(message?.createdAt).fromNow()}
                      </div>
                    </>
                  ) : message.content == 'userRemoved' ? (
                    <>
                      <div className='d-flex d-flex justify-content-center mb-0'>{`You removed ${message?.removedUsers?.fullName}`}</div>
                      <div className='text-muted fs-7 mb-3 text-center'>
                        {moment(message?.createdAt).fromNow()}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='d-flex d-flex justify-content-center mb-0'>
                        {`You changed the channel name from "${message?.oldGroupName}" to
                    "${message?.currentGroupName}"`}
                      </div>
                      <div className='text-muted fs-7 mb-3 text-center'>
                        {moment(message?.createdAt).fromNow()}
                      </div>
                    </>
                  )}
                </div>
              )
            })}
          </div>
        )}

        <div
          className='card-footer pt-2 px-0 pb-0 w-100 border-top-none  position-relative'
          id='kt_chat_messenger_footer'
        >
          <textarea
          style={{resize:'none'}}
            className='form-control form-control-solid mb-3 bg-transparent bg-dark-grey-chat pe-10'
            rows={1}
            data-kt-element='input'
            placeholder='Type a message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyDown={onEnterPress}
          ></textarea>

          <div className='custom_position'>
            <button
              title='Send'
              className='bg-transparent border-0'
              type='button'
              data-kt-element='send'
              onClick={sendMessage}
            >
              <img src='/media/icons/gen016.svg' />
              {/* <i className='fas fa-paper-plane text-dark'></i> */}
            </button>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className='scroll-y pe-5 h-100 py-5 mb-0 pb-0 d-flex align-items-center justify-content-center w-100'>
      <div>
        <h1 className='text-center fs-1 text-dark-blue fw-500'>Welcome!</h1>
        <p className='text-center para-grey'>Please Select Chat to Start Messageing.</p>
      </div>
    </div>
  )
}

export {ChatInner}
