import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  app: boolean
  email: boolean
  opportunityWatch: boolean
  internalInitiatives: boolean
  releaseNotes: boolean
  space: boolean
  // team: boolean
}

// Define a type for the slice state
interface NotificationState {
  data?: entities
}

// Define the initial state using that type
const initialState: NotificationState = {
  data: {
    app: false,
    email: false,
    opportunityWatch: false,
    internalInitiatives: false,
    releaseNotes: false,
    space: false,
    // team: false,
  },
}

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    notificationFetched: (state, action) => {
      state.data = action.payload
    },
  },
})
