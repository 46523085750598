/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import * as boardActions from '../../redux/BoardActions'

export const ProjectKeys: FC = () => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const history = useHistory()
  const dispatch: any = useDispatch()
  const {allProjects}: any = useSelector<RootState>(
    (state) => ({
      allProjects: state.project.allProjects,
      loading: state.board.loading,
    }),
    shallowEqual
  )

  const loadSummary = (project: any) => {
    dispatch(boardActions.updateCurrentProjectState(project))
    history.push(`/${workspaceid}/project/${project._id}/board/summary`)
  }

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown w-200px w-md-150px menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold fs-base w-175px'
        data-kt-menu='true'
      >
        <div className='separator border-gray-200'></div>
        <div className='px-3 py-5'>
          {allProjects?.map((project: any) => {
            return (
              <div className='menu-item px-3 my-0' key={project?._id}>
                <div className='menu-link px-3 py-2'>
                  <span
                    title='Edit Channel'
                    className='menu-title'
                    onClick={(e) => {
                      loadSummary(project)
                    }}
                  >
                    {project.name}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
