import * as serverRequest from './ApprovalsCrud'
import {approvalsSlice} from './ApprovalsSlice'
import {AppDispatch} from '../../../../setup/redux/Store'
const {actions} = approvalsSlice

export const fetchApprovals =
  ({
    search,
    page,
    sortby,
    orderby,
    workSpace,
  }: {
    search: string
    page: number
    sortby: string
    orderby: string
    workSpace: string
  }) =>
  (dispatch: AppDispatch) => {
    return serverRequest
      .getAllApprovals({search, page, sortby, orderby, workSpace})
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data

        dispatch(
          actions.approvalsFetched({
            page,
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data?.data,
          })
        )
      })
  }
export const createApproval = (approvalsToCreate: any) => (dispatch: AppDispatch) => {
  const {workSpace} = approvalsToCreate
  return serverRequest
    .createApproval(
      approvalsToCreate.title,
      approvalsToCreate.description,
      approvalsToCreate.assignee,
      workSpace
    )
    .then((response) => {
      serverRequest
        .getAllApprovals({search: '', page: 0, sortby: '', orderby: '', workSpace: workSpace})
        .then((response) => {
          const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

          dispatch(
            actions.approvalsFetched({page, totalCount, start, end, limitPerPage, entities: data})
          )
        })
    })
    .catch((error) => {
      error.clientMessage = "Can't create approvalss"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}
export const updateApproval = (approvalsToUpdate: any) => (dispatch: AppDispatch) => {
  return serverRequest
    .updateApproval(
      approvalsToUpdate._id,
      approvalsToUpdate.title,
      approvalsToUpdate.description,
      approvalsToUpdate.assignee
    )

    .then((response) => {
      const {data} = response
      dispatch(actions.approvalsUpdated({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update approvalss"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const deleteApproval = (_id: string, workspaceid: string) => (dispatch: AppDispatch) => {
  return serverRequest.deleteApproval(_id).then((response) => {
    serverRequest
      .getAllApprovals({search: '', page: 0, sortby: '', orderby: '', workSpace: workspaceid})
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(
          actions.approvalsFetched({page, totalCount, start, end, limitPerPage, entities: data})
        )
      })
  })
}

export const fetchApproval = (_id: string) => (dispatch: AppDispatch) => {
  return serverRequest
    .fetchApproval(_id)
    .then((response) => {
      const {data} = response?.data

      dispatch(actions.approvalFetched({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch approvals"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const makeApprovalEmpty = (data: any) => (dispatch: AppDispatch) => {
  dispatch(actions.approvalFetched({data}))
}

export const removeErrorMessage = (error: any) => (dispatch: AppDispatch) => {
  dispatch(actions.catchError(error))
}
