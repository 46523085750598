import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {BoardMembersRoles} from './BoardMembersRoles'

export function BoardShareMembers() {
  return (
    <div className='py-3'>
      <div className="card card-custom card-stretch gutter-b">
        <div className='card-header border-0 min-h-auto'>
          <div className="d-flex justify-content-start border-bottom w-100 pb-3">
            <span className='mt-1 fs-6 text-center fw-bolder'>Share</span>
          </div>
          <div className='w-100 pt-5 pb-3' id=''>
            <form className='w-100 position-relative' autoComplete='off'>
              <KTSVG
                path='/media/icons/duotune/general/gen021.svg'
                className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
              />
              <input
                type='text'
                className='form-control form-control-solid px-15'
                name='search'
                placeholder='Email address or name'
              />
            </form>
          </div>
        </div>
      </div>
      <div className='card-body pt-5'>
        <div className='d-flex align-items-center mb-7'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <Link to="/">
                <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/60742c87f67f794749a987e4/182003c4fdd39730e266c400c4562b8b/30.png" alt="Sobia Yaseen (sobiayaseen2)" title="Sobia Yaseen (sobiayaseen2)" />
              </Link>
            </div>
            <div className='d-flex flex-column'>            
              <span className='text-muted fs-7'>Visually plan time and workload.</span>
              <a href='#' className='fs-7 fw-bolder' style={{width: "fit-content",color: "#5e6c84", borderBottom: '1px solid #5e6c84',lineHeight: '15px'}}>
                copy link
              </a>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center mb-7 justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <Link to="/">
                <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/60742c87f67f794749a987e4/182003c4fdd39730e266c400c4562b8b/30.png" alt="Sobia Yaseen (sobiayaseen2)" title="Sobia Yaseen (sobiayaseen2)" />
              </Link>
            </div>
            <div className='d-flex flex-column'>            
              <span className='text-muted fs-7'>Sobia Yaseen (you)</span>
              <a href='#' className='text-muted text-underline text-primary fs-8 fw-bolder'>
                @sobiayaseen2 . Workspace guest
              </a>
            </div>
          </div>
          <div className="board-views-btn">
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='m-0'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                Members<i className="fa fa-angle-down ps-2" aria-hidden="true"></i>
              </button>
              <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true' style={{maxHeight: '345px',overflow: 'auto'}}>                                                     
                <BoardMembersRoles />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center mb-7 justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <Link to="/">
                <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/60742c87f67f794749a987e4/182003c4fdd39730e266c400c4562b8b/30.png" alt="Sobia Yaseen (sobiayaseen2)" title="Sobia Yaseen (sobiayaseen2)" />
              </Link>
            </div>
            <div className='d-flex flex-column'>            
              <span className='text-muted fs-7'>Sobia Yaseen (you)</span>
              <a href='#' className='text-muted text-underline text-primary fs-8 fw-bolder'>
                @sobiayaseen2 . Workspace guest
              </a>
            </div>
          </div>
          <div className="board-views-btn">
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='m-0'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                Members<i className="fa fa-angle-down ps-2" aria-hidden="true"></i>
              </button>
              <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true' style={{maxHeight: '345px',overflow: 'auto'}}>                                                     
                <BoardMembersRoles />
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center mb-7 justify-content-between'>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-5'>
              <Link to="/">
                <img className="member-avatar" src="https://trello-members.s3.amazonaws.com/60742c87f67f794749a987e4/182003c4fdd39730e266c400c4562b8b/30.png" alt="Sobia Yaseen (sobiayaseen2)" title="Sobia Yaseen (sobiayaseen2)" />
              </Link>
            </div>
            <div className='d-flex flex-column'>            
              <span className='text-muted fs-7'>Sobia Yaseen (you)</span>
              <a href='#' className='text-muted text-underline text-primary fs-8 fw-bolder'>
                @sobiayaseen2 . Workspace guest
              </a>
            </div>
          </div>
          <div className="board-views-btn">
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='m-0'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                Members<i className="fa fa-angle-down ps-2" aria-hidden="true"></i>
              </button>
              <div className='menu menu-sub menu-sub-dropdown w-250px w-md-300px' data-kt-menu='true' style={{maxHeight: '345px',overflow: 'auto'}}>                                                     
                <BoardMembersRoles />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
