import {FC} from 'react'
import {ProjectHeader} from './ProjectHeader'
import {ProjectList} from './ProjectList'

type Props = {}

const ProjectData: FC<Props> = () => {
  return (
    <>
      <ProjectHeader />
      <div className="overflow_height_scroll">
      <div className='card-body d-flex flex-column py-3 px-10'>
        <div className='table-responsive'>
          <ProjectList />
        </div>
      </div>
      </div>
    </>
  )
}

export {ProjectData}
