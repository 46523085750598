/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import * as boardActions from '../../redux/BoardActions'

interface Props {
  showPopup: boolean
  column: any
  handleClose: () => void
  // columnName: string
}

const DeleteColumnPopup: FC<Props> = ({showPopup, handleClose, column}) => {
  const [selectColumn, setSelectColumn] = useState('')
  const dispatch: any = useDispatch()
  const {currentProject}: any = useSelector<RootState>(
    (state) => ({
      currentProject: state.board.currentProject,
    }),
    shallowEqual
  )

  const currentColumn = currentProject?.board?.columns.filter((clmn: any) => {
    return clmn._id === column._id
  })

  const allColumnExceptcurrent = currentProject?.board?.columns.filter((clmn: any) => {
    return clmn._id !== column._id
  })

  const deleteItemHandler = () => {
    const deleteColumnId = column._id
    const moveColumnId = selectColumn ? selectColumn : allColumnExceptcurrent[0]?._id
    dispatch(boardActions.moveTaskFromOneColumnToAnother(deleteColumnId, moveColumnId)).then(
      (res: any) => {
        if (res.status === 200) {
        }
        dispatch(boardActions.deleteColumn(column._id)).then((res: any) => {
          if (res.data.acknowledged) {
            dispatch(boardActions.currentProject(currentProject._id))
            handleClose()
          }
        })
      }
    )
  }

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered h-auto'
      show={showPopup}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Move work from "{currentColumn?.[0]?.name}" column</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10'>
                    <div>
                      Select a new home for any work with the "{currentColumn?.[0]?.name}" status
                    </div>
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <label className='fw-bold' htmlFor=''>
                        This status will be deleted:
                      </label>

                      <del className='fw-bolder d-block' style={{color: '#004b87'}}>
                        {currentColumn?.[0]?.name}
                      </del>
                    </div>
                    <div>
                      <label className='fw-bold pb-1' htmlFor=''>
                        Move existing issues to:
                      </label>
                      <select
                        value={selectColumn}
                        onChange={(e: any) => setSelectColumn(e.target.value)}
                        className='d-block w-100'
                      >
                        {allColumnExceptcurrent?.map((column: any, i: any) => {
                          return (
                            <option value={column?._id} key={i}>
                              {column?.name}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack justify-content-end pt-10'>
                <div className='me-2'>
                  <button
                    title='Cancel'
                    type='button'
                    className='btn btn-sm cancel_button me-3'
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    title='Delete'
                    type='submit'
                    className='btn btn-sm btn-blue me-3'
                    onClick={() => {
                      deleteItemHandler()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {DeleteColumnPopup}
