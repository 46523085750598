import React, {Suspense, useEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/redux/AuthInit'
import {Routes} from './routing/Routes'
import {DndProvider} from 'react-dnd'
import {AbilityContext} from '../app/Common/RBAC/Can'
import ability from '../app/Common/RBAC/Ability'
import * as actions from './modules/settings/redux/settingsAction'
import {useDispatch} from 'react-redux'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {TouchBackend} from 'react-dnd-touch-backend'
import {MultiBackend, TouchTransition, MouseTransition} from 'react-dnd-multi-backend'

// import '../_metronic/assets/sass/components/cards.scss'

// const CustomHTML5toTouch = {
//   backends: [
//     {
//       backend: HTML5Backend,
//       transition: MouseTransition,
//       // by default, will dispatch a duplicate `mousedown` event when this backend is activated
//     },
//     {
//       backend: TouchBackend,
//       // Note that you can call your backends with options
//       options: {
//         enableMouseEvents: true,
//         // scrollAngleRanges: [{start: 300}, {end: 60}, {start: 120, end: 240}],
//       },
//       transition: TouchTransition,
//       // will not dispatch a duplicate `touchstart` event when this backend is activated
//       skipDispatchOnTransition: true,
//     },
//   ],
// }

type Props = {
  basename: string
}

const options = {
  enableTouchEvents: true,
  enableMouseEvents: true,
}

const App: React.FC<Props> = ({basename}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      actions.fetchSettings({
        search: '',
        page: '',
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  })

  return (
    <AbilityContext.Provider value={ability}>
      <Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <AuthInit>
                <DndProvider backend={HTML5Backend} options={options}>
                  <Routes />
                </DndProvider>
              </AuthInit>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </Suspense>
    </AbilityContext.Provider>
  )
}

export {App}
