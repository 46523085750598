/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import '../../../_metronic/assets/sass/components/cards.scss'
import '../../../_metronic/assets/sass/components/new-spaces.scss'
import { WorkspaceListing } from './WorkspaceListing'
import axios from 'axios'
import { Loading } from '../../../_metronic/layout/Loading'
import { Can } from '../../Common/RBAC/Can'
import 'nouislider/dist/nouislider.css'
import noUiSlider from 'nouislider'
import { KTSVG } from '../../../_metronic/helpers'
import { Settings } from '../../Common/Settings'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { AppsListing } from './AppsListing'
import { DashboardPopup } from './DashboardPopup'
import { DashboardSettingsPopup } from './DashboardSettingsPopup'
import { RootState } from '../../../setup'
import * as actions from '../../modules/workspace/redux/WorkspaceActions'
import { WorkspaceDelete } from '../../modules/workspace/components/WorkspaceDeleteDialog'
import { WorkspaceSort } from '../../../_metronic/layout/sort/WorkspaceSort'
import { Link, useHistory } from 'react-router-dom'
import { NewSpacesTileView } from './NewSpacesTileView'
import { NewSpacesCardView } from './NewSpacesCardView'
import { Search } from '../../../_metronic/layout/search/Search'
import moment from 'moment'
const API_URL = process.env.REACT_APP_API_URL
const SPACE_ICONS = `${API_URL}/dashboard`
const APPS_ICONS = `${API_URL}/apps/enabledapps/systemApp?systemApp=false`
interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}
var slider: any
const NewSpaces: FC = () => {
  let sliderValue1: any

  const { settings }: any = useSelector<any>(
    (state) => ({
      settings: state.auth.user.settings,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const [workspaces, setWorkspaces] = useState([{}])
  const [filterWorkspaces, setFilterWorkspaces] = useState([{}])
  const [delWorkspace, setDeleteWorkspace] = useState('')
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [fetchReocords, setFetchRecords] = useState(false)
  const [spaceType, setSpaceType] = useState<string>('')
  const [ownerType, setOwnerType] = useState<string>('')
  const [toggleView, setToggleView] = useState(false)
  const [isFiltered, setIsFiltered] = useState(false)

  const [rowDimension, setRow1Dimension] = useState(settings?.iconSize ? settings?.iconSize : '63')
  const [tab, setTab] = useState('vteamsTab1')
  const [dashboardIcons, setDashboardIcons]: any = useState()
  const [filterDashboardIcons, setFilterDashboardIcons]: any = useState()
  const [appsIcons, setAppsIcons]: any = useState()
  const [isLoading, setIsLoading]: any = useState(true)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [showDetailSpace, setShowDetailSpace] = useState<boolean>(
    history.location.pathname === '/spaces' ? true : false
  )
  const [searchValue, setSearchValue] = useState('')
  const [defaultSetting, setDefaultSetting] = useState(false)
  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.workspace,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    dispatch(
      actions.fetchWorkspaces({
        search: '',
        page: 1,
        orderby: 'desc',
        sortby: '',
        createdAt: '',
        spaceType: '',
        assignee: [],
        maintainer: '',
      })
    )
  }, [])
  useEffect(() => {
    if (history.location.pathname == '/spaces') {
      setShowDetailSpace(true)
    }
  }, [history.location.pathname])
  const [sortValues, setSortValues] = useState({
    orderby: '',
    sortby: '',
    createdAt: '',
    spaceType: '',
    assignee: '',
    maintainer: '',
  })

  const { workspaceData, workspaceCount, auth, listLoading, apiSettings }: any =
    useSelector<RootState>(
      (state) => ({
        workspaceData: state.workspace.entities,
        workspaceCount: state.workspace.totalCount,
        auth: state.auth.user,
        listLoading: state.workspace.listLoading,
        apiSettings: state.settings.entities,
      }),
      shallowEqual
    )

  useEffect(() => {
    if (Array.isArray(workspaceData)) {
      setWorkspaces(workspaceData)
      setFilterWorkspaces(workspaceData)
    }
  }, [workspaceData])
  const cards = 12 - (workspaceCount + 1) > 0 ? Array(12 - (workspaceCount + 1))?.fill(2) : Array(0)

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchWorkspaces({
        search: '',
        page: event.selected + 1,
        orderby: 'desc',
        sortby: '',
        createdAt: '',
        spaceType: '',
        assignee: [],
        maintainer: '',
      })
    )
  }
  const deleteWorkspace = (_id: string) => async (event: any) => {
    event.preventDefault();
    setDeleteWorkspace(_id)
    setShowDeleteDialog(true)
  }

  const handleSearchCard = (search: string) => {
    dispatch(
      actions.fetchWorkspaces({
        search: search,
        page: paginationData?.page,
        orderby: '',
        sortby: '',
        createdAt: '',
        spaceType: '',
        assignee: [],
        maintainer: '',
      })
    )
  }
  const searchHandler = (event: any) => {
    // handle search card
    let workspacesFilter = workspaces.filter((search: any) =>
      search?.name?.match(new RegExp(event.target.value, 'i'))
    )
    setFilterWorkspaces(workspacesFilter)

    // handle search tile
    let dashboardIconsFilter = dashboardIcons.filter((search: any) =>
      search?.iconId?.name?.match(new RegExp(event.target.value, 'i'))
    )
    setFilterDashboardIcons(dashboardIconsFilter)
  }

  useEffect(() => {
    const handleFilter = () => {
      dispatch(
        actions.fetchWorkspaces({
          search: '',
          page: paginationData?.page,
          orderby: '',
          sortby: '',
          createdAt: '',
          spaceType: '',
          assignee: [],
          maintainer: '',
        })
      )
    }
  }, [spaceType, ownerType])

  useEffect(() => {
    dispatch(
      actions.fetchWorkspaces({
        search: '',
        page: '',
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        createdAt: sortValues.createdAt,
        spaceType: sortValues.spaceType,
        assignee: sortValues.assignee,
        maintainer: sortValues.maintainer,
      })
    )

    // Here to filter tile view
    let finalSpaces = dashboardIcons

    if (sortValues.spaceType) {
      finalSpaces = dashboardIcons?.filter((search: any) => {
        return search?.iconId?.spaceType == sortValues.spaceType
      })
    }
    if (sortValues.maintainer) {
      finalSpaces = finalSpaces?.filter((search: any) => {
        return search?.iconId?.maintainers?.find((m: any) => m == sortValues.maintainer)
      })
      finalSpaces.sort((a: any, b: any) => {
        if (a?.iconId?.name.toLowerCase() < b?.iconId?.name.toLowerCase()) return 1
        if (a?.iconId?.name.toLowerCase() > b?.iconId?.name.toLowerCase()) return -1
        return 0
      })
    }
    if (sortValues.assignee) {
      finalSpaces = finalSpaces?.filter((search: any) => {
        return search?.iconId?.owner == sortValues.assignee
      })
      finalSpaces.sort((a: any, b: any) => {
        if (a?.iconId?.createdAt.toLowerCase() > b?.iconId?.createdAt.toLowerCase()) return 1
        if (a?.iconId?.createdAt.toLowerCase() < b?.iconId?.createdAt.toLowerCase()) return -1
        return 0
      })
    }
    if (sortValues.createdAt) {
      finalSpaces = finalSpaces?.filter((search: any) => {
        let formatSortValues = moment(sortValues.createdAt).format('YYYY-MM-DD')
        let formatWorkspaceDate = moment(search?.iconId?.createdAt).format('YYYY-MM-DD')

        return formatWorkspaceDate == formatSortValues
      })
    }
    if (sortValues.sortby) {
      if (sortValues.sortby == 'name') {
        finalSpaces.sort((a: any, b: any) => {
          if (a?.iconId?.name.toLowerCase() < b?.iconId?.name.toLowerCase()) return 1
          if (a?.iconId?.name.toLowerCase() > b?.iconId?.name.toLowerCase()) return -1
          return 0
        })
        // console.log('🚀 ~ file: NewSpaces.tsx:225 ~ finalSpaces.sort ~ finalSpaces', finalSpaces)
      }
      if (sortValues?.sortby == 'spaceKey') {
        finalSpaces.sort((a: any, b: any) => {
          if (a?.iconId?.spaceKey.toLowerCase() < b?.iconId?.spaceKey.toLowerCase()) return 1
          if (a?.iconId?.spaceKey.toLowerCase() > b?.iconId?.spaceKey.toLowerCase()) return -1
          return 0
        })
      }
      if (sortValues?.sortby == 'spaceType') {
        finalSpaces.sort((a: any, b: any) => {
          return b?.iconId?.spaceType - a?.iconId?.spaceType
        })
      }
      if (sortValues?.sortby == 'createdAt') {
        finalSpaces.sort((a: any, b: any) => {
          if (a?.iconId?.createdAt.toLowerCase() > b?.iconId?.createdAt.toLowerCase()) return -1
          if (a?.iconId?.createdAt.toLowerCase() < b?.iconId?.createdAt.toLowerCase()) return 1
          return 0
        })
      }
      if (sortValues.orderby) {
        if (sortValues.orderby == 'asc') finalSpaces.reverse()
      }
    }

    setFilterDashboardIcons(finalSpaces)
  }, [sortValues])

  const crumbs = [
    { name: 'Dashboard', url: '/dashboard' },
    { name: 'Spaces', url: '/spaces' },
  ]

  useEffect(() => {
    fetchSpaceData()
      .then((data) => {
        setDashboardIcons(data)
        setFilterDashboardIcons(data)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
    fetchAppData()
      .then((data) => {
        setAppsIcons(data)
        setIsLoading(false)
      })
      .catch((e) => {
        setIsLoading(false)
      })
  }, [])
  // const moveDashboardIcons = useCallback(
  //   (dragIndex: any, hoverIndex: any) => {
  //     const dragItem = dashboardIcons[dragIndex]
  //     const hoverItem = dashboardIcons[hoverIndex]
  //     // Swap places of dragItem and hoverItem in the workspace array
  //     setDashboardIcons((dashboardIcons: any) => {
  //       const updatedIcons = [...dashboardIcons]
  //       updatedIcons[dragIndex] = hoverItem
  //       updatedIcons[hoverIndex] = dragItem
  //       return updatedIcons
  //     })
  //   },
  //   [dashboardIcons]
  // )
  const moveDashboardIcons = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      const dragItem = filterDashboardIcons[dragIndex]
      const hoverItem = filterDashboardIcons[hoverIndex]
      // Swap places of dragItem and hoverItem in the workspace array
      setFilterDashboardIcons((dashboardIcons: any) => {
        const updatedIcons = [...dashboardIcons]
        updatedIcons[dragIndex] = hoverItem
        updatedIcons[hoverIndex] = dragItem
        return updatedIcons
      })
    },
    [filterDashboardIcons]
  )

  const fetchSpaceData = async (params: any = {}) => {
    const spaces = await axios.get(SPACE_ICONS, { params: params })
    return spaces.data.data
  }

  const fetchAppData = async () => {
    const apps = await axios.get(APPS_ICONS)
    return apps.data.data
  }

  const onRefChange = useCallback(
    (node) => {
      if (node) {
        slider = node
        sliderValue1 = node
        sliderValue1?.noUiSlider?.destroy()
        noUiSlider.create(sliderValue1, {
          start: settings?.iconSize ? settings?.iconSize : rowDimension,
          step: 2,
          tooltips: true,
          connect: true,
          range: {
            min: 25,
            max: 145,
          },
          format: {
            to: (v: any) => v | 0,
            from: (v: any) => v | 0,
          },
        })
        sliderValue1?.noUiSlider.on('slide', function (values: any) {
          const size = Math.floor(parseInt(values[0])).toString()
          setRow1Dimension(size)
        })
      }
    },
    [settings?.iconSize]
  )
  useEffect(() => {
    if (defaultSetting === true) {
      slider?.noUiSlider?.set(rowDimension)
    }
  }, [rowDimension])

  const handleSearch = (value: string) => {
    setSearchValue(value)
    // handle search card
    let workspacesFilter = workspaces.filter((search: any) =>
      search?.name?.match(new RegExp(value, 'i'))
    )
    setFilterWorkspaces(workspacesFilter)

    // handle search tile
    let dashboardIconsFilter = dashboardIcons.filter((search: any) =>
      search?.iconId?.name?.match(new RegExp(value, 'i'))
    )
    setFilterDashboardIcons(dashboardIconsFilter)
  }
  

  return (
    <>
      <WorkspaceDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={delWorkspace}
        setFetchRecords={setFetchRecords}
        fetchReocords={fetchReocords}
      />
      <section className='mh-80 tabs-main'>
        <div className=''>
          <div className='card card-custom h-100'>
            <div className='card-body p-0'>
              <div className='stepper stepper-main third'></div>
              <div className='tab-content h-100'>
                {/* {!showDetailSpace && ( */}
                <div
                  className={clsx('tab-pane', {
                    active: tab === 'vteamsTab1',
                  })}
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {/*<div
                        className='card-toolbar mt-0 px-10 pt-5 pb-3 d-flex justify-content-between header_spaces'
                        data-bs-toggle='tooltip'
                        data-bs-placement='top'
                        data-bs-trigger='hover'
                      >
                        <div className=''>
                          <h2 className='fw-500 pt-2 pe-6' title='Spaces'>
                            Spaces
                          </h2>
                        </div>
                        <div className='d-flex align-items-center justify-content-end spaces_header'>
                          <div className='input-group col-sm-2 p-1 me-1'>
                            <input
                              type='text'
                              className='form-control form_control_space  bd-right-none py-1'
                              placeholder='Search'
                              aria-label="Recipient's username"
                              aria-describedby='basic-addon2'
                              title='Search Spaces'
                              value={searchValue}
                              onChange={(e: any) => {
                                setSearchValue(e.target.value)
                                searchHandler(e)
                              }}
                            />
                            /~ {/~ <img src='media/icons/projects/svgexport-7.svg'/> ~/} ~/
                            <span
                              className='input-group-text bd-left-none bg-white py-1 px-3 z-indx-0'
                              id='basic-addon2'
                            >
                              {' '}
                              <span className='p-0 m-0'>
                                {' '}
                                <span>
                                  {' '}
                                  /~ <img src='media/icons/projects/svgexport-7.svg' /> ~/
                                  <KTSVG path='/media/icons/projects/svgexport-7.svg' />
                                </span>
                              </span>
                              /~ {/~ <i className='fas fa-search'></i> ~/} ~/
                            </span>{' '}
                          </div>
                          /~ <Search handleSearch={handleSearch} /> ~/

                          <button
                            className='btn btn-sm btn-icon btn-active-light-primary p-2 ms-2'
                            title='Icon View'
                            onClick={() => setShowDetailSpace(false)}
                          >
                            <a>
                              {' '}
                              /~ <img className='mx-2' src='media/icons/projects/blocks.svg' /> ~/
                              <KTSVG
                                path='/media/icons/projects/blocks.svg'
                                svgClassName={!showDetailSpace ? 'header_icon active' : ''}
                              />
                            </a>
                          </button>

                          <button
                            onClick={() => setShowDetailSpace(true)}
                            className='btn btn-sm btn-icon btn-active-light-primary p-2'
                            title='Tile View'
                          >
                            <a>
                              {' '}
                              /~ <img src='media/icons/projects/gen010.svg' /> ~/
                              <KTSVG
                                path='/media/icons/projects/gen010.svg'
                                svgClassName={showDetailSpace ? 'header_icon active' : ''}
                              />
                            </a>
                          </button>

                          <div className='me-2'>
                            <button
                              type='button'
                              className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3 p-2'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='top-end'
                              title='Filters'
                            >
                              <a>
                                {' '}
                                /~ <img className='me-2' src='media/icons/projects/filter.svg' /> ~/
                                <KTSVG
                                  path='/media/icons/projects/filter.svg'
                                  svgClassName={isFiltered ? 'header_icon active' : ''}
                                />
                              </a>
                            </button>
                            <DashboardPopup
                              sortValues={sortValues}
                              setSortValues={setSortValues}
                              setIsFiltered={setIsFiltered}
                            />
                          </div>
                          <div className='me-2'>
                            <button
                              type='button'
                              className='btn btn-clean btn-sm btn-icon btn-active-light-primary me-n3 p-2'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                              data-kt-menu-flip='top-end'
                              title='Settings'
                            >
                              <KTSVG
                                path='/media/icons/duotune/coding/cod001.svg'
                                className='svg-icon-2 m-0 p-2'
                              />
                            </button>

                            <DashboardSettingsPopup
                              onRefChange={onRefChange}
                              rowDimension={rowDimension}
                              setRow1Dimension={setRow1Dimension}
                              defaultSetting={defaultSetting}
                              setDefaultSetting={setDefaultSetting}
                            // setToggleView={setToggleView}
                            />
                          </div>
                        </div>
                      </div>*/}

                      <div className="px-6 px-sm-12 header_spaces pb-0">
                        <div className="row padding_bottom_header">
                          <div className="col-sm-6">
                            <h2 className='fw-500 fs-2 m-0 py-5'>
                              Spaces
                            </h2>
                          </div>

                          <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
                            <Search handleSearch={handleSearch} />
                            <div>
                              <button
                                className='btn btn-sm btn-icon btn-active-light-primary p-2'
                                title='Icon View'
                                onClick={() => setShowDetailSpace(false)}
                              >
                                <a>
                                  <KTSVG path='/media/icons/projects/blocks.svg' svgClassName={!showDetailSpace ? 'header_icon active' : ''} />
                                </a>
                              </button>

                            </div>
                            <div>

                              <button
                                className='btn btn-sm btn-icon btn-active-light-primary p-2'
                                title='Tile View'
                                onClick={() => setShowDetailSpace(true)}
                              >
                                <a>
                                  <KTSVG path='/media/icons/projects/gen010.svg' svgClassName={showDetailSpace ? 'header_icon active' : ''} />
                                </a>
                              </button>
                            </div>

                            <div>
                              <button type='button' className='btn btn-clean btn-sm btn-icon btn-active-light-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end' title='Filters'>
                                <a>
                                  <KTSVG path='/media/icons/projects/filter.svg' svgClassName={isFiltered ? 'header_icon active' : ''} />
                                </a>
                              </button>
                              <DashboardPopup
                                sortValues={sortValues}
                                setSortValues={setSortValues}
                                setIsFiltered={setIsFiltered}
                              />
                            </div>

                            <div>
                              <button type='button' className='btn btn-clean btn-sm btn-icon btn-active-light-primary' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end' data-kt-menu-flip='top-end' title='Settings'>
                                <a>
                                  <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-2' />
                                </a>
                              </button>
                              <DashboardSettingsPopup
                                onRefChange={onRefChange}
                                rowDimension={rowDimension}
                                setRow1Dimension={setRow1Dimension}
                                defaultSetting={defaultSetting}
                                setDefaultSetting={setDefaultSetting}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="overflow_height_scroll_newspace pb-sm-20">
                        <>
                          {!showDetailSpace && (
                            <NewSpacesTileView
                              apiSettings={apiSettings}
                              dashboardIcons={filterDashboardIcons}
                              rowDimension={rowDimension}
                              moveDashboardIcons={moveDashboardIcons}
                            />
                          )}
                          {showDetailSpace && (
                            <NewSpacesCardView
                              workspaces={filterWorkspaces}
                              toggleView={toggleView}
                              sortValues={sortValues}
                              setSortValues={setSortValues}
                              setIsFiltered={setIsFiltered}
                              deleteWorkspace={deleteWorkspace}
                              apiSettings={apiSettings}
                            />
                          )}
                        </>
                        {((history.location.pathname === '/spaces' && !showDetailSpace) ||
                          history.location.pathname === '/dashboard') && (
                            // <Can I='list' this='apps'>
                              <>
                              <div className='row mx-10 mt-7'>
                                <div className='col-12 p-0'>
                                  <h2 className='fs-20px fw-500' title='Apps'>
                                    Apps
                                  </h2>
                                </div>
                              </div>
                              <div className='d-flex align-items-start margins-dashboard mt-10 flex-wrap justify-content-center justify-content-sm-start'>
                                {appsIcons?.length > 0 &&
                                  appsIcons.map((dashboardIcon: any, index: number) => {
                                    return (
                                      <div
                                        key={index}
                                        className='d-flex'
                                        title={dashboardIcon?.tooltip}
                                        style={{
                                          zoom: (+rowDimension + 37) + '%',
                                        }}
                                      >
                                        <AppsListing
                                          rowDimension={rowDimension}
                                          dashboardIcon={dashboardIcon}
                                        />
                                      </div>
                                    )
                                  })}
                              </div>
                              </>
                            // </Can>
                          )}
                      </div>
                    </>
                  )}

                </div>
                {/*<div
                  className={clsx('tab-pane pb-5 h-100 border-top-right-radius', {
                    active: tab === 'vteamsTab2',
                  })}
                >
                  <div className='row mx-10'>
                    <div className='col-12'>
                      <h2 className='fs-5' title='Spaces'>
                        {/~ Spaces ~/}
                      </h2>
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { NewSpaces }
