import React from 'react'
import {ListTable} from './components/ListTable'
import {ListEdit} from './components/ListEdit'
import {ListDetails} from './components/ListDetails'
import {Route, Switch} from 'react-router-dom'

const List: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/:workspaceid/list'>
          <ListTable className='mb-5 mb-xl-8' />
        </Route>
        <Route exact path='/:workspaceid/list/add'>
          {({history, match}) => {
            return (
              <ListEdit
                onHide={() => {
                  history.push(`/${match?.params?.workspaceid}/list`)
                }}
              />
            )
          }}
        </Route>
        <Route exact path='/:workspaceid/list/:id/edit'>
          {({history, match}) => (
            <ListEdit
              id={match && match.params.id}
              onHide={() => {
                history.push(`/${match?.params?.workspaceid}/list`)
              }}
            />
          )}
        </Route>
        <Route exact path='/:workspaceid/list/:id'>
          {({match, history}) => (
            <ListDetails
              id={match && match.params.id}
              onHide={() => {
                history.push(`/${match?.params?.workspaceid}/list`)
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  )
}

export {List}
