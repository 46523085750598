import {
  AlignmentType,
  HeadingLevel,
  Paragraph,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
} from 'docx'
import {degrees, universities} from '../../../../talentUIHelper'
import {constants} from './Constants'
import {docxTable, indentSpace, showImage, docxBorder} from './DocxFunctions'

export const education = (res: any) => {
  const tableRows: any = []
  tableRows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [showImage('/media/talent/education-icon.jpg', 35, 35)],
          width: {
            size: 7,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),

        new TableCell({
          verticalAlign: VerticalAlign.CENTER,
          children: [
            new Paragraph({
              indent: indentSpace(),
              children: [
                new TextRun({
                  text: constants.Titles.EDUCATION,
                  size: 24,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],

          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              text: '',
              heading: HeadingLevel.HEADING_3,
            }),
          ],
          width: {
            size: 5,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                after: 100,
              },
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text: res.otherDegree
                    ? `${res.otherDegree?.toUpperCase()}`
                    : `${res.degree?.toUpperCase() || ''}`,
                  bold: true,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  size: 20,
                }),
              ],
            }),
            new Paragraph({
              indent: indentSpace(),
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  text:
                    res?.degree && res?.university
                      ? res?.university !== 'other'
                        ? universities.find((u: any) => u.value === res?.university)?.label
                        : res?.otherUniversity
                      : '',
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                  color: constants.Colors.NERO,
                  size: 20,
                }),
              ],
            }),
          ],

          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    })
  )
  return docxTable(tableRows)
}
