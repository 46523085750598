import React, {FC, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
// import {useParams} from 'react-router-dom'
// import * as actions from '../redux/TodoAction'
import {Link, useParams} from 'react-router-dom'
import {TeamsAccordion} from './TeamsAccordion'
import {AssigneeSelector} from './AssigneeSelector'
import {combineAssigneesAndTeams, findAssigneeWithTeams} from './assigneeAndTeamHelper'
import {Field, Form, Formik} from 'formik'
import {RootState} from '../../../setup'
import * as actions from '../../modules/documents/redux/DocumentsAction'
import {socket} from '../../../_metronic/partials/layout/header-menus/HeaderUserMenu'
import '../../../_metronic/assets/sass/components/assignee-team-list.scss'
import {KTSVG} from '../../../_metronic/helpers'
interface Props {
  show: boolean
  handleClose: () => void
  calledBy: string
  setAssigneesId: any
  assigneesId: any
  id?: string | null
  teamsId?: any
  setTeamsId?: any
  assignee: any
  teams: any
  title: string
  discussionActions?: any
  chatType?: any
  group?: any
}

interface UsersSelcetor {
  assignee: []
}

const inits: UsersSelcetor = {
  assignee: [],
}

const AssigneesList: FC<Props> = ({
  discussionActions,
  show,
  handleClose,
  calledBy,
  setAssigneesId,
  assigneesId,
  id,
  teamsId,
  setTeamsId,
  assignee,
  teams,
  title,
  chatType,
  group,
}) => {
  const dispatch: any = useDispatch()
  const [enableTeams, setEnableTeams] = useState(false)
  const [selectedList, setSelectedList] = useState([])
  const [assigneeTeams, setAssigneeTeams]: any = useState()
  const [assigneesData, setAssigneesData]: any = useState()
  const [teamsData, setTeamsData]: any = useState()
  const [searchQuery, setSearchQuery] = useState('')
  const [initValues, setInitValues]: any = useState(inits)
  const [memberAdded, setMemberAdded] = useState(false)
  const [allChatUsers, setAllChatUsers] = useState([])

  const {chatId, user, workspaceDetail}: any = useSelector<RootState>(
    (state) => ({
      chatId: state.discussions.chatId,
      user: state.auth.user,
      workspaceDetail: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  const {workspaceid}: {workspaceid: string} = useParams()
  const {folderid}: {folderid: string} = useParams()

  useEffect(() => {
    if (assignee) {
      if (!id) {
        setAssigneesData(assignee)
        ;(calledBy === 'workspace' ||
          calledBy === 'internalInitiativeAssignee' ||
          calledBy === 'whoWillUpdate') &&
          setTeamsData(teams)
      }

      setAssigneeTeams(findAssigneeWithTeams(teams, assignee))
    }
  }, [assignee])

  useEffect(() => {
    setSearchQuery('')
  }, [handleClose])

  useEffect(() => {
    setEnableTeams(false)
  }, [assigneesId])

  useEffect(() => {
    if (id && assignee) {
      setSelectedList(combineAssigneesAndTeams(assignee, teams, assigneesId, teamsId))
      setAssigneesData(
        assignee?.filter((assignee: any) => {
          return !assigneesId?.includes(assignee._id)
        })
      )
      ;(calledBy === 'workspace' ||
        calledBy === 'internalInitiativeAssignee' ||
        calledBy === 'whoWillUpdate') &&
        setTeamsData(
          teams.filter((team: any) => {
            return !teamsId?.includes(team._id)
          })
        )
    }
  }, [id, assignee, assigneesId])

  useEffect(() => {
    setSelectedList(combineAssigneesAndTeams(assignee, teams, assigneesId, teamsId))
    setAssigneesData(
      assignee
        ?.filter((assignee: any) => {
          return !assigneesId?.includes(assignee._id)
        })
        .slice()
        .sort((a: any, b: any) => {
          return a.email.localeCompare(b.email)
        })
    )
    ;(calledBy === 'workspace' ||
      calledBy === 'internalInitiativeAssignee' ||
      calledBy === 'whoWillUpdate') &&
      setTeamsData(
        teams?.filter((team: any) => {
          return !teamsId?.includes(team._id)
        })
      )
  }, [calledBy])

  useEffect(() => {
    setInitValues({
      assignee: selectedList
        ?.filter((list: any) => {
          if (!list.isTeam) return list?._id
        })
        .map((list: any) => list._id),
      teams: selectedList
        ?.filter((list: any) => {
          if (list.isTeam) return list?._id
        })
        .map((list: any) => list._id),
    })
  }, [selectedList])

  const handleSearch = (e: any) => {
    setSearchQuery(e.target.value)
  }

  const checkboxHandler = (data: any) => {
    if (data.isTeam) {
      setTeamsData((teamData: any) => {
        return [data, ...teamData]
      })
    } else {
      setAssigneesData((assigneeData: any) => {
        return [data, ...assigneeData]
      })
    }
    setSelectedList(
      selectedList.filter((list: any) => {
        return list._id !== data._id
      })
    )
  }
  useEffect(() => {
    setEnableTeams(false)
  }, [show])

  useEffect(() => {
    socket.emit('userAddeed', allChatUsers)
  }, [memberAdded])
  return (
    <Modal
      id='kt_modal_assignees_list_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-xl h-auto'
      show={show}
      onHide={handleClose}
      className='custom-smart-select-popup '
    >
      <div className='modal-content modal_maintainers'>
        <div className='modal-header bg-white py-4'>
          <div className='d-flex align-items-lg-center justify-content-lg-between w-100 flex-column-reverse flex-lg-row'>
            <div className='d-flex align-items-center mt-5 mt-lg-0'>
              <h4 className='fs-8 fs-sm-6'>{'Select ' + title}</h4>
              <div className='ms-auto ms-sm-10'>
                <form data-kt-search-element='form' className='d-flex bdr-none'>
                  {/* <div className='position-relative bg-white px-3 rounded'>
                    <input
                      type='text'
                      className='form-control form-control-flush py-2 ps-10'
                      name='search'
                      placeholder={
                        calledBy === 'workspace' ||
                        calledBy === 'internalInitiativeAssignee' ||
                        calledBy === 'whoWillUpdate'
                          ? 'Search teams / users'
                          : 'Search users'
                      }
                      onChange={handleSearch}
                    />
                    <span className='svg-icon svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 translate-middle-y ms-0'>
                      <svg
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='mh-50px'
                      >
                        <rect
                          opacity='0.5'
                          x='17.0365'
                          y='15.1223'
                          width='8.15546'
                          height='2'
                          rx='1'
                          transform='rotate(45 17.0365 15.1223)'
                          fill='currentColor'
                        ></rect>
                        <path
                          d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                          fill='currentColor'
                        ></path>
                      </svg>
                    </span>
                  </div> */}

                  {(calledBy === 'workspace' ||
                    calledBy === 'internalInitiativeAssignee' ||
                    calledBy === 'whoWillUpdate') && (
                    <div className='d-flex ms-3'>
                      <label className='d-flex flex-stack cursor-pointer me-7 fs-9'>
                        <span
                          className='form-check form-check-custom form-check-solid'
                          title='Users'
                        >
                          <input
                            name='category'
                            className='form-check-input input_maintain'
                            type='radio'
                            value='1'
                            defaultChecked={!enableTeams ? true : false}
                            onClick={() => {
                              setEnableTeams(false)
                            }}
                          />
                          <span className='fs-7 text-black fw-bold ms-2'>Users</span>
                        </span>
                      </label>
                      <label className='d-flex flex-stack cursor-pointer me-3 fs-9'>
                        <span
                          className='form-check form-check-custom form-check-solid'
                          title='Teams'
                        >
                          <input
                            name='category'
                            className='form-check-input input_maintain'
                            type='radio'
                            value='2'
                            defaultChecked={enableTeams ? true : false}
                            onClick={() => {
                              setEnableTeams(true)
                            }}
                          />
                          <span className='fs-7 text-black fw-bold ms-2'>Teams</span>
                        </span>
                      </label>
                    </div>
                  )}
                </form>
              </div>
            </div>

            <div className='d-flex justify-content-between justify-content-lg-end'>
              <div className='input-group col-sm-2 w-75 form_ui_search'>
                <input
                  type='text'
                  className='form-control form-control-flush py-0  my-0 fs-8'
                  name='search'
                  placeholder={
                    calledBy === 'workspace' ||
                    calledBy === 'internalInitiativeAssignee' ||
                    calledBy === 'whoWillUpdate'
                      ? 'Search'
                      : 'Search users'
                  }
                  onChange={handleSearch}
                />
                <span className='bd-left-none bg-white py-1 px-3 z-indx-0' id='basic-addon2'>
                  <button className='btn bg-white btn-focus-white p-0 m-0'>
                    <a>
                      {' '}
                      {/* <img src='/media/icons/projects/svgexport-7.svg' style={{width: '14px'}} /> */}
                      <KTSVG path='/media/icons/projects/svgexport-7.svg' />
                    </a>
                  </button>
                </span>{' '}
              </div>
              {/* <i className='fa fa-times' aria-hidden='true'></i> */}
              {/* <img src='/media/icons/projects/arr061.svg' /> */}
              <i
                title='Cancel'
                className='bg-transparent border-0 p-0 ms-4 fa fa-times cursor-pointer d-flex align-items-center fs-4 fw-600'
                onClick={() => {
                  if (assigneesId) {
                    // if (id) {
                    setSelectedList(combineAssigneesAndTeams(assignee, teams, assigneesId, teamsId))
                    setAssigneesData(
                      assignee?.filter((assignee: any) => {
                        return !assigneesId?.includes(assignee._id)
                      })
                    )
                    ;(calledBy === 'workspace' ||
                      calledBy === 'internalInitiativeAssignee' ||
                      calledBy === 'whoWillUpdate') &&
                      setTeamsData(
                        teams.filter((team: any) => {
                          return !teamsId?.includes(team._id)
                        })
                      )
                    // }
                  } else {
                    setAssigneesData(assignee)
                    ;(calledBy === 'workspace' ||
                      calledBy === 'internalInitiativeAssignee' ||
                      calledBy === 'whoWillUpdate') &&
                      setTeamsData(teams)
                    setSelectedList([])
                  }

                  setEnableTeams(false)
                  setSearchQuery('')
                  handleClose()
                }}
              >
                {/* <span className='cursor-pointer'>
                  <svg
                    height='24px'
                    viewBox='0 0 24 24'
                    width='24px'
                    fill='#ffffff'
                    xmlns='http://www.w3.org/2000/svg'
                    className='mh-50px'
                  >
                    <rect
                      opacity='0.3'
                      x='2'
                      y='2'
                      width='20'
                      height='20'
                      rx='10'
                      fill='currentColor'
                    ></rect>
                    <rect
                      x='7'
                      y='15.3137'
                      width='12'
                      height='2'
                      rx='1'
                      transform='rotate(-45 7 15.3137)'
                      fill='#ffffff'
                    ></rect>
                    <rect
                      x='8.41422'
                      y='7'
                      width='12'
                      height='2'
                      rx='1'
                      transform='rotate(45 8.41422 7)'
                      fill='#ffffff'
                    ></rect>
                  </svg>
                </span> */}
              </i>
            </div>
          </div>
        </div>

        <Formik
          // validationSchema={createAppSchema}
          enableReinitialize
          initialValues={initValues}
          onSubmit={async (values: any) => {
            ;(calledBy === 'workspace' ||
              calledBy === 'internalInitiativeAssignee' ||
              calledBy === 'whoWillUpdate') &&
              setTeamsId(values.teams)

            if (calledBy === 'discussion') {
              setMemberAdded(true)
              const res = await dispatch(
                discussionActions.updatedGroup(chatId, '', values.assignee)
              )
              setAllChatUsers(res.data.users)

              if (res.status === 200) {
                setMemberAdded(false)
                dispatch(discussionActions.memberAdded(true))
              }
              dispatch(discussionActions.memberAdded(false))

              dispatch(discussionActions.getAllChat(workspaceid, chatType))
              dispatch(discussionActions.getAllMessages(chatId))
            }

            setAssigneesId(values.assignee)
            if (calledBy === 'documentFeature' || calledBy === 'documentApp') {
              const document = {
                sharedWith: values.assignee,
                _id: id,
                showOnFront: '1',
              }
              dispatch(
                actions.ShareFile(
                  document,
                  workspaceid ? workspaceid : null,
                  folderid ? folderid : null
                )
              )
            }

            handleClose()
          }}
        >
          {({setFieldValue, values, errors}) => (
            <Form className='bg-white px-6 mx-0'>
              <div className='modal-body p-0'>
                <div className='flex-row-fluid'>
                  <div className='current w-100' data-kt-stepper-element='content'>
                    <div className='row mx-auto w-100'>
                      <div className='col-lg-auto col-sm-12 px-0 flex-grow-1'>
                        <div className='left px-0'>
                          {enableTeams &&
                            (calledBy === 'workspace' ||
                              calledBy === 'internalInitiativeAssignee' ||
                              calledBy === 'whoWillUpdate') && (
                              <TeamsAccordion
                                teams={teamsData}
                                setTeams={setTeamsData}
                                searchQuery={searchQuery}
                                assigneeWithTeams={assigneeTeams}
                                setSelectedList={setSelectedList}
                                selectedList={selectedList}
                                setSearchQuery={setSearchQuery}
                                group={group}
                              />
                            )}
                          {!enableTeams && (
                            <AssigneeSelector
                              assignees={assigneesData}
                              assigneeWithTeams={assigneeTeams}
                              setSelectedList={setSelectedList}
                              selectedList={selectedList}
                              setAssignees={setAssigneesData}
                              searchQuery={searchQuery}
                              setSearchQuery={setSearchQuery}
                              group={group}
                            />
                          )}
                        </div>
                      </div>
                      <div className='col-lg-auto col-md-12 px-lg-0 flex-grow-0 mt-lg-0 mt-md-4'>
                        <div className='right ms-2'>
                          <div className='aside d-flex'>
                            <div className='d-flex align-items-center  py-3 px-4'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                <div className='flex-grow-1 d-flex'>
                                  <Link
                                    to='#'
                                    className='text-primary-800 text-black fw-bolder fs-7'
                                    title={title}
                                  >
                                    {'Selected ' + title}
                                  </Link>
                                  <span className='text-muted fw-bold d-flex align-items-center fs-7'>
                                    {/* {(calledBy === 'workspace' ||
                                      calledBy === 'internalInitiativeAssignee' ||
                                      calledBy === 'whoWillUpdate') && (
                                      <span className='text-dark fs-8'>
                                        {selectedList?.filter((list: any) => list.isTeam).length}{' '}
                                        teams
                                      </span>
                                    )}
                                    {(calledBy === 'workspace' ||
                                      calledBy === 'internalInitiativeAssignee' ||
                                      calledBy === 'whoWillUpdate') && (
                                      <i className='px-3 fa fa-circle fs-10'>.</i>
                                    )} */}
                                    <span className='ms-2  text_users '>
                                      {selectedList?.filter((list: any) => !list.isTeam).length}{' '}
                                      {/* Users */}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='card-body d-flex flex-wrap h-auto p-0 ms-4 border-grey-lite'>
                              {selectedList?.map((data: any, index: number) => {
                                return (
                                  <div
                                    className='d-flex align-items-center  m-2  tags_ui p-2 justify-content-between'
                                    key={index}
                                  >
                                    <div
                                      className='form-check form-check-sm form-check-custom  border-radius-20px form-check-solid'
                                      onClick={() => {
                                        checkboxHandler(data)
                                      }}
                                    >
                                      {/* <Field
                                        name='assignee'
                                        className='form-check-input'
                                        type='checkbox'
                                        id={index}
                                        defaultValue={data?._id}
                                        checked={true}
                                        // disabled={true}
                                      /> */}
                                      <label
                                        className='form-check-label fw-bolder'
                                        htmlFor='checkBox3'
                                        title={data?.isTeam ? data?.name : data?.fullName}
                                      >
                                        {data?.isTeam ? data?.name : data?.fullName}

                                        {data?.isTeam && (
                                          <span className='text-success'>
                                            {' ' + data?.members?.length}
                                          </span>
                                        )}
                                      </label>
                                      <i
                                        className='fa fa-times text-danger ms-3'
                                        aria-hidden='true'
                                      ></i>
                                    </div>
                                    {/* {data?.isTeam && (
                                      <div className='form-check form-check-sm form-check-custom form-check-solid flex-column align-items-center'>
                                        <i className='fa fa-users fs-6 m-0 text-muted'></i>
                                        <span className='fs-8 text-muted fw-bolder'>
                                          {data?.members?.length} members
                                        </span>
                                      </div>
                                    )} */}
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className='d-flex justify-content-end p-4 bg-white form-check form-check-sm form-check-custom form-check-solid border-radius-25px'>
                        <button
                          title='Cancel'
                          type='reset'
                          className='btn btn-sm  cancel_button fs-7 me-3'
                          // disabled={memberAdded}
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <button
                          title='Confirm'
                          type='submit'
                          className='btn btn-lg btn-blue  fs-7'
                          disabled={memberAdded}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        {/* <div className='d-flex justify-content-end p-4 bg-white form-check form-check-sm form-check-custom form-check-solid border-radius-25px'>
          <button
            title='Confirm'
            type='submit'
            className='btn btn-lg bg-transparent px-9 fs-7'
            disabled={memberAdded}
          >
            Cancel
          </button>
          <button
            title='Confirm'
            type='submit'
            className='btn btn-lg btn-blue px-9  py-2 fs-7'
            disabled={memberAdded}
          >
            Done
          </button>
        </div> */}
      </div>
    </Modal>
  )
}

export {AssigneesList}
