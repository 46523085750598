import React, {useEffect, useState} from 'react'
import '../../../../_metronic/assets/sass/components/release-notes.scss'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers/components/KTSVG'
import {Search} from '../../../../_metronic/layout/search/Search'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/ReleaseNoteAction'
import {DeleteReleaseNote} from './ReleaseNoteDelete'
import {Pagination} from '../../../pagination/pagination'
import {NotesRow} from './NotesRow'
import {GridItem} from './GridItem'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {Link} from 'react-router-dom'
import {Can} from '../../../Common/RBAC/Can'

type Props = {
  className: string
}

type ReleaseNote = [
  {
    _id: string
    title: string
    version: string
    description?: string
    createdBy?: string[]
    createdAt?: string
  }
]

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

export const ReleaseNoteTable: React.FC<Props> = ({className}) => {
  const dispatch = useDispatch()

  const [releaseNoteList, setReleaseNoteList] = useState([{}])
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: '',
    createdAt: '',
  })
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState('')
  const [toggleTodoListing, setToggleTodoListing] = useState(true)

  const releaseNoteData: ReleaseNote = useSelector<RootState>(
    (state) => state.releasenote.entities,
    shallowEqual
  ) as ReleaseNote

  const isLoading: boolean = useSelector<RootState>(
    (state) => state.releasenote.listLoading,
    shallowEqual
  ) as boolean

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.releasenote,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (releaseNoteData.length > 0) {
      setReleaseNoteList(releaseNoteData)
    } else {
      setReleaseNoteList([])
    }
  }, [releaseNoteData])

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`todo-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && releaseNoteList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && releaseNoteList?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }

    dispatch(
      actions.fetchReleaseNote({
        search: '',
        page: '',
        sortby: sortValues.sortby,
        orderby: sortValues.orderby,
        createdAt: sortValues.createdAt,
      })
    )
  }, [sortValues])

  const deleteReleaseNote = (_id: string) => async (event: any) => {
    setItemToDelete(_id)
    setShowDeleteDialog(true)
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchReleaseNote({
        search,
        page: paginationData?.page,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchReleaseNote({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  let crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Release Notes', url: ``},
  ]

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
        createdAt: sortValues.createdAt,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
        createdAt: sortValues.createdAt,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  return (
    <>
      <DeleteReleaseNote
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={itemToDelete}
      />
      <>
        <div className={`mh-80 tabs-main card legend-card-main rounded-0`}>
          {!isLoading && (
            <div className='px-6 px-sm-12'>
              <div className="row">
                <div className="col-sm-6">
                  <h2 className='fw-500 fs-2 m-0 py-5'>
                    Release Notes
                  </h2>
                </div>
                <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center">
                  <Search handleSearch={handleSearch}/>

                  <button
                    type='button'
                    className={`btn btn-sm btn-icon btn-active-light-primary p-2 ${
                      toggleTodoListing ? 'active' : ''
                    }`}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    title='List View'
                    onClick={() => {
                      setToggleTodoListing(true)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/text/txt001.svg'
                      svgClassName={toggleTodoListing ? 'header_icon active' : ''}
                    />
                  </button>
                  
                  <button
                    type='button'
                    className={`btn btn-sm btn-icon btn-active-light-primary p-2 ${
                      toggleTodoListing ? '' : 'active'
                    }`}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    title='Grid View'
                    onClick={() => {
                      setToggleTodoListing(false)
                    }}
                  >
                    <KTSVG
                      path='/media/icons/projects/blocks.svg'
                      svgClassName={!toggleTodoListing ? 'header_icon active' : ''}
                    />
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className='d-flex justify-content-between align-items-center bg-lighter'>
            {crumbs && <Breadcrumbs crumbs={crumbs} />}{' '}
            {/* <Can I='create' this='release-notes'>
              <Link
                className='bg-lighter pe-10'
                to={`/release-notes/add`}
              >
                <span
                  className='btn btn-sm bg-transparent text-blue pe-5 d-flex align-items-center'
                  style={{whiteSpace: 'nowrap'}}
                >
                  <i className='fa fa-plus-circle text-blue' aria-hidden='true'></i>
                  Create Release Note
                </span>
              </Link>
            </Can> */}
          </div>

          {!isLoading && toggleTodoListing && releaseNoteList?.length > 0 ? (
            <>
            <div className="overflow_height_scroll">
              <div className='card-body d-flex flex-column py-3 px-10'>
                <div className='table-responsive'>
                  <table className='todo-table table table-row-dashed align-middle gs-0 gy-4'>
                    <thead className='tableHeading'>
                      <tr className='fw-bolder text-muted border-bottom'>
                        <th
                          // className='min-w-120px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='title'
                          id='note-title-head'
                        >
                          Title
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='version'
                          id='note-version-head'
                        >
                          Version
                        </th>
                        <th
                          className='min-w-120px table-sort-desc'
                          style={{cursor: 'pointer'}}
                          onClick={sortColumn}
                          abbr='createdAt'
                          id='note-createdAt-head'
                        >
                          Release Date
                        </th>
                        <th
                          className='min-w-120px'
                          style={{cursor: 'pointer'}}
                          // onClick={sortColumn}
                          abbr='actions'
                          id='note-actions-head'
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className='tableRow'>
                      {releaseNoteList?.map((releaseNote: any, index: number) => {
                        return (
                          <NotesRow
                            key={index}
                            index={index}
                            releaseNote={releaseNote}
                            deleteReleaseNote={deleteReleaseNote}
                          />
                        )
                      })}
                    </tbody>
                  </table>
                  {/* end::Table */}
                </div>
              </div>
            </div>
              <Pagination paginationData={paginationData} fetchData={handlePagination} />
            </>
          ) : !isLoading && !toggleTodoListing && releaseNoteList?.length > 0 ? (
            <>
            <div className="overflow_height_scroll">
              <div className='card card-body p-0 mt-5 mx-2 mx-sm-10'>
                <div className='row drag-drop-card-main g-4 pb-0'>
                  {releaseNoteList?.map((releaseNote: any, index: number) => {
                    return (
                      <GridItem
                        index={index}
                        releaseNote={releaseNote}
                        deleteReleaseNote={deleteReleaseNote}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
              <Pagination paginationData={paginationData} fetchData={handlePagination} />
            </>
          ) : !isLoading && releaseNoteList?.length <= 0 ? (
            <NotFound />
          ) : (
            <Loading />
          )}

          {/* begin::Body */}
        </div>
      </>
    </>
  )
}
