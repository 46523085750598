import React from 'react'
import {WorkspaceTable} from './components/WorkspaceTable'
import {WorkspaceEdit} from './components/WorkspaceEdit'


import {Route, Switch} from 'react-router-dom'

const Workspace: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/space'>
          <WorkspaceTable />
        </Route>
        <Route exact path='/space/add'>
          {({history}) => (
            <WorkspaceEdit
              onHide={() => {
                history.push('/dashboard')
              }}
            />
          )}
        </Route>
        <Route exact path='/space/:id/edit'>
          {({history, match}) => (
            <WorkspaceEdit
              id={match && match.params.id}
              onHide={() => {
                history.push('/dashboard')
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  )
}

export {Workspace}
