import React, {Suspense, useEffect, useRef, useState} from 'react'
import {Link} from 'react-router-dom'
import {useDrag, useDrop} from 'react-dnd'
import axios from 'axios'
import {KTSVG} from '../../../_metronic/helpers'
import { modifyDashboardIconsURL } from '../../../_metronic/helpers/modifyDashboardIconsURL'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../../setup'

const API_URL = process.env.REACT_APP_API_URL
const SET_DASHBOARD_ICONS = `${API_URL}/dashboard/icon-order`

type Props = {
  dashboardIconTitle: string
  dashboardIconId: string
  moveDashboardIcons: any
  index: number
  spaceType: number
  dashboardIcons: any
  rowDimension: any
}

const WorkspaceListing: React.FC<Props> = ({
  dashboardIconTitle,
  dashboardIconId,
  moveDashboardIcons,
  index,
  spaceType,
  dashboardIcons,
  rowDimension,
}) => {
  // useDrag - the list item is draggable
  const [dashIconType, setdashIconType] = useState("")
  const [{isDragging, item}, dragRef] = useDrag({
    type: 'item',
    item: () => ({index, dashboardIconId}),
    collect: (monitor) => ({
      item: monitor.getItem(),
      isDragging: monitor.isDragging(),
    }),
  })

  const { settings}: any = useSelector<RootState>(
    (state) => ({
      settings: state.settings.entities,
    }),
    shallowEqual
  )


  useEffect(()=>{
    const iconType = settings.filter((data : any,index: any)=>{
      return (data.name === "dashMediaIcons")
    })
    setdashIconType(iconType[0]?.value);
  },[settings])


  // useDrop - the list item is also a drop area
  const [spec, dropRef] = useDrop({
    accept: 'item',
    hover: (item: any, monitor: any) => {
      const dragIndex = item.index
      const hoverIndex = index
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top

      // if dragging down, continue only when hover is smaller than middle Y
      if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return
      // if dragging up, continue only when hover is bigger than middle Y
      if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return

      moveDashboardIcons(dragIndex, hoverIndex)
      item.index = hoverIndex
    },
    drop: () => {
      axios.post(SET_DASHBOARD_ICONS, {dashboardIcons: dashboardIcons}).then((response) => {})
    },
  })

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const ref: any = useRef(null)
  const dragDropRef: any = dragRef(dropRef(ref))

  // Make items being dragged transparent, so it's easier to see where we drop them
  const opacity = isDragging && item.dashboardIconId === dashboardIconId ? 0 : 1
  return (
    <div className='demo-icons'>
      <div className='col rounded-2'>
        <div className=' card-item rounded-2'>
          <Link ref={dragDropRef} to={`/${dashboardIconId}/features`} style={{opacity}}>
            {spaceType === 0 ? (
              <div
                className='icon'
                style={{
                  // width: rowDimension + 'px',
                  // height: rowDimension + 'px',
                  backgroundColor: '#0368CD',
                }}
              >
                <KTSVG 
                //group space
                path={modifyDashboardIconsURL(dashIconType, '/media/icons/dashboard/{iconType}/group-space.svg')} 
                noClass={true} 
                />
              </div>
            ) : (
              <div
                className='icon'
                style={{
                  // width: rowDimension + 'px',
                  // height: rowDimension + 'px',
                  backgroundColor: '#0368CD',
                }}
              >
                <KTSVG 
                //personal space
                path={modifyDashboardIconsURL(dashIconType, '/media/icons/dashboard/{iconType}/my-space.svg')} 
                noClass={true} 
                />
              </div>
            )}
            <div className='title'>
              <span>
                <h6 className='masonry-title'> {dashboardIconTitle}</h6>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export {WorkspaceListing}
