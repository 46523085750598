/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import clsx from 'clsx'
import '../../../_metronic/assets/sass/components/cards.scss'
import '../../../_metronic/assets/sass/components/wizards.scss'
import {Link} from 'react-router-dom'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'

const Cards: FC = () => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('vteamsTab1')
  return (
    <>
      {/* <Toolbar /> */}
      <section className='tabs-main mb-5 pb-5'>
        <div className='container px-0'>
          <div className='card card-custom'>
            <div className='card-body p-0'>
              <div className='stepper stepper-main'>
                <div className='stepper-nav'>
                  <ul className='stepper-steps nav' role='tablist'>
                    <li className='nav-item stepper-step active'>
                      <a
                        className={clsx(`nav-link cursor-pointer stepper-wrapper m-0`, {
                          active: tab === 'vteamsTab1',
                        })}
                        onClick={() => setTab('vteamsTab1')}
                        role='tab'
                      >
                        <div className='stepper-label'>
                          <div className='stepper-title'>Personal workspace</div>
                          <div className='stepper-desc'>Workspace description</div>
                        </div>
                      </a>
                    </li>
                    <li className='nav-item stepper-step'>
                      <a
                        className={clsx(`nav-link cursor-pointer stepper-wrapper m-0`, {
                          active: tab === 'vteamsTab2',
                        })}
                        onClick={() => setTab('vteamsTab2')}
                        role='tab'
                      >
                        <div className='stepper-label'>
                          <div className='stepper-title'>vteams workspace</div>
                          <div className='stepper-desc'>Workspace description</div>
                        </div>
                      </a>
                    </li>
                    <li className='nav-item stepper-step'>
                      <a
                        className={clsx(`nav-link cursor-pointer stepper-wrapper m-0`, {
                          active: tab === 'vteamsTab3',
                        })}
                        onClick={() => setTab('vteamsTab3')}
                        role='tab'
                      >
                        <div className='stepper-label'>
                          <div className='stepper-title'>Nextbridge workspace</div>
                          <div className='stepper-desc'>Workspace description</div>
                        </div>
                      </a>
                    </li>
                    <li className='nav-item stepper-step'>
                      <a
                        className={clsx(`nav-link cursor-pointer stepper-wrapper m-0`, {
                          active: tab === 'vteamsTab4',
                        })}
                        onClick={() => setTab('vteamsTab4')}
                        role='tab'
                      >
                        <div className='stepper-label'>
                          <div className='stepper-title'>Personal workspace</div>
                          <div className='stepper-desc'>Workspace description</div>
                        </div>
                      </a>
                    </li>
                    <li className='nav-item stepper-step'>
                      <a
                        className={clsx(`nav-link cursor-pointer stepper-wrapper m-0`, {
                          active: tab === 'vteamsTab5',
                        })}
                        onClick={() => setTab('vteamsTab5')}
                        role='tab'
                      >
                        <div className='stepper-label'>
                          <div className='stepper-title'>vteams workspace</div>
                          <div className='stepper-desc'>Workspace description</div>
                        </div>
                      </a>
                    </li>
                    <li className='nav-item stepper-step'>
                      <a
                        className={clsx(`nav-link cursor-pointer stepper-wrapper m-0`, {
                          active: tab === 'vteamsTab6',
                        })}
                        onClick={() => setTab('vteamsTab6')}
                        role='tab'
                      >
                        <div className='stepper-label'>
                          <div className='stepper-title'>Nextbridge workspace</div>
                          <div className='stepper-desc'>Workspace description</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='tab-content'>
                <div className={clsx('tab-pane pb-5', {active: tab === 'vteamsTab1'})}>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 2'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 2</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 3'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 3</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 4'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 4</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 5'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 5</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 6'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 6</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 7'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 7</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 8'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 8</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 9'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 9</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 10'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 10</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 11'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 11</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 12'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 12</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={clsx('tab-pane pb-5', {active: tab === 'vteamsTab2'})}>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={clsx('tab-pane pb-5', {active: tab === 'vteamsTab3'})}>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 2'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 2</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 3'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 3</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={clsx('tab-pane pb-5', {active: tab === 'vteamsTab4'})}>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={clsx('tab-pane pb-5', {active: tab === 'vteamsTab5'})}>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 2'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 2</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 3'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 3</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={clsx('tab-pane pb-5', {active: tab === 'vteamsTab6'})}>
                  <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                    <div className='col rounded-2' title='Todo'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <Link to='/todo' className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Todo</h6>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Master'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/custom-master.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Master</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/cutomer-onboarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Resource Onboarding'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/resource-onborarding.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Resource Onboarding</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Customer Invoices'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/customer-invoices.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Customer Invoices</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Reports'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/reports.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Reports</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className='col rounded-2' title='Feature 1'>
                      <div className='card-item mx-4 my-4 rounded-2'>
                        <a className='' href='#'>
                          <div className='icon'>
                            <img src='./media/icons/list.png' alt='icon' />
                          </div>
                          <div className='title'>
                            <h6>Feature 1</h6>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export {Cards}
