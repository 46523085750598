import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const BOARDCOLUMNS = `${API_URL}/boards/columns`
const BOARDTASK = `${API_URL}/boards/task`
const BOARD = `${API_URL}/boards`

export function getBoardById(id: string) {
  return axios.get(`${BOARD}/${id}`)
}

export function createColumn(obj: {
  name: string
  iconUrl?: string
  workSpace?: string
  board?: string
  project: string
}) {
  return axios.post(`${BOARDCOLUMNS}`, obj)
}

export function createTask(obj: {
  title: string
  description?: string
  reporter?: string
  assignee?: []
  type?: string
  status?: string
  priority?: string
  board?: string
  project: string
}) {
  return axios.post(`${BOARDTASK}`, obj)
}

export function getSingleTask(id: string) {
  return axios.get(`${BOARDTASK}/${id}`)
}

export function updateColumn(obj: {
  _id: string
  name: string
  iconUrl?: string
  workSpace?: any
  board: string
  project: string
}) {
  return axios.patch(`${BOARDCOLUMNS}/${obj._id}`, obj)
}

export function getColumnByProId(id: string) {
  return axios.get(`${BOARDCOLUMNS}/${id}`)
}

export function deleteColumn(id: string) {
  return axios.delete(`${BOARDCOLUMNS}/${id}`)
}

export function getAllTaksByProjectId(obj: {project: string}) {
  return axios.get(`${BOARDTASK}`, {
    params: obj,
  })
}

export function moveTaskFromOneColumnToAnother(deleteColumnId: string, moveColumnId: string) {
  return axios.patch(`${BOARDTASK}/move/bystatus/${deleteColumnId}/${moveColumnId}`)
}

export function boardSummaryCount(id: string) {
  return axios.get(`${BOARDTASK}/summary/${id}`)
}

export function boardSummaryActivity(id: string, page: number) {
  return axios.get(`${API_URL}/activities/byparent/${id}?page=${page}&per_page=5`)
}

export function sortColumns(ids: string[]) {
  return axios.patch(`${BOARDCOLUMNS}/order`, ids)
}

export function updateTask(data: any, workSpaceId: string) {
  return axios.patch(`${BOARDTASK}/update?workSpace=${workSpaceId}`, data)
}

export function sortTasksOrder(ids: string[]) {
  return axios.patch(`${BOARDTASK}/order`, ids)
}
