/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useRef, useEffect} from 'react'
import {Link} from 'react-router-dom'
import noUiSlider from 'nouislider'
import clsx from 'clsx'

import 'nouislider/dist/nouislider.css'

// import {PageTitle} from '../../../_metronic/layout/core'
import {Cards} from '../../modules/Cards/Cards'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLayout} from '../../../_metronic/layout/core'
import {Header} from '../../../_metronic/layout/components/header/Header'
import {Footer} from '../../../_metronic/layout/components/Footer'
const DashboardDemo: FC = () => {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config

  const [row1Dimension, setRow1Dimension] = useState('62')
  const [row2Dimension, setRow2Dimension] = useState('120')
  const [row3Dimension, setRow3Dimension] = useState('152')

  const convertSize = (size: string) => {
    let integerNumber = parseInt(size)
    let halfSize = JSON.stringify(integerNumber / 2)
    return halfSize + 'px'
  }

  const sliderRef1 = useRef(null)
  const sliderRef2 = useRef(null)
  const sliderRef3 = useRef(null)

  let sliderValue1: any
  let sliderValue2: any
  let sliderValue3: any

  useEffect(() => {
    if (sliderRef1.current) {
      sliderValue1 = sliderRef1.current
      noUiSlider.create(sliderValue1, {
        start: row1Dimension,
        connect: true,
        range: {
          min: 30,
          max: 330,
        },
      })
      sliderValue1?.noUiSlider.on('slide', function (values: any) {
        const size = Math.floor(parseInt(values[0])).toString()
        setRow1Dimension(size)
      })
    }
    if (sliderRef2.current) {
      sliderValue2 = sliderRef2.current
      noUiSlider.create(sliderValue2, {
        start: row2Dimension,
        connect: true,
        range: {
          min: 30,
          max: 330,
        },
      })
      sliderValue2?.noUiSlider.on('slide', function (values: any) {
        const size = Math.floor(parseInt(values[0])).toString()
        setRow2Dimension(size)
      })
    }
    if (sliderRef3.current) {
      sliderValue3 = sliderRef3.current
      noUiSlider.create(sliderValue3, {
        start: row3Dimension,
        connect: true,
        range: {
          min: 30,
          max: 330,
        },
      })
      sliderValue3?.noUiSlider.on('slide', function (values: any) {
        const size = Math.floor(parseInt(values[0])).toString()
        setRow3Dimension(size)
      })
    }
  }, [])

  return (
    <>
      <div className='page d-flex flex-row flex-column-fluid pt-6'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <div
            id='kt_header'
            className={clsx('header border-0', classes.header.join(' '), 'align-items-stretch')}
            data-kt-sticky='true'
            data-kt-sticky-name='header'
            data-kt-sticky-offset="{default: '200px', lg: '300px'}"
            {...attributes.headerMenu}
          >
            <div
              className={clsx(
                classes.headerContainer.join(' '),
                'd-flex justify-content-between align-items-center w-100'
              )}
            >
              {/* begin::Aside mobile toggle */}
              {aside.display && (
                <div
                  className='d-flex align-items-center d-lg-none ms-n3 me-1'
                  title='Show aside menu'
                >
                  <div
                    className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                    id='kt_aside_mobile_toggle'
                  >
                    <KTSVG
                      path='/media/icons/duotune/abstract/abs015.svg'
                      className='svg-icon-2x mt-1'
                    />
                  </div>
                </div>
              )}
              {/* end::Aside mobile toggle */}

              {/* <div
          className='d-flex topbar align-items-center d-lg-none ms-n2 me-3'
          title='Show aside menu'
        >
          <div
            className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
            id='kt_header_menu_mobile_toggle'
            style={{display: 'none'}}
          >
            <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-2x' />
          </div>
        </div> */}

              <div className='header-logo me-3 me-md-3 flex-grow-1 flex-lg-grow-0'>
                <Link to='/'>
                  {/* <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-4.png')}
              className='logo-default h-33px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/logo-2.png')}
              className='logo-sticky h-33px'
            /> */}
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-4.svg')}
                    className='logo-default h-65px'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/logos/logo-2.svg')}
                    className='logo-sticky h-50px'
                  />
                  {/* <img alt="Logo" src="/groupcaptain/media/logos/logo-5.png" class="logo-sticky h-25px"></img> */}
                </Link>
              </div>

              {/* begin::Wrapper */}
              <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
                {/* begin::Navbar */}
                {header.left === 'menu' && (
                  <div className='d-flex align-items-stretch' id='kt_header_nav'>
                    {/* <Header /> */}
                  </div>
                )}

                {header.left === 'page-title' && (
                  <div className='d-flex align-items-center' id='kt_header_nav'>
                    {/* <DefaultTitle /> */}
                  </div>
                )}

                <div className='d-flex align-items-stretch flex-shrink-0'></div>
              </div>
              {/* end::Wrapper */}
            </div>
          </div>

          <div id='kt_content' className='content d-flex flex-column flex-column-fluid mt-5 mb-10'>
            <div className='d-flex flex-column-fluid align-items-start container' id='kt_post'>
              <div id='kt_content_container' className={clsx('content flex-row-fluid')}>
                <section className='tabs-main mb-5 pb-5'>
                  <div className='container px-0'>
                    <div className='card card-custom'>
                      <div className='card-body p-0'>
                        <div className='stepper stepper-main'>
                          <div className='stepper-nav'></div>
                        </div>
                        <div className='tab-content'>
                          <div className='tab-pane tab-pane2 pb-5 active rounded'>
                            <div className='row breadcrumb-main mx-10'>
                              <div className='col-sm-10 col-9 px-0 align-items-center d-flex mt-0'>
                                <ul className='breadcrumb breadcrumb-transparent breadcrumb-lessthan p-0 mb-0 font-size-sm'>
                                  <li className='breadcrumb-item text-active'>
                                    <a href='/dashboard'>Dashboard</a>
                                  </li>
                                  <li className='breadcrumb-item text-active'>
                                    <a href='/spaces'>Spaces</a>
                                  </li>
                                  <li className='breadcrumb-item text-muted'>My Space</li>
                                </ul>
                              </div>
                              <div className='col-sm-2 col-3 px-0 justify-content-end d-flex'>
                                <button
                                  className='btn btn-primary position-relative py-2 px-5 fw-bolder fs-7 d-flex align-items-center'
                                  style={{height: '31px', minWidth: '72px'}}
                                >
                                  <i className='fa fa-angle-left'></i> Back
                                </button>
                              </div>
                            </div>
                            <div className='row demo-icons one mx-auto g-4'>
                              <div className='col rounded-2' title='Todo'>
                                <div className='card-item mx-4 my-4 rounded-2'>
                                  <a className='' href='#'>
                                    <div
                                      className='icon'
                                      style={{
                                        width: row1Dimension + 'px',
                                        height: row1Dimension + 'px',
                                      }}
                                    >
                                      {/* <img
                              src='./media/icons/list.svg'
                              alt='icon'
                              style={{
                                width: convertSize(row1Dimension),
                                height: convertSize(row1Dimension),
                              }}
                            /> */}
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='398'
                                        height='500'
                                        viewBox='0 0 398 500'
                                      >
                                        <g id='_3' data-name='3' transform='translate(-0.001)'>
                                          <g id='c' transform='translate(0.001 0)'>
                                            <g id='Group_27' data-name='Group 27'>
                                              <path
                                                id='Path_48'
                                                data-name='Path 48'
                                                d='M392.17,512c-9.56-2.68-19.65-4.19-28.58-8.25-31.52-14.34-50.36-38.91-55.48-73.36-7.22-48.59,24.96-93.5,71.86-103.88,51.82-11.47,103.75,23.31,113.01,75.74a93.144,93.144,0,0,1-79.05,108.86,16.528,16.528,0,0,0-2.77.89h-19ZM371.43,397.4c-7.92,8.21-15.96,16.55-24.29,25.17,9.5,9.67,19.37,19.77,29.29,29.82,9.46,9.58,19.6,9.54,29.07-.14q23.055-23.565,46-47.23c1.01-1.04,1.94-2.18,2.19-2.47q-12.2-13.005-23.49-25.06c-13.57,12.76-26.83,25.23-40.43,38.03-5.81-5.74-12.06-11.91-18.35-18.12Z'
                                                transform='translate(-96.444 -12.09)'
                                              />
                                              <path
                                                id='Path_49'
                                                data-name='Path 49'
                                                d='M271.17,0c11.76,4.08,16.36,12.54,15.75,24.69-.4,7.97.13,15.99.08,23.99-.09,13.49-7.99,21.61-21.32,21.63q-66.735.1-133.48,0c-13.31-.03-21.01-7.97-21.02-21.2,0-7.83.45-15.7-.13-23.49-.9-12.27,3.43-21.01,15.13-25.62h145Z'
                                                transform='translate(-0.222 0)'
                                              />
                                              <path
                                                id='Path_50'
                                                data-name='Path 50'
                                                d='M80.21,18.13V53.1H48.64Q34.9,53.1,34.9,66.981V454.338c0,9.968.79,10.748,10.88,10.748H213.66V499.77c-1.64.07-3.26.21-4.88.21-78.32.01-87.64.04-165.96,0C21.03,499.97,4.51,486.742.63,466.306a51.335,51.335,0,0,1-.61-9.448Q-.01,259.165.02,61.492C.02,35.3,17.36,18.15,43.67,18.13c11.97-.01,23.95,0,36.54,0Z'
                                                transform='translate(-0.001 0)'
                                              />
                                              <path
                                                id='Path_51'
                                                data-name='Path 51'
                                                d='M398.05,317.49H363.02V63.52c0-8.98-1.39-10.36-10.43-10.36H320.47V18.52c1.29-.13,2.56-.37,3.83-.37,10.83-.03,21.66-.14,32.49.01,23.06.31,40.64,17.76,41.22,40.87.18,7.33.04,14.66.04,21.99V317.5Z'
                                                transform='translate(-0.444 0)'
                                              />
                                              <path
                                                id='Path_52'
                                                data-name='Path 52'
                                                d='M59.85,177.08c8.66-8.03,16.83-15.6,25.51-23.66,7.09,7.7,14.02,15.22,21.2,23.02,16.82-18.31,33.36-36.33,50.23-54.69,8.58,7.87,17,15.6,25.76,23.64-5.32,5.83-10.43,11.46-15.58,17.07q-22.635,24.66-45.32,49.3c-9.46,10.24-20.76,10.24-30.2-.03-10.47-11.38-20.84-22.85-31.6-34.65Z'
                                                transform='translate(-0.097 -0.027)'
                                              />
                                              <path
                                                id='Path_53'
                                                data-name='Path 53'
                                                d='M59.85,283.48c8.61-7.9,16.93-15.53,25.52-23.42,7.16,7.74,14.09,15.25,21.13,22.85,16.87-18.39,33.4-36.4,50.27-54.78,8.57,7.84,17,15.56,25.81,23.61-6.99,7.65-13.78,15.13-20.64,22.57q-20.31,22.035-40.69,44c-9.14,9.82-20.37,9.77-29.43-.06-10.6-11.49-21.15-23.01-31.96-34.77Z'
                                                transform='translate(-0.097 -0.051)'
                                              />
                                              <path
                                                id='Path_54'
                                                data-name='Path 54'
                                                d='M59.84,388.66c8.68-7.93,17.01-15.55,25.57-23.37,7.17,7.78,14.02,15.22,21.17,22.98,16.81-18.34,33.33-36.36,50.26-54.84,8.51,7.78,16.93,15.48,25.66,23.46-7.26,7.91-14.3,15.6-21.36,23.28q-19.8,21.525-39.62,43.02c-9.52,10.31-20.67,10.22-30.13-.13-10.33-11.31-20.7-22.58-31.55-34.4Z'
                                                transform='translate(-0.097 -0.09)'
                                              />
                                              <path
                                                id='Path_55'
                                                data-name='Path 55'
                                                d='M205.18,197.45V163.06H330.92v34.39Z'
                                                transform='translate(-0.335 -6.03)'
                                              />
                                              <path
                                                id='Path_56'
                                                data-name='Path 56'
                                                d='M331.06,302.86H205.22V268.47H331.06Z'
                                                transform='translate(-0.335 -7.051)'
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className='title'>
                                      <h6>Todo</h6>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                className='row demo-icons one mx-auto g-4'
                                style={{marginTop: '10px'}}
                              >
                                <div className='col rounded-2' title='Todo'>
                                  <div className='card-item mx-4 my-4 rounded-2'>
                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                      <span className=''>Size</span>
                                    </label>

                                    <input
                                      type='number'
                                      className='form-control form-control-lg form-control-solid w-50'
                                      name='row1'
                                      value={row1Dimension}
                                      placeholder={row1Dimension}
                                      style={{minHeight: '41px', maxHeight: '41px'}}
                                      onChange={(e) => {
                                        setRow1Dimension(e.target.value)
                                        // setValueSlider(e.target.value, 1)
                                      }}
                                      max='330'
                                      min='30'
                                    />
                                    <div
                                      id='slider1'
                                      ref={sliderRef1}
                                      className='noUi-sm mt-4 w-50'
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row demo-icons one mx-auto g-4'>
                              <div className='col rounded-2' title='Todo'>
                                <div className='card-item mx-4 my-4 rounded-2'>
                                  <a className='' href='#'>
                                    <div
                                      className='icon'
                                      style={{
                                        width: row2Dimension + 'px',
                                        height: row2Dimension + 'px',
                                      }}
                                    >
                                      {/* <svg
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 512 512'
                              style={{
                                width: convertSize(row2Dimension),
                                height: convertSize(row2Dimension),
                              }}
                            >
                              <g id='a' />
                              <g id='b'>
                                <g id='c'>
                                  <g>
                                    <path d='M379,0c9.53,4.1,12.39,11.45,12.28,21.57-.4,38.15-.15,76.3-.17,114.46,0,13.2-5.99,19.16-19.23,19.21-3.8,.01-7.6,0-12.13,0,0,7.05,.07,13.64-.03,20.24-.05,3.82,.14,7.74-.67,11.42-2.16,9.87-11.84,14.87-21.22,11.16-2.45-.97-4.74-2.41-6.95-3.87-18.16-12.07-36.24-24.27-54.49-36.23-2.33-1.53-5.44-2.52-8.22-2.6-9.65-.26-19.93-2.06-28.8,.61-8.86,2.66-16.34,9.95-24.38,15.28-11.93,7.91-23.88,15.81-35.71,23.88-6,4.09-12.13,5.41-18.73,1.85-6.59-3.56-8.94-9.36-8.74-16.66,.23-8.12,.05-16.26,.05-25.08-4.55,0-8.66,.03-12.77,0-11.92-.11-18.21-6.35-18.22-18.2-.03-38.65,.23-77.31-.18-115.96-.11-10.05,3.11-17.01,12.28-21.08h246Zm-20.64,33.14H153.81V122.62c4.2,0,8-.03,11.81,0,12.53,.11,18.71,6.26,18.79,18.7,.02,3.34,0,6.69,0,11.1,13.66-9.12,25.96-17.65,38.63-25.6,3.61-2.27,8.25-3.86,12.48-4.01,13.47-.5,26.99-.47,40.46-.01,4.09,.14,8.59,1.57,12.07,3.74,11.44,7.12,22.49,14.86,33.7,22.33,1.46,.97,3.02,1.8,5.24,3.11,0-3.9-.04-7.01,0-10.11,.21-13.2,6.25-19.19,19.37-19.25,3.93-.02,7.85,0,11.99,0V33.14Z' />
                                    <path d='M177,512c-1.01-.47-2-1.02-3.04-1.4-11.51-4.21-18.74-13.96-18.8-26.26-.14-29.83-.14-59.66,0-89.5,.06-12.56,7.4-22.4,19.34-26.27,11.87-3.85,23.78-7.57,35.75-11.08,13.09-3.83,25.65,1.46,32.26,13.47,4.42,8.01,8.58,16.16,12.86,24.25,.07,.13,.25,.19,.76,.55,.75-1.2,1.56-2.35,2.21-3.58,3.73-7.06,7.31-14.21,11.16-21.21,6.49-11.82,18.95-17.3,31.78-13.62,12.31,3.53,24.53,7.38,36.68,11.42,11.73,3.9,18.83,13.66,18.89,26.07,.14,29.83,.14,59.67,0,89.5-.06,12.34-7.26,22.02-18.8,26.25-1.04,.38-2.03,.93-3.04,1.41H177Zm119.23-122.16c-8.48,16.24-16.7,31.83-24.77,47.5-3.31,6.42-8.02,10.72-15.51,10.71-7.5-.02-12.19-4.36-15.46-10.78-6.04-11.86-12.24-23.63-18.41-35.43-2.06-3.94-4.24-7.82-6.5-11.97-8.12,2.56-15.83,5.2-23.66,7.36-3.42,.94-4.29,2.55-4.27,5.94,.15,23.83,.07,47.65,.09,71.48,0,1.44,.2,2.88,.32,4.45h135.46c.29-.56,.57-.85,.57-1.13,.06-25.66,.14-51.31,.05-76.97,0-1.09-1.25-2.81-2.28-3.15-8.34-2.77-16.77-5.27-25.64-8Z' />
                                    <path d='M0,325c2-7.5,3.4-15.24,6.16-22.45,2.57-6.71,6.6-12.85,10.32-19.87,9.3,7.33,17.62,13.88,25.9,20.41-10.16,16.48-10.37,23.28-9.34,46.48h98.42c.87-16.32,1.07-32.22-9.79-46.21,8.57-6.77,16.87-13.34,25.51-20.16,8.53,10.98,14.29,22.81,15.67,36.05,1.29,12.37,1.55,24.96,.98,37.39-.67,14.31-13.56,25.77-28.84,25.9-35.16,.31-70.33,.27-105.5,.02-14.05-.1-24.55-8.76-28.53-22.31-.23-.78-.64-1.51-.96-2.26,0-11,0-22,0-33Z' />
                                    <path d='M512,358c-.33,.75-.74,1.47-.97,2.24-4.02,13.75-14.63,22.28-29,22.36-34.82,.19-69.64,.23-104.46-.02-17.17-.12-29.59-12.76-29.72-29.84-.11-14.45-1.2-29.02,3.29-43.14,2.94-9.26,7.01-17.92,13.38-25.94,8.53,6.64,16.89,13.15,25.61,19.94-10.69,13.82-10.51,29.69-9.58,46.05h98.44c.99-16.31,1.35-32.32-9.64-46.28,8.56-6.76,16.87-13.31,25.53-20.14,8.8,11.21,13.75,23.5,16.1,36.97,.28,1.61,.68,3.21,1.02,4.81v33Z' />
                                    <path d='M255.9,224.6c31.88-.08,57.97,25.84,58.09,57.7,.12,32.11-25.92,58.45-57.88,58.52-31.91,.07-58.15-26.27-58.08-58.31,.07-31.91,25.97-57.83,57.87-57.91Z' />
                                    <path d='M374.88,243.7c-.03-30.61,24.88-55.61,55.19-55.38,30.26,.23,54.86,25.02,54.88,55.29,.02,29.97-25.05,55.06-55.05,55.1-30,.04-54.99-24.96-55.01-55.02Zm55.05-22.63c-12.58-.04-22.53,9.86-22.58,22.48-.05,12.55,9.96,22.67,22.4,22.64,12.68-.03,22.52-9.85,22.51-22.47,0-12.74-9.75-22.61-22.33-22.65Z' />
                                    <path d='M137.12,243.77c-.06,30.1-25.08,55.06-55.08,54.96-30.03-.1-55.04-25.2-54.98-55.17,.06-30.27,24.74-55.08,54.95-55.24,30.35-.16,55.18,24.81,55.12,55.45Zm-77.39-.4c-.07,12.77,9.54,22.66,22.17,22.82,12.52,.16,22.57-9.69,22.74-22.31,.18-12.56-9.71-22.7-22.24-22.82-12.61-.12-22.61,9.72-22.67,22.3Z' />
                                    <path d='M190.91,93.73V61.44h129.97v32.29H190.91Z' />
                                  </g>
                                </g>
                              </g>
                            </svg> */}
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='398'
                                        height='500'
                                        viewBox='0 0 398 500'
                                      >
                                        <g id='_3' data-name='3' transform='translate(-0.001)'>
                                          <g id='c' transform='translate(0.001 0)'>
                                            <g id='Group_27' data-name='Group 27'>
                                              <path
                                                id='Path_48'
                                                data-name='Path 48'
                                                d='M392.17,512c-9.56-2.68-19.65-4.19-28.58-8.25-31.52-14.34-50.36-38.91-55.48-73.36-7.22-48.59,24.96-93.5,71.86-103.88,51.82-11.47,103.75,23.31,113.01,75.74a93.144,93.144,0,0,1-79.05,108.86,16.528,16.528,0,0,0-2.77.89h-19ZM371.43,397.4c-7.92,8.21-15.96,16.55-24.29,25.17,9.5,9.67,19.37,19.77,29.29,29.82,9.46,9.58,19.6,9.54,29.07-.14q23.055-23.565,46-47.23c1.01-1.04,1.94-2.18,2.19-2.47q-12.2-13.005-23.49-25.06c-13.57,12.76-26.83,25.23-40.43,38.03-5.81-5.74-12.06-11.91-18.35-18.12Z'
                                                transform='translate(-96.444 -12.09)'
                                              />
                                              <path
                                                id='Path_49'
                                                data-name='Path 49'
                                                d='M271.17,0c11.76,4.08,16.36,12.54,15.75,24.69-.4,7.97.13,15.99.08,23.99-.09,13.49-7.99,21.61-21.32,21.63q-66.735.1-133.48,0c-13.31-.03-21.01-7.97-21.02-21.2,0-7.83.45-15.7-.13-23.49-.9-12.27,3.43-21.01,15.13-25.62h145Z'
                                                transform='translate(-0.222 0)'
                                              />
                                              <path
                                                id='Path_50'
                                                data-name='Path 50'
                                                d='M80.21,18.13V53.1H48.64Q34.9,53.1,34.9,66.981V454.338c0,9.968.79,10.748,10.88,10.748H213.66V499.77c-1.64.07-3.26.21-4.88.21-78.32.01-87.64.04-165.96,0C21.03,499.97,4.51,486.742.63,466.306a51.335,51.335,0,0,1-.61-9.448Q-.01,259.165.02,61.492C.02,35.3,17.36,18.15,43.67,18.13c11.97-.01,23.95,0,36.54,0Z'
                                                transform='translate(-0.001 0)'
                                              />
                                              <path
                                                id='Path_51'
                                                data-name='Path 51'
                                                d='M398.05,317.49H363.02V63.52c0-8.98-1.39-10.36-10.43-10.36H320.47V18.52c1.29-.13,2.56-.37,3.83-.37,10.83-.03,21.66-.14,32.49.01,23.06.31,40.64,17.76,41.22,40.87.18,7.33.04,14.66.04,21.99V317.5Z'
                                                transform='translate(-0.444 0)'
                                              />
                                              <path
                                                id='Path_52'
                                                data-name='Path 52'
                                                d='M59.85,177.08c8.66-8.03,16.83-15.6,25.51-23.66,7.09,7.7,14.02,15.22,21.2,23.02,16.82-18.31,33.36-36.33,50.23-54.69,8.58,7.87,17,15.6,25.76,23.64-5.32,5.83-10.43,11.46-15.58,17.07q-22.635,24.66-45.32,49.3c-9.46,10.24-20.76,10.24-30.2-.03-10.47-11.38-20.84-22.85-31.6-34.65Z'
                                                transform='translate(-0.097 -0.027)'
                                              />
                                              <path
                                                id='Path_53'
                                                data-name='Path 53'
                                                d='M59.85,283.48c8.61-7.9,16.93-15.53,25.52-23.42,7.16,7.74,14.09,15.25,21.13,22.85,16.87-18.39,33.4-36.4,50.27-54.78,8.57,7.84,17,15.56,25.81,23.61-6.99,7.65-13.78,15.13-20.64,22.57q-20.31,22.035-40.69,44c-9.14,9.82-20.37,9.77-29.43-.06-10.6-11.49-21.15-23.01-31.96-34.77Z'
                                                transform='translate(-0.097 -0.051)'
                                              />
                                              <path
                                                id='Path_54'
                                                data-name='Path 54'
                                                d='M59.84,388.66c8.68-7.93,17.01-15.55,25.57-23.37,7.17,7.78,14.02,15.22,21.17,22.98,16.81-18.34,33.33-36.36,50.26-54.84,8.51,7.78,16.93,15.48,25.66,23.46-7.26,7.91-14.3,15.6-21.36,23.28q-19.8,21.525-39.62,43.02c-9.52,10.31-20.67,10.22-30.13-.13-10.33-11.31-20.7-22.58-31.55-34.4Z'
                                                transform='translate(-0.097 -0.09)'
                                              />
                                              <path
                                                id='Path_55'
                                                data-name='Path 55'
                                                d='M205.18,197.45V163.06H330.92v34.39Z'
                                                transform='translate(-0.335 -6.03)'
                                              />
                                              <path
                                                id='Path_56'
                                                data-name='Path 56'
                                                d='M331.06,302.86H205.22V268.47H331.06Z'
                                                transform='translate(-0.335 -7.051)'
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className='title'>
                                      <h6>Todo</h6>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                className='row demo-icons one mx-auto g-4'
                                style={{marginTop: '10px'}}
                              >
                                <div className='col rounded-2' title='Todo'>
                                  <div className='card-item mx-4 my-4 rounded-2'>
                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                      <span className=''>Size</span>
                                    </label>

                                    <input
                                      type='number'
                                      className='form-control form-control-lg form-control-solid w-50'
                                      name='row2'
                                      max='330'
                                      min='30'
                                      value={row2Dimension}
                                      placeholder={row2Dimension}
                                      style={{minHeight: '41px', maxHeight: '41px'}}
                                      onChange={(e) => {
                                        setRow2Dimension(e.target.value)
                                      }}
                                    />
                                    <div
                                      id='slider2'
                                      ref={sliderRef2}
                                      className='noUi-sm mt-4 w-50'
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='row demo-icons one mx-auto g-4'>
                              <div className='col rounded-2' title='Todo'>
                                <div className='card-item mx-4 my-4 rounded-2'>
                                  <a className='' href='#'>
                                    <div
                                      className='icon'
                                      style={{
                                        width: row3Dimension + 'px',
                                        height: row3Dimension + 'px',
                                      }}
                                    >
                                      {/* <img
                              src='./media/icons/resource-onborarding.svg'
                              alt='icon'
                              style={{
                                width: convertSize(row3Dimension),
                                height: convertSize(row3Dimension),
                              }}
                            /> */}
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='398'
                                        height='500'
                                        viewBox='0 0 398 500'
                                      >
                                        <g id='_3' data-name='3' transform='translate(-0.001)'>
                                          <g id='c' transform='translate(0.001 0)'>
                                            <g id='Group_27' data-name='Group 27'>
                                              <path
                                                id='Path_48'
                                                data-name='Path 48'
                                                d='M392.17,512c-9.56-2.68-19.65-4.19-28.58-8.25-31.52-14.34-50.36-38.91-55.48-73.36-7.22-48.59,24.96-93.5,71.86-103.88,51.82-11.47,103.75,23.31,113.01,75.74a93.144,93.144,0,0,1-79.05,108.86,16.528,16.528,0,0,0-2.77.89h-19ZM371.43,397.4c-7.92,8.21-15.96,16.55-24.29,25.17,9.5,9.67,19.37,19.77,29.29,29.82,9.46,9.58,19.6,9.54,29.07-.14q23.055-23.565,46-47.23c1.01-1.04,1.94-2.18,2.19-2.47q-12.2-13.005-23.49-25.06c-13.57,12.76-26.83,25.23-40.43,38.03-5.81-5.74-12.06-11.91-18.35-18.12Z'
                                                transform='translate(-96.444 -12.09)'
                                              />
                                              <path
                                                id='Path_49'
                                                data-name='Path 49'
                                                d='M271.17,0c11.76,4.08,16.36,12.54,15.75,24.69-.4,7.97.13,15.99.08,23.99-.09,13.49-7.99,21.61-21.32,21.63q-66.735.1-133.48,0c-13.31-.03-21.01-7.97-21.02-21.2,0-7.83.45-15.7-.13-23.49-.9-12.27,3.43-21.01,15.13-25.62h145Z'
                                                transform='translate(-0.222 0)'
                                              />
                                              <path
                                                id='Path_50'
                                                data-name='Path 50'
                                                d='M80.21,18.13V53.1H48.64Q34.9,53.1,34.9,66.981V454.338c0,9.968.79,10.748,10.88,10.748H213.66V499.77c-1.64.07-3.26.21-4.88.21-78.32.01-87.64.04-165.96,0C21.03,499.97,4.51,486.742.63,466.306a51.335,51.335,0,0,1-.61-9.448Q-.01,259.165.02,61.492C.02,35.3,17.36,18.15,43.67,18.13c11.97-.01,23.95,0,36.54,0Z'
                                                transform='translate(-0.001 0)'
                                              />
                                              <path
                                                id='Path_51'
                                                data-name='Path 51'
                                                d='M398.05,317.49H363.02V63.52c0-8.98-1.39-10.36-10.43-10.36H320.47V18.52c1.29-.13,2.56-.37,3.83-.37,10.83-.03,21.66-.14,32.49.01,23.06.31,40.64,17.76,41.22,40.87.18,7.33.04,14.66.04,21.99V317.5Z'
                                                transform='translate(-0.444 0)'
                                              />
                                              <path
                                                id='Path_52'
                                                data-name='Path 52'
                                                d='M59.85,177.08c8.66-8.03,16.83-15.6,25.51-23.66,7.09,7.7,14.02,15.22,21.2,23.02,16.82-18.31,33.36-36.33,50.23-54.69,8.58,7.87,17,15.6,25.76,23.64-5.32,5.83-10.43,11.46-15.58,17.07q-22.635,24.66-45.32,49.3c-9.46,10.24-20.76,10.24-30.2-.03-10.47-11.38-20.84-22.85-31.6-34.65Z'
                                                transform='translate(-0.097 -0.027)'
                                              />
                                              <path
                                                id='Path_53'
                                                data-name='Path 53'
                                                d='M59.85,283.48c8.61-7.9,16.93-15.53,25.52-23.42,7.16,7.74,14.09,15.25,21.13,22.85,16.87-18.39,33.4-36.4,50.27-54.78,8.57,7.84,17,15.56,25.81,23.61-6.99,7.65-13.78,15.13-20.64,22.57q-20.31,22.035-40.69,44c-9.14,9.82-20.37,9.77-29.43-.06-10.6-11.49-21.15-23.01-31.96-34.77Z'
                                                transform='translate(-0.097 -0.051)'
                                              />
                                              <path
                                                id='Path_54'
                                                data-name='Path 54'
                                                d='M59.84,388.66c8.68-7.93,17.01-15.55,25.57-23.37,7.17,7.78,14.02,15.22,21.17,22.98,16.81-18.34,33.33-36.36,50.26-54.84,8.51,7.78,16.93,15.48,25.66,23.46-7.26,7.91-14.3,15.6-21.36,23.28q-19.8,21.525-39.62,43.02c-9.52,10.31-20.67,10.22-30.13-.13-10.33-11.31-20.7-22.58-31.55-34.4Z'
                                                transform='translate(-0.097 -0.09)'
                                              />
                                              <path
                                                id='Path_55'
                                                data-name='Path 55'
                                                d='M205.18,197.45V163.06H330.92v34.39Z'
                                                transform='translate(-0.335 -6.03)'
                                              />
                                              <path
                                                id='Path_56'
                                                data-name='Path 56'
                                                d='M331.06,302.86H205.22V268.47H331.06Z'
                                                transform='translate(-0.335 -7.051)'
                                              />
                                            </g>
                                          </g>
                                        </g>
                                      </svg>
                                    </div>
                                    <div className='title'>
                                      <h6>Todo</h6>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div
                                className='row demo-icons one mx-auto g-4'
                                style={{marginTop: '10px'}}
                              >
                                <div className='col rounded-2' title='Todo'>
                                  <div className='card-item mx-4 my-4 rounded-2'>
                                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                      <span className=''>Size</span>
                                    </label>

                                    <input
                                      type='number'
                                      className='form-control form-control-lg form-control-solid w-50'
                                      name='row3'
                                      max='330'
                                      min='30'
                                      value={row3Dimension}
                                      placeholder={row3Dimension}
                                      style={{minHeight: '41px', maxHeight: '41px'}}
                                      onChange={(e) => {
                                        setRow3Dimension(e.target.value)
                                      }}
                                    />
                                    <div
                                      id='slider3'
                                      ref={sliderRef3}
                                      className='noUi-sm mt-4 w-50'
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}

export {DashboardDemo}
