import {WorkSpaceNotificationSlice} from './NotificationSlice'
import * as serverRequest from './NotificationCRUD'
import {AppDispatch} from '../../../../../../setup/redux/Store'
const {actions} = WorkSpaceNotificationSlice

export const fetchNotification = (workSpce: any) => (dispatch: AppDispatch) => {
  serverRequest.getUpdatedWorkSpaceNotificationSettings(workSpce).then((updatedData: any) => {
    if (
      updatedData.data &&
      updatedData?.data.notificationSettings &&
      updatedData?.data.notificationSettings !== ''
    ) {
      dispatch(actions.notificationFetched(updatedData?.data.notificationSettings))
    } else if (updatedData?.data.notificationSettings == undefined) {
      dispatch(
        actions.notificationFetched({
          app: false,
          email: false,
          list: false,
          todo: false,
          discussion: false,
          documents: false,
        })
      )
    }
  })
}

export const updateNotification =
  (workSpce: any, notifications: any) => (dispatch: AppDispatch) => {
    serverRequest
      .updateWorkSpaceNotificationSettings(workSpce, notifications)
      .then((updatedData: any) => {
        dispatch(actions.notificationFetched(updatedData.data.notificationSettings))
      })
  }
