import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  chatName: string
  isGroupChat: boolean
  attachments?: any
  users: any
  workSpace: string
  groupAdmin: string
}

// Define a type for the slice state
interface DiscussionState {
  loading?: boolean
  discussionDetail?: entities
  chat?: entities[] | string
  error?: any
  discussion?: any
  allChat?: any
  chatId?: any
  currentSpaceName?: any
  messages?: []
  groupChat?: any
  editChat?: boolean
  userStatus?: any
  unauthorizedForChat?: object
  chatIdByNotification?: string
  groupCreated?: boolean
  showMemberPopup?: boolean
  creatingChannel?: boolean
  memberAdded?: boolean
}

// Define the initial state using that type
const initialState: DiscussionState = {
  loading: true,
  chat: [],
  error: '',
  discussion: [],
  allChat: [],
  chatId: '',
  currentSpaceName: '',
  messages: [],
  groupChat: '',
  editChat: false,
  userStatus: '',
  unauthorizedForChat: {},
  chatIdByNotification: '',
  groupCreated: false,
  showMemberPopup: false,
  creatingChannel: false,
  memberAdded: false,
}

export const discussionsSlice = createSlice({
  name: 'chat',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<DiscussionState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state) => {
      state.loading = true
    },
    discussionsFetched: (state, action: PayloadAction<DiscussionState>) => {
      const {chat} = action.payload
      // state.unauthorizedForChat = {}
      state.chat = chat
      state.loading = false
    },
    allChat: (state, action: PayloadAction<DiscussionState>) => {
      const {chat}: any = action.payload
      state.allChat = chat
    },
    currentUserChatId: (state, action: PayloadAction<DiscussionState>) => {
      const {chatId}: any = action.payload
      state.chatId = chatId
    },
    currentSpaceName: (state, action: PayloadAction<DiscussionState>) => {
      const {chat}: any = action.payload
      state.currentSpaceName = chat
    },
    singleUserMessages: (state, action: PayloadAction<DiscussionState>) => {
      const {chat}: any = action.payload
      state.messages = chat
    },
    currentChatType: (state, action: PayloadAction<DiscussionState>) => {
      const {groupChat}: any = action.payload
      state.groupChat = groupChat
    },
    editChat: (state, action: PayloadAction<DiscussionState>) => {
      const {editChat}: any = action.payload
      state.editChat = editChat
    },
    unauthorizedForChat: (state, action: PayloadAction<DiscussionState>) => {
      const {unauthorizedForChat}: any = action.payload
      state.unauthorizedForChat = unauthorizedForChat
    },
    chatIdByNotification: (state, action: PayloadAction<DiscussionState>) => {
      const {chatIdByNotification}: any = action.payload
      state.chatIdByNotification = chatIdByNotification
    },
    groupCreated: (state, action: PayloadAction<DiscussionState>) => {
      const {groupCreated}: any = action.payload
      state.groupCreated = groupCreated
    },

    showMemberPopup: (state, action: PayloadAction<DiscussionState>) => {
      const {showMemberPopup}: any = action.payload
      state.showMemberPopup = showMemberPopup
    },
    creatingChannel: (state, action: PayloadAction<DiscussionState>) => {
      const {creatingChannel}: any = action.payload
      state.creatingChannel = creatingChannel
    },
    memberAdded: (state, action: PayloadAction<DiscussionState>) => {
      const {memberAdded}: any = action.payload
      state.memberAdded = memberAdded
    },
  },
})
