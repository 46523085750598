import {Link} from 'react-router-dom'
import React, {FC, useEffect, useState} from 'react'
import {Field} from 'formik'
import {getImageURL, toAbsoluteUrl} from '../../../_metronic/helpers'
import {RootState} from '../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
interface Props {
  assignees: any
  assigneeWithTeams: any
  setSelectedList: any
  setAssignees: any
  searchQuery: string
  selectedList: any
  setSearchQuery: any
  group: any
}
const teamColor = ['light-danger', 'light-warning', 'light-success', 'success', 'danger']

const AssigneeSelector: FC<Props> = ({
  assignees,
  assigneeWithTeams,
  setSelectedList,
  selectedList,
  setAssignees,
  searchQuery,
  setSearchQuery,
  group,
}) => {
  const [selectAll, setSelectAll] = useState(false)
  const [sortAssigneeName, setSortAssigneeName] = useState(true)
  const [sortAssigneeEmail, setSortAssigneeEmail] = useState(true)
  const [sort, setSort] = useState(true)

  const {accessToken}: any = useSelector<RootState>(
    (state) => ({
      accessToken: state.auth.accessToken,
    }),
    shallowEqual
  )

  const checkBoxAssigneeHandler = (data: any) => {
    setAssignees(
      assignees.filter((assignee: any) => {
        return assignee._id !== data._id
      })
    )
    setSelectedList((list: any) => {
      return [data, ...list]
    })
  }

  useEffect(() => {
    if (assignees?.length > 0) {
      setSelectAll(false)
    }
    // setAssignees(assignees)
  }, [assignees])

  //Sort Assignees Email
  useEffect(() => {
    setSort(false)
    if (assignees?.length > 0) {
      if (sortAssigneeEmail) {
        setAssignees(
          assignees.slice().sort((a: any, b: any) => {
            return a.email?.localeCompare(b.email)
          })
        )
      } else {
        setAssignees(
          assignees.slice().sort((a: any, b: any) => {
            return b.email?.localeCompare(a.email)
          })
        )
      }
    }
  }, [sortAssigneeEmail])

  //Sort Assignees Name
  useEffect(() => {
    setSort(true)
    if (assignees?.length > 0) {
      if (sortAssigneeName) {
        setAssignees(
          assignees.slice().sort((a: any, b: any) => {
            return a.fullName?.localeCompare(b.fullName)
          })
        )
      } else {
        setAssignees(
          assignees.slice().sort((a: any, b: any) => {
            return b.fullName?.localeCompare(a.fullName)
          })
        )
      }
    }
  }, [sortAssigneeName])
  return (
    <div className='table-responsive'>
      <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
        <thead>
          <tr className='fw-bolder text-muted  border-bottom'>
            <th className='w-25px'>
              <div className='form-check form-check-sm form-check-custom form-check-solid'>
                <Field
                  name='selectAll'
                  className='form-check-input head_ui_input'
                  type='checkbox'
                  id='checkBox1'
                  value='selectAll'
                  checked={selectAll ? true : false}
                  onClick={() => {
                    if (!selectAll && assignees?.length > 0) {
                      setSelectAll(true)
                      if (selectedList?.length <= 0) {
                        setSelectedList(assignees)
                        setAssignees([])
                      } else {
                        setSelectedList((list: any) => [...assignees, ...list])
                        setAssignees([])
                      }
                    }
                    // else {
                    //   setSelectAll(false)
                    //   setAssignees(selectedList)
                    //   setSelectedList(
                    //     selectedList.filter((list: any) => {
                    //       return list.isTeam === true
                    //     })
                    //   )
                    // }
                  }}
                />
              </div>
            </th>
            <th
              className='head_ui'
              style={{cursor: 'pointer'}}
              onClick={() => setSortAssigneeName(!sortAssigneeName)}
            >
              Name
              {sort && (
                <i
                  className={sortAssigneeName ? 'fa fa-fw fa-caret-up' : 'fa fa-fw fa-caret-down'}
                ></i>
              )}
            </th>
            {/* <th className='text-center'>id</th> */}
            <th className='text-start head_ui'>team/Group</th>
            <th
              className='text-start head_ui'
              style={{cursor: 'pointer'}}
              onClick={() => setSortAssigneeEmail(!sortAssigneeEmail)}
            >
              email{' '}
              {!sort && (
                <i
                  className={sortAssigneeEmail ? 'fa fa-fw fa-caret-up' : 'fa fa-fw fa-caret-down'}
                ></i>
              )}
            </th>
          </tr>
        </thead>

        <tbody className='maintainer_table'>
          {assignees?.length > 0 ? (
            assignees?.map((assignee: any, index: number) => {
              if (
                assignee?.fullName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                assignee?.email?.toLowerCase().includes(searchQuery.toLowerCase())
              ) {
                return (
                  <tr key={index}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <Field
                          name='assignee'
                          className='form-check-input widget-9-check head_ui_input'
                          type='checkbox'
                          id={assignee._id}
                          // defaultChecked={selectAll ? true : false}
                          value={assignee}
                          onChange={() => {
                            checkBoxAssigneeHandler(assignee)
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <span
                        className='text-black-grey fw-bold d-block fs-7 text-truncate'
                        style={{width: '200px'}}
                        title={assignee.fullName}
                      >
                        <div
                          title='Profile'
                          className='cursor-pointer symbol symbol-30px symbol-md-30px pe-6'
                        >
                          <img
                            className='rounded-circle'
                            src={
                              assignee?.image[0]?.thumbnailUrl
                                ? getImageURL(assignee?.image[0]?.thumbnailUrl, accessToken)
                                : toAbsoluteUrl('/media/avatars/blank.png')
                            }
                            alt={'user-image'}
                          />
                        </div>

                        {assignee?.fullName}
                      </span>
                    </td>
                    {/* <td>
                    <span className='text-muted d-block text-center fs-7'>{assignee._id}</span>
                  </td> */}
                    <td>
                      <div className='d-block text-start text-truncate' style={{width: '300px'}}>
                        {group ? group : ''}
                        {/* {assigneeWithTeams?.map((assigneeWithTeam: any) => {
                          if (assignee._id === assigneeWithTeam._id) {
                            return assigneeWithTeam.team.map((team: any, index: number) => {
                              return (
                                <span
                                  key={index}
                                  className='text-muted d-inline-block fs-7'
                                  title={team}
                                >
                                  {index === 0 ? team : ', ' + team}
                                </span>
                              )
                            })
                          }
                        })} */}
                      </div>
                    </td>
                    <td>
                      <Link
                        to='#'
                        className='text-muted d-block fs-7 text-left text-black-grey '
                        title={assignee.email}
                      >
                        {assignee.email}
                      </Link>
                    </td>
                  </tr>
                )
              }
            })
          ) : (
            <tr>
              <td></td> <td></td>
              <td className='text-muted text-center'>No user remaining</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
export {AssigneeSelector}
