import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {RootState} from '../../../../setup'

export default function TalentSuccess(props: any) {
  const history = useHistory()
  // const id = sessionStorage.getItem('id')
  const [color, setColor] = useState(null)
  const displayWidget = history?.location?.search.includes('widget')
  useEffect(() => {
    if (!props?.location?.state?.success || !props?.location?.state?.id) {
      history.push(`/talent${history.location.search}`)
    }
    if (displayWidget) {
      document.body.style.backgroundImage = 'none'
      document.body.style.backgroundColor = 'transparent'
    } else {
      document.body.style.backgroundColor = '#f5f8fa'
    }
  }, [])

  const {widgetSettings}: any = useSelector<RootState>(
    (state) => ({
      widgetSettings: state.talent.widgetSettings,
    }),
    shallowEqual
  )

  const styles = {
    color: '#fff',
    borderRadius: '0.475rem',
    backgroundColor: color ?? widgetSettings.buttonBgColor,
    padding:
      widgetSettings.buttonType === 'medium'
        ? '11.725px 23.75px'
        : widgetSettings.buttonType === 'large'
        ? '15.725px 35.75px'
        : '8.725px 20.75px',
    fontSize:
      widgetSettings.buttonType === 'medium'
        ? '1.15rem'
        : widgetSettings.buttonType === 'large'
        ? '1.35rem'
        : '1.0rem',
  }
  return (
    <div
      className={`w-lg-500px bg-white rounded p-10 p-lg-15 mx-auto  ${
        displayWidget ? 'mt-20 shadow-none' : 'shadow-sm'
      }`}
    >
      <div>
        <div className='mb-4 text-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='text-success'
            width='75'
            height='75'
            fill={widgetSettings.buttonBgColor}
            viewBox='0 0 16 16'
          >
            <path d='M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z' />
          </svg>
        </div>
        <div className='text-center'>
          <h1 style={{color: widgetSettings.labelsColor}}>{widgetSettings?.thankUPageTitle}</h1>
          <p
            style={{
              color: widgetSettings.labelsColor,
              marginBottom: displayWidget ? '25px' : '16px',
            }}
          >
            {widgetSettings?.thankUPageMessage}
          </p>

          {!widgetSettings?.allowEditOnSubmission ? (
            <Link
              to={`/talent/${props.location.state.id}${history.location.search}`}
              style={styles}
              onMouseEnter={() => setColor(widgetSettings.buttonHoverColor)}
              onMouseLeave={() => setColor(widgetSettings.buttonBgColor)}
            >
              View
            </Link>
          ) : (
            <Link
              to={`/talent/${props.location.state.id}/edit${history.location.search}`}
              style={styles}
              onMouseEnter={() => setColor(widgetSettings.buttonHoverColor)}
              onMouseLeave={() => setColor(widgetSettings.buttonBgColor)}
            >
              Edit
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}
