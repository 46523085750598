/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import React, {FC, useEffect, useRef, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
import '../../../_metronic/assets/sass/components/prof-icon.scss'
interface SortingValues {
  sortby: string
  orderby: string
}

type Props = {
  setIsFiltered: any
  setSortValues: any
  sortValues: SortingValues
}
const API_URL = process.env.REACT_APP_API_URL
const LIST_USERS = `${API_URL}/users`

const DashboardPopup: FC<Props> = ({setSortValues, sortValues, setIsFiltered}) => {
  const [orderby, setOrderBy] = useState<string>(sortValues.orderby)
  const [sortby, setSortBy] = useState<string>(sortValues.sortby)
  const [spaceType, setSpaceType] = useState<string>('')
  const [createdDate, setCreatedDate] = useState<string>('')
  const [assignee, setAssignee] = useState('')
  const [maintainer, setMaintainer] = useState('')

  const [rerender, setRerender] = useState(false)
  const [assignees, setAssignees] = useState<any>([])

  const selectRef = useRef<any>()

  const userInfo: any = useSelector<RootState>((state) => state.auth.user, shallowEqual)

  // useEffect(() => {
  //   MenuComponent.reinitialization()
  // }, [])

  useEffect(() => {
    setOrderBy(sortValues.orderby)
    setSortBy(sortValues.sortby)
  }, [sortValues])

  const resetData = () => {
    if (selectRef && selectRef.current) {
      selectRef.current.clearValue()

      setRerender(rerender ? false : true)
    }

    setSortBy('')
    setOrderBy('desc')
    setCreatedDate('')
    setSpaceType('')
    setAssignee('')
    setMaintainer('')
    setIsFiltered(false)
  }

  const filterData = (e: any) => {
    if (
      orderby === 'desc' &&
      sortby === '' &&
      spaceType === '' &&
      createdDate === '' &&
      assignee === '' &&
      maintainer === ''
    ) {
      setIsFiltered(false)
    } else {
      setIsFiltered(true)
    }
    setSortValues({
      orderby: orderby,
      sortby: sortby,
      spaceType: spaceType,
      createdAt: createdDate,
      assignee: assignee,
      maintainer: maintainer,
    })
  }

  const fetchAssigneesData = async () => {
    const assigneeData = await axios.get(`${LIST_USERS}`)
    setAssignees(
      assigneeData?.data?.map((assigne: any) => {
        return {
          value: assigne._id,
          label: userInfo._id === assigne._id ? assigne.fullName + ' ' + '(Me)' : assigne.fullName,
          id: assigne._id,
        }
      })
    )
  }

  useEffect(() => {
    fetchAssigneesData()
  }, [])

  return (
    <div
      className='mh-80 menu menu-sub menu-sub-dropdown w-250px w-md-300px dropdown_dialog border-right-top-bottom z-index-78'
      data-kt-menu='true'
    >
      <div className='px-7 py-5 d-flex justify-content-between'>
        <div className='fs-6 text-dark fw-500'>Search Filters</div>

        <div className='btn btn-active-light p-0'>
          {' '}
          <i className='fa fa-times' aria-hidden='true' data-kt-menu-dismiss='true'></i>
        </div>

        {/* <i className="fa-light fa-xmark"></i> */}
      </div>

      <div className='separator border-gray-200'></div>

      <div className='overflow_height_scroll px-7 pt-0 py-7'>
        <div className=''>
          <label className='form-label f-15px fw-bold mt-10 mb-3'>Space Type</label>

          <div className='d-flex input_dashboard justify-content-between mt-3 mb-4'>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                checked={spaceType == ''}
                value={''}
                name='flexRadioDefault'
                onChange={(e) => setSpaceType(e.target.value)}
              />
              <label className='form-check-label'>Any</label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                value={'0'}
                checked={spaceType == '0'}
                name='flexRadioDefault'
                onChange={(e) => setSpaceType(e.target.value)}
              />
              <label className='form-check-label'>Group</label>
            </div>
            <div className='form-check'>
              <input
                className='form-check-input'
                type='radio'
                value={'1'}
                checked={spaceType == '1'}
                name='flexRadioDefault'
                onChange={(e) => setSpaceType(e.target.value)}
              />
              <label className='form-check-label'>Personal</label>
            </div>
          </div>

          <div className='my-5'>
            <label className='mb-3 f-13px'>Maintainer</label>
            <select
              className='form-select form-select-solid dashboard_select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => {
                setMaintainer(e.target.value)
              }}
              value={maintainer}
            >
              <option value=''>Select</option>
              {assignees.map((assignee: any, index: number) => {
                return (
                  <option value={assignee.id} key={index}>
                    {assignee.label}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='my-5'>
            <label className='mb-3 f-13px'>Created By</label>
            <select
              className='form-select form-select-solid dashboard_select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => {
                setAssignee(e.target.value)
              }}
              value={assignee}
            >
              <option value=''>Select</option>
              {assignees.map((assignee: any, index: number) => {
                return (
                  <option value={assignee.id} key={index}>
                    {assignee.label}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='my-5'>
            <label className='mb-3 f-13px'>Created Date</label>
            <input
              type='date'
              name='createdAt'
              value={createdDate}
              className='form-select form-select-solid date-field'
              onChange={(event) => {
                setCreatedDate(event.target.value)
              }}
            />
          </div>
          <div className='my-5'>
            <label className='mb-3 f-13px'>Sort By</label>
            <select
              className='form-select form-select-solid dashboard_select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => setSortBy(e.target.value)}
              value={sortby}
            >
              <option value=''>Select</option>
              <option value='name'>Name</option>
              <option value='spaceKey'>Space Key</option>
              <option value='spaceType'>Space Type</option>
              <option value='createdAt'>Created Date</option>
            </select>
          </div>
          <div className='my-5'>
            <label className='mb-3 f-13px'>Order By</label>
            <select
              className='form-select form-select-solid dashboard_select'
              data-kt-select2='true'
              data-placeholder='Select option'
              data-allow-clear='true'
              onChange={(e) => setOrderBy(e.target.value)}
              value={orderby}
            >
              <option value={''}>Select</option>
              <option value={'asc'}>Asc</option>
              <option value='desc'>Desc</option>
            </select>
          </div>
        </div>

        <div className='d-flex justify-content-end popup_setting'>
          <button
            type='button'
            onClick={resetData}
            className='btn btn-sm btn-white btn-active-light-primary me-2'
          >
            Clear
          </button>

          <button type='button' onClick={filterData} className='btn btn-sm btn-primary py-2 px-7' data-kt-menu-dismiss='true'>
            Apply
          </button>
        </div>
      </div>
    </div>
  )
}

export {DashboardPopup}
