import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  name: string
  description: string
  slug: string
  default: boolean
  owner: string
  type: number
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

// Define a type for the slice state
interface WorkspaceState {
  workspaceDetail?: entities
  totalCount: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  page: number
  error?: any
  listLoading: boolean
  actionsLoading: boolean
}

// Define the initial state using that type
const initialState: WorkspaceState = {
  totalCount: 0,
  entities: [{_id: '', name: '', description: '', slug: '', default: false, owner: '', type: 1}],
  start: 1,
  end: 11,
  limitPerPage: 11,
  page: 1,
  error: '',
  listLoading: true,
  actionsLoading: false,
}

export const workspaceSlice = createSlice({
  name: 'workspace',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    catchError: (state, action: PayloadAction<WorkspaceState>) => {
      state.error = `${action.payload}`
      state.actionsLoading = false
    },
    workspacesFetched: (state, action: PayloadAction<WorkspaceState>) => {
      const {page, totalCount, entities, start, end} = action.payload
      state.listLoading = false
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
      state.page = page
    },
    workspaceCreated: (state, action) => {
      const {data} = action.payload

      state.entities.push(data)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    workspaceUpdated: (state, action) => {
      const {data} = action.payload
      if (state && state.entities.length > 0) {
        state.entities = state.entities.map((entity: entities) => {
          if (entity._id === data._id) {
            return data
          }
          return entity
        })
      }
    },
    // deleteWorkspace
    workspaceDeleted: (state, action) => {
      state.entities = state.entities.filter((el) => el._id !== action.payload._id)
    },
    workspaceFetched: (state, action) => {
      state.listLoading = false
      const {data} = action.payload
      state.workspaceDetail = data
    },
  },
})
