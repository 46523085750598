export function newObjectId() {
  const timestamp = Math.floor(new Date().getTime() / 1000).toString(16)
  const objectId =
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, () => {
        return Math.floor(Math.random() * 16).toString(16)
      })
      .toLowerCase()

  return objectId
}

export const findAndInsertNode = (tempRolesObject: any, nodeId: string) => {
  if (nodeId === 'root') {
    tempRolesObject.parent = 'root'
    tempRolesObject.nodeId = newObjectId()
    tempRolesObject.name = ''
    tempRolesObject.type = ''
    tempRolesObject.groupHeadName = ''
  } else if (tempRolesObject?.nodeId === nodeId) {
    let childrens = tempRolesObject?.childrens
    if (childrens?.length > 0) {
      tempRolesObject.childrens = [
        ...childrens,
        {
          parent: nodeId,
          nodeId: newObjectId(),
          name: '',
          type: '',
          groupHeadName: '',
        },
      ]
    } else {
      tempRolesObject.childrens = [
        {
          parent: nodeId,
          nodeId: newObjectId(),
          name: '',
          type: '',
          groupHeadName: '',
        },
      ]
    }
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject?.childrens?.map((node: any) => {
      findAndInsertNode(node, nodeId)
    })
  }
}

export const findAndBuildNode = (tempRolesObject: any, Node: any) => {
  if (tempRolesObject?.nodeId === Node.nodeId) {
    tempRolesObject.name = Node.name
    tempRolesObject.type = Node.type
    tempRolesObject.groupHeadName = Node.groupHeadName
    tempRolesObject.user = Node.user
    tempRolesObject.role = Node.role
    tempRolesObject.edit = false
    tempRolesObject.roleName = Node.roleName
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject?.childrens?.map((node: any) => {
      findAndBuildNode(node, Node)
    })
  }
}

export const findEmptyNode = (tempRolesObject: any, permission: any) => {
  if ((tempRolesObject.name === '' && tempRolesObject.type === '') || tempRolesObject.edit) {
    permission.valid = false
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject?.childrens?.map((node: any) => {
      findEmptyNode(node, permission)
    })
  }
}

export const findAndRemoveNode = (tempRolesObject: any, NodeId: any) => {
  if (tempRolesObject.parent === 'root' && tempRolesObject.nodeId === NodeId) {
    return {}
  } else {
    tempRolesObject.childrens = tempRolesObject?.childrens
      ?.filter(function (child: any) {
        return child.nodeId !== NodeId
      })
      ?.map(function (child: any) {
        return findAndRemoveNode(child, NodeId)
      })
    return tempRolesObject
  }
}

export const dragAndDropNode = async (tempRolesObject: any, childNode: any, parentNode: any) => {
  const childNodeObject = {node: {}}
  await getSingleNode(tempRolesObject, childNode?.nodeId, childNodeObject)
  const updatedJSON = await findAndRemoveNode(tempRolesObject, childNode.nodeId)
  await replaceNodes(updatedJSON, childNode, parentNode, childNodeObject.node)
}

export const replaceNodes = async (
  tempRolesObject: any,
  childNode: any,
  parentNode: any,
  childNodeObject: any
) => {
  if (tempRolesObject?.nodeId === parentNode.nodeId) {
    childNodeObject.parent = parentNode.nodeId
    let childrens = tempRolesObject?.childrens
    if (childrens?.length > 0) {
      tempRolesObject.childrens = [childNodeObject, ...childrens]
    } else {
      tempRolesObject.childrens = [childNodeObject]
    }
    return
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject?.childrens?.map((node: any) => {
      replaceNodes(node, childNode, parentNode, childNodeObject)
    })
  }
}

export const getSingleNode = (tempRolesObject: any, nodeId: any, childNodeObject: any) => {
  if (tempRolesObject?.nodeId === nodeId) {
    childNodeObject.node = tempRolesObject
    return
  } else if (tempRolesObject?.childrens?.length > 0) {
    return tempRolesObject?.childrens?.map((node: any) => {
      getSingleNode(node, nodeId, childNodeObject)
    })
  }
}

export const validateDragAndDrop = async (
  tempRolesObject: any,
  childNode: any,
  parentNode: any,
  permission: any
) => {
  const childNodeObject: any = {node: {}}
  const parentNodeObject: any = {node: {}}
  await getSingleNode(tempRolesObject, childNode?.nodeId, childNodeObject)
  await getSingleNode(tempRolesObject, parentNode?.nodeId, parentNodeObject)
  parentNodeObject?.node?.childrens?.filter((node: any) => {
    if (node.nodeId === childNodeObject.node.nodeId) {
      permission.valid = false
    }
  })
  if (permission.valid) {
    await findSpecificNode(childNodeObject.node, parentNode.nodeId, permission)
  }
}

export const validateAllDragAndDropTargets = async (
  originalRolesObject: any,
  tempRolesObject: any,
  pickedNode: any
) => {
  if (tempRolesObject.nodeId === pickedNode.nodeId) {
    const parentNodeObject: any = {node: {}}
    await getSingleNode(originalRolesObject, tempRolesObject?.parent, parentNodeObject)
    parentNodeObject.node.validTarget = false
    tempRolesObject.validTarget = false
    await makeAllChildInvalid(tempRolesObject)
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject.validTarget = true
    tempRolesObject?.childrens?.map((node: any) =>
      validateAllDragAndDropTargets(originalRolesObject, node, pickedNode)
    )
  } else {
    tempRolesObject.validTarget = true
  }
}

const makeAllChildInvalid = (tempRolesObject: any) => {
  if (tempRolesObject?.childrens === undefined || tempRolesObject?.childrens.length === 0) {
    tempRolesObject.validTarget = false
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject.validTarget = false
    tempRolesObject?.childrens?.map((node: any) => makeAllChildInvalid(node))
  }
}

export const removeValidationOnNodes = (tempRolesObject: any) => {
  if (tempRolesObject?.childrens === undefined) {
    tempRolesObject.validTarget = undefined
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject.validTarget = undefined
    tempRolesObject?.childrens?.map((node: any) => removeValidationOnNodes(node))
  } else {
    tempRolesObject.validTarget = undefined
  }
}

export const findSpecificNode = (tempRolesObject: any, parentNodeId: string, permission: any) => {
  if (tempRolesObject.nodeId === parentNodeId) {
    permission.valid = false
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject?.childrens?.map((node: any) => {
      findSpecificNode(node, parentNodeId, permission)
    })
  }
}

export const findNodeAndMakeIsEditable = (
  tempRolesObject: any,
  nodeId: string,
  status: boolean
) => {
  if (tempRolesObject.nodeId === nodeId) {
    tempRolesObject.edit = status
  } else if (tempRolesObject?.childrens?.length > 0) {
    tempRolesObject?.childrens?.map((node: any) => {
      findNodeAndMakeIsEditable(node, nodeId, status)
    })
  }
}
