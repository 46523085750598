import * as serverRequest from './BoardCrud'
import {AppDispatch} from '../../../../setup/redux/Store'
import * as workSpaceRequest from '../../workspace/redux/WorkspaceCrud'
import * as pmsServerRequest from '../../project/redux/ProjectCrud'
import {boardSlice} from './BoardSlice'

const {actions} = boardSlice

export const fetchWorkspace = (workSpace: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall())
  return workSpaceRequest.fetchWorkspace(workSpace).then((res: any) => {
    dispatch(actions.currentSpaceName({currentSpace: res.data, loading: false}))
  })
}

export const getAllWorkspaceUsers = (workSpace: string, user: any) => (dispatch: AppDispatch) => {
  return workSpaceRequest.getAllWorkspaceUsers(workSpace).then((res: any) => {
    dispatch(actions.currentSpaceUsers({currentSpaceUsers: [...res.data, user]}))
  })
}

export const createColumn =
  (obj: {
    name: string
    project: string
    iconUrl?: string
    workSpace?: string
    board?: string
    order: number
  }) =>
  () => {
    return serverRequest.createColumn(obj).then((res: any) => {
      return res
    })
  }
export const updateColumn =
  (obj: {
    _id: string
    name: string
    iconUrl?: string
    workSpace?: any
    board: string
    project: string
  }) =>
  () => {
    return serverRequest.updateColumn(obj).then((res: any) => {
      return res
    })
  }

export const createTask =
  (obj: {
    title: string
    description?: string
    reporter?: string
    assignee?: []
    type?: string
    status?: string
    priority?: string
    board?: string
    project: string
    order: number
  }) =>
  () => {
    return serverRequest.createTask(obj).then((res: any) => {
      return res
    })
  }

export const currentProject = (id: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall())
  return pmsServerRequest.getProjectById(id).then((res: any) => {
    dispatch(actions.currentProject({currentProject: res.data.data, loading: false}))
    dispatch(actions.boardColumns({boardColumns: res.data.data.board.columns}))
    return res
  })
}

export const updateCurrentProject =
  (data: any, filter: any = false) =>
  (dispatch: AppDispatch) => {
    dispatch(actions.updateCurrentProject({currentProject: {...data, filter}, loading: false}))
  }
export const filterProject = (filter: any) => (dispatch: AppDispatch) => {
  dispatch(actions.filterProject({filter, loading: false}))
}
// return serverRequest.getAllTaksByProjectId(id).then((res: any) => {
//   dispatch(actions.boardTasks({boardTasks: res.data.data}))
// })
export const getAllTasksByProjectId = (obj: {project: string}) => (dispatch: AppDispatch) => {
  return serverRequest.getAllTaksByProjectId(obj).then((res: any) => {
    dispatch(actions.boardTasks({boardTasks: res.data.data}))
  })
}

export const updatedAllTasks = (data: any) => (dispatch: AppDispatch) => {
  dispatch(actions.boardTasks({boardTasks: data}))
}

export const getSingleTask = (id: string) => (dispatch: AppDispatch) => {
  return serverRequest.getSingleTask(id).then((res: any) => {
    dispatch(actions.singleTask({singleTask: res.data.data}))
  })
}

export const updatedSingleTask = (data: any) => (dispatch: AppDispatch) => {
  dispatch(actions.singleTask({singleTask: data}))
}

export const getColumnByProId = (id: string) => (dispatch: AppDispatch) => {
  return serverRequest.getColumnByProId(id).then((res: any) => {
    dispatch(actions.columnByProId({columnByProId: res.data.data}))
  })
}

export const deleteColumn = (id: string) => (dispatch: AppDispatch) => {
  return serverRequest.deleteColumn(id).then((res: any) => {
    return res
  })
}

export const moveTaskFromOneColumnToAnother =
  (deleteColumnId: string, moveColumnId: string) => (dispatch: AppDispatch) => {
    return serverRequest
      .moveTaskFromOneColumnToAnother(deleteColumnId, moveColumnId)
      .then((res) => {
        return res
      })
  }

export const boardSummaryCount = (id: string) => (dispatch: AppDispatch) => {
  return serverRequest.boardSummaryCount(id).then((res: any) => {
    dispatch(
      actions.boardTasksActivities({
        boardTasksActivities: {
          doneTasks: res.data.doneTasks,
          updatedTask: res.data.updatedTask,
          createdTasks: res.data.createdTasks,
          dueTasks: res.data.dueTasks,
          columns: res.data.column,
          totalTask: res.data.totalTask,
          // activities: res.data.activities,
        },
      })
    )
    return res.data
  })
}

export const boardSummaryActivity = (id: string, page: number) => (dispatch: AppDispatch) => {
  return serverRequest.boardSummaryActivity(id, page).then((res: any) => {
    dispatch(
      actions.boardTaskSummaryActivities({
        summaryActivities: res.data,
        summaryActivitiesData: res.data.data,
      })
    )
    return res.data
  })
}

export const updateCurrentProjectState = (project: any) => (dispatch: AppDispatch) => {
  dispatch(actions.currentProject({currentProject: project, loading: false}))
}
export const sortColumns = (ids: string[]) => (dispatch: AppDispatch) => {
  return serverRequest.sortColumns(ids).then((res: any) => {
    return res
  })
}

export const updateTask = (data: any, workSpaceId: string) => (dispatch: AppDispatch) => {
  return serverRequest.updateTask(data, workSpaceId).then((res: any) => {
    return res
  })
}
export const sortTasksOrder = (ids: string[]) => (dispatch: AppDispatch) => {
  return serverRequest.sortTasksOrder(ids).then((res: any) => {
    return res
  })
}
export const reset = () => (dispatch: AppDispatch) => {
  dispatch(actions.startCall())
  dispatch(actions.currentProject({currentProject: {}, loading: true}))
  dispatch(actions.boardColumns({boardColumns: []}))
}
