import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'


export function BoardVisibility() {
  return (
    <div className='py-3'>
      <div className="card card-custom card-stretch gutter-b">
        <div className='card-header border-0 min-h-auto'>
        <div className="d-flex justify-content-center border-bottom w-100 pb-3">
          <span className='mt-1 fs-7 text-center' style={{fontWeight: '300'}}>visibility</span>
        </div>
          {/* <h3 className='card-header border-0 px-0 pt-4 justify-content-center'>
            <span className='card-label fw-bolder text-center text-dark fs-4 mb-2'>Upgrade to get more views</span>
            <span className='mt-1 fs-7 text-center' style={{fontWeight: '300'}}>View key timelines, assignments, data, and more directly from your Trello board with Trello Premium.</span>
          </h3> */}
        </div>
      </div>
      <div className='card-body pt-5'>
        <div className='d-flex align-items-center mb-4'>
          <div className='d-flex flex-column'>
            <a href='#' className='text-muted fs-7 text-hover-primary fw-bolder'>
            <i className="fa fa-lock text-muted fs-8" aria-hidden="true"></i> Private 
            </a>
            <span className='text-muted fs-8'>Only board members can see and edit this board.</span>
          </div>
        </div>
        <div className='d-flex align-items-center mb-4'>
          <div className='d-flex flex-column'>
            <a href='#' className='text-muted fs-7 text-hover-primary fw-bolder'>
            <i className="fa fa-users text-muted fs-8" aria-hidden="true"></i> Workspace
            </a>
            <span className='text-muted fs-8'>All members of the Workspace can see and edit this board. The board must be added to a Workspace to enable this.</span>
          </div>
        </div>
        <div className='d-flex align-items-center mb-4'>
          <div className='d-flex flex-column'>
            <a href='#' className='text-muted fs-7 text-hover-primary fw-bolder'>
            <i className="fa fa-sitemap text-muted fs-8" aria-hidden="true"></i> Organization
            </a>
            <span className='text-muted fs-8'>All members of the organization can see this board. The board must be added to an enterprise Workspace to enable this.</span>
          </div>
        </div>
        <div className='d-flex align-items-center mb-0'>
          <div className='d-flex flex-column'>
            <a href='#' className='text-muted fs-7 text-hover-primary fw-bolder'>
            <i className="fa fa-globe text-muted fs-8" aria-hidden="true"></i> Public
            </a>
            <span className='text-muted fs-8'>Anyone on the internet (including Google) can see this board. Only board members can edit.</span>
          </div>
        </div>
      </div>
    </div>
  )
}
