import {
  AlignmentType,
  Document,
  ExternalHyperlink,
  Footer,
  FrameAnchorType,
  Header,
  HeadingLevel,
  LineNumberRestartFormat,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from 'docx'
import {
  addLineBreak,
  docxBorder,
  headerImage,
  indentSpace,
  linesBreak,
  resumeSummary,
  showImage,
  titlePageImage,
} from './partials/DocxFunctions'
import {constants} from './partials/Constants'
import {education} from './partials/Education'
import {skillsTools} from './partials/SkillsTools'
import {projectHeader, projects} from './partials/Projects'
import {personalInfo} from './partials/PersonalInfo'
import {careerProfile} from './partials/CareerProfile'

export const create = (res: any, templateName: string): Document => {
  const projectsData = res.data.projects.map((project: any) => {
    return projects(project)
  })

  const document = new Document({
    sections: [
      {
        properties: {
          titlePage: templateName === 'nextbridge' ? true : false,
          page: {
            margin: {
              left: 1200,
              right: 1200,
            },
          },
        },

        headers: {
          default: new Header({
            children: [
              ...(templateName === 'vteams'
                ? [headerImage('/media/talent/vTeam-logo.png', 100, 30)]
                : []),
              ...(templateName === 'nextbridge'
                ? [headerImage('/media/talent/logo.png', 150, 15)]
                : []),
              new Paragraph({
                frame: {
                  position: {
                    x: 5000,
                    y: -650,
                  },
                  width: 5000,
                  height: 1000,
                  anchor: {
                    horizontal: FrameAnchorType.MARGIN,
                    vertical: FrameAnchorType.MARGIN,
                  },
                },
                children: [
                  new TextRun({
                    size: 18,
                    font: {
                      name: constants.CONTENT_FONT_FAMILY,
                    },
                    text: constants.Titles.HEADER_ID,
                  }),
                ],
                alignment: AlignmentType.CENTER,
                indent: {
                  start: 1900,
                },
              }),
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [],
          }),
        },

        children: [
          ...(templateName === 'nextbridge' ? [linesBreak(390)] : []),
          ...(templateName === 'nextbridge' ? [showImage('/media/talent/logo.png', 490, 45)] : []),
          ...(templateName === 'nextbridge' ? [linesBreak(2900)] : []),
          ...(templateName === 'nextbridge'
            ? [
                new Paragraph({
                  indent: indentSpace(),
                  alignment: AlignmentType.CENTER,
                  heading: HeadingLevel.TITLE,
                  children: [
                    new TextRun({
                      text: constants.Titles.NEXTBRIDGE_HEADING,
                      italics: true,
                      font: {
                        name: constants.BOOk_ANTIQUA_FAMILY,
                      },
                      color: constants.Colors.BLACK,
                      bold: true,
                      size: 32,
                    }),
                  ],
                }),
              ]
            : []),

          ...(templateName === 'nextbridge' ? [linesBreak(10)] : []),
          ...(templateName === 'nextbridge' ? [resumeSummary()] : []),
          ...(templateName === 'nextbridge'
            ? [titlePageImage('/media/talent/title-page-img.png', 800, 1130)]
            : []),

          ...(templateName === 'nextbridge' ? [linesBreak(3800)] : []),
          ...(templateName === 'nextbridge'
            ? [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new ExternalHyperlink({
                      children: [
                        new TextRun({
                          characterSpacing: 60,
                          text: constants.Titles.NEXTBRIDGE_URL_TEXT,
                          font: {
                            name: constants.CONTENT_FONT_FAMILY,
                          },
                          color: constants.Colors.BLACK,
                          size: 20,
                        }),
                      ],
                      link: constants.Titles.NEXTBRIDGE_URL,
                    }),
                  ],
                }),
              ]
            : []),

          personalInfo(res.data, templateName),
          linesBreak(100),
          skillsTools(res.data.skills, templateName),
          linesBreak(100),
          careerProfile(res.data),
          linesBreak(70),
          projectHeader(),

          ...projectsData?.flat(),

          linesBreak(100),
          education(res.data),
          linesBreak(4000),
          new Paragraph({
            indent: indentSpace(),
            children: [
              new TextRun({
                text: constants.Titles.V_7_FORM,
                size: 22,
                font: {name: constants.CONTENT_FONT_FAMILY},
                color: constants.Colors.DARK_GRAY,
              }),
            ],
          }),
          new Paragraph({
            indent: indentSpace(),
            children: [
              new TextRun({
                text:
                  templateName === 'nextbridge'
                    ? constants.Descriptions.NEXTBRIDGE_FOOTER_DESCRIPTION
                    : constants.Descriptions.VTEAMS_FOOTER_DESCRIPTION,
                size: 18,
                font: {name: constants.CONTENT_FONT_FAMILY},
                color: constants.Colors.DARK_GRAY,
              }),
            ],
          }),
          new Paragraph({
            indent: indentSpace(),
            children: [
              new TextRun({
                text:
                  templateName === 'nextbridge'
                    ? constants.Descriptions.NEXTBRIDGE_COPYRIGHT
                    : constants.Descriptions.VTEAMS_COPYRIGHT,
                size: 18,
                font: {name: constants.CONTENT_FONT_FAMILY},
                color: constants.Colors.DARK_GRAY,
              }),
            ],
          }),
        ],
      },
    ],
  })

  return document
}
