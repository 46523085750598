// export const generalSettings = {
//   defaultValues: {
//     app: false,
//     email: false,
//     opportunityWatch: false,
//     opportunityWatchCreate: true,
//     opportunityWatchEdit: false,
//     opportunityWatchDelete: false,
//     opportunityWatchComment: false,
//     internalInitiatives: false,
//     internalInitiativesCreate: true,
//     internalInitiativesEdit: true,
//     internalInitiativesDelete: true,
//     releaseNotes: false,
//     releaseNotesNew: true,
//     space: true,
//     spaceCreate: true,
//     spaceEdit: true,
//     spaceDelete: true,
//     team: true,
//     teamCreate: true,
//     teamEdit: true,
//     teamDelete: true,
//   },
//   heading: 'Notifications',
//   typeHeading: 'Notification Type',
//   setting: [
//     {
//       name: 'In App Notifications',
//       key: 'app',
//     },
//     {
//       name: 'Email Notifications',
//       key: 'email',
//     },
//   ],
//   data: [
//     {
//       name: 'Spaces ',
//       module: [
//         {
//           name: 'Space',
//           key: 'space',
//           actions: [
//             {
//               name: 'Create',
//               key: 'spaceCreate',
//             },
//             {
//               name: 'Edit',
//               key: 'spaceEdit',
//             },
//             {
//               name: 'Delete',
//               key: 'spaceDelete',
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: 'Teams',
//       module: [
//         {
//           name: 'Team',
//           key: 'team',
//           actions: [
//             {
//               name: 'Create',
//               key: 'teamCreate',
//             },
//             {
//               name: 'Edit',
//               key: 'teamEdit',
//             },
//             {
//               name: 'Delete',
//               key: 'teamDelete',
//             },
//           ],
//         },
//       ],
//     },
//     {
//       name: 'Apps',
//       module: [
//         {
//           name: 'Opportunity Watch',
//           key: 'opportunityWatch',
//           actions: [
//             {
//               name: 'Create',
//               key: 'opportunityWatchCreate',
//             },
//             {
//               name: 'Edit',
//               key: 'opportunityWatchEdit',
//             },
//             {
//               name: 'Delete',
//               key: 'opportunityWatchDelete',
//             },
//             {
//               name: 'Comment',
//               key: 'opportunityWatchComment',
//             },
//           ],
//         },
//         {
//           name: 'Internal Initiatives',
//           key: 'internalInitiatives',

//           actions: [
//             {
//               name: 'Create',
//               key: 'internalInitiativesCreate',
//             },
//             {
//               name: 'Edit',
//               key: 'internalInitiativesEdit',
//             },
//             {
//               name: 'Delete',
//               key: 'internalInitiativesDelete',
//             },
//           ],
//         },
//         {
//           name: 'Release Notes',
//           key: 'releaseNotes',

//           actions: [
//             {
//               name: 'New',
//               key: 'releaseNotesNew',
//             },
//           ],
//         },
//       ],
//     },
//   ],
// }

export const workSpaceNotifications = {
  defaultValues: {
    app: false,
    email: false,
    todo: false,
    list: false,
    discussion: false,
    documents: false,
  },
  heading: 'Notifications',
  typeHeading: 'Notification Type',
  featureHeading: 'Features',
  setting: [
    {
      name: 'In App Notifications',
      key: 'app',
    },
    {
      name: 'Email Notifications',
      key: 'email',
    },
  ],
  data: [
    {
      name: 'Features',
      module: [
        {
          name: 'Todo',
          key: 'todo',

          actions: [
            {
              name: 'Create',
              key: 'todoCreate',
            },
            {
              name: 'Edit',
              key: 'todoEdit',
            },
            {
              name: 'Delete',
              key: 'todoDelete',
            },
            {
              name: 'Activity',
              key: 'todoActivity',
            },
            {
              name: 'Mark Done',
              key: 'todoMarkDone',
            },
            {
              name: 'Comment',
              key: 'todoComment',
            },
          ],
        },
        {
          name: 'List',
          key: 'list',

          actions: [
            {
              name: 'Create',
              key: 'listCreate',
            },
            {
              name: 'Edit',
              key: 'listEdit',
            },
            {
              name: 'Delete',
              key: 'listDelete',
            },
          ],
        },
        {
          name: 'Discussion',
          key: 'discussion',

          actions: [
            {
              name: 'Create',
              key: 'discussionCreate',
            },
            {
              name: 'Edit',
              key: 'discussionEdit',
            },
            {
              name: 'Delete',
              key: 'discussionDelete',
            },
          ],
        },
        {
          name: 'Documents',
          key: 'documents',

          actions: [
            {
              name: 'Create',
              key: 'documentsCreate',
            },
            {
              name: 'Edit',
              key: 'documentsEdit',
            },
            {
              name: 'Delete',
              key: 'documentsDelete',
            },
          ],
        },
      ],
    },
  ],
}

export const generalNotifications = {
  defaultValues: {
    app: true,
    email: true,
    opportunityWatch: true,
    internalInitiatives: true,
    releaseNotes: true,
    space: true,
    // team: true,
    discussion: false,
  },
  heading: 'Notifications',
  typeHeading: 'Notification Type',
  featureHeading: 'Features',
  setting: [
    {
      name: 'In App Notifications',
      key: 'app',
    },
    {
      name: 'Email Notifications',
      key: 'email',
    },
  ],
  data: [
    {
      name: 'Space ',
      module: [
        {
          name: 'Space',
          key: 'space',
        },
      ],
    },
    // {
    //   name: 'Team ',
    //   module: [
    //     {
    //       name: 'Team',
    //       key: 'team',
    //     },
    //   ],
    // },
    {
      name: 'Apps ',
      module: [
        {
          name: 'Opportunity Watch',
          key: 'opportunityWatch',
        },
        {
          name: 'Internal Initiatives',
          key: 'internalInitiatives',
        },
        {
          name: 'Release Notes',
          key: 'releaseNotes',
        },
        {
          name: 'Discussion',
          key: 'discussion',
        },
      ],
    },
  ],
}
