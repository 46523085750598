import * as serverRequest from './avengersInitiativesCRUD'
import {avengersInitiativesSlice, callTypes} from './avengersInitiativesSlice'

const {actions} = avengersInitiativesSlice

export const fetchAvengersInitiatives =
  ({
    search,
    page,
    sortby,
    orderby,
    currentStatus,
    updateFrequency,
    activity,
    workspace,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    currentStatus: number
    updateFrequency: number
    activity: number
    workspace: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest
      .getAvengersInitiativesList({
        search,
        page,
        sortby,
        orderby,
        currentStatus,
        updateFrequency,
        activity,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(
          actions.avengersInitiativesFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }
export const createAvengersInitiativess =
  (avengersInitiativesToCreate: any, workspaceid: any) => (dispatch: any) => {
    return serverRequest
      .addAvengersInitiativesList(
        avengersInitiativesToCreate.title,
        avengersInitiativesToCreate.description,
        avengersInitiativesToCreate.currentStatus,
        avengersInitiativesToCreate.updateFrequency,
        avengersInitiativesToCreate.activity,
        avengersInitiativesToCreate.assignee,
        avengersInitiativesToCreate.whoWillUpdate,
        avengersInitiativesToCreate.whoWillUpdateTeam,
        avengersInitiativesToCreate.teams
        // avengersInitiativesToCreate.workspace
      )
      .then((response) => {
        const {data}: {data: any; totalCount: any} = response.data
        // dispatch(actions.avengersInitiativesCreated({entities: data}))
        return serverRequest
          .getAvengersInitiativesList({
            search: '',
            page: '',
            sortby: '',
            orderby: '',
            currentStatus: 0,
            updateFrequency: 0,
            activity: 0,
            // assignee,
            // whoWillUpdate,

            // workspace: workspaceid,
          })
          .then((response) => {
            const {data, totalCount, start, end, limitPerPage} = response?.data?.data
            dispatch(
              actions.avengersInitiativesFetched({
                totalCount,
                start,
                end,
                limitPerPage,
                entities: data,
                listLoading: false,
                actionsLoading: false,
              })
            )
          })
      })
  }
export const UpdateAvengersInitiativess = (avengersInitiativesToUpdate: any) => (dispatch: any) => {
  const {
    _id,
    title,
    description,
    currentStatus,
    updateFrequency,
    activity,
    assignee,
    whoWillUpdate,
    whoWillUpdateTeam,
    teams,
    // workspace,
  } = avengersInitiativesToUpdate
  return serverRequest
    .updateAvengersInitiativesList({
      _id,
      title,
      description,
      currentStatus,
      updateFrequency,
      activity,
      assignee,
      whoWillUpdate,
      whoWillUpdateTeam,
      teams,
      // workspace,
    })
    .then((response) => {
      serverRequest
        .getAvengersInitiativesList({
          search: '',
          page: '',
          sortby: '',
          orderby: '',
          currentStatus: 0,
          updateFrequency: 0,
          activity: 0,
          // assignee,
          // whoWillUpdate,

          // workspace: workspace,
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response?.data?.data
          dispatch(
            actions.avengersInitiativesFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
              actionsLoading: false,
            })
          )
          // dispatch(activityAction.fetchSingleAvengersInitiatives(avengersInitiativesToCreate._id))
          // dispatch(
          //   activityAction.fetchAvengersInitiativesActivites({
          //     avengersInitiativesId: avengersInitiativesToCreate._id,
          //     page: '',
          //   })
          // )
        })
    })
}

export const deleteAvengersInitiatives = (_id: string, workspaceid: any) => (dispatch: any) => {
  return serverRequest.deleteAvengersInitiativesList(_id, workspaceid).then((response) => {
    return serverRequest
      .getAvengersInitiativesList({
        search: '',
        page: '',
        sortby: '',
        orderby: '',
        currentStatus: 0,
        updateFrequency: 0,
        activity: 0,
        // workspace: workspaceid,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(
          actions.avengersInitiativesFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  })
}

export const fetchAssignees =
  ({workspaceid}: {workspaceid: any}) =>
  (dispatch: any) => {
    return serverRequest
      .fetchAssignees({
        workspaceid,
      })
      .then((response) => {
        const {data} = response

        dispatch(
          actions.assigneesFetched({
            assignees: data,
          })
        )
      })
  }

export const fetchAvengersInitiative = (_id: string, workspaceid: any) => (dispatch: any) => {
  return serverRequest.fetchAvengersInitiative(_id).then((response) => {
    const {data} = response.data

    dispatch(
      actions.avengersInitiativeFetched({
        entities: data,
      })
    )
  })
}
