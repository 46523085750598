import moment from 'moment'
import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import {Can} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/todo-table-row.scss'
type Props = {
  index: number
  releaseNote: {
    title: string
    version: string
    createdAt: string
    _id: string
  }
  deleteReleaseNote: Function
}

export const NotesRow: React.FC<Props> = ({index, releaseNote, deleteReleaseNote}) => {
  return (
    <tr key={index} className="border-bottom releaseNote">
      <td data-label='Title' title={releaseNote?.title ? releaseNote?.title : '-'}>
        {releaseNote?.title ? (
          <Link to={`/release-notes/${releaseNote?._id}`}>
            <div
              title={releaseNote?.title}
              style={{
                display: 'block',
                width: '280px',
                minWidth: '280px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {releaseNote?.title}
            </div>
          </Link>
        ) : (
          <div className='d-flex align-items-center text-center'>
            <div className='d-flex justify-content-start flex-column'>{'-'}</div>
          </div>
        )}
      </td>
      <td data-label='Version'>
          <div className='d-flex align-items-center text-center'>
            <div className='d-flex justify-content-start flex-column'>
              <div title={releaseNote?.version ? releaseNote?.version : '-'}>
                {releaseNote?.version ? releaseNote?.version : '-'}
              </div>
            </div>
          </div>
      </td>
      <td
        data-label='Release Date'
        className='text-end'
        title={moment(releaseNote?.createdAt).format('MM/DD/YYYY')}
      >
        <div className='d-flex flex-column'>
          <div className='d-flex flex-stack'>
            {moment(releaseNote?.createdAt).format('MM/DD/YYYY')}
          </div>
        </div>
      </td>
      <td data-label='Actions' className='action_btns'>
        <div className=' flex-shrink-0'>
          <Can I='read' this='release-notes'>
            <Link
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='View'
              to={`/release-notes/${releaseNote?._id}`}
            >
              <KTSVG path='/media/icons/view.svg' className='svg-icon-4' />
            </Link>
          </Can>

          <Can I='update' this='release-notes'>
            <Link
              to={`/release-notes/${releaseNote?._id}/edit`}
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
              title='Edit'
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
            </Link>
          </Can>
          <Can I='delete' this='release-notes'>
            <button
              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_delete_app'
              id='kt_toolbar_primary_button'
              onClick={deleteReleaseNote(releaseNote?._id)}
              title='Delete'
            >
              <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
            </button>
          </Can>
        </div>
      </td>
    </tr>
  )
}
