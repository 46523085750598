import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  title: string
  description: string
}

// Define a type for the slice state
interface workspaceMembersState {
  totalCount?: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  assignees?: any
  workspaceDetail?: any
  listLoading: boolean
  actionsLoading: boolean
}

// Define the initial state using that type
const initialState: workspaceMembersState = {
  totalCount: 0,
  entities: [{_id: '', title: '', description: ''}],
  start: 1,
  end: 20,
  limitPerPage: 20,
  assignees: [],
  listLoading: true,
  actionsLoading: true,
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const workspaceMembersSlice = createSlice({
  name: 'workspaceMembers',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startCall: (state) => {
      state.listLoading = true
    },
    workspaceMembersFetched: (state, action: PayloadAction<workspaceMembersState>) => {
      const {entities} = action.payload

      state.entities = entities
    },
    workspaceMembersCreated: (state, action) => {
      const {entities} = action.payload
      state.entities.push(entities)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    workspaceMembersUpdate: (state, action) => {
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    assigneesFetched: (state, action) => {
      const {assignees} = action.payload
      state.assignees = assignees
      state.listLoading = false
    },
    workspaceMemberFetched: (state, action) => {
      const {entities} = action.payload

      state.workspaceDetail = entities
    },
  },
})
