import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const WORKSPACE_URL = `${API_URL}/approvals`

export function getAllApprovals({
  search,
  page,
  sortby,
  orderby,
  workSpace,
}: {
  search: string
  page: number
  sortby: string
  orderby: string
  workSpace: string
}) {
  return axios.get(WORKSPACE_URL, {params: {search, page, sortby, orderby, workSpace}})
}
export function createApproval(
  title: string,
  description: string,
  assignee: [],
  workSpace: string
) {
  return axios.post(`${WORKSPACE_URL}/create`, {title, description, assignee, workSpace})
}
export function updateApproval(_id: string, title: string, description: string, assignee: []) {
  return axios.patch(`${WORKSPACE_URL}/${_id}`, {
    title,
    description,
    assignee,
  })
}

export function deleteApproval(_id: string) {
  return axios.delete(`${WORKSPACE_URL}/${_id}`)
}

export function fetchApproval(_id: string) {
  return axios.get(`${WORKSPACE_URL}/${_id}`)
}
