import React, { useEffect } from 'react'
import { AsideDefault } from './components/aside/AsideDefault'
import { Footer } from './components/Footer'
import { HeaderWrapper } from './components/header/HeaderWrapper'
// import {Toolbar} from './components/toolbar/Toolbar'
import { ScrollTop } from './components/ScrollTop'
import { Content } from './components/Content'
import { PageDataProvider, useLayout } from './core'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../../_metronic/assets/ts/components'
import clsx from 'clsx'
import { UpdateAuthModel } from '../../app/modules/auth/models/UpdateAuthModel'
import { shallowEqual, useSelector } from 'react-redux'
import { RootState } from '../../setup'

const MasterLayout: React.FC = ({ children }) => {
  const authorData: UpdateAuthModel = useSelector<RootState>(
    ({ auth }) => auth,
    shallowEqual
  ) as UpdateAuthModel

  const { classes } = useLayout()

  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  return (
    <PageDataProvider>
      <HeaderWrapper />
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          {/* d-flex flex-column flex-column-fluid mt-5 mb-10 */}
          <div id='kt_content' className='content '>
            {/* <Toolbar /> */}
            <div
              className={
                clsx(
                  'd-flex flex-column-fluid align-items-start',
                  authorData?.user?.settings?.fullWidth ? 'container-fluid p-0' : classes?.contentContainer?.join(' ')
                )
              }
              id='kt_post'
            >
              <AsideDefault />
              <Content>{children}</Content>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {/* begin:: Modals */}
      {/* end:: Modals */}
      <ScrollTop />
    </PageDataProvider>
  )
}

export { MasterLayout }
