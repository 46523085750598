export const timeFormatter = (time: string) => {
  let _d = new Date(time)

  let options: any = {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }
  return _d.toLocaleTimeString('en-us', options)
}

export const dateFormatter = (date: Date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
export const TodoStatuses = ['Todo', 'In Progress', 'Done']
export const ApprovalStatuses = ['Pending', 'Approved', 'Rejected', 'Canceled']

export const findAssigneesForSelect = (addedAssignees: any, assigneeOptions: any): any => {
  let filteredAssignees: any = []
  addedAssignees.map((addedAssignee: any, index: number) => {
    assigneeOptions.map((assignee: any, index: number) => {
      if (assignee.id === addedAssignee._id) {
        filteredAssignees.push({
          value: assignee.value,
          label: assignee.label,
          id: assignee.id,
        })
      }
    })
  })
  return filteredAssignees
}
export const findAssigneeName = (
  assigneeId: String,
  assigneeOptions: any,
  authId: String,
  assigneeIndex: any
): any => {
  let assigneeName: String = ''
  assigneeOptions.map((assigneeOption: any, index: number) => {
    if (assigneeId === assigneeOption._id) {
      assigneeName = (assigneeIndex !== 0 ? ', ' : '') + assigneeOption.firstName
      // assigneeName = assigneeOption.firstName + ','
    }
  })

  return assigneeName
}
export const findAssigneesForTitle = (assigneeId: String, assigneeOptions: any): any => {
  let assigneeName: String = ''
  assigneeOptions.map((assigneeOption: any, index: number) => {
    if (assigneeId === assigneeOption._id) {
      assigneeName = assigneeOption.firstName
    }
  })

  return assigneeName
}
