import { Link, useHistory } from 'react-router-dom'
import '../../_metronic/assets/sass/components/breadcrumbs.scss'
import { toAbsoluteUrl } from '../../_metronic/helpers'

export function Breadcrumbs({ crumbs, containerClass }: any) {
  const history = useHistory()

  return (
    <>
      <div className='row w-100 m-0 d-flex justify-content-center  small-srn-dir'>
        <div className='col-sm-12 py-4 px-sm-10  bg-lighter  align-items-start align-items-sm-center d-flex mt-0'>
          <div className='p-0 mb-0 font-size-sm d-flex'>
            <span>
              <a
                onClick={history.goBack}
                className='position-relative fw-bolder d-flex align-items-center'
              >
                <img
                  src={toAbsoluteUrl('/media/icons/projects/Group 1131.svg')}
                  className='spacing_img'
                />
                {/* <i className='fa fa-arrow-left text-blue fs-3' aria-hidden='true'></i> */}
              </a>
            </span>
            <span>
              <span className='bullet bg-secondary h-20px w-1px mx-3'></span>
            </span>

            <ul id="bc1" className='li-breadcrumb breadcrumb breadcrumb-transparent breadcrumb-lessthan p-0 mb-0 font-size-sm d-flex flex-wrap w-100'>
              {crumbs?.map((list: any, index: number) => {
                const isLast = index === crumbs.length - 1
                if (isLast) {
                  return (
                    // text-name-dot w-25
                    <li className='breadcrumb-item text-blue fs-7 li-default line-clamp' key={index}>
                      {list.name}
                    </li>
                  )
                }
                return (
                  <li className='breadcrumb-item text-muted fs-7 li-default line-clamp' key={index}>
                    <Link to={list.url}>
                      {list.name}
                      <img
                        src={toAbsoluteUrl('/media/icons/projects/Path 4830.svg')}
                        className='spacing_img w-14px'
                      />
                    </Link>
                  </li>
                )
              })}

            </ul>
          </div>
        </div>
      </div>
    </>
  )
}
