/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useRef} from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
// import '../../../../_metronic/assets/sass/components/cards.scss'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import * as actions from '../redux/organizationActions'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {Props, CreateOrganization} from '../organizationModel'

const inits: CreateOrganization = {
  name: '',
  descriptions: '',
  defaultValue: false,
}

const OrganizationEdit: FC<Props> = ({onHide, id}) => {
  const {organizationDetail}: any = useSelector<RootState>(
    (state) => ({
      organizationDetail: state.organization.organizationDetail,
    }),
    shallowEqual
  ) as any

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const dispatch: any = useDispatch()
  const userValidationSchema = {
    name: Yup.string().required('Name is required').label('name'),
    // .min(1, 'Name must contain more than 1 digits'),
    descriptions: Yup.string().nullable(true),
    defaultValue: Yup.boolean(),
  }
  const createAppSchema = Yup.object().shape(userValidationSchema)

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchOrganization({id}))
    }
  }, [])

  const initialValuesOnEdit = {
    name: organizationDetail?.name,
    descriptions: organizationDetail?.descriptions,
    defaultValue: organizationDetail?.default,
  }

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  let crumbs

  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: `Organizations`, url: `/organization`},
    {
      name: id ? 'Edit Organization' : 'Create Organization',
      url: ``,
    },
  ]

  return (
    <div className='mh-80 page-content'>
      <div className='px-6 px-sm-12'>
        <div className='row'>
          <div className='col-sm-6'>
            <h2 className='fw-500 fs-2 m-0 py-5'>
              {id ? 'Edit Organization' : 'Create Organization'}
            </h2>
          </div>
        </div>
      </div>
      <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />

      <div className='overflow_height_scroll'>
        <div className='page-body py-lg-10 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={createAppSchema}
                initialValues={id ? {...inits, ...initialValuesOnEdit} : inits}
                enableReinitialize
                onSubmit={(values: any) => {
                  if (id) {
                    values.id = id
                    dispatch(actions.updateOrganization(values)).then((response: any) => {
                      onHide()
                    })
                  } else {
                    dispatch(actions.createOrganization(values)).then((response: any) => {
                      onHide()
                    })
                  }
                }}
              >
                {({setFieldValue, values, errors}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Name</span>
                          </label>
                          <Field
                            type='text'
                            name='name'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Name'
                            style={{minHeight: '41px', maxHeight: '41px'}}
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span>Description</span>
                          </label>
                          <Field
                            className='form-control form-control-lg form-control-solid'
                            name='descriptions'
                            placeholder='Description'
                            as='textarea'
                            rows='3'
                            id='descriptions'
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='descriptions' />
                          </div>
                        </div>
                        <div className='d-flex flex-row pt-2'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid pb-2 pe-2'>
                            <Field
                              type='checkbox'
                              name='defaultValue'
                              className='form-check-input widget-9-check'
                            />
                          </div>
                          <div className='col-md'>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span>Mark as default</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bolder me-3 fs-7 cancel_button'
                          onClick={onHide}
                          title='Cancel'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary btn-blue fs-7'
                          title={id ? 'Update' : 'Create'}
                        >
                          <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {OrganizationEdit}
