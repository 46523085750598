import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // This only needs to be imported once in your app

interface Props {
  images: string[]
  isOpen: boolean
  setIsOpen: any
  photoIndex: number
  setPhotoIndex: any
}

const IGLightbox: React.FC<Props> = ({images, isOpen, setIsOpen, photoIndex, setPhotoIndex}) => {
  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => {
            let index = (photoIndex + images.length - 1) % images.length
            setPhotoIndex(index)
          }}
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex: any) => (photoIndex + 1) % images.length)
          }
        />
      )}
    </>
  )
}

export {IGLightbox}
