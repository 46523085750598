import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const OPPORTUNITY_WATCH_URL = `${API_URL}/opportunity-watch`
export function getOpportunityWatchList({
  search,
  page,
  orderby,
  sortby,
  status,
  createdAt,
  activeDate,
}: // assignee,
// teams,
// workSpace,
{
  search: string
  page: number | string
  orderby: string
  sortby: string
  status: string
  createdAt: string
  activeDate: string
  // assignee: any
  // teams: any
  // workSpace: string | null
}) {
  // assignee = JSON.stringify(assignee)

  return axios.get(OPPORTUNITY_WATCH_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      status,
      createdAt,
      activeDate,
      // assignee,
      // teams,
      // workSpace,
    },
  })
}
export function addOpportunityWatchList({
  technology,
  plan,
  status,
  activeDate,
  lead,
  attachmentPaths,
}: {
  technology: string
  plan: string
  status: number
  activeDate: string
  lead: string
  attachmentPaths: string[]
}) {
  return axios.post(OPPORTUNITY_WATCH_URL, {
    technology,
    plan,
    status,
    activeDate,
    lead,
    attachmentPaths,
  })
}
export function updateOpportunityWatchList({
  id,
  technology,
  plan,
  status,
  activeDate,
  lead,
  attachmentPaths,
}: {
  id: string
  technology: string
  plan: string
  status: number
  activeDate: string
  lead: string
  attachmentPaths: string[]
}) {
  return axios.patch(`${OPPORTUNITY_WATCH_URL}/${id}`, {
    id,
    technology,
    plan,
    status,
    activeDate,
    lead,
    attachmentPaths,
  })
}

export function deleteOpportunityWatchList(_id: string, workspace: string | null) {
  return axios.delete(`${OPPORTUNITY_WATCH_URL}/${_id}`)
}

export function fetchOpportunityWatchById(_id: string) {
  return axios.get(`${OPPORTUNITY_WATCH_URL}/${_id}`)
}
