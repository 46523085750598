import React from 'react'
import {ApprovalTable} from './components/ApprovalTable'
import {ApprovalEdit} from './components/ApprovalEdit'
import {Route, Switch} from 'react-router-dom'

const Approvals: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/:workspaceid/approvals'>
          <ApprovalTable className='mb-5 mb-xl-8' />
        </Route>
        <Route exact path='/:workspaceid/approvals/add'>
          {({history, match}) => {
            return (
              <ApprovalEdit
                onHide={() => {
                  history.push(`/${match?.params?.workspaceid}/approvals`)
                }}
              />
            )
          }}
        </Route>
        <Route exact path='/:workspaceid/approvals/:id/edit'>
          {({history, match}) => (
            <ApprovalEdit
              id={match && match.params.id}
              onHide={() => {
                history.push(`/${match?.params?.workspaceid}/approvals`)
              }}
            />
          )}
        </Route>
      </Switch>
    </>
  )
}

export {Approvals}
