import {useFormikContext} from 'formik'
import {useEffect} from 'react'

export const FormikOnChangeSubmit = () => {
  const formik = useFormikContext()

  useEffect(() => {
    if (formik.values !== formik.initialValues) {
      formik.submitForm()
    }
  }, [formik.values])

  return null
}
