export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      if (accessToken && !window.location.search.includes('widget')) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )

  axios.interceptors.response.use(
    (response: any) => {
      return response
    },
    (error: any) => {
      if (error.response.status === 401) {
        localStorage.clear()
        // window.location.reload()
        window.location.assign('/login')
        //place your reentry code
      }
      return Promise.reject(error)
    }
  )
}
