import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import {todoSlice} from '../../app/modules/Todo/redux/TodoSlice'
import {workspaceSlice} from '../../app/modules/workspace/redux/WorkspaceSlice'
import {approvalsSlice} from '../../app/modules/approvals/redux/ApprovalsSlice'
// import {discussionsSlice} from '../../app/modules/chat/redux/DiscussionSlice'
import {discussionsSlice} from '../../app/modules/workspace/components/chat/redux/DiscussionSlice'
import {teamsSlice} from '../../app/modules/accounts/components/settings/teams/redux/teamsSlice'
import {opportunityWatchSlice} from '../../app/modules/opportunityWatch/redux/OpportunityWatchSlice'
import {avengersInitiativesSlice} from '../../app/modules/avengersInitiatives/redux/avengersInitiativesSlice'
import * as auth from '../../app/modules/auth'
import {listSlice} from '../../app/modules/list/redux/listSlice'
import {ReleaseNoteSlice} from '../../app/modules/releaseNotes/redux/ReleaseNoteSlice'
import {workspaceMembersSlice} from '../../app/modules/workspaceMembers/redux/workspaceMembersSlice'
import {generalActivityLogsSlice} from '../../app/modules/generalActivityLogs/redux/activityLogsSlice'
import {NotificationSlice} from '../../app/modules/notifications/redux/NotificationSlice'
import {allNotificationSlice} from '../../app/modules/notifications/redux/listing/AllNotificationSlice'
import {documentSlice} from '../../app/modules/documents/redux/DocumentSlice'
import {notificationSlice} from '../../app/modules/accounts/components/settings/notification/redux/NotificationSlice'
import {WorkSpaceNotificationSlice} from '../../app/modules/workspace/components/notifications/redux/NotificationSlice'
import {boardSlice} from '../../app/modules/board/redux/BoardSlice'
import {projectSlice} from '../../app/modules/project/redux/ProjectSlice'
import {taskSlice} from '../../app/modules/Task/redux/TaskSlice'
import {talentSlice} from '../../app/modules/talent/redux/talentSlice'
import {SettingsSlice} from '../../app/modules/settings/redux/settingsSlice'
import {organizationSlice} from '../../app/modules/organizations/redux/organizationSlice'
export const rootReducer = combineReducers({
  auth: auth.reducer,
  todo: todoSlice.reducer,
  task: taskSlice.reducer,
  workspace: workspaceSlice.reducer,
  approvals: approvalsSlice.reducer,
  teams: teamsSlice.reducer,
  discussions: discussionsSlice.reducer,
  project: projectSlice.reducer,
  board: boardSlice.reducer,
  opportunityWatch: opportunityWatchSlice.reducer,
  list: listSlice.reducer,
  avengersInitiatives: avengersInitiativesSlice.reducer,
  releasenote: ReleaseNoteSlice.reducer,
  workspaceMembers: workspaceMembersSlice.reducer,
  activities: generalActivityLogsSlice.reducer,
  notification: NotificationSlice.reducer,
  notificationListing: allNotificationSlice.reducer,
  notifications: notificationSlice.reducer,
  workSpaceNotifications: WorkSpaceNotificationSlice.reducer,
  documents: documentSlice.reducer,
  settings: SettingsSlice.reducer,
  talent: talentSlice.reducer,
  organization: organizationSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
