/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import moment from 'moment'
import {FC, useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import '../../../../_metronic/assets/sass/components/comments.scss'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import * as actions from '../../../../app/modules/notifications/redux/NotificationAction'
import * as allNotificationActions from '../../../../app/modules/notifications/redux/listing/AllNotificationAction'

import {RootState} from '../../../../setup'

import {
  convertTemplateToPlainText,
  makeRouter,
  replaceNotificationType,
} from '../../../../app/Common/notification/CommonFunctions'
import * as discussionActions from '../../../../app/modules/workspace/components/chat/redux/DiscussionActions'

const HeaderNotificationsMenu: FC = () => {
  const dispatch = useDispatch()
  const myInterval: any = useRef()

  type Notification = {
    end: number
    limitPerPage: number
    message?: string
    start?: number
    totalCount?: number
    unSeenCount?: number
    data: Array<object>
  }

  const {notificationData}: any = useSelector<RootState>(
    (state) => ({
      notificationData: state.notification.entities,
      auth: state.auth,
    }),
    shallowEqual
  ) as Notification

  const {auth}: any = useSelector<RootState>(
    (state) => ({
      auth: state.auth?.user,
    }),
    shallowEqual
  ) as any

  useEffect(() => {
    if (auth) {
      getNotifications()
      myInterval.current = setInterval(() => {
        getNotifications()
      }, 10000)
    } else {
      clearInterval(myInterval.current)
    }
  }, [auth])

  const getNotifications = () => {
    if (auth) {
      dispatch(
        actions.fetchNotification({
          search: '',
          page: '',
          sortby: '',
          orderby: '',
          createdAt: '',
        })
      )
    }
  }

  const renderSingleNotification = (
    template: string,
    from: string,
    fromId: string,
    workSpace: string,
    list: string,
    todo: string,
    opportunityWatch: string,
    discussion: string,
    avengersInitiative: string
  ) => {
    return (
      <div
        className='text-gray-800 fs-8 mt-2 menu-style-note w_100px'
        dangerouslySetInnerHTML={{
          __html: convertTemplateToPlainText(
            template,
            from,
            fromId,
            workSpace,
            list,
            todo,
            opportunityWatch,
            avengersInitiative,
            discussion,
            auth
          ),
        }}
      ></div>
    )
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px border_radius_11px'
      data-kt-menu='true'
    >
      <div className='d-flex flex-column border_bottom_notification'>
        <h3 className='fw-bold px-9 mt-5 mb-5'>
          Notifications{' '}
          {notificationData?.data?.length > 0 && (
            <span className='fs-8 opacity-75  border-count ms-4'>
              {notificationData?.unSeenCount}
            </span>
          )}
          {notificationData?.unSeenCount !== 0 && (
            <Link
              className='fs-8  ps-3 opacity-76 text-hover-opacity-100'
              onClick={() => {
                dispatch(actions.UpdateNotification())
                dispatch(allNotificationActions.UpdateNotification(1))
              }}
              style={{float: 'right', marginTop: '5px'}}
              to={'#'}
            >
              Mark all as read
            </Link>
          )}
        </h3>
      </div>

      <div className='tab-content'>
        <div className={'tab-pane fade show active'} id='kt_topbar_notifications_1' role='tabpanel'>
          <div className='scroll-y mh-325px  px-0'>
            {notificationData?.data?.length > 0 ? (
              notificationData?.data?.map((notification: any, index: number) => {
                return (
                  <Link
                    key={`alert${index}`}
                    className={`d-flex flex-stack py-2 px-2 border_bottom_notification ${
                      !notification.isSeen ? 'active_notification' : ''
                    }`}
                    to={makeRouter(notification.itemType, notification.item)}
                    onClick={() => {
                      if (notification.itemType.includes('Chat')) {
                        dispatch(
                          discussionActions.chatIdByNotification(notification.item.discussion._id)
                        )
                      }
                      if (!notification.isSeen)
                        dispatch(actions.UpdateANotification(notification._id))
                    }}
                  >
                    <div className='d-flex align-items-center'>
                      <div title='Profile' className={'cursor-pointer symbol pe-3'}>
                        <img
                          className='rounded-circle'
                          src={toAbsoluteUrl('/media/avatars/blank.png')}
                          alt='profile_picture'
                          style={{height: 40, width: 40}}
                        />
                      </div>

                      <div className='mb-0 me-2' style={{lineHeight: 1.2}}>
                        {renderSingleNotification(
                          notification?.template,
                          notification?.from?.fullName,
                          notification?.from?._id,
                          notification?.item?.workSpace?.name,
                          notification?.item?.list?.title,
                          notification?.item?.todo?.title,
                          notification?.item?.opportunityWatch?.lead,
                          notification?.item?.discussion?.chatName,
                          notification?.item?.avengersInitiative?.title
                        )}

                        <div className='text-gray-400 fs-9 mt-2'>
                          {notification.itemType[0] == 'Chat'
                            ? replaceNotificationType('AppChat')
                            : replaceNotificationType(
                                notification.itemType[notification.itemType.length - 1]
                              )}
                        </div>
                      </div>
                    </div>
                    <span
                      className={clsx(
                        'badge fs-8 ',
                        `${
                          !notification.isSeen
                            ? 'badge-light-primary'
                            : 'badge-white me-5 fw-normal'
                        }`
                      )}
                    >
                      {moment(notification.createdAt).fromNow()}
                    </span>
                  </Link>
                )
              })
            ) : (
              <div className='d-flex flex-column align-items-center'>
                <h4 className='text-black fw-bold mt-15 mb-15'>No notifications</h4>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-center pt-4 pb-4'>
        {notificationData?.data?.length !== 0 && <Link to='/notifications'>View All</Link>}
      </div>
    </div>
  )
}

export {HeaderNotificationsMenu}
