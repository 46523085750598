/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import '../../../_metronic/assets/sass/components/cards.scss'
import { Link, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Breadcrumbs } from '../Breadcrumbs'
import * as actions from '../../modules/workspace/redux/WorkspaceActions'
import '../../../_metronic/assets/sass/components/cards.scss'
import { Loading } from '../../../_metronic/layout/Loading'
import { Can } from '../../Common/RBAC/Can'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import axios from 'axios'
import moment from 'moment'
import { getNewSpaceMembers } from '../UIHelper'
import { RootState } from '../../../setup'
import { modifyDashboardIconsURL } from '../../../_metronic/helpers/modifyDashboardIconsURL'
import SVG from 'react-inlinesvg'

const SpaceDetail: FC = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const SET_FEATURES_ICONS = `${API_URL}/apps/enabledfeatures`
  const { workspaceid }: { workspaceid: string } = useParams()

  const dispatch: any = useDispatch()
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([])
  const [enabledFeatures, setEnabledFeatures] = useState<String[]>([])
  const [spaceDetail, setSpaceDetail] = useState<any>({})
  const [dashIconType, setdashIconType] = useState('')
  const { isLoading, settings, user, dashSettings }: any = useSelector<any>(
    (state) => ({
      isLoading: state.workspace.listLoading,
      settings: state.auth.user.settings,
      user: state.auth.user,
      dashSettings: state.settings.entities,
    }),
    shallowEqual
  )
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any
  const fetchEnabledFeaturesData = async () => {
    const enabledSystemFeatures = await axios.get(SET_FEATURES_ICONS)
    setEnabledFeatures(enabledSystemFeatures.data.data)
  }

  useEffect(() => {
    const iconType = dashSettings.filter((data: any, index: any) => {
      return data.name === 'dashMediaIcons'
    })
    setdashIconType(iconType[0]?.value)
  }, [dashSettings])

  useEffect(() => {
    if (workspaceid) {
      fetchEnabledFeaturesData()
      dispatch(actions.fetchWorkspace(workspaceid)).then((data: any) => {
        setSpaceDetail(data)

        setSelectedFeatures(data?.features)
      })
    }
  }, [dispatch, workspaceid])

  const isAuthorized = () => {
    if (user._id === spaceDetail?.owner?._id) {
      return true
    } else if (spaceDetail?.maintainers?.some((e: any) => e._id === user._id)) {
      return true
    } else {
      return false
    }
  }

  let crumbs: any

  if (Object.keys(spaceDetail).length) {
    crumbs = [
      { name: 'Spaces', url: '/dashboard' },
      { name: `${spaceDetail?.name}`, url: `/${spaceDetail?._id}/features` },
    ]
  }
  const workSpaceMembers = getNewSpaceMembers(
    spaceDetail?.assignee,
    spaceDetail?.teams,
    spaceDetail?.maintainers,
    spaceDetail?.owner,
    accessToken
  )
  return (
    <>
      <section className='tabs-main'>
        <div className=''>
          <div className='card card-custom h-100'>
            <div className='card-body p-0'>
              <div className='tab-content h-100'>
                <div className='mh-80 tab-pane tab-pane2 active'>
                  <div className="px-6 px-sm-12">
                    <div className="row padding_bottom_header">
                      <div className="col-sm-6 pe-md-20">
                        <h2 className='fw-500 fs-2 m-0 py-5'>
                          {spaceDetail?.name}
                        </h2>
                      </div>

                      <div className="col-sm-6 d-flex justify-content-start justify-content-sm-end align-items-center flex-wrap flex-sm-nowrap">
                        <div className=''>
                          <span className='text-muted fs-7 mb-0 me-2' style={{ whiteSpace: 'nowrap' }}>
                            Created By
                          </span>

                          <span className='fs-7  mb-0 me-2' style={{ whiteSpace: 'nowrap' }}>
                            {spaceDetail?.owner?.fullName}
                          </span>

                          <span className='text-muted fs-7 mb-0 me-2' style={{ whiteSpace: 'nowrap' }}>
                            on {moment(spaceDetail?.createdAt).format('DD MMM, YYYY')}
                          </span>
                        </div>

                        <div className='d-flex align-items-center'>
                          <span className='fs-7  mb-0 me-6' style={{ whiteSpace: 'nowrap' }}>
                            Members
                          </span>

                          <div className='d-flex px-1 spaces_card_img'>
                            <div className='symbol-group'>


                              {workSpaceMembers?.map((user: any, index: number, row: any) => {
                                if (index < 5) {
                                  return row.length > 5 && index == 4 ? (
                                    <div
                                      className='symbol symbol-35px symbol-circle symbol_service'
                                      key={index}
                                    >
                                      <img
                                        className='symbol-label fs-8 fw-bolder'
                                        src={
                                          user?.image !== ''
                                            ? user.image
                                            : toAbsoluteUrl('/media/avatars/blank.png')
                                        }
                                        alt={user?.fullName}
                                        title={user?.firstName}
                                      />
                                      <span>
                                        {row?.length > 5 ? '+' + (row?.length - 5) : ''}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className='symbol symbol-35px symbol-circle' key={index}>
                                      <img
                                        src={
                                          user?.image !== ''
                                            ? user.image
                                            : toAbsoluteUrl('/media/avatars/blank.png')
                                        }
                                        alt={user?.fullName}
                                        title={user?.firstName}
                                      />
                                    </div>
                                  )
                                }
                              })}

                              {/* <div className='symbol symbol-35px symbol-circle symbol_service'>
                                <img className='symbol-label fs-8 fw-bolder'
                                  src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />
                              </div>


                              <div className='symbol symbol-35px symbol-circle symbol_service'>
                                <img className='symbol-label fs-8 fw-bolder'
                                  src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />
                              </div>
                              <div className='symbol symbol-35px symbol-circle symbol_service'>
                                <img className='symbol-label fs-8 fw-bolder'
                                  src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />

                              </div>
                              <div className='symbol symbol-35px symbol-circle symbol_service'>
                                <img
                                  className='symbol-label fs-8 fw-bolder'
                                  src={user?.image !== '' ? user.image : toAbsoluteUrl('/media/avatars/blank.png')} alt={user?.fullName} title={user?.firstName} />

                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {crumbs?.length > 0 && <Breadcrumbs crumbs={crumbs} />}
                  {isLoading && <Loading />}

                  <div className="overflow_height_scroll">
                    <div className='margins-dashboard d-flex mx-sm-10 dashboard_space mt-10 pt-5 mb-10 flex-wrap cards-wrapper justify-content-center justify-content-md-start '>
                      {!isLoading &&
                        selectedFeatures?.length > 0 &&
                        enabledFeatures?.map((feature: any, index: number) => {
                          return (
                            <React.Fragment key={index}>
                              {(selectedFeatures.includes(feature.appKey) || feature.systemApp) &&
                                feature.isEnabled &&
                                (isAuthorized() || feature.appKey !== 'settings') && (
                                  <div className='d-flex mb-4 rounded-2' title={feature.tooltip}
                                    style={{ zoom: (+settings?.iconSize + 37) + '%' }}>
                                    <div className='demo-icons'>
                                      <div className='col rounded-2'>
                                        <div className='card-item rounded-2'>
                                          <Can I='list' this='spaces'>
                                            <Link
                                              to={
                                                feature.appKey === 'settings'
                                                  ? `/space/${workspaceid}/edit`
                                                  : `/${workspaceid}/${feature.appKey}`
                                              }
                                            >
                                              <div
                                                className='icon'
                                                style={{
                                                  backgroundColor: feature.backgroundColor,
                                                }}
                                              >
                                                <SVG src={modifyDashboardIconsURL(
                                                  dashIconType,
                                                  toAbsoluteUrl(`/${feature.icon}`)
                                                )} />
                                                {/* <KTSVG path={`/${feature.icon}`} noClass={true} /> */}
                                              </div>
                                              <div className='title'>
                                                <h6>{feature.tooltip}</h6>
                                              </div>
                                            </Link>
                                          </Can>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </React.Fragment>
                          )
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export { SpaceDetail }
