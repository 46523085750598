/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import clsx from 'clsx'
import '../../../_metronic/assets/sass/components/cards.scss'
// import '../../../_metronic/assets/sass/components/wizards.scss'
import {Link} from 'react-router-dom'
import {SpacesDropdown} from './SpacesDropdown'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'

const DashboardWrapper10: FC = () => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('vteamsTab1')

  return (
    <>
      <section className='tabs-main mb-5 pb-5'>
        <div className='container px-0'>
          <div className='card card-custom'>
            <div className='card-body p-0'>
              <div className='tab-content'>
                <div className='tab-pane tab-pane2 pb-5 active rounded'>
                  <div className='row drag-drop-card-main mx-md-20 mx-10 g-4'>
                    <div className='col-12 w-100 row mx-auto p-0'>
                      <div className='col-12 d-flex justify-content-start px-0'>
                        <div className='card-toolbar'>
                          {/* begin::Menu */}
                          <button
                            type='button'
                            className='btn bg-primary text-white position-relative mt-10 mb-7'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-start'
                            data-kt-menu-flip='top-start'
                          >
                            <Link to='/dashboard8' className='fw-bolder text-white fs-6'>
                              <i className='fa fa-angle-left text-white'></i> Back
                            </Link>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 1</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          {/* <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p> */}
                          <div className='d-flex w-100 justify-content-between'>
                            <span className='d-flex align-items-center'>
                              <b className='pe-2'>Key:</b>
                              <Link to='#' style={{fontSize: '12px', lineHeight: '21px'}}>
                                3123123
                              </Link>
                            </span>
                            <span style={{fontSize: '12px'}}>30 March, 2022</span>
                          </div>
                          <div className='d-flex w-100 justify-content-between'>
                            <span className='d-flex'>
                              <b className='pe-2'>Members:</b>
                              <span className='spaces-members'>
                                Sobia Yaseen, Kashif Anwar ,Sobia Yaseen, Kashif Anwar ,Sobia
                                Yaseen, Kashif Anwar ,Sobia Yaseen, Kashif Anwar ,Sobia Yaseen,
                                Kashif Anwar ,Sobia Yaseen, Kashif Anwar{' '}
                              </span>
                            </span>
                          </div>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <Link to='/' className='card'>
                        <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                          <div className='card-header border-0 justify-content-center'>
                            <div className='card-title'>
                              <h1 style={{color: '#999'}}>Create a space</h1>
                              <i className='fa fa-plus ps-3 fs-3' style={{color: '#999'}}></i>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 border bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between mt-2 mb-3'>
                          <div
                            className='card-title text-dark-gray bg-dark-gray px-4 py-3'
                            style={{width: '85px'}}
                          ></div>
                          <div className='card-toolbar text-dark-gray bg-dark-gray px-4 py-3'></div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <div className='d-flex w-100 text-dark-gray bg-dark-gray py-3 mb-1'></div>
                          <div className='d-flex w-100 text-dark-gray bg-dark-gray py-3 mb-1'></div>
                          <div
                            className='card-title text-dark-gray bg-dark-gray px-4 py-3 mt-3 mb-0'
                            style={{width: '85px'}}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 4</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <p className=''>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum industry's
                          </p>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 5</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <p className=''>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum industry's
                          </p>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 6</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever
                            since the 1500s, when an unknown printer took a galley of type and
                            scrambled. Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 7</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <p className=''>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum industry's
                          </p>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 8</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <p className=''>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum industry's
                          </p>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-4 col-md-6 mt-0'>
                      <div className='drag-drop-card card mb-5 bg-gray justify-content-evenly'>
                        <div className='card-header border-0 justify-content-between'>
                          <div className='card-title'>
                            <h3 className='card-label'>Space 9</h3>
                          </div>
                          <div className='card-toolbar'>
                            <a
                              href='#'
                              className='btn btn-icon btn-hover-light-primary draggable-handle justify-content-end'
                            >
                              <i className='fa fa-cog'></i>
                            </a>
                          </div>
                        </div>
                        <div className='card-body pt-0 d-flex justify-content-between align-items-start flex-column'>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                            industry. Lorem Ipsum has been the industry's standard dummy text ever
                            since the 1500s, when an unknown printer took a galley of type and
                            scrambled. Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry.
                          </p>
                          <a href='#' className='btn btn-primary font-weight-bold py-1 px-4 w-auto'>
                            Go to Space
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export {DashboardWrapper10}
