import {useRef} from 'react'
import {throttle} from 'lodash'

/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
// import {MAIN_APP_SIDEBAR_ID} from '../../constants/global'
const MAIN_APP_SIDEBAR_ID = 'kanban-main'

/*------------------------------------------------*/
/* CONSTANTS
/*------------------------------------------------*/
const OFFSET = 50
const PX_DIFF = 5

/*------------------------------------------------*/
/* GLOBAL VARIABLES
/*------------------------------------------------*/
let scrollIncrementRightLeft = 0
let sidebarElement: any = null

const useDragScrolling = () => {
  const isScrolling = useRef(false)

  const goDown = () => {
    document.documentElement.scrollTop += 6

    const {offsetHeight, scrollTop, scrollHeight} = document.documentElement
    const isScrollEnd = offsetHeight + scrollTop >= scrollHeight

    if (isScrolling.current && !isScrollEnd) {
      window.requestAnimationFrame(goDown)
    }
  }

  const goUp = () => {
    document.documentElement.scrollTop -= 6

    if (isScrolling.current && window.scrollY > 0) {
      window.requestAnimationFrame(goUp)
    }
  }

  /*------------------------------------------------*/
  /* METHODS
/*------------------------------------------------*/

  /**
   * Scroll right in the sidebar.
   */
  const goRight = () => {
    scrollIncrementRightLeft = PX_DIFF + scrollIncrementRightLeft
    sidebarElement.scrollLeft = scrollIncrementRightLeft

    if (isScrolling && scrollIncrementRightLeft >= 0) {
      window.requestAnimationFrame(goRight)
    }
  }

  /**
   * Scroll left in the sidebar.
   */
  const goLeft = () => {
    scrollIncrementRightLeft = scrollIncrementRightLeft - PX_DIFF
    sidebarElement.scrollLeft = scrollIncrementRightLeft
    // && scrollIncrement >= 0

    if (isScrolling && scrollIncrementRightLeft > 0) {
      window.requestAnimationFrame(goLeft)
    }
  }

  const onDragOver = (event: any) => {
    if (
      (sidebarElement?.scrollLeft >= 0 &&
        sidebarElement?.scrollLeft <
          sidebarElement.parentNode.parentNode.parentNode?.offsetWidth + 100) ||
      scrollIncrementRightLeft === 0
    ) {
      scrollIncrementRightLeft = sidebarElement?.scrollLeft || 0
    }
    const isMouseOnTop = event.clientY < 100
    const isMouseOnDown = window.innerHeight - event.clientY < 50

    const isMouseOnLeft =
      scrollIncrementRightLeft > 0 && 600 < sidebarElement?.offsetWidth - event.clientX

    const isMouseOnRight =
      scrollIncrementRightLeft >= 0 &&
      scrollIncrementRightLeft <= sidebarElement?.offsetWidth &&
      OFFSET > sidebarElement?.offsetWidth - event.clientX

    if (!isScrolling.current && (isMouseOnTop || isMouseOnDown)) {
      isScrolling.current = true

      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp)
      }

      if (isMouseOnDown) {
        window.requestAnimationFrame(goDown)
      }

      if (isMouseOnRight) {
        window.requestAnimationFrame(goRight)
      } else if (isMouseOnLeft) {
        window.requestAnimationFrame(goLeft)
      }
    } else if (!isMouseOnTop && !isMouseOnDown) {
      isScrolling.current = false
    }
  }

  const throttleOnDragOver = throttle(onDragOver, 300)

  const addEventListenerForWindow = () => {
    window.addEventListener('dragover', throttleOnDragOver, false)
  }

  const removeEventListenerForWindow = () => {
    window.removeEventListener('dragover', throttleOnDragOver, false)
    isScrolling.current = false
  }

  return {
    addEventListenerForWindow,
    removeEventListenerForWindow,
  }
}

export default useDragScrolling
