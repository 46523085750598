import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const DOCUMENT_URL = `${API_URL}/documents`
const TODO_ATTACHMENT_DATA = `${API_URL}/attachments`

export function getDocumentsList({
  search,
  page,
  orderby,
  sortby,
  workspace,
  parent,
  grandParent,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  workspace: string | null
  parent: string | null
  grandParent?: string | null
}) {
  return axios.get(DOCUMENT_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      workspace,
      parent,
      grandParent,
    },
  })
}

export function addDocumentList({
  name,
  document,
  workspace,
  type,
  parent,
}: {
  name: string
  document: []
  workspace: string | null
  type: string
  parent: string | null
}) {
  return axios.post(DOCUMENT_URL, {
    name,
    document,
    workspace,
    type,
    parent,
  })
}
export function updateDocumentList({_id, name}: {_id: string; name: string}) {
  return axios.patch(
    `${DOCUMENT_URL}/${_id}`,
    {
      _id,
      name,
    },
    {params: {_id}}
  )
}

export function shareFile({
  _id,
  sharedWith,
  showOnFront,
}: {
  _id: string
  sharedWith: string
  showOnFront: string
}) {
  return axios.patch(
    `${DOCUMENT_URL}/${_id}`,
    {
      _id,
      sharedWith,
      showOnFront,
    },
    {params: {_id}}
  )
}

export function deleteDocumentList(_id: string) {
  return axios.delete(`${DOCUMENT_URL}/${_id}`)
}

export function fetchDocument(_id: string) {
  return axios.get(`${DOCUMENT_URL}/${_id}`)
}
