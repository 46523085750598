import {AppDispatch} from '../../../../setup/redux/Store'
import * as serverRequest from './DocumentCrud'
import {documentSlice, callTypes} from './DocumentSlice'

const {actions} = documentSlice

export const fetchDocuments =
  ({
    search,
    page,
    sortby,
    orderby,
    workspace,
    parent,
    grandParent,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    workspace: string
    parent: string
    grandParent: string
  }) =>
  (dispatch: any) => {
    return serverRequest
      .getDocumentsList({search, page, sortby, orderby, workspace, parent, grandParent})
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data
        dispatch(
          actions.documentsFetched({
            page,
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  }

export const createDocuments =
  (todoToCreate: any, workspaceid: any, parent: any) => (dispatch: any) => {
    const {name, document, type} = todoToCreate
    return serverRequest
      .addDocumentList({
        name,
        document,
        workspace: workspaceid,
        type,
        parent,
      })
      .then((response) => {
        const {data, totalCount}: {data: any; totalCount: any} = response.data
        dispatch(actions.documentsCreated({totalCount, entities: data}))
        // if (data.attachmentPaths.length > 0) {
        //   serverRequest.addTodoAttachmentData(data)
        // }

        dispatch(actions.startCall({callType: callTypes.action}))
        serverRequest
          .getDocumentsList({
            search: '',
            page: '',
            orderby: 'desc',
            sortby: 'createdAt',
            workspace: workspaceid,
            parent: parent,
          })
          .then((response) => {
            const {data, totalCount, start, end, limitPerPage, page} = response.data
            dispatch(
              actions.documentsFetched({
                page,
                totalCount,
                start,
                end,
                limitPerPage,
                entities: data,
                listLoading: false,
                actionsLoading: false,
              })
            )
          })
      })
  }

export const UpdateDocument =
  (documentsToUpdate: any, workspaceid: any, parentid: any) => (dispatch: any) => {
    const {_id, name} = documentsToUpdate
    return serverRequest
      .updateDocumentList({
        _id,
        name,
      })
      .then(() => {
        serverRequest
          .getDocumentsList({
            search: '',
            page: '',
            orderby: 'desc',
            sortby: 'createdAt',
            workspace: workspaceid,
            parent: parentid,
          })
          .then((response) => {
            dispatch(actions.startCall({callType: callTypes.action}))
            const {data, page, totalCount, start, end, limitPerPage} = response.data
            dispatch(
              actions.documentsFetched({
                page,
                totalCount,
                start,
                end,
                limitPerPage,
                entities: data,
                listLoading: false,
                actionsLoading: false,
              })
            )
          })
      })
  }

export const ShareFile =
  (documentsToUpdate: any, workspaceid: any, parentid: any) => (dispatch: any) => {
    const {_id, sharedWith, showOnFront} = documentsToUpdate
    return serverRequest
      .shareFile({
        _id,
        sharedWith,
        showOnFront,
      })
      .then(() => {
        serverRequest
          .getDocumentsList({
            search: '',
            page: '',
            orderby: 'desc',
            sortby: 'createdAt',
            workspace: workspaceid,
            parent: parentid,
          })
          .then((response) => {
            dispatch(actions.startCall({callType: callTypes.action}))
            const {data, page, totalCount, start, end, limitPerPage} = response.data
            dispatch(
              actions.documentsFetched({
                page,
                totalCount,
                start,
                end,
                limitPerPage,
                entities: data,
                listLoading: false,
                actionsLoading: false,
              })
            )
          })
      })
  }

export const deleteDocument = (_id: string, workspaceid: any, parent: any) => (dispatch: any) => {
  return serverRequest.deleteDocumentList(_id).then((response) => {
    serverRequest
      .getDocumentsList({
        search: '',
        page: '',
        orderby: 'desc',
        sortby: 'createdAt',
        workspace: workspaceid,
        parent: parent,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage, page} = response.data
        dispatch(
          actions.documentsFetched({
            page,
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
  })
}

export const loadAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}
// export const removeTodoDetail = (data: any) => (dispatch: any) => {
//   dispatch(actions.loadAttachments(data))
//   //   dispatch(actions.todoFetched(data))
// }
export const removeAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}

export const fetchDocument = (_id: string) => (dispatch: AppDispatch) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return serverRequest.fetchDocument(_id).then((response) => {
    const {data} = response
    dispatch(actions.documentFetched({data}))
    return data
  })
}
