import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  name: string
  description: string
  slug: string
  default: boolean
  owner: string
  type: number
}

// Define a type for the slice state
interface approvalsState {
  approvalsDetail?: entities
  totalCount: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  page: number
  error?: any
}

// Define the initial state using that type
const initialState: approvalsState = {
  totalCount: 0,
  entities: [{_id: '', name: '', description: '', slug: '', default: false, owner: '', type: 1}],
  start: 1,
  end: 11,
  limitPerPage: 11,
  page: 1,
  error: '',
}

export const approvalsSlice = createSlice({
  name: 'approvals',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<approvalsState>) => {
      state.error = `${action.payload}`
    },
    approvalsFetched: (state, action: PayloadAction<approvalsState>) => {
      const {page, totalCount, entities, start, end} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
      state.page = page
    },
    approvalsCreated: (state, action) => {
      const {data} = action.payload

      state.entities.push(data)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    approvalsUpdated: (state, action) => {
      const {data} = action.payload
      if (state && state.entities.length > 0) {
        state.entities = state.entities.map((entity: entities) => {
          if (entity._id === data._id) {
            return data
          }
          return entity
        })
      }
    },
    // deleteApproval
    approvalsDeleted: (state, action) => {
      state.entities = state.entities.filter((el) => el._id !== action.payload._id)
    },
    approvalFetched: (state, action) => {
      const {data} = action.payload

      state.approvalsDetail = data
    },
  },
})
