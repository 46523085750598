import React, {useEffect, useState} from 'react'
import '../../../../_metronic/assets/sass/components/opportunity-watch-add.scss'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import moment from 'moment'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import * as actions from '../redux/OpportunityWatchAction'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {useParams} from 'react-router-dom'
import {Attachment} from '../../../Common/attachments/Attachment'
import {AttachmentListing} from '../../../Common/attachments/AttachmentListing'

interface ICreateAccount {
  technology: string
  plan: string
  status: number
  activeDate: Date | string | null
  lead: string
}

const inits: ICreateAccount = {
  technology: '',
  plan: '',
  status: 0,
  activeDate: '',
  lead: '',
}

interface Props {
  onHide: () => void
  id?: string | null
  workSpaceId?: string | null
}

const today = new Date(Date.now() - 86400000)

export const OpportunityWatchAdd: React.FC<Props> = ({workSpaceId, id, onHide}) => {
  const dispatch = useDispatch()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [imagePaths, setImagePaths]: any = useState([])

  const createUpdateWorkspaceSchema = Yup.object().shape({
    technology: Yup.string().nullable(true),

    plan: Yup.string().nullable(true),
    activeDate: id
      ? Yup.date().nullable(true)
      : Yup.date().min(today, 'Date must be today or later than today.').nullable(true),
    lead: Yup.string()
      .required('Lead is required')
      .label('Lead')
      .min(1, 'Lead must contain more than 3 characters'),
    status: Yup.number().required('Space type is required'),
  })

  const {opportunityWatchDetail, attachments}: any = useSelector<RootState>(
    (state) => ({
      opportunityWatchDetail: state.opportunityWatch.opportunityWatchDetail,
      attachments: state.opportunityWatch.attachments,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (opportunityWatchDetail && id) {
      setInitValues({
        technology: opportunityWatchDetail?.technology,
        lead: opportunityWatchDetail?.lead,
        status: opportunityWatchDetail?.status,
        activeDate: opportunityWatchDetail?.activeDate
          ? moment(opportunityWatchDetail?.activeDate).format('YYYY-MM-DD')
          : null,
        plan: opportunityWatchDetail?.plan,
      })
    }
  }, [opportunityWatchDetail])

  useEffect(() => {
    if (workSpaceId) {
      dispatch(workspaceActions.fetchWorkspace(workSpaceId))
    }
  }, [dispatch])

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchOpportunityWatchById(id))
    } else {
      dispatch(actions.removeAttachments([]))
    }
  }, [])

  useEffect(() => {
    if (Array.isArray(attachments)) {
      setImagePaths(
        attachments.map((attachment: any) => {
          const staticURL = attachment.path.split('/')[0]
          if (staticURL === 'public') {
            return attachment.path
          }
          return attachment.type ? attachment.path + '.' + attachment.type : attachment.path
        })
      )
    }
  }, [attachments])

  let crumbs
  // if (opportunityWatchDetail) {
  crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Opportunity Watch', url: `/opportunity-watch`},
    // {name: `${opportunityWatchDetail.lead}`, url: `/opportunity-watch/${id}`},
    {
      name: id ? 'Edit Opportunity Watch' : 'Create Opportunity Watch',
      url: id ? `/opportunity-watch/edit/${id}` : `/opportunity-watch/add`,
    },
  ]
  // }

  return (
    <div className='mh-80 page-content'>
      <div className='px-6 px-sm-12'>
        <div className="row">
          <div className="col-sm-6">
            <h2 className='fw-500 fs-2 m-0 py-5'>
              {id ? 'Edit Opportunity Watch' : 'Create Opportunity Watch'}
            </h2>
          </div>
        </div>
      </div>
      <Breadcrumbs crumbs={crumbs} containerClass='row mx-10' />
      <div className="overflow_height_scroll">
        <div className='page-body py-lg-10 px-lg-10'>
          <div
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={createUpdateWorkspaceSchema}
                initialValues={id ? initValues : inits}
                enableReinitialize
                onSubmit={(values: any) => {
                  if (id) {
                    values.attachmentPaths = imagePaths
                    values.id = id

                    dispatch(actions.UpdateOpportunityWatch(values))
                    onHide()
                  } else {
                    if (imagePaths?.length > 0) {
                      values.attachmentPaths = imagePaths
                    }
                    dispatch(actions.createOpportunityWatch(values))
                    onHide()
                  }
                }}
              >
                {() => (
                  <Form
                    className='form form_opportunity_form'
                    noValidate
                    id='kt_modal_create_app_form'
                  >
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        <div className='row'>
                          <div className='col-md-6 fv-row mb-5'>
                            <label className='fs-6 fw-bold mb-2 required'>
                              <span>Lead/Team</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg  form-control-solid'
                              name='lead'
                              placeholder='Lead'
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='lead' />
                            </div>
                          </div>

                          <div className='col-md-6 fv-row mb-5'>
                            <label className='d-flex align-items-center fs-6 fw-bold  mb-2'>
                              Technology
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='technology'
                              placeholder='Technology'
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='technology' />
                            </div>
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-6 fv-row mb-5'>
                            <label className='fs-6 fw-bold fw-bold form-label mb-2'>Status</label>
                            <div className='row fv-row'>
                              <div className='col-12'>
                                <Field
                                  as='select'
                                  name='status'
                                  className='form-select  form-select-solid selectpicker'
                                >
                                  <option value={0}>Select</option>
                                  <option value={1}>Declined</option>
                                  <option value={2}>Active</option>
                                  <option value={3}>Fulfilled</option>
                                  <option value={4}>Fell Off</option>
                                </Field>
                                <div className='text-danger'>
                                  <ErrorMessage name='status' />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-6 fv-row mb-5'>
                            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                              Active Date
                            </label>

                            <Field
                              type='date'
                              name='activeDate'
                              min={moment().format('YYYY-MM-DD')}
                              className='form-select form-select-solid date-field'
                            />

                            <div className='text-danger'>
                              <ErrorMessage name='activeDate' />
                            </div>
                          </div>
                        </div>

                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-6 fw-bold  mb-2'>
                            <span className=''>Plan</span>
                          </label>

                          <Field
                            className='form-control form-control-lg form-control-solid'
                            name='plan'
                            placeholder='Plan'
                            as='textarea'
                            rows='5'
                          />

                          <div className='text-danger'>
                            <ErrorMessage name='plan' />
                          </div>
                        </div>
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-6 fw-bold  mb-2'>
                            <span className=''>Attachments</span>
                          </label>
                          <div className=''>
                            <Attachment
                              attachments={attachments}
                              loadAttachments={actions.loadAttachments}
                              attachmentType='attachmentUpload'
                              id={id}
                              itemType='opportunityWatch'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bolder  me-3 fs-7 cancel_button'
                          onClick={() => onHide()}
                          title='Cancel'
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary btn-blue  fs-7'
                          title={id ? 'Update' : 'Create'}
                        >
                          <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
