import * as SettingsCRUD from './settingsCRUD'
import { SettingsSlice } from './settingsSlice'

const {actions} = SettingsSlice


export const fetchSettings =
  ({
    search,
    page,
    sortby,
    orderby,
    createdAt,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    createdAt: string
  }) =>
  (dispatch: any) => {
    if (sortby === '' && page === '') {
      dispatch(actions.startCall())
    }
    return SettingsCRUD
      .getSettings({
        search,
        page,
        sortby,
        orderby,
        createdAt,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.SettingsFetched({
            totalCount,
            start,
            end,
            limitPerPage,
            entities: data,
            listLoading: false,
          })
        )
      })
  }

export const UpdateSettings = (Settings: any) => (dispatch: any) => {
  return SettingsCRUD
    .updateSettings(
      Settings.name,
      Settings.value,
    )
    .then((response) => {
      SettingsCRUD
        .getSettings({
          search: '',
          page: '',
          orderby: '',
          sortby: '',
          createdAt: '',
        })
        .then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.SettingsFetched({
              totalCount,
              start,
              end,
              limitPerPage,
              entities: data,
              listLoading: false,
            })
          )
        })
    })
}
