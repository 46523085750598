/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/listActions'
import {useParams} from 'react-router-dom'

interface Props {
  show: boolean
  handleClose: () => void
  _id: string
  setFetchRecords: any
  fetchReocords: boolean
}

const ListDeleteDialog: FC<Props> = ({show, handleClose, _id, setFetchRecords, fetchReocords}) => {
  const {workspaceid}: {workspaceid: string} = useParams()

  const dispatch = useDispatch<any>()
  const deleteListHandler = () => {
    dispatch(actions.deleteList(_id, workspaceid))
      .then((response: any) => {
        setFetchRecords(fetchReocords ? false : true)
        handleClose()
      })
      .catch((error: string) => {})
  }

  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-500px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Delete List</h2>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-15'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row mb-10'>
                    <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                      Are you sure you want to delete this list ?
                    </label>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack justify-content-center pt-'>
                <div className='me-2'>
                  <button
                    title='Cancel'
                    type='button'
                    className='btn btn-sm cancel_button me-3'
                    onClick={() => {
                      handleClose()
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    title='Delete'
                    type='submit'
                    className='btn btn-sm btn-blue me-3'
                    onClick={() => {
                      deleteListHandler()
                    }}
                  >
                    <span className='indicator-label'>Delete</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {ListDeleteDialog}
