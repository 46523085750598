import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {useSelector, shallowEqual} from 'react-redux'
import {IGLightbox} from '../../../Common/IGLightbox/IGLightbox'
import {RootState} from '../../../../setup'
interface Props {
  attachments: any
  attachmentType: string
  id: string | null | undefined
  itemType: string
  calledThrough: string
  name: string
  type: string
}
const filesExtensions = ['csv', 'pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx']

const AttachmentListing: React.FC<Props> = ({
  attachments,
  attachmentType,
  id,
  itemType,
  calledThrough,
  name,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)
  // const [imagesUrl, setImagesUrl]: any = useState([])

  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  let images = attachments?.map((attachment: any) => {
    if (attachment.type === 'jpg' || attachment.type === 'jpeg' || attachment.type === 'png')
      return `${attachment.url}?token=${accessToken}&itemType=${itemType}&itemId=${id}`
  })
  images = images.filter((url: string) => url)
  useEffect(() => {}, [images, photoIndex, isOpen])
  const handleOpen = (index: number) => {
    setPhotoIndex(index)
    setIsOpen((isOpen) => !isOpen)
  }
  return (
    <>
      {id && (
        <>
          {isOpen && (
            <IGLightbox
              images={images}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              photoIndex={photoIndex}
              setPhotoIndex={setPhotoIndex}
            />
          )}

          <div className=' d-inline-flex'>
            <div className='activity-icons'>
              {attachments?.length > 0 &&
                attachments?.map((attach: any, index: number) =>
                  filesExtensions.includes(attach?.type) && attach?.uploaded !== false ? (
                    <>
                      {attach?.url && (
                        <div className='activity-inner' key={index}>
                          <a
                            href={`${attach?.url}?token=${accessToken}&itemType=${itemType}&itemId=${id}`}
                            target='_blank'
                            rel='noreferrer'
                          >
                            {calledThrough === 'icon' ? (
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_delete_app'
                                id='kt_toolbar_primary_button'
                                title={'Download File'}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/arrows/arr044.svg'
                                  className='svg-icon-2'
                                />
                              </button>
                            ) : (
                              <>
                                {' '}
                                <img
                                  src={`/media/icons/files/${type}.png`}
                                  title={name}
                                  style={{height: '25px', width: '25px'}}
                                  className='me-2'
                                />
                                {name}
                              </>
                            )}
                          </a>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {attach?.url && (
                        <div className='activity-inner' key={index}>
                          {calledThrough === 'icon' ? (
                            <button
                              //   style={{
                              //     cursor: 'pointer',
                              //   }}
                              className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_delete_app'
                              id='kt_toolbar_primary_button'
                              title={'Show image'}
                              onClick={(e) => handleOpen(index)}
                            >
                              {/* <img
                                src={`${attach?.url}?token=${accessToken}&itemType=${itemType}&itemId=${id}`}
                              /> */}
                              <KTSVG path='/media/icons/view.svg' className='svg-icon-3' />
                            </button>
                          ) : (
                            <a style={{cursor: 'pointer'}} onClick={(e) => handleOpen(index)}>
                              <img
                                style={{height: '25px', width: '25px'}}
                                className='me-2'
                                src={`${
                                  attachments[0]?.thumbnailUrl
                                }?token=${accessToken}&itemType=${'documents'}&itemId=${id}`}
                              />
                              {name}
                            </a>
                          )}
                        </div>
                      )}
                    </>
                  )
                )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {AttachmentListing}
