import moment from 'moment'
import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import '../../../../_metronic/assets/sass/components/workspace-edit.scss'
import {getMembers, getNewSpaceMembers, getSpacePreviewMembers} from '../../../pages/UIHelper'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

type Props = {
  values: any
  userInfo: any
  id?: string | null
  assigneesMember: any
  assineesTeams: any
  maintainersMember: any
}

const SpacePreview: FC<Props> = ({
  values,
  userInfo,
  id,
  assigneesMember,
  assineesTeams,
  maintainersMember,
}) => {
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  const workSpaceMemberForPreview = getSpacePreviewMembers(
    assigneesMember,
    assineesTeams,
    maintainersMember,
    userInfo,
    accessToken
  )

  return (
    <>
      <div className='drag-drop-card card mb-5  px-2 py-0 justify-content-evenly'>
        <div
          className='card-header p-4 d-block'
          style={{border: '1px solid rgb(226 227 235', borderRadius: '10px'}}
        >
          <div className='card-title d-flex flex-column'>
            <p
              className={
                !values?.name
                  ? 'card-label spaces-members fs-6 bg_grey_content_none'
                  : 'card-label spaces-members fs-6'
              }
              title='My Space'
            >
              {values.name}
            </p>
            <p
              className={
                !values?.name
                  ? 'fs-9 mt-1 mb-2 h-12px color-grey bg_grey_content_none'
                  : 'fs-9 mt-1 mb-2 color-grey'
              }
            >
              Created on{' '}
              {id
                ? moment(values?.createdAt).format('DD MMM, YYYY')
                : moment().format('DD MMM, YYYY')}
            </p>
          </div>
          <div className='d-flex justify-content-between w-100'>
            <div className='d-flex flex-column'>
              <p
                className={
                  !values?.spaceKey
                    ? 'fs-9 mt-1 mb-2 color-grey bg_grey_content_none w-173pr'
                    : 'fs-7 text-blue fw-500 mb-1'
                }
              >
                {values.spaceKey}
              </p>
              <p
                className={
                  !values?.spaceKey ? 'fs-9 mb-0 color_grey bg_grey_content_none h-12px' : 'fs-9 mb-0'
                }
              >
                Space Key
              </p>
            </div>
            <div className='d-flex flex-column ms-6'>
              <p
                className={
                  !values?.owner?.fullName
                    ? 'fs-7 fw-500 mb-1 bg_grey_content_none'
                    : 'fs-7 fw-500 mb-1'
                }
              >
                {id ? values?.owner?.fullName : userInfo?.fullName}
              </p>
              <p
                className={
                  !values?.owner?.fullName
                    ? 'fs-9 mb-0 color_grey bg_grey_content_none h-12px w-75'
                    : 'fs-9 mb-0'
                }
              >
                Created By
              </p>
            </div>
          </div>

          {workSpaceMemberForPreview?.length === 0 ? (
            <div className='blur-image-data'>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <div className='d-flex justify-content-between w-100'>
              <div className='d-flex px-1 mt-4 spaces_card_img'>
                <div className='card-header d-flex p-0 border_bottom_none' id='kt_chat_messenger_header'>
                  <div className='card-title'>
                    <div
                      className={
                        values.assignee
                          ? 'symbol-group'
                          : 'color-grey bg_grey_content_none'
                      }
                    >
                      {workSpaceMemberForPreview?.map((user: any, index: number, row: any) => {
                        if (index < 5) {
                          return row.length > 5 && index == 4 ? (
                            <div
                              className='symbol symbol-35px symbol-circle symbol_service'
                              key={index}
                            >
                              <img
                                className='symbol-label fs-8 fw-bolder'
                                src={
                                  user.hasOwnProperty('image') && user?.image !== ''
                                    ? user.image
                                    : toAbsoluteUrl('/media/avatars/blank.png')
                                }
                                alt={user?.fullName}
                                title={user?.firstName}
                              />
                              <span>{row?.length > 5 ? '+' + (row?.length - 5) : ''}</span>
                            </div>
                          ) : (
                            <div className='symbol symbol-35px symbol-circle' key={index}>
                              <img
                                src={
                                  user.hasOwnProperty('image') && user?.image !== ''
                                    ? user.image
                                    : toAbsoluteUrl('/media/avatars/blank.png')
                                }
                                alt={user?.fullName}
                                title={user?.firstName}
                              />
                            </div>
                          )
                        }
                      })}
                    </div>
                  </div>
                  {/* <div className='card-toolbar'>
                  <div className='me-n3'>
                    <div
                      className='menu menu-sub menu-sub-dropdown w-250px w-md-300px'
                      data-kt-menu='true'
                    >
                      <div className='px-7 py-5'>
                        <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
                      </div>
                      <div className='separator border-gray-200'></div>
                      <div className='px-7 py-5'>
                        <div className='mb-10'>
                          <label className='form-label fw-bold'>Status:</label>
                          <div>
                            <select
                              className='form-select form-select-solid'
                              data-kt-select2='true'
                              data-placeholder='Select option'
                              data-allow-clear='true'
                            >
                              <option></option>
                              <option value='1'>Approved</option>
                              <option value='2'>Pending</option>
                              <option value='3'>In Process</option>
                              <option value='4'>Rejected</option>
                            </select>
                          </div>
                        </div>
                        <div className='mb-10'>
                          <label className='form-label fw-bold'>Member Type:</label>
                          <div className='d-flex'>
                            <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                              <input className='form-check-input' type='checkbox' value='1' />
                              <span className='form-check-label'>Author</span>
                            </label>
                            <label className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value='2' />
                              <span className='form-check-label'>Customer</span>
                            </label>
                          </div>
                        </div>
                        <div className='mb-10'>
                          <label className='form-label fw-bold'>Notifications:</label>
                          <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              name='notifications'
                              value=''
                            />
                            <label className='form-check-label'>Enabled</label>
                          </div>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <button
                            type='reset'
                            className='btn btn-sm btn-light btn-active-light-primary me-2'
                            data-kt-menu-dismiss='true'
                          >
                            Reset
                          </button>
                          <button
                            type='submit'
                            className='btn btn-sm btn-primary'
                            data-kt-menu-dismiss='true'
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export {SpacePreview}
