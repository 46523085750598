import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import '../../../../_metronic/assets/sass/components/assignee-team-list.scss'
import { useParams } from 'react-router-dom'
import { RootState } from '../../../../setup'
import { Breadcrumbs } from '../../../pages/Breadcrumbs'
import { NotFound } from '../../../../_metronic/layout/NotFound'
import { Loading } from '../../../../_metronic/layout/Loading'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import * as actions from '../redux/listActions'

interface Props {
  id?: string | null
  onHide: () => void
}

interface ICreateAccount {
  title: string
  description: string
  assignee: any
}

const inits: ICreateAccount = {
  title: '',
  description: '',
  assignee: [],
}

const ListDetails: React.FC<Props> = ({ id, onHide }) => {
  const { workspaceid }: { workspaceid: string } = useParams()
  const [toggleMember, setToggleMember] = useState<boolean>(false)
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [selectedFeatures, setSelectedFeatures] = useState<String[]>([])
  const dispatch = useDispatch<any>()
  const { spaceData }: any = useSelector<RootState>(
    (state) => ({
      spaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  const { spaceDetail, error, userInfo }: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.list.listDetail,
      error: state.list.error,
      userInfo: state.auth.user,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  useEffect(() => {
    if (spaceDetail) {
      setToggleMember(spaceDetail?.spaceType === 1)
      setInitValues({
        title: spaceDetail?.title,
        description: spaceDetail?.description,
        assignee: spaceDetail?.assignee?.map((assignee: any) => {
          return {
            value: assignee._id,
            label:
              userInfo._id === assignee._id ? assignee.fullName + ' ' + '(Me)' : assignee.fullName,
            id: assignee._id,
          }
        }),
      })
    }
  }, [spaceDetail])

  useEffect(() => {
    setSelectedFeatures([])
    if (id) {
      dispatch(actions.fetchList(id, workspaceid))
    }
    dispatch(actions.removeErrorMessage(''))
  }, [dispatch])

  let crumbs
  if (spaceData) {
    crumbs = [
      { name: 'Spaces', url: '/dashboard' },
      { name: `${spaceData?.name}`, url: `/${spaceData?._id}/features` },
      { name: 'List', url: `/${spaceData?._id}/list` },
      { name: `${spaceDetail?.title}`, url: `/${spaceData?._id}/list` },
    ]
  }
  return (
    <>
      <div className='mh-80 card rounded-0'>
        <div className='px-6 px-sm-12 '>
          <div className="row">
            <div className="col-sm-6">
              <h2 className='fw-500 fs-2 m-0 py-5 heading_overflow_control'>
              {spaceDetail?.title}
              </h2>
            </div>
          </div>
        </div>
        {/*<div className='card-header border-0 px-10 py-3'>
          /~ begin::Header ~/
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{spaceDetail?.title}</span>
          </h3>
        </div>*/}
        <div className='d-flex justify-content-between align-items-center bg-lighter'>
          <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 ' />
        </div>
        <div className="overflow_height_scroll">
          <div className='card-body mx-2 mx-sm-10 p-0 pt-5 pb-5'>
            {spaceDetail ? (
              <div className='d-flex flex-row justify-content-between  align-items-start mb-2 border_grey_info'>
                <div className='d-flex flex-column'>
                  {spaceDetail?.title && (
                    <div
                      className='align-items-center mb-2 text-gray-800  word_break list_hide fs-2 fw-bolder me-1'
                      title={spaceDetail?.title}
                    >
                      {spaceDetail?.title}
                    </div>
                  )}
                  <div className='d-flex  list-box mobile_direction_column'>
                    {spaceDetail.createdBy && (
                      <div
                        className='text me-4 mb-sm-3'
                        title={' ' + spaceDetail.createdBy?.firstName + ' ' + spaceDetail.createdBy?.lastName}
                        style={{
                          display: 'block',
                        }}
                      >
                        <span className='fw-bolder text-muted my-4'>Created By</span>
                        {' ' + spaceDetail.createdBy?.firstName + ' ' + spaceDetail.createdBy?.lastName}
                      </div>
                    )}
                    {spaceDetail?.createdAt && (
                      <div
                        className='text  me-4 mb-sm-3'
                        title={
                          spaceDetail?.createdAt
                            ? moment(spaceDetail?.createdAt).format('MM/DD/YYYY')
                            : ''
                        }
                        style={{
                          display: 'block',
                        }}
                      >
                        <span className='fw-bolder pe-2 text-muted'>Created Date</span>
                        {spaceDetail?.createdAt
                          ? moment(spaceDetail?.createdAt).format('MM/DD/YYYY')
                          : ''}
                      </div>
                    )}

                  </div>
                  {spaceDetail.assignee.length > 0 && (
                    <div className='list-list-assigee'>
                      <span className='me-1 d-flex'>
                        <span className='fw-bolder text-muted'>Assignees&nbsp;</span>
                        <span className='symbol d-flex flex-wrap'>
                          {spaceDetail.assignee?.map((assignee: any, index: number) => {
                            return (
                              <>
                                {index === 0 ? `${assignee.firstName} ${assignee.lastName}` : `, ${assignee.firstName} ${assignee.lastName}`}
                              </>
                            )
                          })}
                        </span>
                      </span>
                    </div>
                  )}

                  {spaceDetail?.description && (
                    <span className='d-flex flex-column align-items-start'>
                      <span className='fw-bolder mt-4 text-muted mb-2'>Description</span>
                      <div
                        title={'List Descriptions'}
                        className='d-flex flex-column'
                        dangerouslySetInnerHTML={{ __html: spaceDetail?.description }}
                      />
                    </span>
                  )}
                </div>
              </div>
            ) : !spaceDetail ? (
              <NotFound />
            ) : (
              <Loading />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export { ListDetails }
