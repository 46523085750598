/* eslint-disable jsx-a11y/anchor-is-valid */
import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FC, useEffect, useRef, useState} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import * as boardActions from '../../redux/BoardActions'
type Props = {
  index: any
  columnId: any
  moveCard: any
  columnIndex: number
  board: string
  task: null
  dragType: string
  handleDragType: any
}
const AddNewTask: FC<Props> = ({
  columnId,
  columnIndex,
  board,
  index,
  moveCard,
  task,
  dragType,
  handleDragType,
}) => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const [addForm, setAddForm] = useState<boolean>(false)
  const wrapperRef: any = useRef(null)
  // const ref: any = useRef(null)
  const dispatch: any = useDispatch()

  // const [, drop] = useDrop({
  //   accept: 'item',

  //   hover(draggedTask: any, monitor: any) {
  //     if (dragType === 'column') {
  //       return
  //     }
  //     if (draggedTask?.task) {
  //       handleDragType('column')
  //       return
  //     }

  //     const dragIndex = draggedTask?.index
  //     const hoverIndex = index

  //     const dragColumnIndex = draggedTask?.columnIndex
  //     const hoverColumnIndex = columnIndex

  //     // Don't replace items with themselves
  //     if (dragColumnIndex === hoverColumnIndex && dragIndex === hoverIndex) {
  //       return
  //     }

  //     if (dragColumnIndex === hoverColumnIndex && !task) {
  //       return
  //     }
  //     // dropTask(task)
  //     moveCard(dragColumnIndex, dragIndex, hoverColumnIndex, hoverIndex)
  //     draggedTask.index = index
  //     draggedTask.columnIndex = columnIndex
  //   },
  //   canDrop(item, monitor) {
  //     return dragType === 'card'
  //   },
  // })

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setAddForm(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const createTask = (values: {title: string}) => {
    const createTask: {
      title: string
      status: string
      board: string
      project: string
      order: number
    } = {
      title: values.title,
      status: columnId,
      board: board,
      project: id,
      order: index,
    }
    dispatch(boardActions.createTask(createTask)).then((res: any) => {
      if (res.status === 201) {
        setAddForm(false)
        dispatch(boardActions.currentProject(id))
        const data = {
          project: id,
        }
        dispatch(boardActions.getAllTasksByProjectId(data))
      }
    })
  }

  return (
    <>
      {!addForm ? (
        <div
          className='kanban-title-board kanban-add-card mb-6'
          // ref={drop}
          onClick={() => {
            setAddForm(true)
          }}
        >
          <div className='pb-10'>
            <a className='kanban-board-add-card-hover'>
              <span>
                <i className='fa fa-plus' aria-hidden='true'>
                  <span className='add-card'>Add a task</span>
                </i>
              </span>
            </a>
          </div>
        </div>
      ) : (
        <Formik
          initialValues={{title: ''}}
          validate={(values) => {}}
          onSubmit={(values: any) => {
            createTask(values)
          }}
        >
          {() => (
            <Form
              ref={wrapperRef}
              className=' todo-bar-search-from position-relative boder-1 rounded mt-2 form-search-dimention'
            >
              <Field
                type='text'
                name='title'
                className='kanban-title-input form-control-flush py-2 w-100'
                placeholder='Enter a title...'
              />
              <ErrorMessage name='title' component='div' />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export {AddNewTask}
