import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {resetPassword} from '../redux/AuthCRUD'
import '../../../../_metronic/assets/sass/components/forgot-password.scss'

const initialValues = {
  password: '',
  confirmPassword: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Password must contain upper and lowercase letters and at least one number'
    ),
  confirmPassword: Yup.string().when('password', {
    is: (val: string) => (val && val.length > 0 ? true : false),
    then: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords do not match')
      .required('Confirm Password is required'),
  }),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [response, setResponse] = useState('')
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const {token}: {token: string} = useParams()

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, resetForm}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        setDisabled(true)
        resetPassword(values.password, token)
          .then(({data: {message}}) => {
            setStatus(message)
            setHasErrors(false)
            setLoading(false)
            setDisabled(false)
            setResponse(message)
            resetForm()
          })
          .catch((e) => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus(e.response.data.message)
            setDisabled(false)
          })
      }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework forgot_password_ui'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10 heading'>
          {/* begin::Title */}
          <p className='text-dark mb-3'>Reset Password</p>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='fw-bold fs-4 subheading'>
            <p>Enter your new password</p>
          </div>

          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{response}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label  text-gray-900 fs-6'>Password</label>
          <div className='col-lg-12 fv-row position-relative'>
            <i
              title={showPassword ? 'Hide Password' : 'Show Password'}
              onClick={() => setShowPassword(!showPassword)}
              className={`bi position-absolute cursor-pointer ${
                showPassword ? 'bi-eye' : 'bi-eye-slash'
              }`}
              style={{right: '20px', top: '16px'}}
            ></i>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label  text-gray-900 fs-6'>Confirm Password</label>
          <div className='col-lg-12 fv-row position-relative'>
            <i
              title={showConfirmPassword ? 'Hide Password' : 'Show Password'}
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className={`bi position-absolute cursor-pointer ${
                showConfirmPassword ? 'bi-eye' : 'bi-eye-slash'
              }`}
              style={{right: '20px', top: '16px'}}
            ></i>
            <input
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder='Confirm Password'
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex justify-content-center '>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg fw-bolder w-100 submitbtn'
            title='Reset'
            disabled={loading ? true : false}
          >
            <span className='indicator-label'>Reset</span>
            {loading && (
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='d-flex justify-content-center w-100 mt-5'>
          <Link
            to='/login'
            title='Cancel'
            id='kt_login_password_reset_form_cancel_button'
            className='link fw-500 fs-4 text-blue me-4'
          >
            Back to Sign In
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
