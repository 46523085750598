import {Field} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Accordion, Card} from 'react-bootstrap-v5'
import '../../../_metronic/assets/sass/components/team-accordion.scss'
import {Link} from 'react-router-dom'

interface Props {
  teams: any
  setTeams: any
  searchQuery: string
  setSelectedList: any
  selectedList: any
  assigneeWithTeams: any
  setSearchQuery: any
  group: any
}
const teamColor = ['light-danger', 'light-warning', 'light-success', 'success', 'danger']

const TeamsAccordion: FC<Props> = ({
  teams,
  setTeams,
  searchQuery,
  setSelectedList,
  selectedList,
  assigneeWithTeams,
  setSearchQuery,
  group,
}) => {
  const [sortAssigneeName, setSortAssigneeName] = useState(true)
  const [sortAssigneeEmail, setSortAssigneeEmail] = useState(true)
  const [sort, setSort] = useState(true)

  const checkBoxTeamHandler = (data: any) => {
    data.isTeam = true
    setTeams(
      teams.filter((team: any) => {
        return team._id !== data._id
      })
    )
    setSelectedList((list: any) => {
      return [data, ...list]
    })
  }
  const sortValues = (members: any) => {
    if (sort) {
      if (sortAssigneeName)
        return members.slice().sort((a: any, b: any) => {
          return a.firstName.localeCompare(b.firstName)
        })
      else {
        return members.slice().sort((a: any, b: any) => {
          return b.firstName.localeCompare(a.firstName)
        })
      }
    } else {
      if (sortAssigneeEmail)
        return members.slice().sort((a: any, b: any) => {
          return a.email.localeCompare(b.email)
        })
      else {
        return members.slice().sort((a: any, b: any) => {
          return b.email.localeCompare(a.email)
        })
      }
    }
  }
  return (
    <>
      <tr className='fw-bolder text-muted  border-bottom d-flex justify-content-between'>
        <div className='d-flex'>
          <th className='w-25px'>
            <div className='form-check form-check-sm form-check-custom form-check-solid'>
              <input
                name='selectAll'
                className='form-check-input head_ui_input'
                type='checkbox'
                id='checkBox1'
                value='selectAll'
              />
            </div>
          </th>
          <th className='head_ui fw-normal'>Team Name</th>
        </div>
        <th className='head_ui pe-20 ps-0 me-3  fw-normal'>Members</th>
      </tr>

      <div className='custom-select-accordion'>
        <Accordion>
          {teams.length > 0 ? (
            teams.map((team: any, index: number) => {
              if (team?.name?.toLowerCase().includes(searchQuery.toLowerCase())) {
                return (
                  <Card key={index}>
                    <div className='d-flex align-items-center border-bottom justify-content-between h_62px'>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <Field
                          name='team'
                          className='form-check-input border_black_event'
                          type='checkbox'
                          id={team._id}
                          value={team}
                          onChange={() => {
                            checkBoxTeamHandler(team)
                          }}
                        />
                        <label
                          className='form-check-label fs-7 text-muted ms-4 fw-bolder'
                          htmlFor='checkBox3'
                          title={team.name}
                        >
                          {team.name}
                        </label>
                      </div>
                      <div className='form-check form-check-sm form-check-custom form-check-solid flex-column align-items-center'>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey={index.toString()}
                          className='border-0 text-dark'
                        >
                          <span className='fs-7 border-0'>{`${team?.members?.length} members`}</span>
                        </Accordion.Toggle>
                      </div>
                    </div>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body className='py-0 ps-12 pe-0'>
                        <div className='table-responsive'>
                          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                              <tr className='fw-bolder text-muted'>
                                <th
                                  style={{cursor: 'pointer'}}
                                  className='fs-7'
                                  onClick={() => {
                                    setSort(true)
                                    setSortAssigneeName(!sortAssigneeName)
                                  }}
                                >
                                  Name
                                  {sort && (
                                    <i
                                      className={
                                        sortAssigneeName
                                          ? 'fa fa-fw fa-caret-up'
                                          : 'fa fa-fw fa-caret-down'
                                      }
                                    ></i>
                                  )}
                                </th>
                                <th className='text-start fs-7'>teams</th>

                                <th
                                  style={{cursor: 'pointer'}}
                                  className='text-start fs-7'
                                  onClick={() => {
                                    setSort(false)
                                    setSortAssigneeEmail(!sortAssigneeEmail)
                                  }}
                                >
                                  email address{' '}
                                  {!sort && (
                                    <i
                                      className={
                                        sortAssigneeEmail
                                          ? 'fa fa-fw fa-caret-up'
                                          : 'fa fa-fw fa-caret-down'
                                      }
                                    ></i>
                                  )}{' '}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sortValues(team?.members).map((member: any, i: number) => {
                                return (
                                  <tr key={i}>
                                    <td>
                                      <span
                                        className='text-muted d-block fs-7'
                                        title={member.firstName + ' ' + member.lastName}
                                      >
                                        {member.firstName + ' ' + member.lastName}
                                      </span>
                                    </td>
                                    <td>
                                      <div className='d-flex justify-content-start'>
                                        {group ? group : ''}

                                        {/* {assigneeWithTeams?.map((assigneeWithTeam: any) => {
                                          if (member._id === assigneeWithTeam._id) {
                                            return assigneeWithTeam.team.map(
                                              (team: any, index: number) => {
                                                return (
                                                  <span
                                                    key={index}
                                                    className='text-muted d-block fs-7'
                                                    title={team}
                                                  >
                                                    {index === 0 ? team : ', ' + team}
                                                  </span>
                                                )
                                              }
                                            )
                                          }
                                        })} */}
                                      </div>
                                    </td>
                                    <td>
                                      <Link
                                        to='#'
                                        className='text-muted d-block fs-7 text-start'
                                        title={member.email}
                                      >
                                        {member.email}
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              }
            })
          ) : (
            <Card>
              <label
                className='form-check-label  text-center text-muted ms-2 fw-bolder mt-5'
                htmlFor='checkBox3'
              >
                {selectedList?.some((list: any) => list.isTeam)
                  ? 'No team remaining'
                  : 'No team added'}
              </label>
            </Card>
          )}
        </Accordion>
      </div>
    </>
  )
}

export {TeamsAccordion}
