/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, MutableRefObject, useEffect, useRef, useState} from 'react'
import {Formik, Form, useFormikContext} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import * as actions from '../redux/talentActions'
import {RootState} from '../../../../setup'
import {useHistory, useParams, useLocation} from 'react-router-dom'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {TalentStepOne} from './TalentStepOne'
import {TalentStepTwo} from './TalentStepTwo'
import './style.css'
import Ability from '../../../Common/RBAC/Ability'
import {create} from './filesDownload/docx/DocumentCreator'
import {Packer} from 'docx'
import {saveAs} from 'file-saver'
import _ from 'lodash'
interface ICreateAccount {
  name: string
  jobTitle: string
  professionalSummary: string
  skills: any
  projects: any
  degree: string
  otherDegree: string
  university: string
  otherUniversity: string
  year: string
  jobMode: string
  noticePeriod: string
  earlyJoining: string
  initialStatus: string
  currentSalary: number | string
  salaryDemand: number | string
  interviewTimeSlotStart: Date
  interviewTimeSlotEnd: Date
  clientDecision: string
  remarks: string
  sharedWith: string
  step: string
  experienceInMonth: string
  widget: string | undefined | null
}

const inits: ICreateAccount = {
  name: '',
  jobTitle: '',
  professionalSummary: '',
  skills: [],
  projects: [],
  degree: '',
  otherDegree: '',
  university: '',
  otherUniversity: '',
  year: '',
  jobMode: '',
  noticePeriod: '',
  earlyJoining: '',
  initialStatus: '',
  currentSalary: '',
  salaryDemand: '',
  interviewTimeSlotStart: new Date(),
  interviewTimeSlotEnd: new Date(),
  clientDecision: '',
  remarks: '',
  sharedWith: '',
  step: '1',
  experienceInMonth: '',
  widget: null,
}

interface Props {
  onHide: () => void
  id?: string | null
}

const TalentEdit: FC<Props> = ({onHide, id}) => {
  const location = useLocation()
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const successSaveData = useRef() as MutableRefObject<HTMLDivElement>
  const dispatch = useDispatch<any>()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [adminInitValues, setAdminInitValues] = useState<ICreateAccount>(inits)
  const [response, setResponse] = useState<boolean>(false)
  const [saveData, setSaveData] = useState<boolean>(false)
  const [color, setColor] = useState(null)

  const errorRef = useRef<any>(null)
  const history: any = useHistory()
  const [formAvailability, setFormAvailability] = useState<boolean>(true)
  const {talentDetail, error, userInfo, widgetSettings, loading, auth, settings}: any =
    useSelector<RootState>(
      (state) => ({
        talentDetail: state.talent.talentDetail,
        widgetSettings: state.talent.widgetSettings,
        error: state.talent.error,
        userInfo: state.auth.user,
        loading: state.talent.loading,
        auth: state.auth,
        settings: state.settings.entities,
      }),
      shallowEqual
    )

  let minSkills = settings?.find((s: any) => s.name === 'talentMinSkills')?.value
  minSkills = minSkills ? parseInt(minSkills) : 1

  let minProjects = settings?.find((s: any) => s.name === 'talentMinProjects')?.value
  minProjects = minProjects ? parseInt(minProjects) : 1

  useEffect(() => {
    if (minSkills && minProjects) {
      const clonedInitValues = _.cloneDeep(adminInitValues)
      clonedInitValues.skills = [] // resetting the array
      //Admin limitations Applying here for minimum skills
      Array(minSkills)
        .fill(0)
        .map((i) => {
          clonedInitValues.skills.push({name: '', rating: '', experience: ''})
        })
      clonedInitValues.projects = [] // resetting the array
      //Admin limitations Applying here for minimum projects
      Array(minProjects)
        .fill(0)
        .map((i) => {
          clonedInitValues.projects.push({
            name: '',
            description: '',
            technologies: '',
            responsibility: '',
            url: '',
          })
        })

      setAdminInitValues(clonedInitValues)
    }
  }, [minSkills, minProjects])

  const editFieldPermissions = () => {
    return widgetId
      ? talentDetail?.resumeStatus === 'initialized' ||
          uniqueId === widgetUniqueId ||
          (formAvailability && talentDetail?.resumeStatus === 'submitted')
      : !formAvailability
  }

  const {uniqueId: widgetUniqueId} = widgetSettings

  useEffect(() => {
    if (location?.pathname.includes('edit') || location?.pathname.includes('add')) {
      setFormAvailability(false)
    }
    if (widgetId && (location?.pathname.includes('edit') || location?.pathname.includes('add'))) {
      setFormAvailability(
        uniqueId === widgetUniqueId ? false : widgetSettings?.allowEditOnSubmission
      )
    }
  }, [widgetSettings?.allowEditOnSubmission])

  const query = new URLSearchParams(history?.location?.search)
  const widgetId = query.get('widget_id') || ''
  const uniqueId = query.get('unique_id') || ''
  const status = query.get('status') || ''

  const updateProjects = (projects: any) => {
    setInitValues({...initValues, projects})
  }
  const createUpdateTalentSchema = Yup.object().shape({
    name: Yup.string().label('Name').required('Name is required ').min(3),
    jobTitle: Yup.string().label('Job Title').required('Job Title is required '),
    professionalSummary: Yup.string()
      .label('Professional Summary')
      .required('Professional summary is required ')
      .min(1)
      .max(2000),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().label('Skill Name').required('Skill name is required '),
          rating: Yup.number()
            .integer()
            .label('Skill Rating')
            .min(1)
            .max(5)
            .required('Skill rating is required '),
        })
      )
      .min(minSkills, `Minimum ${minSkills} skills are required`),
    projects: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required('Project name is required'),
          description: Yup.string().required('Project description is required'),
          technologies: Yup.string().required('Project technology is required'),
          responsibility: Yup.string().required('Project responsibility is required'),
          url: Yup.string().nullable().url('Project url must be a valid URL'),
        })
      )
      .min(minProjects, `Minimum ${minProjects} projects are required`),
    degree: Yup.string().label('Degree').required('Degree is required '),
    otherDegree: Yup.string().when('degree', {
      is: 'other',
      then: Yup.string().required('Degree is required '),
    }),
    university: Yup.string().label('University').required('University is required '),
    otherUniversity: Yup.string().when('university', {
      is: 'other',
      then: Yup.string().required('University is required '),
    }),
    year: Yup.number().label('Year').required('Year is required '),
    currentSalary: Yup.string()
      .required('Current salary is required')
      // .integer()
      .typeError('Current salary is required')
      .label('Current salary')
      .min(1),
    salaryDemand: Yup.string()
      // .integer()
      .label('Salary demand')
      .typeError('Salary demand is required')
      .min(1)
      .required('Salary demand is required '),
    jobMode: Yup.string().label('Job mode').required('Job mode is required '),
    noticePeriod: Yup.string().label('Notice period').required('Notice period is required '),
    earlyJoining: Yup.string().label('Early joining').required('Early joining is required '),
    initialStatus: Yup.string().label('Initial status'),
    interviewTimeSlotStart: Yup.date()
      .label('Interview time slot start')
      .required('Interview time slot start is required '),
    interviewTimeSlotEnd: Yup.date()
      .min(
        Yup.ref('interviewTimeSlotStart'),
        "Interview end time slot can't be lesser than start time slot"
      )
      .label('Interview time slot end')
      .required('Interview time slot end is required '),
    clientDecision: Yup.string().label('Client Decision'),
    remarks: Yup.string().label('Remarks'),
    sharedWith: Yup.string().label('Share With'),
  })

  useEffect(() => {
    if (widgetId) {
      dispatch(actions.fetchWidget(widgetId))
    }

    if (!id && userInfo && !Ability.can('list', 'talent')) {
      dispatch(actions.fetchUserTalent())
    }

    if ((status === 'nextbridge' || status === 'vteam') && id) downloadTemplate(id, status)
    return () => {
      dispatch(actions.makeListEmpty(null))
    }
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {
    if (talentDetail) {
      const clonedTalentDetail = _.cloneDeep(talentDetail)

      //Admin limitations Applying here for minimum skills
      if (talentDetail?.skills?.length < minSkills && editFieldPermissions()) {
        Array(minSkills - talentDetail?.skills?.length)
          .fill(0)
          .map((i) => {
            clonedTalentDetail.skills.push({name: '', rating: '', experience: ''})
          })
      }

      //Admin limitations Applying here for minimum projects
      if (talentDetail?.projects?.length < minProjects && editFieldPermissions()) {
        Array(minProjects - talentDetail?.projects?.length)
          .fill(0)
          .map((i) => {
            clonedTalentDetail.projects.push({
              name: '',
              description: '',
              technologies: '',
              responsibility: '',
              url: '',
            })
          })
      }

      setInitValues(clonedTalentDetail)
    }
  }, [talentDetail])

  useEffect(() => {
    if (id || (id && widgetId && uniqueId)) {
      dispatch(actions.fetchList(id, widgetId, uniqueId || '', userInfo))
    }
    dispatch(actions.removeErrorMessage(''))
  }, [dispatch, uniqueId])

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  let crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Talent', url: `/talent`},
    {
      name:
        location?.pathname.includes('edit') && id
          ? 'Edit Talent'
          : id
          ? 'View Talent'
          : 'Create Talent',
      url: `/talent`,
    },
  ]
  const scrollBodySmooth = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    })
  }
  const styles = {
    backgroundColor: color ?? widgetSettings.buttonBgColor,
    padding:
      widgetSettings.buttonType === 'medium'
        ? '11.725px 23.75px'
        : widgetSettings.buttonType === 'large'
        ? '15.725px 35.75px'
        : '8.725px 20.75px',
    fontSize:
      widgetSettings.buttonType === 'medium'
        ? '1.15rem'
        : widgetSettings.buttonType === 'large'
        ? '1.35rem'
        : '1.0rem',
  }

  const saveFieldData = (data: any) => {
    const values = {...data, save: true}
    if (id) {
      values._id = id
      values.skills = values.skills.map((skill: any) => {
        return {name: skill.name, rating: +skill.rating, experience: skill.experience}
      })
      setResponse(true)
      dispatch(actions.updateList(values, widgetId))
        .then((response: any) => {
          setSaveData(true)
          setResponse(false)
          errorRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })

          scrollBodySmooth()
          errorRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        })
        .catch((error: string) => {
          errorRef.current.scrollIntoView({behavior: 'smooth'})
          setResponse(false)
        })
    } else {
      setResponse(true)

      values.skills = values.skills.map((skill: any) => {
        return {name: skill.name, rating: +skill.rating, experience: skill.experience}
      })
      dispatch(actions.createList({...values}, widgetId))
        .then((response: any) => {
          setSaveData(true)
          setResponse(false)
          scrollBodySmooth()
          errorRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        })
        .catch((error: string) => {
          errorRef.current.scrollIntoView({behavior: 'smooth'})
          setResponse(false)
        })
    }
  }

  const mouseEnter = (e: any, hoverColor: string) => {
    if (widgetId) e.target.style.backgroundColor = hoverColor
  }
  const mouseLeave = (e: any, bgColor: string) => {
    if (widgetId) e.target.style.backgroundColor = bgColor
  }

  if (error && widgetId)
    return (
      <div className='text-center error404-content clr pt-5'>
        <h2 className='error-title'>{widgetSettings?.errorTitle}</h2>
        <p className='error-text'>{widgetSettings?.errorDescription}</p>
      </div>
    )

  const downloadTemplate = (talentId: string, organization: string) => {
    dispatch(actions.fetchList(talentId, widgetId, uniqueId, '')).then(async (res: any) => {
      const doc = create(res, organization)
      Packer.toBlob(doc).then((blob) => {
        const fileName = `${res.data.name}-${organization}`.toLowerCase().split(' ').join('-')
        saveAs(blob, fileName + '.docx')
      })
    })
  }
  return (
    <>
      {!loading && (
        <>
          <div
            className={`mh-80 page-content  ${widgetId ? 'bg-transparent' : ''}`}
            style={{boxShadow: widgetId ? 'unset' : '0 0.5rem 1rem rgb(0 0 0 / 10%)'}}
          >
            {!widgetId && (
              <div className='px-6 px-sm-12'>
                <div className='row'>
                  <div
                    ref={successSaveData}
                    className='col-sm-6'
                    title={`${
                      location?.pathname.includes('edit') && id
                        ? 'Edit Talent'
                        : id
                        ? 'Talent'
                        : 'Create Talent'
                    }`}
                  >
                    <h2 className='fw-500 fs-2 m-0 py-5'>
                      {location?.pathname.includes('edit') && id
                        ? 'Edit Talent'
                        : id
                        ? 'Talent'
                        : 'Create Talent'}
                    </h2>
                  </div>
                </div>
              </div>
            )}

            {!window.location.search.includes('widget') && userInfo && (
              <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 ' />
            )}

            <div className='overflow_height_scroll' ref={errorRef}>
              <div className='page-body py-lg-10 px-lg-10'>
                <div
                  ref={stepperRef}
                  className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                  id='kt_modal_create_app_stepper'
                >
                  <div className='flex-row-fluid py-lg-5 px-lg-15'>
                    {saveData && (
                      <div className='alert alert-success' role='alert'>
                        Data saved!
                      </div>
                    )}

                    <Formik
                      validationSchema={createUpdateTalentSchema}
                      initialValues={
                        id ? {...inits, ...initValues} : {...inits, ...adminInitValues}
                      }
                      enableReinitialize
                      validateOnChange={false}
                      validateOnBlur={false}
                      onSubmit={(values: any, {resetForm}) => {
                        if (widgetId) values.resumeStatus = 'submitted'
                        if (id) {
                          values._id = id
                          values.skills = values.skills.map((skill: any) => {
                            return {
                              name: skill.name,
                              rating: +skill.rating,
                              experience: skill.experience,
                            }
                          })
                          setResponse(true)
                          setSaveData(false)

                          dispatch(actions.updateList(values, widgetId))
                            .then((response: any) => {
                              onHide()
                            })
                            .catch((error: string) => {
                              errorRef.current.scrollIntoView({behavior: 'smooth'})
                              setResponse(false)
                            })
                        } else {
                          setResponse(true)

                          values.skills = values.skills.map((skill: any) => {
                            return {
                              name: skill.name,
                              rating: +skill.rating,
                              experience: skill.experience,
                            }
                          })

                          dispatch(actions.createList(values, widgetId))
                            .then((response: any) => {
                              // localStorage.setItem('talentId', response?.data?.data?._id)

                              sessionStorage.setItem('id', response?.data?.data?._id)

                              history.push({
                                pathname: widgetId ? `/talent/success` : `/talent`,
                                state: {success: true, id: response.data.data._id},
                                search: history.location.search,
                              })
                            })
                            .catch((error: string) => {
                              errorRef.current.scrollIntoView({behavior: 'smooth'})
                              setResponse(false)
                            })
                        }
                      }}
                    >
                      {({values, setFieldValue, errors}: any) => (
                        <Form className='form talent-form' noValidate id='kt_modal_create_app_form'>
                          <ScrollToError ref={errorRef} onHide={() => {}} />
                          {widgetId && uniqueId === widgetUniqueId && (
                            <div className='row'>
                              <div className='col-md-12 text-end'>
                                <button
                                  type='button'
                                  className={
                                    widgetId
                                      ? 'talent-form-custom-btn me-3 fs-7'
                                      : 'btn btn-sm btn-blue fs-7 me-3'
                                  }
                                  title='Download Resume'
                                  style={widgetId ? styles : {}}
                                  onMouseEnter={(e) =>
                                    widgetId && mouseEnter(e, widgetSettings.buttonHoverColor)
                                  }
                                  onMouseLeave={(e) =>
                                    widgetId && mouseLeave(e, widgetSettings.buttonBgColor)
                                  }
                                  onClick={(e: any) => {
                                    if (id) downloadTemplate(id, 'nextbridge')
                                  }}
                                >
                                  Download Nextbridge Resume
                                </button>

                                <button
                                  type='button'
                                  className={
                                    widgetId
                                      ? 'talent-form-custom-btn me-3 fs-7'
                                      : 'btn btn-sm btn-blue fs-7 me-3'
                                  }
                                  title='Download Resume'
                                  style={widgetId ? styles : {}}
                                  onMouseEnter={(e) =>
                                    widgetId && mouseEnter(e, widgetSettings.buttonHoverColor)
                                  }
                                  onMouseLeave={(e) =>
                                    widgetId && mouseLeave(e, widgetSettings.buttonBgColor)
                                  }
                                  onClick={(e: any) => {
                                    if (id) downloadTemplate(id, 'vteams')
                                  }}
                                >
                                  Download vteams Resume
                                </button>
                              </div>
                            </div>
                          )}

                          <TalentStepOne
                            values={values}
                            error={error}
                            ref={errorRef}
                            saveData={!saveData}
                            widgetId={widgetId}
                            updateProjects={updateProjects}
                            editFieldPermissions={editFieldPermissions}
                            validationErrors={errors}
                            minSkills={minSkills}
                            minProjects={minProjects}
                          />

                          <TalentStepTwo
                            uniqueId={uniqueId}
                            values={values}
                            setFieldValue={setFieldValue}
                            error={errors}
                            ref={errorRef}
                            saveData={!saveData}
                            widgetId={widgetId}
                            editFieldPermissions={editFieldPermissions}
                            id={id}
                          />

                          <div className='d-flex flex-stack pt-10'>
                            <div></div>
                            <div className='me-2'>
                              {editFieldPermissions() && (
                                <>
                                  {
                                    <button
                                      disabled={response}
                                      type='button'
                                      className={
                                        widgetId
                                          ? 'talent-form-custom-btn me-3 fs-7'
                                          : 'btn btn-sm btn-blue fs-7 me-3'
                                      }
                                      title='Save'
                                      style={widgetId ? styles : {}}
                                      onMouseEnter={(e) =>
                                        widgetId && mouseEnter(e, widgetSettings.buttonHoverColor)
                                      }
                                      onMouseLeave={(e) =>
                                        widgetId && mouseLeave(e, widgetSettings.buttonBgColor)
                                      }
                                      onClick={(e: any) => {
                                        saveFieldData(values)
                                      }}
                                    >
                                      Save
                                    </button>
                                  }

                                  <button
                                    disabled={response}
                                    type={'submit'}
                                    onClick={() => setSaveData(false)}
                                    className={
                                      widgetId
                                        ? 'talent-form-custom-btn fs-7'
                                        : 'btn btn-sm btn-blue fs-7'
                                    }
                                    title={
                                      widgetId && id
                                        ? 'Save and Finish'
                                        : id
                                        ? 'Save and Update'
                                        : 'Save and Finish'
                                    }
                                    style={widgetId ? styles : {}}
                                    onMouseEnter={(e) =>
                                      widgetId && mouseEnter(e, widgetSettings.buttonHoverColor)
                                    }
                                    onMouseLeave={(e) =>
                                      widgetId && mouseLeave(e, widgetSettings.buttonBgColor)
                                    }
                                  >
                                    {widgetId && id
                                      ? 'Save and Finish'
                                      : id
                                      ? 'Save and Update'
                                      : 'Save and Finish'}
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
interface Props {}

export const ScrollToError = React.forwardRef<any, Props>(({}, ref: any) => {
  const {formik, errors}: any = useFormikContext()
  const submitting = formik?.isSubmitting
  useEffect(() => {
    if (Object.keys(errors).length) {
      ref.current.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [submitting, errors])
  return null
})

export {TalentEdit}
