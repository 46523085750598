/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import ReactApexChart from 'react-apexcharts'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../../setup'

export const RadialBar: FC = () => {
  const [columnValues, setColumnValues] = useState([])
  const [options, setOptions] = useState({})
  const [columnNames, setColumnNames] = useState([])
  const dispatch: any = useDispatch()
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()

  const {boardTasksActivities}: any = useSelector<RootState>(
    (state) => ({
      boardTasksActivities: state.board.boardTasksActivities,
    }),
    shallowEqual
  )

  useEffect(() => {
    const seriesData: any = []

    boardTasksActivities?.columns?.filter((singleColumn: any) => {
      if (boardTasksActivities.totalTask) {
        seriesData.push(
          Math.round(
            (parseInt(singleColumn.task.length) / parseInt(boardTasksActivities.totalTask)) * 100
          )
        )
      } else {
        seriesData.push(0)
      }
    })
    setColumnValues(seriesData)
    graphOptions()
  }, [boardTasksActivities])

  const graphOptions = () => {
    const graphOption = {
      chart: {
        height: 290,
        type: 'radialBar',
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 270,
          offsetX: 140,
          offsetY: 0,
          hollow: {
            margin: 30,
            size: '40%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: [
        '#A1A5B7',
        '#181C32',
        '#004B87',
        '#479800',
        '#01919A',
        '#000000',
        '#00546F',
        '#B2CBD3',
        '#80ACCA',
        '#1D3B4F',
      ],
      labels: boardTasksActivities?.columns?.map((column: any) => {
        return `${column.name} <span class="task-count"> ${column.task.length}</span>`
      }),
      legend: {
        show: true,
        floating: true,
        fontSize: '14px',
        position: 'left',
        offsetX: 0,
        offsetY: 10,
        labels: {
          useSeriesColors: true,
        },
        markers: {
          size: 0,
        },

        itemMargin: {
          vertical: 3,
        },
      },
      responsive: [  
        {
          breakpoint: 480,
          options: {
            plotOptions: {
              radialBar: {
                offsetX: 0,
              },
            },
            legend: {
              show: false,
              fontSize: '12px',
            },
          },
        },          
        {
          breakpoint: 767,
          options: {
            plotOptions: {
              radialBar: {
                offsetX: 110,
              },
            },
            legend: {
              show: true,
              fontSize: '14px',
            },
          },
        },  
        {
          breakpoint: 1399,
          options: {
            plotOptions: {
              radialBar: {
                offsetX: 80,
              },
            },
            legend: {
              show: true,
              fontSize: '12px',
            },
          },
        }, 
        
      ],
    }
    setOptions(graphOption)
  }

  return (
    <>
      {options && columnValues.length > 0 && (
        <div className='radial_chart_ui'>
          <ReactApexChart options={options} series={columnValues} type='radialBar' height={250} />
        </div>
      )}
      {/* {options && columnValues.length > 0 && (
        <div className='radial_chart_ui'
          style={{
            transform: 'scaleX(-1)',
            position: 'relative',
            width: '107%',
            left: '-110px',
            zIndex: 77,
          }}
        >
          <ReactApexChart options={options} series={columnValues} type='radialBar' height={250} />
        </div>
      )} */}
    </>
  )
}
