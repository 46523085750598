import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {DocumentAddDialog} from './components/DocumentAddDialog'
import {DocumentTable} from './components/DocumentsTable'

const Document: React.FC = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={[
            '/documents',
            '/:workspaceid/documents',
            '/documents/:folderid',
            '/:workspaceid/documents/:folderid',
          ]}
        >
          {({history, match}: {history: any; match: any}) => {
            if (match.params.folderid === 'add') {
              return (
                <DocumentAddDialog
                  onHide={() => {
                    history.push(
                      match?.params?.workspaceid
                        ? `/${match?.params?.workspaceid}/documents`
                        : '/documents'
                    )
                  }}
                />
              )
            }
            return <DocumentTable className='mb-5 mb-xl-8' />
          }}
        </Route>
        <Route
          exact
          path={[
            '/documents/add',
            '/:workspaceid/documents/add',
            '/documents/:folderid/add',
            '/:workspaceid/documents/:folderid/add',
          ]}
        >
          {({history, match}: {history: any; match: any}) => {
            return (
              <DocumentAddDialog
                onHide={() => {
                  history.push(
                    match?.params?.workspaceid
                      ? `/${match?.params?.workspaceid}/documents`
                      : match?.params?.workspaceid && match?.params?.folderid
                      ? `/${match?.params?.workspaceid}/documents/${match?.params?.folderid}`
                      : !match?.params?.workspaceid && match?.params?.folderid
                      ? `/documents/${match?.params?.folderid}`
                      : '/documents'
                  )
                }}
              />
            )
          }}
        </Route>
      </Switch>
    </>
  )
}

export {Document}
