/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {register} from '../redux/AuthCRUD'
import {Link, useHistory} from 'react-router-dom'
import {updateAbility} from '../../../Common/RBAC/Ability'
import {AbilityContext} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/auth.scss'

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(1, 'Minimum 1 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Last name is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      'Password must contain upper and lowercase letters and at least one number'
    ),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
})

export function Registration() {
  const ability = useContext(AbilityContext)
  const history = useHistory()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const _id = params.get('id')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        register(values.email, values.firstName, values.lastName, values.password)
          .then(({data: {accessToken, user}}) => {
            setLoading(false)
            dispatch(auth.actions.login(accessToken))
            dispatch(auth.actions.fulfillUser(user))
            updateAbility(ability, user)
            if (code) {
              history.push(`/verify-invitation?code=${code}&id=${_id}`)
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(e?.response?.data?.message)
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework register_page_ui'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center heading'>
        <p className='text-dark mb-18 mt-5'>Create Account</p>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row'>
        <div className='col-xl-6'>
          <div className='fv-row mb-5'>
            <label className='form-label 
             text-dark fs-6'>First Name</label>
            <input
              placeholder='First Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('firstName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',                
              )}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.firstName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='col-xl-6'>
          {/* begin::Form group Lastname */}
          <div className='fv-row mb-5'>
            <label className='form-label  text-dark fs-6'>Last Name</label>
            <input
              placeholder='Last Name'
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('lastName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',                
              )}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lastName}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label  text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',            
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-7 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label  text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <i
              title={showPassword ? 'Hide Password' : 'Show Password'}
              onClick={() => setShowPassword(!showPassword)}
              className={`bi position-absolute cursor-pointer ${(showPassword) ? 'bi-eye' : 'bi-eye-slash'}`}
              style={{right: '20px', top: '16px'}}
            ></i>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',                
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-7'>
        <label className='form-label  text-dark fs-6'>Confirm Password</label>
        <div className='position-relative mb-3'>
          <i
            title={showConfirmPassword ? 'Hide Password' : 'Show Password'}
            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            className={`bi position-absolute cursor-pointer ${(showConfirmPassword) ? 'bi-eye' : 'bi-eye-slash'}`}
            style={{right: '20px', top: '16px'}}
          ></i>
          <input
            type={showConfirmPassword ? 'text' : 'password'}
            placeholder='Confirm Password'
            autoComplete='off'
            {...formik.getFieldProps('changepassword')}
            className={clsx(
              'form-control form-control-lg form-control-solid',              
            )}
          />
          {formik.touched.changepassword && formik.errors.changepassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.changepassword}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}
      {/* begin::Form group */}
      <div className='text-center d-flex justify-content-end'>
        <button
          title='Submit'
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg w-100 mb-10 submitbtn'
          disabled={loading ? true : false}
        >
          {<span className='indicator-label'>Sign Up</span>}
        </button>
      </div>
      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-4 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>
      <div className='d-flex justify-content-center  subheading'>
        <p>Already have an account?</p>
        <Link
          to='/login'
          className='link fw-500'
          style={{marginLeft: '5px', fontSize: '18px'}}
          title='Login'
        >
          Login
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
