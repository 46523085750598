import React, {useEffect} from 'react'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {DrawerComponent} from '../../assets/ts/components'
import { UpdateAuthModel } from '../../../app/modules/auth/models/UpdateAuthModel'
import { RootState } from '../../../setup/redux/RootReducer'
import { shallowEqual, useSelector } from 'react-redux'

const Content: React.FC = ({children}) => {
  const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, [location])

  const authorData: UpdateAuthModel = useSelector<RootState>(
    ({ auth }) => auth,
    shallowEqual
  ) as UpdateAuthModel

  return (
    <div id='kt_content_container' className={clsx('content flex-row-fluid overflow-hidden',authorData?.user?.settings?.fullWidth? 'border-radius-0px':'border-radius-15px')}>
      {children}
    </div>
  )
}

export {Content}
