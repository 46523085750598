import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  technology: string
  plan: string
  assignees?: []
  teams?: []
  createdBy: String
  activeDate: any
  status: number
}

// Define a type for the slice state
interface OpportunityWatchState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount?: number
  opportunityWatchDetail?: entities
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  error?: any
  attachments?: any
}

// Define the initial state using that type
const initialState: OpportunityWatchState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
  attachments: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const opportunityWatchSlice = createSlice({
  name: 'opportunityWatch',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<OpportunityWatchState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    opportunityWatchFetched: (state, action: PayloadAction<OpportunityWatchState>) => {
      state.listLoading = false
      const {totalCount, entities, start, end} = action.payload
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    opportunityWatchCreated: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.actionsLoading = false
      state.entities.push(action.payload.entities)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    opportunityWatchUpdate: (state, action) => {
      state.actionsLoading = false
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      }
    },
    opportunityWatchFetchedById: (state, action) => {
      const {data} = action.payload
      state.opportunityWatchDetail = data
      state.listLoading = false
    },
    loadAttachments: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.attachments = action.payload
      } else {
        state.attachments.push(action.payload)
      }
    },
  },
})
