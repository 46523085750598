import {generalActivityLogsSlice, callTypes} from './activityLogsSlice'
import * as serverRequest from './activityLogsCRUD'

const {actions} = generalActivityLogsSlice

export const fetchActivites =
  ({itemId, page, itemType}: {itemId: string; page: number | string; itemType: string}) =>
  (dispatch: any) => {
    // dispatch(actions.startCall({callType: callTypes.list}))
    return serverRequest.getActivityList({itemId, page, itemType}).then((response) => {
      const {data, totalCount, start, end, limitPerPage} = response.data
      dispatch(
        actions.activityLogsFetched({
          entities: data,
          totalCount,
          start,
          end,
          limitPerPage,
          listLoading: false,
          actionsLoading: false,
        })
      )
    })
  }

export const createActivites =
  (description: string, itemId: string, itemType: string, attachment: Array<string>) =>
  (dispatch: any) => {
    return serverRequest
      .addActivityList({
        itemType: itemType,
        itemId: itemId,
        description: description,
        attachment,
      })
      .then((response) => {
        const {data, totalCount}: {data: any; totalCount: any} = response.data
        dispatch(actions.activityLogsCreated({totalCount, entities: data}))
        serverRequest.getActivityList({itemId, page: '', itemType}).then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.activityLogsFetched({
              entities: data,
              totalCount,
              start,
              end,
              limitPerPage,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
      })
  }
export const UpdateActivites =
  (
    _id: string,
    itemId: string,
    description: string,
    attachments: Array<string>,
    itemType: string
  ) =>
  (dispatch: any) => {
    return serverRequest
      .updateActivityList({
        _id,
        itemId: itemId,
        description,
        attachments,
      })
      .then((response) => {
        dispatch(actions.removeAttachments([]))
        serverRequest.getActivityList({itemId, page: '', itemType}).then((response) => {
          const {data, totalCount, start, end, limitPerPage} = response.data
          dispatch(
            actions.activityLogsFetched({
              entities: data,
              totalCount,
              start,
              end,
              limitPerPage,
              listLoading: false,
              actionsLoading: false,
            })
          )
        })
      })
  }
export const deleteActivites =
  (_id: string, itemId: string, itemType: string) => (dispatch: any) => {
    return serverRequest.deleteActivityList(_id).then((response) => {
      serverRequest.getActivityList({itemId, page: '', itemType}).then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response.data
        dispatch(
          actions.activityLogsFetched({
            entities: data,
            totalCount,
            start,
            end,
            limitPerPage,
            listLoading: false,
            actionsLoading: false,
          })
        )
      })
    })
  }
export const uploadAttachment = (data: any) => (dispatch: any) => {
  return serverRequest
    .uploadSingleFile(data)
    .then((response) => {
      dispatch(actions.imageUploaded(response.data))
    })
    .catch((error) => {
      throw new Error('Something went wrong')
    })
}
export const loadAttachments = (data: any) => (dispatch: any) => {
  dispatch(actions.loadAttachments(data))
}

export const removeAttachments = () => (dispatch: any) => {
  dispatch(actions.removeAttachments([]))
}

export const fetchSingleTodo = (_id: string) => (dispatch: any) => {
  dispatch(actions.startCall({callType: callTypes.list}))
  return serverRequest.fetchSingleTodo(_id).then((response) => {
    dispatch(
      actions.singleTodoFetched({
        todo: response?.data?.data,
      })
    )
  })
}
