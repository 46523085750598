import React, {useEffect, useState} from 'react'
import {EditorState, convertToRaw, ContentState} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './style.css'

type Props = {
  value: any
  field: string
  setFieldValue: any
}

const EditorContainer: React.FC<Props> = ({value, field, setFieldValue}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())

  useEffect(() => {
    const contentBlock = htmlToDraft(value)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      const editorState = EditorState.createWithContent(contentState)
      setEditorState(editorState)
    }
  }, [value])

  const onEditorStateChange = (editorState: any) => {
    setEditorState(editorState)
  }
  const handleEditorStateChange = (editorState2: any) => {
    if (editorState.getCurrentContent().getPlainText() == '') {
      setFieldValue(field, '')
    } else {
      setFieldValue(field, draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }
  }

  return (
    <div className='editor'>
      <Editor
        editorState={editorState}
        wrapperClassName='demo-wrapper'
        editorClassName='demo-editor'
        onEditorStateChange={onEditorStateChange}
        onBlur={handleEditorStateChange}
      />
    </div>
  )
}

export default EditorContainer
