import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {NotificationTable} from './components/Notifications'

const Notification: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/notifications'>
          <NotificationTable className='mb-5 mb-xl-8' />
        </Route>
      </Switch>
    </>
  )
}

export {Notification}
