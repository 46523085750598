import axios from 'axios'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {shallowEqual, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import * as Yup from 'yup'
import {UpdateAuthModel} from '../../../../app/modules/auth/models/UpdateAuthModel'
import {RootState} from '../../../../setup'
import {InviteMembers, inviteMembersInitValues as initialValues} from './settings/SettingsModel'

interface Props {
  show: boolean
  handleClose: () => void
}
const isEmail = (value: any) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
const profileDetailsSchema = Yup.object({
  email: Yup.string()
    .transform((value) => Array.from(new Set(value.split(','))).join(','))
    .required('Email is required')
    .test(
      'emails',
      'Invalid email address',
      (value: any) => value && value.split(',').every(isEmail)
    ),
})

const API_URL = process.env.REACT_APP_API_URL
const SEND_INVITE = `${API_URL}/user-invitation`

const InviteUsers: React.FC<Props> = ({show, handleClose}) => {
  const history = useHistory()
  const authorData: UpdateAuthModel = useSelector<RootState>(
    ({auth}) => auth,
    shallowEqual
  ) as UpdateAuthModel

  const [loading, setLoading] = useState(false)
  const [userUpdated, setUserUpdated] = useState('')

  const formik = useFormik<InviteMembers>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      let email = values.email.split(',').map(function (value) {
        return value.trim()
      })
      let data = {email, user: authorData.user._id}

      setLoading(true)
      setTimeout(() => {
        axios
          .post(SEND_INVITE, data)
          .then((updatedData) => {
            setUserUpdated('inviteSend')
            setSubmitting(true)
            setLoading(false)
            setStatus('Invitation send successfully')
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setUserUpdated('notSend')
            setStatus(e.response.data.message)
          })
        setLoading(false)
      }, 1000)
    },
  })
  return (
    <Modal
      id='kt_modal_invite_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered mw-700px h-auto'
      contentClassName='border-radius-15px'
      show={show}
      onHide={handleClose}
    >
      <div className=''>
        <div className='modal-header'>
          <h3 className='fs-6 m-0'>Invite Users</h3>
          <button onClick={handleClose} className='btn-close shadow-none'></button>
        </div>

        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid  d-flex '>
              <form onSubmit={formik.handleSubmit} noValidate className='form col-sm-12'>
                  {userUpdated === 'inviteSend' ? (
                    <div className='row mb-2'>
                      <div className='card card-custom bg-light-success  mb-5'>
                        <div className='card-body d-flex flex-column flex-center'>
                          <p className='fs-3 mb-0 text-dark '>{formik.status}</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    userUpdated === 'notSend' && (
                      <div className='row mb-2'>
                        <div className='card card-custom bg-light-danger  mb-5'>
                          <div className='card-body d-flex flex-column flex-center'>
                            <p className='fs-3 mb-0 text-dark '>{formik.status}</p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                <div className='card-body d-flex justify-content-center'>
                  <div className='row mb-6'>
                    <label className='col-lg-12 col-form-label fw-bold fs-6'>
                      <span className='required fs-7'>User Email</span>
                    </label>

                    <div className='col-lg-11 fv-row'>
                      <input
                        // name='email'
                        type='email'
                        className='mb-5 form-control form-control-lg form-control-solid fs-7'
                        placeholder='User Email'
                        {...formik.getFieldProps('email')}
                      />
                      <span className='text-grey'>
                      Enter the Email of a Person you would like to invite.
                      </span>
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.email}</span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='card-footer align-items-center d-flex justify-content-end py-6 px-0'>
                  <button
                    type='button'
                    className='btn me-3 cancel_button fs-7'
                    disabled={loading}
                    onClick={handleClose}
                    title='Cancel'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-sm btn-blue fs-7'
                    disabled={loading}
                    title='Save Changes'
                  >
                    Invite
                  </button>
                </div>
              </form>
            </div>
            {/* <div className='card-footer align-items-center d-flex justify-content-end py-6 px-0'>
                  <button
                    type='button'
                    className='btn me-3 cancel_button fs-7'
                    disabled={loading}
                    onClick={handleClose}
                    title='Cancel'
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className='btn btn-sm btn-blue fs-7'
                    disabled={loading}
                    title='Save Changes'
                  >
                    Invite
                  </button>
                </div> */}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {InviteUsers}
