/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import * as auth from '../redux/AuthRedux'
import {ldaplogin} from '../redux/AuthCRUD'
import {updateAbility} from '../../../Common/RBAC/Ability'
import {AbilityContext} from '../../../Common/RBAC/Can'
import '../../../../_metronic/assets/sass/components/auth.scss'
import '../../../../_metronic/assets/sass/components/login.scss'

const LdaploginSchema = Yup.object().shape({
  username: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Username is required'),
  password: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

export function LdapLogin({ldapForm}: any) {
  const ability = useContext(AbilityContext)
  const history: any = useHistory()
  const search = window.location.search
  const params = new URLSearchParams(search)
  const code = params.get('code')
  const _id = params.get('id')
  const [prevPath, setPrevPath] = useState('')

  useEffect(() => {
    if (history?.location?.state?.prevPath) {
      setPrevPath(history?.location?.state?.prevPath)
    }
    return () => {
      setPrevPath('')
    }
  }, [history])

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: LdaploginSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setTimeout(() => {
        ldaplogin(values.username, values.password)
          .then(({data: {accessToken, user}}) => {
            setLoading(false)
            dispatch(auth.actions.login(accessToken))
            dispatch(auth.actions.fulfillUser(user))
            updateAbility(ability, user)
            if (code) {
              history.push(`/verify-invitation?code=${code}&id=${_id}`)
            } else if (prevPath) {
              history.push(prevPath)
            }
          })
          .catch((e) => {
            setLoading(false)
            setSubmitting(false)
            setStatus(e?.response?.data?.message)
          })
      }, 1000)
    },
  })

  return (
    <form
      className='form w-100 login_page_ui'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <div className='heading'>
          <p className='text-dark mb-3  fw-bold'>Welcome to GC</p>
        </div>
        <div className='text-gray-400 fw-400 fs-5 subheading'>
          <p>Enter your LDAP details to Sign In!</p>
        </div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>LDAP Username</label>
        <input
          placeholder='Username'
          {...formik.getFieldProps('username')}
          className={clsx('form-control form-control-lg form-control-solid  bg-light-grey')}
          type='username'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-5'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label text-dark fs-6 mb-0'>Password</label>
          </div>
        </div>
        <div className='col-lg-12 fv-row position-relative'>
          <i
            title={showPassword ? 'Hide Password' : 'Show Password'}
            onClick={() => setShowPassword(!showPassword)}
            className={`bi position-absolute cursor-pointer ${
              showPassword ? 'bi-eye' : 'bi-eye-slash'
            }`}
            style={{right: '20px', top: '16px'}}
          ></i>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx('form-control form-control-lg form-control-solid bg-light-grey')}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='text-center d-flex w-100'>
        <button
          title='Sign In'
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg mb-10 w-100 submitbtn'
          disabled={loading ? true : false}
        >
          <span className='indicator-label fs-6'>Sign In</span>
        </button>
      </div>
      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-4 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>
      <div className='text-center d-flex w-100'>
        <button
          type='button'
          onClick={() => ldapForm()}
          className='btn btn-lg mb-10 w-100 submitbtn indicator-label fs-6'
          style={{marginLeft: '5px'}}
          title='Sign In with Email'
        >
          Sign In With Email
        </button>
      </div>
    </form>
  )
}
