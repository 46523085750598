/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { toAbsoluteUrl } from '../helpers'

const Loading: FC = () => {
  return (
    <div className='d-flex align-items-center justify-content-center flex-column'>
      <img
        src={toAbsoluteUrl('/media/logos/logo-2.svg')}
        style={{marginTop: 120, marginBottom: 120}}
        width='150px'
        alt='icon'
      />
    </div>
  )
}

export {Loading}
