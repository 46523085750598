import {ErrorMessage, Field, useField} from 'formik'
import React, {FC, useRef} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {KTSVG} from '../../../../_metronic/helpers'
interface Props {
  values: any
  arrayHelpers: any
  saveData: boolean
  widgetId: string
  updateProjects: any
  editFieldPermissions: any
  minProjects?: number
}

interface TextAreaProps {
  label: string
  name: string
  placeholder?: string
  className: string
  rows?: number
}
const TextArea: FC<TextAreaProps> = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props)
  return <textarea {...field} {...props} />
}

export const ProjectsTable: FC<Props> = ({
  values,
  saveData,
  arrayHelpers,
  widgetId,
  updateProjects,
  editFieldPermissions,
  minProjects,
}) => {
  const dragItem: any = useRef<HTMLDivElement | null>(null)
  const dragOverItem: any = useRef<HTMLDivElement | null>(null)
  const {widgetSettings}: any = useSelector<RootState>(
    (state) => ({
      widgetSettings: state.talent.widgetSettings,
    }),
    shallowEqual
  )

  const dragStart = (e: any, position: any) => {
    dragItem.current = position
  }
  const dragEnter = (e: any, position: any) => {
    dragOverItem.current = position
  }

  const drop = (e: any) => {
    const copyProjectList = [...values?.projects]
    const dragProjectContent = copyProjectList[dragItem.current]
    copyProjectList.splice(dragItem.current, 1)
    copyProjectList.splice(dragOverItem.current, 0, dragProjectContent)
    dragItem.current = null
    dragOverItem.current = null
    updateProjects(copyProjectList)
  }

  const allowDrop = (e: any) => {
    window.scrollTo({
      top: 500,
      behavior: 'smooth',
    })
  }
  const hasEditFieldPermissions = editFieldPermissions()

  return (
    <div>
      {values?.projects.map((project: any, index: any) => (
        <table
          className='table table-responsive'
          key={index}
          onDragStart={hasEditFieldPermissions ? (e) => dragStart(e, index) : undefined}
          onDragEnter={hasEditFieldPermissions ? (e) => dragEnter(e, index) : undefined}
          draggable={hasEditFieldPermissions ? true : undefined}
          onDragOver={hasEditFieldPermissions ? (e) => allowDrop(e) : undefined}
          onDragEnd={hasEditFieldPermissions ? drop : undefined}
        >
          <thead>
            <tr>
              <th>
                <label
                  className='form-label required'
                  style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
                >
                  Project
                </label>

                {hasEditFieldPermissions && (
                  <button
                    type='button'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3 bg-light-blue-actions'
                    onClick={() => arrayHelpers.remove(index)}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Remove Item'
                    disabled={
                      minProjects ? index < minProjects : values.projects.length > 1 ? false : true
                    }
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen027.svg'
                      className='svg-icon svg-icon-3'
                    />
                  </button>
                )}

                {values.projects.length === index + 1 && hasEditFieldPermissions && (
                  <button
                    type='button'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3 bg-light-blue-actions'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Add New Item'
                    onClick={() =>
                      arrayHelpers.push({
                        name: '',
                        description: '',
                        technologies: '',
                        responsibility: '',
                        url: '',
                      })
                    }
                    disabled={!hasEditFieldPermissions}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr013.svg'
                      className='svg-icon  svg-icon-3'
                    />
                  </button>
                )}
                {values.projects.length > 1 && hasEditFieldPermissions && (
                  <button
                    type='button'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title='Drag'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen022.svg'
                      className='svg-icon svg-icon-3'
                    />
                  </button>
                )}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td data-label='Name'>
                <div className='d-flex flex-column w-100'>
                  <Field
                    name={`projects.[${index}].name`}
                    placeholder='Name'
                    className='form-control form-control-md form-control-solid'
                    style={
                      widgetId
                        ? {
                            background: widgetSettings.fieldBg,
                            color: `${widgetSettings?.textColor}`,
                            borderColor: widgetSettings.borderColor,
                          }
                        : {}
                    }
                    readOnly={!hasEditFieldPermissions}
                  />

                  <div className='text-danger'>
                    {saveData && <ErrorMessage name={`projects.[${index}].name`} />}
                  </div>
                </div>
              </td>
            </tr>

            <tr>
              <td data-label='Description'>
                <div className='d-flex flex-column w-100'>
                  <TextArea
                    name={`projects.[${index}].description`}
                    placeholder='Description'
                    className='form-control form-control-md form-control-solid'
                    label='Description'
                    rows={3}
                    // @ts-ignore
                    style={
                      widgetId
                        ? {
                            color: `${widgetSettings?.textColor}`,
                            background: widgetSettings.fieldBg,
                            borderColor: widgetSettings.borderColor,
                          }
                        : {}
                    }
                    readOnly={!hasEditFieldPermissions}
                  />
                  <div className='text-danger'>
                    {saveData && <ErrorMessage name={`projects.[${index}].description`} />}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label='Tools & Technologies'>
                <div className='d-flex flex-column w-100'>
                  <TextArea
                    name={`projects.[${index}].technologies`}
                    placeholder='Tools & Technologies'
                    className='form-control form-control-md form-control-solid'
                    label='technologies'
                    rows={3}
                    // @ts-ignore
                    style={
                      widgetId
                        ? {
                            color: `${widgetSettings?.textColor}`,
                            background: widgetSettings.fieldBg,
                            borderColor: widgetSettings.borderColor,
                          }
                        : {}
                    }
                    readOnly={!hasEditFieldPermissions}
                  />
                  <div className='text-danger'>
                    {saveData && <ErrorMessage name={`projects.[${index}].technologies`} />}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label='Roles & Responsibility'>
                <div className='d-flex flex-column w-100'>
                  <TextArea
                    name={`projects.[${index}].responsibility`}
                    placeholder='Roles & Responsibility'
                    className='form-control form-control-md form-control-solid'
                    label='responsibility'
                    rows={3}
                    // @ts-ignore
                    style={
                      widgetId
                        ? {
                            color: `${widgetSettings?.textColor}`,
                            background: widgetSettings.fieldBg,
                            borderColor: widgetSettings.borderColor,
                          }
                        : {}
                    }
                    readOnly={!hasEditFieldPermissions}
                  />
                  <div className='text-danger'>
                    {saveData && <ErrorMessage name={`projects.[${index}].responsibility`} />}
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td data-label='Url'>
                <div className='d-flex flex-column w-100'>
                  <Field
                    name={`projects.[${index}].url`}
                    placeholder='URL'
                    className='form-control form-control-md form-control-solid'
                    label='URL'
                    style={
                      widgetId
                        ? {
                            color: `${widgetSettings?.textColor}`,
                            background: widgetSettings.fieldBg,
                            borderColor: widgetSettings.borderColor,
                          }
                        : {}
                    }
                    readOnly={!hasEditFieldPermissions}
                  />
                  <div className='text-danger'>
                    {saveData && <ErrorMessage name={`projects.[${index}].url`} />}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ))}
    </div>
  )
}
