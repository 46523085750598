/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import clsx from 'clsx'
import '../../../_metronic/assets/sass/components/cards.scss'
import '../../../_metronic/assets/sass/components/wizards.scss'
import {Link} from 'react-router-dom'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'

const DashboardWrapper6: FC = () => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('vteamsTab1')
  
  return (
    <>
      <section className='drag-drop-card-main mb-5 pb-5'>
        <div className='container px-0'>
          <div className="row">
            <div className="col-xl-6">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="flex-grow-1 p-12 pb-40 card-rounded" style={{backgroundColor: '#FFF4DE'}}>
                    <div className="row">
                      <div className="col-12 col-xl-7">
                        <h4 className="text-danger masonry-title font-weight-bolder">Space 1</h4>
                        <p className="masonry-description text-dark-50 my-7 font-size-xl font-weight-bold">Offering discounts for your online store can be a powerful weapon in to drive loyalty</p>
                        <a href="#" className="masonry-btn btn btn-danger  font-weight-bold py-1 px-4">Go to Space</a>
                      </div>
                      <div className="col-12 col-xl-5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-danger flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-inverse-danger masonry-title font-weight-bolder">Space 2</h4>
                    <p className="masonry-description text-inverse-danger my-7 font-size-xl font-weight-bold">Boost marketing &amp; sales 
                    <br />through product confidence.</p>
                    <a href="#" className="masonry-btn btn btn-warning font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-primary flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-inverse-danger masonry-title font-weight-bolder">Space 3</h4>
                    <p className="masonry-description text-inverse-danger my-7 font-size-xl font-weight-bold">Boost marketing &amp; sales 
                    <br />through product confidence.</p>
                    <a href="#" className="masonry-btn btn btn-light font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-warning flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-white masonry-title font-weight-bolder">Space 4</h4>
                    <p className="masonry-description text-dark-50 my-7 font-size-xl font-weight-bold">Offering discounts for your online store can be a powerful weapon in to drive loyalty</p>
                    <a href="#" className="masonry-btn btn btn-light-success font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-info flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-inverse-info masonry-title font-weight-bolder">Space 5</h4>
                    <p className="masonry-description text-inverse-info my-7 font-size-xl font-weight-bold">Offering discounts for better online a store can loyalty weapon into driving</p>
                    <a href="#" className="masonry-btn btn btn-success font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="flex-grow-1 p-12 pb-40 card-rounded" style={{backgroundColor: '#FFF4DE'}}>
                    <div className='row'>
                      <div className='col-xl-12'>
                        <h4 className="text-warning masonry-title font-weight-bolder">Space 6</h4>
                        <p className="masonry-description text-dark-50 my-7 font-size-xl font-weight-bold">Start with a modern site design and customize it with your branding content, and features. All Premium blogs include custom CSS.</p>
                        <a href="#" className="masonry-btn btn btn-warning font-weight-bold py-1 px-4">Go to Space</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="flex-grow-1 p-12 pb-40 card-rounded">
                    <div className='row'>
                      <div className='col-xl-8'>
                        <h4 className="text-danger masonry-title font-weight-bolder">Space 7</h4>
                        <p className="masonry-description text-dark-50 my-7 font-size-xl font-weight-bold">Start with a modern site design and customize it with your branding content, and features. All Premium blogs include custom CSS.</p>
                        <a href="#" className="masonry-btn btn btn-danger font-weight-bold py-1 px-4">Go to Space</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-danger flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-inverse-info masonry-title font-weight-bolder">Space 8</h4>
                    <p className="masonry-description text-inverse-info my-7 font-size-xl font-weight-bold">Offering discounts for better online a store can loyalty weapon into driving</p>
                    <a href="#" className="masonry-btn btn btn-warning font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-xl-6">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="flex-grow-1 p-12 pb-40 card-rounded" style={{backgroundColor: '#1B283F'}}>
                    <div className='row'>
                      <div className='col-xl-12'>
                        <h4 className="text-white masonry-title font-weight-bolder">Space 9</h4>
                        <p className="masonry-description text-muted my-7 font-size-xl font-weight-bold">Start with a modern site design and customize it with your branding content, and features. All Premium blogs include custom CSS.</p>
                        <a href="#" className="masonry-btn btn btn-danger font-weight-bold py-1 px-4">Go to Space</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>           
            <div className="col-xl-6">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-warning flex-grow-1 p-12 pb-40 card-rounded">
                    <div className='row'>
                      <div className='col-xl-12'>
                        <h4 className="text-white masonry-title font-weight-bolder">Space 10</h4>
                        <p className="masonry-description text-white my-7 font-size-xl font-weight-bold">Start with a modern site design and customize it with your branding content, and features. All Premium blogs include custom CSS.</p>
                        <a href="#" className="masonry-btn btn btn-danger font-weight-bold py-1 px-4">Go to Space</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-danger flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-inverse-danger masonry-title font-weight-bolder">Space 11</h4>
                    <p className="masonry-description text-inverse-danger my-7 font-size-xl font-weight-bold">Boost marketing &amp; sales 
                    <br />through product confidence.</p>
                    <a href="#" className="masonry-btn btn btn-warning font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-primary flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-inverse-danger masonry-title font-weight-bolder">Space 12</h4>
                    <p className="masonry-description text-inverse-danger my-7 font-size-xl font-weight-bold">Boost marketing &amp; sales 
                    <br />through product confidence.</p>
                    <a href="#" className="masonry-btn btn btn-light font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
              <div className="card mb-6">
                <div className="card-body d-flex p-0">
                  <div className="bg-light-success flex-grow-1 p-12 pb-40 card-rounded">
                    <h4 className="text-danger masonry-title font-weight-bolder">Space 13</h4>
                    <p className="masonry-description text-dark-50 my-7 font-size-xl font-weight-bold">Offering discounts for your online store can be a powerful weapon in to drive loyalty</p>
                    <a href="#" className="masonry-btn btn btn-danger font-weight-bold py-1 px-4">Go to Space</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3">
                <div className="card mb-6">
                  <div className="card-body d-flex p-0">
                    <div className="bg-info flex-grow-1 p-12 pb-40 card-rounded">
                      <h4 className="text-inverse-info masonry-title font-weight-bolder">Space 14</h4>
                      <p className="masonry-description text-inverse-info my-7 font-size-xl font-weight-bold">Offering discounts for better online a store can loyalty weapon into driving</p>
                      <a href="#" className="masonry-btn btn btn-success font-weight-bold py-1 px-4">Go to Space</a>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export {DashboardWrapper6}
