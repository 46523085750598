import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import * as Yup from 'yup'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/DocumentsAction'
import {Modal} from 'react-bootstrap-v5'
import '../../../../_metronic/assets/sass/components/document-upload.scss'

interface Props {
  show: boolean
  documentsData: any
  workspaceid: string
  folderid: string
  setShowTable: any
  setCreateFolder: any
  id: string | null
  setIdRename: any
  accessToken: any
  handleClose: () => void
}
interface ICreateFolder {
  name: string
}
const inits: ICreateFolder = {
  name: '',
}
const filesExtensions = ['csv', 'pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx']

export const RenameFile: React.FC<Props> = ({
  show,
  handleClose,
  workspaceid,
  folderid,
  documentsData,
  setShowTable,
  setCreateFolder,
  id,
  setIdRename,
  accessToken,
}) => {
  const dispatch = useDispatch()
  const createFolderSchema = Yup.object().shape({
    name: Yup.string().required('Folder name is required').label('name'),
  })
  const [initValues, setInitValues] = useState<ICreateFolder>(inits)
  const [doc, setDoc] = useState<any>({})

  useEffect(() => {
    if (id) {
      const data = documentsData.filter((doc: any) => {
        return doc._id === id
      })
      setDoc(data[0])
      setInitValues({
        name: data[0]?.name,
      })
    }
  }, [id])
  return (
    <Modal
      id='kt_modal_delete_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered  border-radius-1rem mw-700px h-auto'
      show={show}
      onHide={handleClose}
    >
      <div className='modal-content border-radius-1rem'>
        <div className='modal-header d-flex justify-content-between'>
          <h2 className='fw-500 fs-6'>Create Folder</h2>
          <i className="fa fa-times fs-4" aria-hidden="true" data-kt-menu-dismiss="true" role={'button'} onClick={handleClose}></i>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'>
            <div className='flex-row-fluid px-lg-17'>
              <div className='current' data-kt-stepper-element='content'>
                <div className='w-100'>
                  <div className='fv-row'>
                    <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
                      Folder Name
                    </label>
                    <Formik
                      validationSchema={createFolderSchema}
                      initialValues={id ? initValues : inits}
                      enableReinitialize
                      onSubmit={(values: any) => {
                        if (!id) {
                          values.type = 'folder'
                          if (workspaceid) values.workspace = workspaceid

                          dispatch(
                            actions.createDocuments(
                              values,
                              workspaceid ? workspaceid : null,
                              folderid ? folderid : null
                            )
                          )
                          setCreateFolder(false)
                        } else {
                          values._id = doc._id
                          dispatch(
                            actions.UpdateDocument(
                              values,
                              workspaceid ? workspaceid : null,
                              folderid ? folderid : null
                            )
                          )
                          setIdRename('')
                        }
                        handleClose()
                      }}
                    >
                      <Form>
                        <div className='align-items-center'>
                          <div>
                            {/* <img
              src={
                doc?.type === 'file'
                  ? filesExtensions.includes(doc?.attachments[0]?.type) &&
                    doc?.attachments[0]?.uploaded !== false
                    ? `/media/icons/files/${doc?.attachments[0].type}.png`
                    : `${
                        doc?.attachments[0]?.thumbnailUrl
                      }?token=${accessToken}&itemType=${'documents'}&itemId=${doc._id}`
                  : '/media/icons/files/folder.png'
              }
              style={{height: '40px', width: '40px'}}
              className='me-2'
            /> */}

                            {/* {doc?.type === 'file' ? (
                  <img
                    src={
                      filesExtensions.includes(doc?.attachments[0]?.type) &&
                      doc?.attachments[0]?.uploaded !== false
                        ? `/media/icons/files/${doc?.attachments[0].type}.png`
                        : `${
                            doc?.attachments[0]?.thumbnailUrl
                          }?token=${accessToken}&itemType=${'documents'}&itemId=${doc._id}`
                    }
                    style={{height: '40px', width: '40px'}}
                    className='me-2'
                  />
                ) : (
                  <KTSVG
                    path='/media/icons/duotune/files/fil012.svg'
                    className='svg-icon-1 me-2 mt-3'
                  />
                )} */}
                            <Field
                              type='text'
                              className='form-control form-control-lg fs-6 form-control-solid'
                              name='name'
                              placeholder='Folder name'
                            />

                            {/* <button
                              type='submit'
                              className='btn btn-sm btn-light-primary ms-2'
                              style={{width: '60px'}}
                              title='Create Folder'
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen037.svg'
                                className='svg-icon-1'
                              />
                            </button>
                            <button
                              className='btn btn-sm btn-light-danger ms-2'
                              title='Cancel'
                              onClick={() => {
                                if (!id) {
                                  documentsData.length <= 0 && setShowTable(false)
                                  setCreateFolder(false)
                                } else {
                                  setIdRename('')
                                }
                                handleClose()
                              }}
                              style={{width: '60px'}}
                            >
                              <KTSVG
                                path='/media/icons/duotune/general/gen034.svg'
                                className='svg-icon-1'
                              />
                            </button> */}
                          </div>
                          <div className='text-danger ms-15 mt-1'>
                            <ErrorMessage name='name' />
                          </div>
                       <div className='modal-footer mt-20 pb-0 px-0'>
                       <div className='d-flex flex-stack justify-content-end mt-6'>
                            <div className='me-2'>
                              <button
                                className='btn btn-sm cancel_button fs-7 ms-2 fw-bolder me-3'
                                type='button'
                                title='Cancel'
                                onClick={() => {
                                  handleClose()
                                  if (!id) {
                                    documentsData.length <= 0 && setShowTable(false)
                                    setCreateFolder(false)
                                  } else {
                                    setIdRename('')
                                  }
                                }}
                                // style={{width: '60px'}}
                              >
                                Cancel
                              </button>
                            </div>
                            <div>
                              {!id ? (
                                <button
                                  className='btn btn-sm btn-blue fs-7'
                                  type='submit'
                                  // style={{width: '60px'}}
                                  title='Create Folder'
                                >
                                  <span className='indicator-label fs-7'>Create</span>
                                </button>
                              ) : (
                                <button
                                  className='btn btn-sm btn-blue me-3'
                                  type='submit'
                                  // style={{width: '60px'}}
                                  title='Create Folder'
                                >
                                  <span className='indicator-label'>Update</span>
                                </button>
                              )}
                            </div>
                          </div>
                       </div>
                        </div>
                      </Form>
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
