import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/ReleaseNoteAction'
import {NotFound} from '../../../../_metronic/layout/NotFound'
import {Loading} from '../../../../_metronic/layout/Loading'
import {KTSVG} from '../../../../_metronic/helpers'
import {Search} from '../../../../_metronic/layout/search/Search'
import '../../../../_metronic/assets/sass/components/cards.scss'
import '../../../../_metronic/assets/sass/components/release-notes.scss'
import {Pagination} from '../../../pagination/pagination'

interface Props {
  userId?: string | null
  onHide: () => void
}

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

const NotesDetails: React.FC<Props> = ({userId, onHide}) => {
  const dispatch = useDispatch()
  const listDetail: any = useSelector<RootState>(
    (state) => state.releasenote.ReleaseNoteDetail,
    shallowEqual
  ) as any
  const listLoading: any = useSelector<RootState>(
    (state) => state.releasenote.listLoading,
    shallowEqual
  ) as any

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.releasenote,
    shallowEqual
  ) as PaginationModel

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchReleaseNote({
        search,
        page: paginationData?.page,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchReleaseNote({
        search: '',
        page: event.selected + 1,
        sortby: '',
        orderby: '',
        createdAt: '',
      })
    )
  }

  const {id}: {id: string} = useParams()
  const [toggleTodoListing, setToggleTodoListing] = useState(true)

  const fetchTodoData = async () => {
    dispatch(actions.fetchReleaseNoteById(id))
  }
  useEffect(() => {
    fetchTodoData()
  }, [id])

  const crumbs = [
    {name: 'Dashboard', url: '/dashboard'},
    {name: 'Release Notes', url: `/release-notes`},
    {name: `${listDetail?.title}`, url: ''},
  ]

  return (
    <>
      <div className='mh-80 bg-white'>
        {!listLoading && (
          // <div className='card-header border-0 px-12 py-5'>
          //   <h3 className='card-title align-items-start flex-column m-0'>
          //     <span className='card-label fw-500 fs-2'>{listDetail?.title}</span>
          //   </h3>
          // </div>
          <div className='px-6 px-sm-12'>
            <div className="row">
              <div className="col-sm-6">
                <h2 className='fw-500 fs-2 m-0 py-5'>
                  {listDetail?.title}
                </h2>
              </div>
            </div>
          </div>
        )}
        {crumbs?.length > 0 && <Breadcrumbs crumbs={crumbs} />}
        <div className="overflow_height_scroll">
        <div className='card-body px-0 mt-5 mx-2 mx-sm-10'>
          {!listLoading && listDetail ? (
            <div className='row mx-auto drag-drop-card-main g-4 pb-0'>
              <div className='col-lg-12 mt-0'>
                <div className='drag-drop-card card mb-5 border-card-grey justify-content-evenly'>
                  <div className="col-sm-8">
                    <div className='border-0 justify-content-center mb-3  '>
                      <div className='card-title' title={listDetail?.title}>
                        {listDetail?.title}
                      </div>
                    </div>
                    <div className='d-flex list-box mb-5 flex-column flex-sm-row'>
                      {listDetail?.version && (
                        <div
                          className='text versionContent mb-5 mb-sm-0'
                          title={listDetail?.version}
                          style={{marginRight: '50px'}}
                        >
                          <span className='fw-bolder pe-2 subheading mb-5'>Version </span>
                          {listDetail?.version}
                        </div>
                      )}
                      {listDetail?.createdAt && (
                        <div
                          className='text createdAtContent'
                          title={
                            listDetail?.createdAt
                              ? moment(listDetail?.createdAt).format('MM/DD/YYYY')
                              : ''
                          }
                        >
                          <span className='fw-bolder pe-2 subheading'>Release Date </span>
                          {listDetail?.createdAt
                            ? moment(listDetail?.createdAt).format('MM/DD/YYYY')
                            : ''}
                        </div>
                      )}
                    </div>
                    {listDetail?.description && (
                      <span className='d-flex flex-column align-items-start'>
                        <span className='fw-bolder subheading mb-5'>Description</span>
                        <div
                          title={'Release Notes Descriptions'}
                          className='d-flex flex-column description '
                          dangerouslySetInnerHTML={{__html: listDetail?.description}}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : !listLoading && !listDetail ? (
            <NotFound />
          ) : (
            <Loading />
          )}
        </div>      
        </div>
      </div>
    </>
  )
}

export {NotesDetails}
