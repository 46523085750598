import React, {useEffect, useState, FC, useRef} from 'react'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import {useSelector, shallowEqual} from 'react-redux'
import axios from 'axios'
import {RootState} from '../../../setup'

interface SortingValues {
  sortby: string
  orderby: string
}
type Props = {
  setIsFiltered: any
  setSortValues: any
  sortValues: SortingValues
}
const API_URL = process.env.REACT_APP_API_URL
const LIST_USERS = `${API_URL}/users`

const WorkspaceSort: FC<Props> = ({setSortValues, sortValues, setIsFiltered}) => {
  const [orderby, setOrderBy] = useState<string>(sortValues.orderby)
  const [sortby, setSortBy] = useState<string>(sortValues.sortby)
  const [spaceType, setSpaceType] = useState<string>('')
  const [createdDate, setCreatedDate] = useState<string>('')
  const [assignee, setAssignee] = useState('')
  const [maintainer, setMaintainer] = useState('')

  const [rerender, setRerender] = useState(false)
  const [assignees, setAssignees] = useState<any>([])

  const selectRef = useRef<any>()

  const userInfo: any = useSelector<RootState>((state) => state.auth.user, shallowEqual)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  useEffect(() => {
    setOrderBy(sortValues.orderby)
    setSortBy(sortValues.sortby)
  }, [sortValues])

  const resetData = () => {
    if (selectRef && selectRef.current) {
      selectRef.current.clearValue()

      setRerender(rerender ? false : true)
    }

    setSortBy('')
    setOrderBy('desc')
    setCreatedDate('')
    setSpaceType('')
    setAssignee('')
    setMaintainer('')
  }

  const filterData = (e: any) => {
    if (
      orderby === 'desc' &&
      sortby === '' &&
      spaceType === '' &&
      createdDate === '' &&
      assignee === '' &&
      maintainer === ''
    ) {
      setIsFiltered(false)
    } else {
      setIsFiltered(true)
    }
    setSortValues({
      orderby: orderby,
      sortby: sortby,
      spaceType: spaceType,
      createdAt: createdDate,
      assignee: assignee,
      maintainer: maintainer,
    })
  }

  const fetchAssigneesData = async () => {
    const assigneeData = await axios.get(`${LIST_USERS}`)
    setAssignees(
      assigneeData?.data?.map((assigne: any) => {
        return {
          value: assigne._id,
          label: userInfo._id === assigne._id ? assigne.fullName + ' ' + '(Me)' : assigne.fullName,
          id: assigne._id,
        }
      })
    )
  }

  useEffect(() => {
    fetchAssigneesData()
  }, [])
  return (
    <>
      <div className='px-7 py-5 filters' data-kt-user-table-filter='form' title='Filters'>
        <>
          <div className='row mx-auto'>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='mb-5'>
                <label className='form-label fw-bolder'>Space Type</label>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='spaceType'
                  data-hide-search='true'
                  onChange={(e) => setSpaceType(e.target.value)}
                  value={spaceType}
                >
                  <option value=''></option>
                  <option value='0'>Group Space</option>
                  <option value='1'>Personal Space</option>
                </select>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='mb-5 multisel'>
                <label className='form-label fw-bolder'>Maintainer</label>

                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='maintainer'
                  data-hide-search='true'
                  onChange={(e) => {
                    setMaintainer(e.target.value)
                  }}
                  value={maintainer}
                >
                  <option value=''></option>
                  {assignees.map((assignee: any, index: number) => {
                    return (
                      <option value={assignee.id} key={index}>
                        {assignee.label}
                      </option>
                    )
                  })}
                </select>

                {/* <Select
                  ref={selectRef}
                  placeholder='Select'
                  name='assignee'
                  className='multi-select-container'
                  classNamePrefix='multi-select'
                  onChange={(e: any) => {
                    setAssignee(e.id)
                  }}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  components={animatedComponents}
                  options={assignees}
                /> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='mb-5 multisel'>
                <label className='form-label fw-bolder'>Created By</label>

                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='assignee'
                  data-hide-search='true'
                  onChange={(e) => {
                    setAssignee(e.target.value)
                  }}
                  value={assignee}
                >
                  <option value=''></option>
                  {assignees.map((assignee: any, index: number) => {
                    return (
                      <option value={assignee.id} key={index}>
                        {assignee.label}
                      </option>
                    )
                  })}
                </select>

                {/* <Select
                  ref={selectRef}
                  placeholder='Select'
                  name='assignee'
                  className='multi-select-container'
                  classNamePrefix='multi-select'
                  onChange={(e: any) => {
                    setAssignee(e.id)
                  }}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  components={animatedComponents}
                  options={assignees}
                /> */}
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='mb-5'>
                <label className='form-label fw-bolder'>Created Date</label>
                <input
                  type='date'
                  name='createdAt'
                  value={createdDate}
                  className='form-select form-select-solid date-field'
                  onChange={(event) => {
                    setCreatedDate(event.target.value)
                  }}
                />
              </div>
            </div>
          </div>

          <div className='row mx-auto'>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='mb-5'>
                <label className='form-label fw-bolder'>Sort By</label>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='orderby'
                  data-hide-search='true'
                  onChange={(e) => setSortBy(e.target.value)}
                  value={sortby}
                >
                  <option value=''></option>
                  <option value='name'>Name</option>
                  <option value='spaceKey'>Space Key</option>
                  <option value='spaceType'>Space Type</option>
                  {/* <option value='createdBy'>Created By</option> */}
                  <option value='createdAt'>Created Date</option>
                </select>
              </div>
            </div>
            <div className='col-lg-3 col-md-6 col-12'>
              <div className='mb-5'>
                <label className='form-label fw-bolder'>Order By</label>
                <select
                  className='form-select form-select-solid'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  data-kt-user-table-filter='two-step'
                  data-hide-search='true'
                  onChange={(e) => setOrderBy(e.target.value)}
                  value={orderby}
                >
                  <option value={'asc'}>Asc</option>
                  <option value='desc'>Desc</option>
                </select>
              </div>
            </div>
          </div>

          <div className='row mx-auto'>
            <div className='col-12 d-flex justify-content-end'>
              <button
                type='button'
                onClick={resetData}
                className='btn btn-light btn-active-light-primary fs-7 fw-bold me-2 px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='reset'
                title='Clear'
              >
                Clear
              </button>
              <button
                type='button'
                onClick={filterData}
                className='btn btn-primary fs-7 fw-bold px-6'
                title='Apply'
              >
                Apply
              </button>
            </div>
          </div>
        </>
      </div>
    </>
  )
}

export {WorkspaceSort}
