import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import _ from 'lodash'

interface entities {
  _id?: string
  name: string
  step?: string
  description: string
  slug: string
  default: boolean
  owner: string
  type: number
}

interface widgetModel {
  _id?: string
  borderColor: string
  buttonBgColor: string
  buttonHoverColor: string
  buttonType: string
  fieldBg: string
  labelsColor: string
  textColor: string
  secondaryButtonBgColor: string
  secondaryButtonHoverColor: string
  secondaryButtonTextColor: string
  thankUPageTitle: string
  thankUPageMessage: string
  errorTitle: string
  errorDescription: string
  allowEditOnSubmission: boolean
}

// Define a type for the slice state
interface talentState {
  talentLoading: boolean
  actionsLoading: boolean
  talentDetail?: entities
  totalCount: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  page: number
  error?: string
  widgetSettings?: widgetModel
  userHaveTalent?: boolean
  loading?: boolean
}

// Define the initial state using that type
const initialState: talentState = {
  talentLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 11,
  limitPerPage: 10,
  page: 1,
  error: '',
  widgetSettings: {
    fieldBg: '#f5f8fa',
    textColor: '#5e6278',
    borderColor: '#eef3f7',
    buttonBgColor: '#004b87',
    buttonHoverColor: '#073b66',
    buttonType: 'medium',
    labelsColor: '#181c32',
    secondaryButtonHoverColor: '#004b87',
    secondaryButtonBgColor: '#f1faff',
    secondaryButtonTextColor: '#004b87',
    thankUPageTitle: 'Thank You !',
    thankUPageMessage: "You're application has been submitted successfully.",
    errorTitle: 'This page could not be found!',
    errorDescription:
      'We are sorry. But the page you are looking for is not available. Perhaps you can try a new search.',
    allowEditOnSubmission: false,
  },
  loading: false,
}
export const callTypes = {
  talent: 'talent',
  action: 'action',
}

export const talentSlice = createSlice({
  name: 'talent',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    catchError: (state, action: PayloadAction<talentState>) => {
      state.error = `${action.payload}`
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.talent) {
        state.talentLoading = true
      }
    },
    singleTalent: (state, action) => {
      state.loading = action.payload
    },
    talentsFetched: (state, action: PayloadAction<talentState>) => {
      const {page, totalCount, entities, start, end} = action.payload
      state.talentLoading = false
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
      state.page = page
    },
    talentCreated: (state, action) => {
      state.actionsLoading = false
      const {data} = action.payload

      state.entities.push(data)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    talentUpdated: (state, action) => {
      state.actionsLoading = false
      const {data} = action.payload
      if (state && state.entities.length > 0) {
        state.entities = state.entities.map((entity: entities) => {
          if (entity._id === data._id) {
            return data
          }
          return entity
        })
      }
    },
    // deleteList
    talentDeleted: (state, action) => {
      state.entities = state.entities.filter((el) => el._id !== action.payload._id)
    },
    talentFetched: (state, action) => {
      const {data, userTalent} = action.payload
      state.talentDetail = data
      state.userHaveTalent = userTalent
      state.loading = false
    },

    widgetFetched: (state, action) => {
      state.widgetSettings = action.payload
    },
  },
})
