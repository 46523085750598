import * as serverRequest from './listCrud'
import {listSlice, callTypes} from './listSlice'
import {AppDispatch} from '../../../../setup/redux/Store'
const {actions} = listSlice

export const fetchLists =
  ({
    search,
    page,
    sortby,
    orderby,
    workspace,
    NotFound,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    workspace: string
    NotFound: string
  }) =>
  (dispatch: AppDispatch) => {
    if (NotFound === '') {
      dispatch(actions.startCall({callType: callTypes.list}))
    }
    return serverRequest.getLists({search, page, sortby, orderby, workspace}).then((response) => {
      const {data, page, totalCount, start, end, limitPerPage} = response?.data
      dispatch(
        actions.listsFetched({
          page,
          totalCount,
          start,
          end,
          limitPerPage,
          entities: data,
          listLoading: false,
          actionsLoading: false,
        })
      )
    })
  }
export const createList = (listToCreate: any) => (dispatch: AppDispatch) => {
  const {workspace} = listToCreate
  return serverRequest
    .createList(listToCreate.title, listToCreate.description, listToCreate.assignee, workspace)
    .then((response) => {
      serverRequest
        .getLists({search: '', page: 0, sortby: '', orderby: '', workspace: workspace})
        .then((response) => {
          const {data, page, totalCount, start, end, limitPerPage} = response?.data

          dispatch(
            actions.listFetched({page, totalCount, start, end, limitPerPage, entities: data})
          )
        })
    })
    .catch((error) => {
      error.clientMessage = "Can't create lists"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}
export const updateList = (listToUpdate: any) => (dispatch: AppDispatch) => {
  return serverRequest
    .updateList(
      listToUpdate._id,
      listToUpdate.title,
      listToUpdate.description,
      listToUpdate.assignee,
      listToUpdate.workspace
    )

    .then((response) => {
      const {data} = response
      dispatch(actions.listUpdated({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't update lists"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const deleteList = (_id: string, workspaceid: string) => (dispatch: AppDispatch) => {
  return serverRequest.deleteList(_id, workspaceid).then((response) => {
    serverRequest
      .getLists({search: '', page: 0, sortby: '', orderby: '', workspace: workspaceid})
      .then((response) => {
        const {data, page, totalCount, start, end, limitPerPage} = response?.data?.data

        dispatch(actions.listFetched({page, totalCount, start, end, limitPerPage, entities: data}))
      })
  })
}

export const fetchList = (_id: string, workspaceid: string) => (dispatch: AppDispatch) => {
  return serverRequest
    .fetchList(_id, workspaceid)
    .then((response) => {
      const {data} = response

      dispatch(actions.listFetched({data}))
    })
    .catch((error) => {
      error.clientMessage = "Can't fetch list"
      const errorMessage = error?.response?.data?.message
      dispatch(actions.catchError(errorMessage))
      throw new Error(errorMessage)
    })
}

export const makeListEmpty = (data: any) => (dispatch: AppDispatch) => {
  dispatch(actions.listFetched({data}))
}

export const removeErrorMessage = (error: any) => (dispatch: AppDispatch) => {
  dispatch(actions.catchError(error))
}
