import {ErrorMessage, Field, FieldArray} from 'formik'
import React, {Dispatch, SetStateAction} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {degrees, expWorkFrom, expWorkTo, expYears, universities} from '../talentUIHelper'
import {ProjectsTable} from './ProjectsTable'
import {SkillsTable} from './SkillsTable'
import {TextArea} from './TextArea'
import {RootState} from '../../../../setup'
interface Props {
  values: any
  error: string
  saveData: boolean
  widgetId: string
  updateProjects: any
  editFieldPermissions: any
  validationErrors: any
  minSkills?: number
  minProjects?: number
}

export const TalentStepOne = React.forwardRef<any, Props>(
  (
    {
      values,
      saveData,
      error,
      widgetId,
      updateProjects,
      editFieldPermissions,
      validationErrors,
      minSkills,
      minProjects,
    },
    ref: any
  ) => {
    const {widgetSettings}: any = useSelector<RootState>(
      (state) => ({
        widgetSettings: state.talent.widgetSettings,
      }),
      shallowEqual
    )

    return (
      <div className='current' data-kt-stepper-element='content'>
        <div className='w-100'>
          {error && (
            <div className='mb-lg-15 alert alert-danger' ref={ref}>
              <div className='alert-text font-weight-bold'>{error}</div>
            </div>
          )}

          <div className='fv-row mb-5'>
            <label
              className='d-flex align-items-center fs-5 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Name</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='name'
              placeholder='Name'
              style={
                widgetId
                  ? {
                      color: `${widgetSettings?.textColor}`,
                      background: widgetSettings.fieldBg,
                      borderColor: widgetSettings.borderColor,
                    }
                  : {}
              }
              readOnly={!editFieldPermissions()}
            />
            <div className='text-danger'>{saveData && <ErrorMessage name='name' />}</div>
          </div>
          <div className='fv-row mb-5'>
            <label
              className='d-flex align-items-center fs-5 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Job Title</span>
            </label>

            <Field
              type='text'
              className='form-control form-control-lg form-control-solid'
              name='jobTitle'
              placeholder='Job Title'
              style={
                widgetId
                  ? {
                      color: `${widgetSettings?.textColor}`,
                      background: widgetSettings.fieldBg,
                      borderColor: widgetSettings.borderColor,
                    }
                  : {}
              }
              readOnly={!editFieldPermissions()}
            />

            <div className='text-danger'>{saveData && <ErrorMessage name='jobTitle' />}</div>
          </div>

          <div className='fv-row'>
            <label
              className='d-flex align-items-center fs-5 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Professional Summary</span>
            </label>

            <TextArea
              name='professionalSummary'
              className='form-control form-control-lg form-control-solid'
              placeholder='Write a brief summary of your professional career in 5-10 lines.'
              label='Professional Summary'
              rows={5}
              /* @ts-ignore */
              style={
                widgetId
                  ? {
                      color: `${widgetSettings?.textColor}`,
                      background: widgetSettings.fieldBg,
                      borderColor: widgetSettings.borderColor,
                    }
                  : {}
              }
              formAvailability={!editFieldPermissions()}
            />

            <div className='text-danger'>
              {saveData && <ErrorMessage name='professionalSummary' />}
            </div>
          </div>
          <div className='fv-row mt-5'>
            <label
              className='d-flex align-items-center fs-5 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Skills</span>
            </label>
            <span>
              Rate your skills from a scale of 1-5 (5 being the Highest).
              {editFieldPermissions() &&
                ` Add at least ${minSkills} ${minSkills && minSkills > 1 ? 'skills' : 'skill'}.`}
            </span>
            <FieldArray
              name='skills'
              render={(arrayHelpers) => (
                <SkillsTable
                  widgetId={widgetId}
                  values={values}
                  saveData={saveData}
                  arrayHelpers={arrayHelpers}
                  editFieldPermissions={editFieldPermissions}
                  minSkills={minSkills}
                />
              )}
            />
            <div className='text-danger'>
              {values?.skills?.length < 3 && !Array.isArray(validationErrors?.skills) && (
                <ErrorMessage name='skills' />
              )}
            </div>
          </div>
          <div className='fv-row mt-5'>
            <label
              className='d-flex align-items-center fs-5 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Projects</span>
            </label>
            <span>
              Provide the following details for each of your projects worked on/completed to date.
              {editFieldPermissions() &&
                ` Add at least ${minProjects} ${
                  minProjects && minProjects > 1 ? 'projects' : 'project'
                }.`}
            </span>
            <FieldArray
              name='projects'
              render={(arrayHelpers) => (
                <ProjectsTable
                  widgetId={widgetId}
                  values={values}
                  saveData={saveData}
                  arrayHelpers={arrayHelpers}
                  updateProjects={updateProjects}
                  editFieldPermissions={editFieldPermissions}
                  minProjects={minProjects}
                />
              )}
            />
          </div>
          <div className='w-100'>
            <label
              className='fs-6 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Education</span>
            </label>
            <br />
            <span>Mention your last degree along name of university.</span>
            <div className='row fv-row'>
              <div className='col-12 col-sm-6 py-2 py-sm-0'>
                <Field
                  as='select'
                  name={`degree`}
                  className='form-select form-select-md form-select-solid'
                  style={
                    widgetId
                      ? {
                          minHeight: '41px',
                          maxHeight: '41px',
                          color: `${widgetSettings?.textColor}`,
                          background: widgetSettings.fieldBg,
                          borderColor: widgetSettings.borderColor,
                        }
                      : {}
                  }
                  disabled={!editFieldPermissions()}
                >
                  <option value=''>Select degree</option>
                  {degrees.map((degree: any, index: number) => {
                    return (
                      <option key={index} value={degree.value}>
                        {degree.label}
                      </option>
                    )
                  })}
                </Field>
                <div className='text-danger'>{saveData && <ErrorMessage name='degree' />}</div>
                {values?.degree === 'other' && (
                  <>
                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid mt-2'
                      name='otherDegree'
                      placeholder='MCS'
                      style={
                        widgetId
                          ? {
                              color: `${widgetSettings?.textColor}`,
                              background: widgetSettings.fieldBg,
                              borderColor: widgetSettings.borderColor,
                            }
                          : {}
                      }
                      disabled={!editFieldPermissions()}
                    />
                    <div className='text-danger'>
                      {saveData && <ErrorMessage name='otherDegree' />}
                    </div>
                  </>
                )}
              </div>
              <div className='col-12 col-sm-6 py-2 py-sm-0'>
                <Field
                  as='select'
                  name={`university`}
                  className='form-select form-select-md form-select-solid'
                  style={
                    widgetId
                      ? {
                          minHeight: '41px',
                          maxHeight: '41px',
                          color: `${widgetSettings?.textColor}`,
                          background: widgetSettings.fieldBg,
                          borderColor: widgetSettings.borderColor,
                        }
                      : {}
                  }
                  disabled={!editFieldPermissions()}
                >
                  <option value=''>Select university</option>
                  {universities.map((university: any, index: number) => {
                    return (
                      <option key={index} value={university.value}>
                        {university.label}
                      </option>
                    )
                  })}
                </Field>
                <div className='text-danger'>{saveData && <ErrorMessage name='university' />}</div>
                {values?.university === 'other' && (
                  <>
                    <Field
                      type='text'
                      className='form-control form-control-lg form-control-solid mt-2'
                      name='otherUniversity'
                      placeholder='Punjab University'
                      style={
                        widgetId
                          ? {
                              color: `${widgetSettings?.textColor}`,
                              background: widgetSettings.fieldBg,
                              borderColor: widgetSettings.borderColor,
                            }
                          : {}
                      }
                      readOnly={!editFieldPermissions()}
                    />
                    <div className='text-danger'>
                      {saveData && <ErrorMessage name='otherUniversity' />}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='w-100 mt-5'>
            <label
              className='fs-6 fw-bold mb-2'
              style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
            >
              <span className='required'>Working Tenure</span>
            </label>
            <br />
            <span>Mention the total working tenure of your professional career.</span>
            <div className='row fv-row'>
              <div className='col-12 col-sm-6 py-2 py-sm-0'>
                <Field
                  as='select'
                  name={`year`}
                  className='form-select form-select-md form-select-solid'
                  style={
                    widgetId
                      ? {
                          minHeight: '41px',
                          maxHeight: '41px',
                          color: `${widgetSettings?.textColor}`,
                          background: widgetSettings.fieldBg,
                          borderColor: widgetSettings.borderColor,
                        }
                      : {}
                  }
                  disabled={!editFieldPermissions()}
                >
                  <option value=''>Total experience (years)</option>
                  {expYears.map((year: any, index: number) => {
                    return (
                      <option key={index} value={year.value}>
                        {year.label}
                      </option>
                    )
                  })}
                </Field>
                <div className='text-danger'>{saveData && <ErrorMessage name='year' />}</div>
              </div>
              <div className='col-12 col-sm-6 py-2 py-sm-0'>
                <Field
                  as='select'
                  name={`experienceInMonth`}
                  className='form-select form-select-md form-select-solid'
                  style={
                    widgetId
                      ? {
                          minHeight: '41px',
                          maxHeight: '41px',
                          color: `${widgetSettings?.textColor}`,
                          background: widgetSettings.fieldBg,
                          borderColor: widgetSettings.borderColor,
                        }
                      : {}
                  }
                  disabled={!editFieldPermissions()}
                >
                  <option value=''>Select month</option>
                  {Array(11)
                    .fill('')
                    .map((month: number, index: number) => {
                      return (
                        <option key={index} value={index + 1}>
                          {index + 1}
                        </option>
                      )
                    })}
                </Field>
                <div className='text-danger'>{saveData && <ErrorMessage name='workFrom' />}</div>
              </div>
              {/* <div className='col-3'>
                <Field
                  as='select'
                  name={`workTo`}
                  className='form-select form-select-md form-select-solid'
                  style={{
                    minHeight: '41px',
                    maxHeight: '41px',
                    color: `${widgetSettings?.textColor}`,
                    background: widgetSettings.fieldBg,
                    borderColor: widgetSettings.borderColor,
                  }}
                >
                  <option value=''>Work to</option>
                  {expWorkTo.map((workTo: any, index: number) => {
                    return (
                      <option key={index} value={workTo.value}>
                        {workTo.label}
                      </option>
                    )
                  })}
                </Field>
                <div className='text-danger'>{saveData && <ErrorMessage name='workTo' />}</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
)
