import React, {useEffect, useState} from 'react'
import ReactPaginate from 'react-paginate'
import '../../_metronic/assets/sass/components/pagination.scss'

// Example items, to simulate fetching from another resources.

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  activityID?: string
}
type Props = {
  paginationData: PaginationModel
  fetchData: any
}

const Pagination: React.FC<Props> = ({paginationData, fetchData}) => {
  const {start, end, limitPerPage, totalCount, activityID} = paginationData

  // We start with an empty list of items.

  const [pageCount, setPageCount] = useState(0)
  // setDefaultPage(page)
  useEffect(() => {
    // Fetch items from another resources.

    setPageCount(Math.ceil(totalCount / limitPerPage))
  }, [limitPerPage, totalCount, start])

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    fetchData(event)
  }

  return (
    <>
      {totalCount > 0 && (
        <div className='d-flex flex-stack flex-wrap py-3 px-10'>
          <div className='fs-6 fw-bold text-light-grey-pagination pagination-entries'>
            {`Showing ${start} to ${end} of ${totalCount} entries`}
          </div>

          {totalCount > limitPerPage && (
            <ReactPaginate
              containerClassName='pagination'
              pageClassName='page-item '
              pageLinkClassName='page-link'
              activeClassName='page-item active'
              previousClassName='page-item previous'
              nextClassName='page-item next'
              previousLinkClassName='page-link'
              nextLinkClassName='page-link'
              breakLabel='...'
              nextLabel='>'
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              pageCount={pageCount}
              // previousLabel='«'
              previousLabel='<'
              // renderOnZeroPageCount={null}
            />
          )}
        </div>
      )}
    </>
  )
}

export {Pagination}
