import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const TALENT_URL = `${API_URL}/talents`
const WIDGETS_URL = `${API_URL}/widgets`

export function getLists({
  search,
  page,
  sortby,
  orderby,
  workspace,
}: {
  search: string
  page: number | string
  sortby: string
  orderby: string
  workspace: string
}) {
  return axios.get(`${TALENT_URL}`, {params: {search, page, sortby, orderby}})
}
export function createList(talentToUpdate: any, widget_id = '') {
  return axios.post(`${TALENT_URL}?widget_id=${widget_id}`, talentToUpdate)
}
export function updateList(talentToUpdate: any, widget_id = '') {
  return axios.patch(`${TALENT_URL}/${talentToUpdate?._id}?widget_id=${widget_id}`, talentToUpdate)
}

export function deleteList(_id: string, workspace: string) {
  return axios.delete(`${TALENT_URL}/${_id}`, {params: {workspace}})
}

export function fetchList(_id: string, widget_id = '', unique_id = '') {
  return axios.get(`${TALENT_URL}/${_id}?widget_id=${widget_id}&unique_id=${unique_id}`)
}
export function fetchUserTalent() {
  return axios.get(`${TALENT_URL}/user`)
}

export function findOne(id: string) {
  return axios.get(`${TALENT_URL}/${id}`)
}

export function fetchWidget(_id: any) {
  return axios.get(`${WIDGETS_URL}/${_id}`)
}

export function getAllWidgets() {
  return axios.get(`${WIDGETS_URL}/all`)
}
