/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, {FC, useEffect, useLayoutEffect} from 'react'
import {Redirect, Switch, Route, useLocation} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {PrivateRoutes} from './PrivateRoutes'
import {Logout, AuthPage} from '../modules/auth'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import {VerifyUserInvitation} from '../modules/accounts/VerifyUserInvitation'
import {DashboardDemo} from '../pages/dashboard/DashboardDemo'
import {TalentPublic} from '../modules/talent/talentPublicPage'
import 'bootstrap/dist/js/bootstrap.min.js'
import {Tooltip} from 'bootstrap'
import {toAbsoluteUrl} from '../../_metronic/helpers'

const Routes: FC = () => {
  const isAuthorized: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  {
    /*  const location = useLocation()
  useLayoutEffect(() => {
    setTimeout(() => {
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[title]'))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new Tooltip(tooltipTriggerEl)
        // return new Tooltip(tooltipTriggerEl, {
        //   trigger: 'hover',
        // })
      })
    }, 500)
  }, [location])*/
  }
  // const location = useLocation()
  // useLayoutEffect(() => {
  //   const tooltipInstances: any = []
  //   setTimeout(() => {
  //   const tooltipTriggerList = [].slice.call(document.querySelectorAll('[title]'))
  //   tooltipTriggerList.map(function (tooltipTriggerEl) {
  //     const tooltipInstance = new Tooltip(tooltipTriggerEl, {
  //       trigger: 'hover',
  //     })
  //     tooltipInstances.push(tooltipInstance)
  //   })
  // }, 500)
  //   return function cleanup() {
  //     tooltipInstances.forEach(function (tooltipInstance: any) {
  //       tooltipInstance.dispose()
  //     })
  //   }
  // }, [location])
  //   var banner: an
  //   if(isAuthorized){

  // }
  const urlParams = new URLSearchParams(window.location.search)
  const widget = urlParams.get('widget_id')

  var banner: any

  if (!widget) {
    if (isAuthorized) {
      banner = isAuthorized?.settings?.banner
    }

    if (!banner) {
      banner = localStorage.getItem('banner') ? localStorage.getItem('banner') : '7'
    }
  }
  useEffect(() => {
    document.body.style.backgroundImage = `url(${toAbsoluteUrl(
      `/media/patterns/custom/bg-${banner}.jpg`
    )})`
  }, [banner])

  return (
    <>
      <Switch>
        {window.location.search.includes('widget') && (
          <Route path='/talent' component={TalentPublic} />
        )}
        <Route path='/dashboardDemo' component={DashboardDemo} />
        <Route path='/verify-invitation' component={VerifyUserInvitation} />
        {!isAuthorized ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from='/groupcaptain' to='/' />
        )}

        <Route path='/error' component={ErrorsPage} />
        <Route path='/logout' component={Logout} />
        {!isAuthorized ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to='/login' />
        ) : (
          <MasterLayout>
            <PrivateRoutes />
          </MasterLayout>
        )}
      </Switch>
      <MasterInit />
    </>
  )
}

export {Routes}
