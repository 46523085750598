import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import * as projectActions from '../redux/ProjectActions'
import * as Yup from 'yup'
import {Templates} from './ProjectTemplates'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
type Props = {onHide?: any}

const CreateProjectForm: FC<Props> = ({onHide}) => {
  const dispatch: any = useDispatch()
  const [response, setResponse] = useState<boolean>(false)
  const [responseMessage, setResponseMessage] = useState<string>('')
  const [oldProjectName, setOldProjectName] = useState<string>('')
  const [oldProjectType, setOldProjectType] = useState<string>('')
  const [projectType, setProjectType] = useState<string>('')
  const [projectValidation, setProjectValidation] = useState<boolean>(false)
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()

  useEffect(() => {
    if (id) {
      dispatch(projectActions.getProjectById(id)).then((res: any) => {
        setOldProjectName(res.data.data.name)
        setOldProjectType(res.data.data.type)
        setProjectType(res.data.data.type)
      })
    }
  }, [id])

  const projectValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .trim('The project name cannot include leading and trailing spaces')
      .label('name')
      .min(1, 'Name must contain more than 3 characters'),
  })

  const initialValues = {
    ...(id ? {name: oldProjectName, type: oldProjectType} : {name: '', type: ''}),
  }
  const submitProjectData = (data: {name: string}) => {
    if (!projectType) {
      setProjectValidation(true)
      return
    }
    const projectData = {
      name: data.name,
      type: projectType,
      space: workspaceid ? workspaceid : '',
    }
    if (!id) {
      setResponse(true)
      dispatch(projectActions.createProject(projectData))
        .then((res: any) => {
          setResponse(false)
          onHide()
        })
        .catch((error: any) => {
          setResponseMessage(error?.response?.data?.message)
          setResponse(false)
        })
    } else {
      dispatch(projectActions.updateProjectById(id, projectData))
        .then((res: any) => {
          setResponse(false)
          onHide()
        })
        .catch((error: any) => {
          setResponseMessage(error?.response?.data?.message)
          setResponse(false)
        })
    }
  }

  const activeProjectType = (tempName: any) => {
    setProjectType(tempName)
  }
  return (
    <div className='create_project_content row m-0'>
      <div className={`create_project_cards col-lg-4 col-md-12 p-0 ${id ? 'disable-hover' : ''}`}>
        {projectValidation && !projectType && (
          <div className='pb-4 text-danger'>Please select one project type.</div>
        )}

        {Templates.map((template: any, i: number) => {
          return (
            <div
              className={`${projectType === template.key ? 'active' : ''} card`}
              key={i}
              onClick={id ? () => {} : () => activeProjectType(template?.key)}
            >
              <div className='d-flex justify-content-between'>
                <div className='my-2 icon-class-card'>
                  <img src={toAbsoluteUrl(template?.url)} />
                </div>
                <span>
                  <i
                    className={
                      projectType === template.key && id
                        ? `fa fa-lock`
                        : id
                        ? ''
                        : `fa fa-angle-right`
                    }
                    aria-hidden='true'
                  ></i>
                </span>
              </div>
              <h5>{template?.name}</h5>
              <p>{template?.description}</p>
            </div>
          )
        })}
      </div>
      {projectType && (
        <div className='p-0 col-lg-7 col-md-12 border-grey'>
          {responseMessage ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{responseMessage}</div>
            </div>
          ) : null}
          <Formik
            validationSchema={projectValidationSchema}
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values: any) => submitProjectData(values)}
          >
            {({resetForm}) => (
              <Form className='form p-12 p-sm-2px' noValidate id='kt_modal_create_app_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <div className='w-100 d-flex  flex-column justify-content-between'>
                    <div className='d-flex flex-column'>
                      <h4> {id ? 'Edit' : 'Create'} Project</h4>
                      <p className='font-small'>
                        You can change these details anytime in the project
                      </p>
                    </div>
                    <div className='d-flex mt-5 opposite-direction-responsive'>
                      <div className='fv-row mb-5  me-8'>
                        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                          <span className='required'>Name</span>
                        </label>

                        <Field
                          type='text'
                          className='form-control form-control-lg form-control-solid'
                          name='name'
                          placeholder='Add name'
                        />
                        <div className='text-danger'>
                          <ErrorMessage name='name' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex flex-start pt-10'>
                  <div className='me-2 d-flex buttons-responsive align-items-center'>
                    <button
                      disabled={response}
                      type='submit'
                      className='btn  btn-blue fs-7  me-3 mr-sm-0'
                      title={id ? 'Update' : 'Create'}
                    >
                      <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                    </button>
                    <button
                      type='submit'
                      className='btn btn-sm fs-7  fw-bolder  cancel_button'
                      onClick={() => {
                        onHide()
                      }}
                      title='Cancel'
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  )
}

export {CreateProjectForm}
