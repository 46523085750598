import {ErrorMessage, Field, Form, Formik} from 'formik'
import {FC, useEffect, useRef, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import {RootState} from '../../../../../setup'
import * as boardActions from '../../redux/BoardActions'

type Props = {
  scroll?: any
}

const AddNewColumn: FC<Props> = ({scroll}) => {
  const [showColumnForm, setShowColumnForm] = useState<boolean>(false)
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()
  const wrapperRef: any = useRef(null)
  const dispatch: any = useDispatch()

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowColumnForm(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    if (wrapperRef?.current) {
      wrapperRef?.current.scrollIntoView({behavior: 'smooth'})
    }
  }, [showColumnForm])

  const {currentProject}: any = useSelector<RootState>(
    (state) => ({
      currentProject: state.board.currentProject,
    }),
    shallowEqual
  )

  const createColumn = (columnData: any) => {
    if (columnData.name) {
      const createColumn: {
        name: string
        workSpace: string
        board: string
        project: string
        order: number
      } = {
        name: columnData.name,
        workSpace: workspaceid ? workspaceid : '',
        board: currentProject.board._id,
        project: currentProject._id,
        order: currentProject?.board?.columns?.length,
      }
      dispatch(boardActions.createColumn(createColumn)).then((res: any) => {
        if (res.status === 201) {
          dispatch(boardActions.currentProject(id))
          // resetForm()
          setShowColumnForm(false)
        }
      })
    }
  }

  return (
    <>
      {!showColumnForm && (
        <div className='d-block h-21px' onClick={() => setShowColumnForm(true)}>
          <a className='addition-svg'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='19px'
              height='19px'
              viewBox='0 0 512 512'
            >
              <path d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z' />
            </svg>
          </a>
        </div>
      )}

      {showColumnForm && (
        <Formik
          initialValues={{name: ''}}
          // validate={''}
          onSubmit={(values) => {
            createColumn(values)
          }}
        >
          {({isSubmitting}) => (
            <Form
              ref={wrapperRef}
              className=' todo-bar-search-from position-relative px-3 boder-1 rounded mt-9'
              style={{flex: '0 0 337px'}}
            >
              <Field
                type='text'
                name='name'
                className='form-control form-control-flush py-2'
                placeholder='Column Name...'
                autoComplete='off'
              />
              <ErrorMessage name='name' component='div' />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

export {AddNewColumn}
