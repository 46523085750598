import {createSlice, PayloadAction} from '@reduxjs/toolkit'

interface entities {
  _id?: string
  title: string
  description: string
}

// Define a type for the slice state
interface TaskState {
  listLoading: boolean
  actionsLoading: boolean
  totalCount?: number
  entities: entities[]
  start: number
  end: number
  limitPerPage: number
  attachments?: any
  taskDetail?: any
  newAttachments?: any
}

// Define the initial state using that type
const initialState: TaskState = {
  listLoading: true,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  start: 1,
  end: 10,
  limitPerPage: 10,
  attachments: [],
}

export const callTypes = {
  list: 'list',
  action: 'action',
}

export const taskSlice = createSlice({
  name: 'task',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true
      } else {
        state.actionsLoading = true
      }
    },
    tasksFetched: (state, action: PayloadAction<TaskState>) => {
      const {totalCount, entities, start, end} = action.payload
      state.listLoading = false
      state.entities = entities
      state.totalCount = totalCount
      state.start = start
      state.end = end
    },
    taskCreated: (state, action) => {
      // state.entities.splice(0, 0, action.payload.entities)
      state.actionsLoading = false
      state.entities.push(action.payload.entities)
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    taskUpdated: (state, action) => {
      state.actionsLoading = false
      const {entities} = action.payload
      if (state && state.entities.length > 0) {
        state.entities.map((entity: entities) => {
          if (entity._id === entities._id) {
            return state.entities.push(entities)
          }
          return state.entities.push(entity)
        })
      } else {
        state.entities = entities
      }
    },
    loadAttachments: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.attachments = action.payload
      } else {
        state.attachments.push(action.payload)
      }
    },
    removeAttachments: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)
      state.attachments = action.payload
    },

    activityLogsCreated: (state, action) => {
      //   state.entities.splice(0, 0, action.payload.entities)
      state.entities.push(action.payload.entities)
    },
    activityLogsFetched: (state, action: PayloadAction<TaskState>) => {
      const {entities, totalCount, start, end, limitPerPage} = action.payload
      state.listLoading = false
      if (entities && Array.isArray(entities)) {
        state.entities = entities
        state.totalCount = totalCount
        state.start = start
        state.end = end
      }
    },
    taskFetched: (state, action) => {
      const {entities} = action.payload

      state.taskDetail = entities
    },
  },
})
