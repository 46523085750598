/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import { toAbsoluteUrl } from '../../../../../_metronic/helpers'

export const CountStatus: FC = () => {
  const {boardTasksActivities}: any = useSelector<RootState>(
    (state) => ({
      boardTasksActivities: state.board.boardTasksActivities,
    }),
    shallowEqual
  )

  return (
    <>
      <div className='col-lg-6 col-md-6 col-sm-12 mt-7'>
        <div className='p-5 accordion-style-card'>
          <div>
            <p className='text-muted font-12px'>In the last 7 days</p>
            <p>{boardTasksActivities?.doneTasks} Done</p>
          </div>
          <div className='d-flex justify-content-end'>
            <img src={toAbsoluteUrl('/media/icons/projects/icon.png')} className='accord-image' />
          </div>
        </div>
      </div>
      <div className='col-lg-6  col-md-6 col-sm-12 mt-7'>
        <div className='p-5 accordion-style-card'>
          <div>
            <p className='text-muted font-12px'>In the last 30 days</p>
            <p>{boardTasksActivities?.updatedTask} Updated</p>
          </div>
          <div className='d-flex justify-content-end'>
            <img src={toAbsoluteUrl('/media/icons/projects/doc.svg')} className='accord-image' />
          </div>
        </div>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12 mt-7'>
        <div className='p-5 accordion-style-card'>
          <div>
            <p className='text-muted font-12px mb-1'>In the last 10 days</p>
            <p>{boardTasksActivities?.createdTasks} Created</p>
          </div>
          <div className='d-flex justify-content-end'>
            <img src={toAbsoluteUrl('/media/icons/projects/mask.svg')} className='accord-image' />
          </div>
        </div>
      </div>
      <div className='col-lg-6 col-md-6 col-sm-12 mt-7'>
        <div className='p-5 accordion-style-card'>
          <div>
            <p className='text-muted font-12px'>In the last 30 days</p>
            <p>{boardTasksActivities?.dueTasks} Due</p>
          </div>
          <div className='d-flex justify-content-end'>
            <img src={toAbsoluteUrl('/media/icons/projects/calendar.png')} className='accord-image' />
          </div>
        </div>
      </div>
    </>
  )
}
