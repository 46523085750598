/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import clsx from 'clsx'
import '../../../_metronic/assets/sass/components/cards.scss'
import '../../../_metronic/assets/sass/components/wizards.scss'
import {Link} from 'react-router-dom'
import {getLayout, ILayout, LayoutSetup, useLayout} from '../../../_metronic/layout/core'

const DashboardWrapper5: FC = () => {
  const {setLayout} = useLayout()
  const [tab, setTab] = useState('vteamsTab1')
  
  return (
    <>
      <section className='drag-drop-card-main mb-5 pb-5'>
        <div className='container px-0'>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 1</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 2</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 3</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 4</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 5</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 6</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 7</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 8</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p className="">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum industry's</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="drag-drop-card card mb-7">
                <div className="card-header border-0">
                  <div className="card-title">
                    <h3 className="card-label">Space 9</h3>
                  </div>
                  <div className="card-toolbar">
                    <a href="#" className="btn btn-icon btn-hover-light-primary draggable-handle justify-content-end">
                      <i className="fa fa-cog"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                  <a href="#" className="btn btn-primary font-weight-bold py-1 px-4 w-auto">Go to Space</a>
                </div>
              </div>
            </div>  
          </div>
        </div>
      </section>
    </>
  )
}

export {DashboardWrapper5}
