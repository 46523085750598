/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'
import {UserModel} from '../../../../app/modules/auth/models/UserModel'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {RootState} from '../../../../setup'
import {getImageURL} from '../../../helpers'
import {io} from 'socket.io-client'
import {InviteUsers} from '../../../../app/modules/accounts/components/InviteUsers'

const baseURL: any = process.env.REACT_APP_CHAT_URL
export const socket = io(baseURL)
let cId: any
socket.once('connected', (clientId) => {
  cId = clientId
})
const HeaderUserMenu: FC = () => {
  const [showInviteUserDialogue, setShowInviteUserDialogue] = useState(false)
  const {loginUser}: any = useSelector<RootState>(
    (state) => ({
      loginUser: state.auth.user,
    }),

    shallowEqual
  )

  const history = useHistory()
  useEffect(() => {
    if (loginUser) {
      socket.emit('update status', {
        socketId: cId,
        userId: loginUser._id,
        time: new Date(),
      })
    }
  }, [cId])
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any
  const dispatch = useDispatch()
  const logout = () => {
    if (localStorage.getItem('banner')) {
      localStorage.setItem('banner', '7')
    }
    socket.disconnect()
    dispatch(auth.actions.logout())
    // history.replace()
    // history.replace({state: {}})
    // window.location.reload()
    history.push({
      pathname: '/login',
      state: {
        prevPath: '/dashboard',
      },
    })

    // history.push({pathname: '/login', state: {prevPath: '/dashboard'}})
  }

  return (
    <>
      <InviteUsers
        show={showInviteUserDialogue}
        handleClose={() => {
          setShowInviteUserDialogue(false)
        }}
      />
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img
                alt={user?.fullName}
                className=''
                src={getImageURL(user?.image?.[0]?.thumbnailUrl, accessToken)}
              />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>{user?.fullName}</div>
              <a
                href='/account/my-profile'
                className='fw-bold text-muted text-hover-primary menu_ellipse'
                title='Email'
              >
                {user?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5' title='My Profile'>
          <Link to={'/account/my-profile'} className='menu-link px-5'>
            My Profile
          </Link>
        </div>
        <div className='menu-item px-5' title='Spaces'>
          <Link to={'/spaces'} className='menu-link px-5'>
            Spaces
          </Link>
        </div>

        <div
          className='menu-item px-5 my-1'
          title='Invite Users'
          data-toggle='modal'
          data-target='#exampleModalCenter'
          onClick={() => {
            setShowInviteUserDialogue(true)
          }}
        >
          <Link to={'#'} className='menu-link px-5'>
            Invite Users
          </Link>
        </div>

        <div className='menu-item px-5 my-1' title='Teams'>
          <Link to='/account/teams' className='menu-link px-5'>
            Teams
          </Link>
        </div>

        <div className='menu-item px-5 my-1' title='Teams'>
          <Link to='/account/notification-settings' className='menu-link px-5'>
            Notifications
          </Link>
        </div>
        {/* <div className='menu-item px-5 my-1' title='Teams'>
          <Link to='/account/cover-image' className='menu-link px-5'>
            Cover Image
          </Link>
        </div> */}

        <div className='separator my-2'></div>

        <div className='menu-item px-5' title='Sign Out'>
          <a onClick={logout} className='menu-link px-5'>
            Sign Out
          </a>
        </div>
      </div>
    </>
  )
}

export {HeaderUserMenu}
