export const Templates: any = [
  {
    name: 'Project',
    key: 'project',
    url: '/media/icons/projects/blank.png',
    description: 'Start fresh with a blank business project template.',
  },
  {
    name: 'Project Management',
    url: '/media/icons/projects/project.png',
    description: 'Manage activities for completing a business project.',
    key: 'project-management',
  },
  {
    name: 'Client On-boarding',
    url: '/media/icons/projects/boarding.png',
    description: 'Manage activities for client on-boarding.',
    key: 'client-on-boarding',
  },
  {
    name: 'Marketing Campaigns',
    url: '/media/icons/projects/management.png',
    description: 'Drive a marketing campaign from idea to execution.',
    key: 'marketing-campaigns',
  },
]
