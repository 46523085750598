/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../setup'
type Props = {
  discussionActions?: any
  setShowGroupPopup?: any
  workspaceid?: any
  setIsGeneralChannel?: any
  removeSelectedChat?: any
  setIsGroupAdmin?: any
  oldChatName?: any
  chatType?: any
}

export const CreateChannel: FC<Props> = ({
  discussionActions,
  setShowGroupPopup,
  workspaceid,
  setIsGeneralChannel,
  setIsGroupAdmin,
  removeSelectedChat,
  oldChatName,
  chatType,
}) => {
  const [loading, setLoading] = useState<any>(false)
  const dispatch: any = useDispatch()

  const {editChat, chatId, messages, loginUser, groupChat, groupCreated}: any =
    useSelector<RootState>(
      (state) => ({
        state,
        chatId: state.discussions.chatId,
        messages: state.discussions.messages,
        loginUser: state.auth.user,
        groupChat: state.discussions.groupChat,
        editChat: state.discussions.editChat,
        groupCreated: state.discussions.groupCreated,
      }),

      shallowEqual
    )

  const submitValues = async (val: any) => {
    dispatch(discussionActions.creatingChannel(false))

    if (editChat) {
      setLoading(true)
      const getRes = await dispatch(discussionActions.updatedGroup(chatId, val.chatName, ''))

      if (getRes.status === 200) {
        setLoading(false)
      }
      dispatch(discussionActions.getAllChat(workspaceid, chatType))
      dispatch(discussionActions.getAllMessages(chatId))
      setShowGroupPopup(false)
      dispatch(discussionActions.editChat(false))
    } else {
      const chatName = val.chatName
      const workSpace: any = workspaceid
      const users: Array<any> = []
      setLoading(true)
      const getRes = await dispatch(
        discussionActions.createGroup(chatName, users, workSpace, chatType)
      )
      if (getRes.length) {
        setLoading(false)
      }
      dispatch(discussionActions.groupCreated(true))
      dispatch(discussionActions.getAllChat(workspaceid, chatType))
      setIsGeneralChannel(false)
      removeSelectedChat('')
      dispatch(discussionActions.currentChatType(true))
      setShowGroupPopup(false)
      setIsGroupAdmin(loginUser._id)
    }
  }

  const cancelUpdate = () => {
    setShowGroupPopup(false)
    dispatch(discussionActions.editChat(false))
  }

  return (
    <div className='scroll-y h-sm-auto h-lg-475px mh-475px mb-3 w-100 p-0 border border-white '>
      <Formik
        initialValues={{chatName: editChat ? oldChatName : ''}}
        validationSchema={Yup.object({
          chatName: Yup.string().required('Channel name is required'),
        })}
        onSubmit={(values) => {
          submitValues(values)
        }}
        enableReinitialize={true}
      >
        <Form className='mb-5'>
          <div className='fv-row mb-5' style={{marginBottom: '100px'}}>
            <label className='d-flex align-items-center fs-6 fw-bold mb-2'>
              <span className='required'>Channel name</span>
            </label>
            <div className='d-flex input-group'>
              <Field
                type='text'
                className='pl-1 form-control form-control-lg form-control-solid fs-6'
                name='chatName'
                placeholder='Title'
              />
              <button
                type='submit'
                className=' float-end btn btn-blue rounded'
                data-kt-element='send'
                title={editChat ? 'Update' : 'Create'}
                disabled={loading}
              >
                {editChat ? 'Update' : 'Create'}
              </button>
            </div>
            <span className='text-danger' style={{fontSize: '13px'}}>
              <ErrorMessage name='chatName' />
            </span>
          </div>
        </Form>
      </Formik>
    </div>
  )
}
