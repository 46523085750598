export const constants = {
  CONTENT_FONT_FAMILY: 'Verdana',
  BOOk_ANTIQUA_FAMILY: 'Book Antiqua',
  PARAGRAPH_LINE_HEIGHT: 370,
  Colors: {
    NERO: '292929',
    GREY: '808080',
    BUNKER: '24292f',
    SHADE_GRAY: '7f7f7f',
    BLACK: '000000',
    DARK_GRAY: '4d4d4d',
    GRAY_BORDER: 'c0c0c0',
  },
  Titles: {
    CAREER_PROFILE: 'CAREER PROFILE',
    EDUCATION: 'EDUCATION',
    SKILLS_TOOLS: 'SKILLS & TOOLS',
    TOOLS_TECHNOLOGIES: 'Tools & Technologies',
    ROLE_AND_RESPONSIBILITIES: 'Role & Responsibilities/ Task Completed',
    PROJECTS_SUMMARY: 'PROJECTS SUMMARY',
    DESIGNATION: 'Designation',
    YEARS_OF_EXPERIENCE: ' Years’ Experience',
    NEXTBRIDGE_SALARY: 'PKR 0,000.00',
    VTEAMS_SALARY: '$ 0,000.00',
    PER_MONTH: ' per month',
    TIME: '12:00 AM to 09:00 AM EDT',
    URL: 'URL:',
    V_7_FORM: 'Candidate Resume Cover Form V-7',
    NEXTBRIDGE_URL_TEXT: 'www.nextbridge.com ',
    NEXTBRIDGE_URL: 'https://nextbridge.com',
    NEXTBRIDGE_HEADING: 'We Are Your Next Bridge',
    HEADER_ID: 'NXB-OPS-T-02-v1.0-Private',
  },
  Descriptions: {
    RESUME_SUMMARY:
      'Nextbridge Pvt. Ltd. is a software development company headquartered in Lahore with 3 other offices in Lahore and Islamabad. Formed in 1996, brought to life as a one-man team and has since groomed in to organization of 400+ resources. One of the most honest development companies in Pakistan software industry today. Our goal is to provide unique software development, outsourcing and consulting services to our clients that are beyond compare in the industry. Take a look at our candidate’s profile; his skills and professional projects articulated with a critical eye.',
    NEXTBRIDGE_FOOTER_DESCRIPTION:
      'Version 7. This fact sheet is for informational purposes only. NEXTBRIDGE makes no warranties, express or implied, in this document. The information contained in this document represents the current view and offerings of NEXTBRIDGE.',
    VTEAMS_FOOTER_DESCRIPTION:
      'Version 7. This fact sheet is for informational purposes only. NEXTWERK makes no warranties, express or implied, in this document. The information contained in this document represents the current view and offerings of NEXTWERK.',
    NEXTBRIDGE_COPYRIGHT: `© ${new Date().getFullYear()} NEXTBRIDGE PVT. LTD. All rights reserved`,
    VTEAMS_COPYRIGHT:
      '© 2003 NEXTWERK INC. All rights reserved. NEXTWERK, VTEAMS, VSUBSIDIARY, VIRTUALTEAMS are either registered trademarks or trademarks of NEXTWERK Inc. in the United States and/or other countries.',
  },
}
