import {ErrorMessage, Field} from 'formik'
import React, {useEffect} from 'react'
import {jobModes, joinings, noticeTime} from '../talentUIHelper'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import {TalentRecruiterFields} from './TalentRecruiterFields'
import {Can} from '../../../Common/RBAC/Can'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'
import {NumericFormat} from 'react-number-format'
interface Props {
  values: any
  setFieldValue: any
  error: string
  saveData: boolean
  widgetId: string
  id: any
  uniqueId: string
  editFieldPermissions: any
}

export const TalentStepTwo = React.forwardRef<any, Props>(
  ({values, saveData, id, setFieldValue, widgetId, uniqueId, editFieldPermissions}, ref: any) => {
    const {widgetSettings}: any = useSelector<RootState>(
      (state) => ({
        widgetSettings: state.talent.widgetSettings,
      }),
      shallowEqual
    )
    const {uniqueId: widgetUniqueId} = widgetSettings
    useEffect(() => {
      window.scrollTo(0, 0)
      ref?.current?.scrollIntoView({behavior: 'smooth'})
      const dateTime = document.getElementsByClassName(
        'date-time-piker'
      ) as HTMLCollectionOf<HTMLElement>

      for (let i = 0; i < dateTime.length; i++) {
        dateTime[i].style.color = widgetSettings?.textColor
        dateTime[i].style.background = widgetSettings?.fieldBg
        dateTime[i].style.borderColor = widgetSettings?.borderColor
      }
    }, [])

    return (
      <div className='current1 pt-5' data-kt-stepper-element='content1'>
        <div className='w-100'>
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label
                className='d-flex align-items-center fs-5 fw-bold mb-2'
                style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
              >
                <span className='required'>Job Mode</span>
              </label>

              <Field
                as='select'
                name='jobMode'
                className='form-select form-select-md form-select-solid'
                style={
                  widgetId
                    ? {
                        color: widgetSettings.textColor,
                        background: widgetSettings.fieldBg,
                        borderColor: widgetSettings.borderColor,
                      }
                    : {}
                }
                disabled={!editFieldPermissions()}
              >
                <option value=''>Select job mode</option>
                {jobModes.map((job: any, i: any) => {
                  return (
                    <option key={i} value={job.value}>
                      {job.label}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger'>{saveData && <ErrorMessage name='jobMode' />}</div>
            </div>

            <div className='col-md-6 fv-row mb-5'>
              <label
                className='d-flex align-items-center fs-5 fw-bold mb-2'
                style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
              >
                <span className='required'>Notice Period</span>
              </label>

              <Field
                as='select'
                name='noticePeriod'
                className='form-select form-select-md form-select-solid'
                style={
                  widgetId
                    ? {
                        color: `${widgetSettings?.textColor}`,
                        background: widgetSettings.fieldBg,
                        borderColor: widgetSettings.borderColor,
                      }
                    : {}
                }
                disabled={!editFieldPermissions()}
              >
                <option value=''>Select notice period</option>
                {noticeTime.map((notice: any, i: any) => {
                  return (
                    <option key={i} value={notice.value}>
                      {notice.label}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger'>{saveData && <ErrorMessage name='noticePeriod' />}</div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label
                className='d-flex align-items-center fs-5 fw-bold mb-2'
                style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
              >
                <span className='required'>Early Joining</span>
              </label>

              <Field
                as='select'
                name='earlyJoining'
                className='form-select form-select-md form-select-solid'
                style={
                  widgetId
                    ? {
                        color: `${widgetSettings?.textColor}`,
                        background: widgetSettings.fieldBg,
                        borderColor: widgetSettings.borderColor,
                      }
                    : {}
                }
                disabled={!editFieldPermissions()}
              >
                <option value=''>Select early joining</option>
                {joinings.map((joining: any, i: any) => {
                  return (
                    <option key={i} value={joining.value}>
                      {joining.label}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger'>{saveData && <ErrorMessage name='earlyJoining' />}</div>
            </div>
            <div className='col-md-6 fv-row mb-5'>
              <label
                className='d-flex align-items-center fs-5 fw-bold mb-2'
                style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
              >
                <span className='required'>Interview Time Slot</span>
              </label>

              <div className='row'>
                <div className='col-md-6 py-2 py-sm-0'>
                  <DatePicker
                    id='interviewTimeSlotStart'
                    name='interviewTimeSlotStart'
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    dateFormat='hh:mm a'
                    selected={moment(values?.interviewTimeSlotStart).toDate()}
                    className='form-control form-control-lg form-control-solid date-time-piker'
                    onChange={(date: Date) => {
                      setFieldValue('interviewTimeSlotStart', date)
                    }}
                    readOnly={!editFieldPermissions()}
                  />
                  <div className='text-danger'>
                    {saveData && <ErrorMessage name='interviewTimeSlotStart' />}
                  </div>
                </div>
                <div className='col-md-6 py-2 py-sm-0'>
                  <DatePicker
                    id='interviewTimeSlotEnd'
                    name='interviewTimeSlotEnd'
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption='Time'
                    dateFormat='hh:mm a'
                    selected={moment(values?.interviewTimeSlotEnd).toDate()}
                    className='form-control form-control-lg form-control-solid date-time-piker'
                    // @ts-ignore

                    onChange={(date: Date) => {
                      setFieldValue('interviewTimeSlotEnd', date)
                    }}
                    readOnly={!editFieldPermissions()}
                  />
                  <div className='text-danger'>
                    {saveData && <ErrorMessage name='interviewTimeSlotEnd' />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 fv-row mb-5'>
              <label
                className='d-flex align-items-center fs-5 fw-bold mb-2'
                style={widgetId ? {color: `${widgetSettings?.labelsColor}`} : {}}
              >
                <span className='required'>Current Salary(PKR)</span>
              </label>

              <NumericFormat
                name='currentSalary'
                value={values.currentSalary}
                thousandSeparator={true}
                onChange={(e: any) => {
                  setFieldValue('currentSalary', e.target.value)
                }}
                className='form-control form-control-lg form-control-solid'
                style={
                  widgetId
                    ? {
                        color: `${widgetSettings?.textColor}`,
                        background: widgetSettings.fieldBg,
                        borderColor: widgetSettings.borderColor,
                      }
                    : {}
                }
                readOnly={!editFieldPermissions()}
              />

              <div className='text-danger'>{saveData && <ErrorMessage name='currentSalary' />}</div>
            </div>

            <div className='col-md-6 fv-row mb-5'>
              <label
                className='d-flex align-items-center fs-5 fw-bold mb-2'
                style={{color: `${widgetSettings?.labelsColor}`}}
              >
                <span className='required'>Salary Demand(PKR)</span>
              </label>

              <NumericFormat
                name='salaryDemand'
                value={values.salaryDemand}
                thousandSeparator={true}
                onChange={(e: any) => {
                  setFieldValue('salaryDemand', e.target.value)
                }}
                className='form-control form-control-lg form-control-solid'
                style={
                  widgetId
                    ? {
                        color: `${widgetSettings?.textColor}`,
                        background: widgetSettings.fieldBg,
                        borderColor: widgetSettings.borderColor,
                      }
                    : {}
                }
                readOnly={!editFieldPermissions()}
              />

              <div className='text-danger'>{saveData && <ErrorMessage name='salaryDemand' />}</div>
            </div>
          </div>
          {widgetId && uniqueId === widgetUniqueId && (
            <TalentRecruiterFields widgetId={widgetId} id={id} saveData={saveData} />
          )}
          <Can I='update' this='talent'>
            <TalentRecruiterFields widgetId={widgetId} id={id} saveData={saveData} />
          </Can>
        </div>
      </div>
    )
  }
)
