import {AlignmentType, BorderStyle, ImageRun, Paragraph, Table, TextRun, WidthType} from 'docx'
import {toAbsoluteUrl} from '../../../../../../../_metronic/helpers'
import {constants} from './Constants'

export const linesBreak = (numberOfLines: number): Paragraph => {
  return new Paragraph({
    text: '',
    spacing: {
      after: numberOfLines,
    },
  })
}

export const blob: any = async (url: any) => {
  return await fetch(toAbsoluteUrl(url)).then((r) => r.blob())
}
export const showImage = (url: string, width: number, height: number): Paragraph => {
  return new Paragraph({
    alignment: AlignmentType.CENTER,
    children: [
      new ImageRun({
        data: blob(url),
        transformation: {
          width,
          height,
        },
      }),
    ],
  })
}

export const headerImage = (url: string, width: number, height: number): Paragraph => {
  return new Paragraph({
    indent: {
      left: 300,
    },
    alignment: AlignmentType.LEFT,
    children: [
      new ImageRun({
        data: blob(url),
        transformation: {
          width,
          height,
        },
      }),
    ],
  })
}

export const titlePageImage = (url: string, width: number, height: number): Paragraph => {
  return new Paragraph({
    alignment: AlignmentType.LEFT,
    children: [
      new ImageRun({
        data: blob(url),
        transformation: {
          width,
          height,
        },
        floating: {
          zIndex: 0,
          allowOverlap: true,
          horizontalPosition: {
            offset: 0,
          },
          verticalPosition: {
            offset: 0,
          },
          behindDocument: true,
        },
      }),
    ],
  })
}

export const resumeSummary = (): Paragraph => {
  return new Paragraph({
    alignment: AlignmentType.JUSTIFIED,
    indent: indentSpace(1500, 1500),
    spacing: {
      line: constants.PARAGRAPH_LINE_HEIGHT,
    },
    children: [
      new TextRun({
        break: 1,
        text: constants.Descriptions.RESUME_SUMMARY,
        font: {
          name: constants.CONTENT_FONT_FAMILY,
        },
        size: 20,
        color: constants.Colors.BLACK,
      }),
    ],
  })
}

export const indentSpace = (start = 100, end = 100) => {
  return {
    start,
    end,
  }
}

export const docxBorder = (
  color = 'ffffff',
  top = 0,
  bottom = 0,
  left = 0,
  right = 0,
  borderType = 'SINGLE'
) => {
  // @ts-ignore
  const borderT = BorderStyle[borderType]

  return {
    top: {
      style: top === 0 ? BorderStyle.NONE : borderT,
      color: color,
      size: top,
    },
    bottom: {
      style: bottom === 0 ? BorderStyle.NONE : borderT,
      color: color,
      size: bottom,
    },
    left: {
      style: left === 0 ? BorderStyle.NONE : borderT,
      color: color,
      size: left,
    },
    right: {
      style: right === 0 ? BorderStyle.NONE : borderT,
      color: color,
      size: right,
    },
  }
}
export const docxTable = (
  tableRows: any,
  borderColor = 'ffffff',
  borderTop = 0,
  borderBottom = 0,
  borderLeft = 0,
  borderRight = 0
) => {
  return new Table({
    rows: tableRows,
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    borders: docxBorder(borderColor, borderTop, borderBottom, borderLeft, borderRight),
  })
}

export const addLineBreak = (arr: any) => {
  let run: any = []
  arr?.map((text: any, i: any) => {
    run.push(
      new Paragraph({
        indent: indentSpace(),
        spacing: {
          line: constants.PARAGRAPH_LINE_HEIGHT,
        },
        alignment: AlignmentType.JUSTIFIED,
        children: [
          new TextRun({
            text: `${text}`,
            color: constants.Colors.BUNKER,
            font: {name: constants.CONTENT_FONT_FAMILY},
            size: 20,
          }),
        ],
      })
    )
  })

  return run
}
