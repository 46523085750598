import { getImageURL } from "../../_metronic/helpers"

export const getMembers = (assignees: [], teams: [], maintainers: [], owner: any) => {
  let totalMembers = 0
  const userIds: any[] = []
  if (assignees?.length > 0) {
    assignees?.map((assignee: any) => {
      // if (assignee?._id != owner?._id) totalMembers += 1
      userIds.push(assignee?._id)
    })
    totalMembers += assignees?.length
  }
  if (teams?.length > 0) {
    teams?.map((team: any) => {
      // team.members?.map((member: any) => {
      //   if (!userIds.includes(member?._id) && member?._id != owner?._id) totalMembers += 1
      //   userIds.push(member?._id)
      // })
      totalMembers += team?.members?.length
    })
  }

  if (maintainers?.length > 0) {
    maintainers?.map((maintainer: any) => {
      if (!userIds.includes(maintainer) && maintainer?._id != owner?._id) totalMembers += 1
    })
    // totalMembers += maintainers?.length
  }

  if (owner) {
    totalMembers += 1
  }

  return totalMembers
}

export const getNewSpaceMembers = (assignees: [], teams: [], maintainers: [], owner: any, accessToken: any = "") => {
  let totalMembers: any = []
  const userIds: any[] = []
  if (assignees?.length > 0) {
    assignees?.map((assignee: any) => {
      userIds.push(assignee?._id)
      totalMembers.push(assignee)
    })
  }
  if (teams?.length > 0) {
    teams?.map((team: any) => {
      team.members?.map((member: any) => {
        if (!userIds.includes(member?._id) && member?._id != owner?._id) {
          userIds.push(member?._id)
          totalMembers.push(member)
        }
      })
    })
  }

  if (maintainers?.length > 0) {
    maintainers?.map((maintainer: any) => {
      if (!userIds.includes(maintainer?._id) && maintainer?._id != owner?._id) {
        userIds.push(maintainer?._id)
        totalMembers.push(maintainer)
      }
    })
  }

  if (owner) {
    if (!userIds.includes(owner?._id)) {
      totalMembers.push(owner)
    }
  }

  totalMembers = JSON.parse(JSON.stringify(totalMembers));
  totalMembers.map((user: any) => {
    if (Array.isArray(user?.image)) {
      user.image = getImageURL(user?.image[0].thumbnailUrl, accessToken)
    }
  })

  return totalMembers
}
export const getSpacePreviewMembers = (assignees: [], teams: [], maintainers: [], owner: any, accessToken: any = "") => {
  let totalMembers: any = []
  const userIds: any[] = []
  if (assignees?.length > 0) {
    assignees?.map((assignee: any) => {
      userIds.push(assignee?._id)
      totalMembers.push(assignee)
    })
  }
  if (teams?.length > 0) {
    teams?.map((team: any) => {
      team.members?.map((member: any) => {
        if (!userIds.includes(member?._id) && member?._id != owner?._id) {
          userIds.push(member?._id)
          totalMembers.push(member)
        }
      })
    })
  }

  if (maintainers?.length > 0) {
    maintainers?.map((maintainer: any) => {
      if (!userIds.includes(maintainer?._id) && maintainer?._id != owner?._id) {
        userIds.push(maintainer?._id)
        totalMembers.push(maintainer)
      }
    })
  }

  if (owner) {
    if (!userIds.includes(owner?._id)) {
      totalMembers.push(owner)
    }
  }

  totalMembers = JSON.parse(JSON.stringify(totalMembers));
  totalMembers.map((user: any) => {
    if (Array.isArray(user?.image)) {
      user.image = getImageURL(user?.image[0].thumbnailUrl, accessToken)
    }
  })

  return totalMembers
}
