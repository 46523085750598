/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useRef, useState} from 'react'
import {useDrag, useDrop} from 'react-dnd'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {TaskCard} from './partial/TaskCard'
import {TaskPopup} from './TaskPopup'
// import scroll from '../scrolling'
import useDragScrolling from '../useDragScrolling'

type Props = {
  task: any
  index: any
  columnId: any
  moveCard: any
  dragType: string
  handleDragType: any
  columnIndex: number
  sortTasks: any
}
const Task: FC<Props> = ({
  task,
  index,
  moveCard,
  columnId,
  handleDragType,
  columnIndex,
  dragType,
  sortTasks,
}) => {
  const [showTask, setShowTask] = useState<boolean>(false)
  const [taskId, setTaskId] = useState<string>('')
  const ref: any = useRef(null)
  const {accessToken}: any = useSelector<RootState>(
    (state) => ({
      accessToken: state.auth.accessToken,
    }),
    shallowEqual
  )

  const {addEventListenerForWindow, removeEventListenerForWindow} = useDragScrolling()

  const [, drop] = useDrop({
    accept: 'item',
    hover(task: any, monitor) {
      // scroll.addEventListenerForSidebar()
      // handleDragType('card')
      if (dragType === 'column') {
        return
      }
      if (task?.task) {
        handleDragType('column')
        return
      }
      if (!ref?.current) {
        return
      }

      const dragIndex = task?.index
      const hoverIndex = index

      const dragColumnIndex = task?.columnIndex
      const hoverColumnIndex = columnIndex

      // Don't replace items with themselves
      if (dragColumnIndex === hoverColumnIndex && dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect?.bottom - hoverBoundingRect?.top) / 2

      // Get horizontal middle
      const hoverMiddleX = (hoverBoundingRect?.right - hoverBoundingRect?.left) / 2

      // Determine mouse position
      const clientOffset: any = monitor?.getClientOffset()

      // Get pixels to the top
      const hoverClientY = clientOffset?.y - hoverBoundingRect?.top

      // Get pixels to the left
      const hoverClientX = clientOffset?.x - hoverBoundingRect?.left

      const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY
      const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY

      const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX
      const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX

      if (upwards && (leftwards || rightwards)) {
        return
      }

      if (downwards && (leftwards || rightwards)) {
        return
      }

      moveCard(dragColumnIndex, dragIndex, hoverColumnIndex, hoverIndex)
      task.index = index
      task.columnIndex = columnIndex
    },
    drop(task: any, monitor: any) {
      removeEventListenerForWindow()
      handleDragType('column')
      if (dragType !== 'column') {
        sortTasks(columnIndex, task._id)
      }
    },
  })

  const [{isDragging}, drag]: any = useDrag({
    type: 'item',
    item: () => {
      addEventListenerForWindow()
      return {...task, index, columnIndex}
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      // like end drag
      removeEventListenerForWindow()
    },
    canDrag: (monitor: any) => {
      handleDragType('card')

      return true
    },
  })

  const opacity = isDragging ? 0.5 : 1
  const dragAndDropRef = drag(drop(ref))
  // const {updatePosition} = useScroll(dragAndDropRef)

  // const dragDropManager = useDragDropManager()
  // const monitor = dragDropManager.getMonitor()

  // useEffect(() => {
  //   const unsubscribe = monitor.subscribeToOffsetChange(() => {
  //     const offset = monitor.getSourceClientOffset()?.x as number
  //     updatePosition({position: offset, isScrollAllowed: true})
  //   })
  //   return unsubscribe
  // }, [monitor, updatePosition])

  const openPopup = (id: string) => {
    setTaskId(id)
    setShowTask(true)
  }

  let isBroken = false
  return (
    <>
      <TaskPopup
        show={showTask}
        handleClose={() => {
          setShowTask(false)
        }}
        taskId={taskId}
      />
      <div
        ref={dragAndDropRef}
        className={`kanban-item ${task?.attachments?.[0]?.url ? 'p-0' : ''}`}
        data-classname='light-primary'
        role='button'
        onClick={() => openPopup(task._id)}
      >
        {task?.attachments?.[0]?.url ? (
          <>
            {task?.attachments?.map((img: any, i: any) => {
              if (
                (img?.type?.toLowerCase() === 'jpg' ||
                  img?.type?.toLowerCase() === 'png' ||
                  img?.type?.toLowerCase() === 'jpeg' ||
                  img?.type?.toLowerCase() === 'gif') &&
                !isBroken
              ) {
                isBroken = true
                return (
                  <img
                    key={i}
                    src={`${
                      img.thumbnailUrl
                    }?token=${accessToken}&imageType=${'landscape'}&itemType=${'task'}&itemId=${
                      task._id
                    }`}
                    className='kanban-image-ui'
                  />
                )
              }
            })}

            {/* </Link> */}
            <div className='p-3'>
              <TaskCard task={task} />
            </div>
          </>
        ) : (
          <TaskCard task={task} />
        )}
      </div>
    </>
  )
}

export {Task}
