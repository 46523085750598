const currentYear = new Date().getFullYear()
const range = (start: number, stop: number, step: number) =>
  Array.from({length: (stop - start) / step + 1}, (_, i) => start + i * step)

export const degrees = [
  {value: 'bscs', label: 'BSCS'},
  {value: 'bsse', label: 'BSSE'},
  {value: 'bsit', label: 'BSIT'},
  {value: 'bsds', label: 'BSDS'},
  {value: 'bsis', label: 'BSIS'},
  {value: 'bsce', label: 'BSCE'},
  {value: 'mcs', label: 'MCS'},
  {value: 'msai', label: 'MSAI'},
  {value: 'msbis', label: 'MSBIS'},
  {value: 'mscs', label: 'MSCS'},
  {value: 'phdcs', label: 'PHDCS'},
  {value: 'phdse', label: 'PHDSE'},
  {value: 'phdds', label: 'PHDDS'},
  {value: 'phdis', label: 'PHDIS'},
  {value: 'phdai', label: 'PHDAI'},
  {value: 'other', label: 'Other'},
]

export const universities = [
  {value: 'pu', label: 'Punjab University PU Lahore(PU)'},
  {value: 'uet', label: 'University of Engineering and Technology(UET)'},
  {value: 'umt', label: 'University of Management & Technology(UMT)'},
  {value: 'riphah', label: 'Riphah International University'},
  {value: 'uof', label: 'University of Agriculture Faisalabad(UOF)'},
  {value: 'uog', label: 'University of Gujrat(UOG)'},
  {value: 'aiou', label: 'Allama Iqbal Open University (AIOU)'},
  {value: 'uos', label: 'University of Sargodha (UOS)'},
  {value: 'gcu', label: 'Government College University (GCU)'},
  {value: 'iub', label: 'The Islamia University of Bahawalpur (IUB)'},
  {value: 'bzu', label: 'Bahauddin Zakariya University (BZU)'},
  {value: 'lcwu', label: 'Lahore College for Women University (LCWU)'},
  {value: 'ue', label: 'University of Education'},
  {value: 'qui', label: 'Quaid-i-Azam University Islamabad'},
  {value: 'comsats', label: 'COMSATS University'},
  {value: 'iiu', label: 'International Islamic University(IIU)'},
  {value: 'uol', label: 'The University of Lahore'},
  {value: 'fc', label: 'Forman Christian College University Lahore'},
  {value: 'ucp', label: 'University of Central Punjab (UCP) Lahore'},
  {value: 'usa', label: 'University of South Asia Lahore'},
  {value: 'qmc', label: 'Queen Mary College, Lahore'},
  {value: 'uok', label: 'University of Karachi'},
  {value: 'au', label: 'Air University Islamabad'},
  {value: 'vu', label: 'Virtual University of Pakistan Lahore'},
  {value: 'su', label: 'Superior University'},
  {value: 'other', label: 'Other'},
]

export const expYears = range(20, 0, -1)
  .map((year: number) => {
    return {
      label: year === 0 ? 'Fresh' : year,
      value: year,
    }
  })
  .reverse()

export const expWorkTo = range(currentYear, currentYear - 20, -1).map((year: number) => {
  return {label: year === currentYear ? 'Present' : year, value: year}
})

export const expWorkFrom = range(currentYear, currentYear - 20, -1).map((year: number) => {
  return {label: year, value: year}
})

export const noticeTime = [
  {value: '7', label: '1 Week'},
  {value: '14', label: '2 Week'},
  {value: '21', label: '3 Week'},
  {value: '30', label: '1 Month'},
  {value: '60', label: '2 Month'},
  {value: '90', label: '3 Month'},
  {value: '120', label: '4 Month'},
  {value: '150', label: '5 Month'},
  {value: '180', label: '6 Month'},
]

export const joinings = [
  {value: 'interested', label: 'Interested'},
  {value: 'not-interested', label: 'Not Interested'},
]
export const statuses = [
  {value: 'shorlisted', label: 'Shorlisted'},
  {value: 'not-shortlisted', label: 'Not Shortlisted'},
]
export const approvedStatuses = [
  {value: 'initialized', label: 'Initialized'},
  {value: 'submitted', label: 'Submitted'},
  {value: 'confirmed', label: 'Confirmed'},
]
export const jobModes = [
  {value: 'onsite', label: 'Onsite'},
  {value: 'remote', label: 'Remote'},
  {value: 'hybrid', label: 'Hybrid'},
]
let experienceArray = range(1, 11, +1)
range(1, 20, +1).map((value) => {
  experienceArray.push(value)
})
export const ratings: Array<number> = [1, 2, 3, 4, 5]
export let experiences: Array<any> = []
for (let i = 0; i < 31; i++) {
  if (i < 11) {
    experiences.push({
      label: experienceArray[i] === 1 ? '1 Month' : `${experienceArray[i]} Months`,
      value: experienceArray[i],
    })
  } else
    experiences.push({
      label: experienceArray[i] === 1 ? '1 Year' : `${experienceArray[i]} Years`,
      value: experienceArray[i],
    })
}
