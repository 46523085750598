/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment'
import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../../../setup'
import {getImageURL} from '../../../../../../_metronic/helpers'
type Props = {
  task?: any
}
const TaskCard: FC<Props> = ({task}) => {
  const {workspaceid, id}: {workspaceid: string; id: string} = useParams()

  const {accessToken}: any = useSelector<RootState>(
    (state) => ({
      accessToken: state.auth.accessToken,
    }),
    shallowEqual
  )

  return (
    // <Link to={`/${workspaceid}/project/${id}/task/${task?._id}`}>
    <div>
      <span className='font-weight-bold w-70'>
        <h3>{task?.title}</h3>
      </span>
      {task?.label && (
        <div className='d-flex flex-wrap mt-4'>
          {/* <span className='bg-red btn-sm text-white'>Design</span> */}
          {/* <span className='bg-light-blue btn-sm  mx-3 text-white'>Design</span> */}
          {task?.label.map((label: any, i: number) => {
            return (
              <span key={i} className={`bg-light-blue btn-sm  mb-2 me-3 text-white`}>
                {label.charAt(0).toUpperCase() + label.slice(1)}
              </span>
            )
          })}
        </div>
      )}

      {task?.priority && (
        <div className='mt-4'>
          <div className='progress' style={{height: '3px'}}>
            <div
              className={`progress-bar ${
                task?.priority === 'low'
                  ? 'bg-success'
                  : task?.priority === 'medium'
                  ? 'bg-warning'
                  : task?.priority === 'high'
                  ? 'bg-danger'
                  : ''
              } `}
              role='progressbar'
              style={{width: '25%'}}
            ></div>
          </div>
        </div>
      )}

      <div>
        <div className='mt-4 d-flex align-items-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            height='15px'
            width='15px'
            style={{marginRight: '7px'}}
            viewBox='0 0 512 512'
          >
            <path d='M232 120C232 106.7 242.7 96 256 96C269.3 96 280 106.7 280 120V243.2L365.3 300C376.3 307.4 379.3 322.3 371.1 333.3C364.6 344.3 349.7 347.3 338.7 339.1L242.7 275.1C236 271.5 232 264 232 255.1L232 120zM256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48C141.1 48 48 141.1 48 256z' />
          </svg>
          {task?.createdAt && (
            <span className='text-dark'>{moment(task?.createdAt).fromNow()}</span>
          )}
        </div>
        <div className='mt-4 d-flex align-items-center justify-content-between'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              height='15px'
              width='15px'
              style={{marginRight: '7px'}}
              viewBox='0 0 384 512'
            >
              <path d='M336 0h-288C21.49 0 0 21.49 0 48v431.9c0 24.7 26.79 40.08 48.12 27.64L192 423.6l143.9 83.93C357.2 519.1 384 504.6 384 479.9V48C384 21.49 362.5 0 336 0zM336 452L192 368l-144 84V54C48 50.63 50.63 48 53.1 48h276C333.4 48 336 50.63 336 54V452z' />
            </svg>
            <span className='captain-text text-blue'>{task?.key}</span>
          </div>

          <div className='d-flex images-card'>
            {task?.assignee?.length > 0 && (
              <ul className='d-flex'>
                {task?.assignee?.map((assigne: any, i: any) => {
                  return (
                    <li key={i}>
                      <img
                        title={assigne?.fullName}
                        className='member-avatar'
                        src={getImageURL(assigne.image?.[0]?.thumbnailUrl, accessToken)}
                        alt={assigne?.fullName}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
    // </Link>
  )
}

export {TaskCard}
