/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { UpdateAuthModel } from '../../../app/modules/auth/models/UpdateAuthModel'
import { RootState } from '../../../setup'
import {useLayout} from '../core'

const Footer: FC = () => {
  const authorData: UpdateAuthModel = useSelector<RootState>(
    ({ auth }) => auth,
    shallowEqual
    ) as UpdateAuthModel 
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
                className={`d-flex flex-column flex-md-row align-items-center justify-content-between  ${authorData?.user?.settings?.fullWidth ? 'container-fluid': classes?.footerContainer}`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-bold me-2'>
            Copyright &copy; {new Date().getFullYear()}, Group Captain. All Rights Reserved.
          </span>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
          <li className='menu-item' title='v2.9'>
            <span className='text-muted fw-bold me-2'>v2.9</span>
          </li>
        </ul>
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
