/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {WorkspaceListing} from './WorkspaceListing'
import {Can} from '../../Common/RBAC/Can'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Link} from 'react-router-dom'
import '../../../_metronic/assets/sass/components/new-spaces.scss'

type Props = {
  dashboardIcons: any
  rowDimension: any
  moveDashboardIcons: any
  apiSettings: any
}

const NewSpacesTileView: FC<Props> = ({
  dashboardIcons,
  rowDimension,
  moveDashboardIcons,
  apiSettings,
}) => {
  const [viewMore, setViewMore] = useState(0)
  const [viewLess, setViewLess] = useState<boolean>(false)
  const noOfSpaces = apiSettings.find((obj: any) => {
    return obj.name === 'noOfSpaces'
  })

  useEffect(() => {
    setViewMore(+noOfSpaces?.value)
  }, [])

  return (
    <>
      <Can I='list' this='spaces'>
        <div className='d-flex  margins-dashboard dashboard_space mt-20 pt-5 px-2 mb-10 flex-wrap cards-wrapper justify-content-center justify-content-sm-start'>
          <div
            className='add_btn_spaces mb-4'
            // style={{
            //   width: rowDimension + 'px',
            //   height: rowDimension + 'px',
            //   // marginRight: rowDimension - 60 > 0 ? rowDimension - 60 : 0 + 'px',
            // }}
            style={{
              zoom: (+rowDimension + 37) + '%',
            }}
          >
            
            <span className='col rounded-2 demo-icons' title='Create Space'>
              <span className='card-item rounded-2'>
                <Link to='/space/add' className='card'>
                  <div
                    className='icon d-flex icon_hover'
                    style={{
                      width: '100px',
                      height: '100px',
                    }}
                    // style={{
                    //   width: rowDimension + 'px',
                    //   height: rowDimension + 'px',
                    // }}
                    
                  >
                    <div className='create_icon d-flex justify-content-center align-items-center'><img src={toAbsoluteUrl('/media/icons/projects/add-icon.svg')} /></div>
                    {/* <KTSVG className='icon_hover' path={'/media/icons/projects/add_icon.svg'} noClass={true} /> */}
                  </div>
                </Link>
              </span>
            </span>
          </div>
          {dashboardIcons?.length > 0 &&
            dashboardIcons.map((dashboardIcon: any, index: number) => {
              if (index < viewMore) {
                return (
                  <div
                    key={index}
                    className='d-flex mb-4 rounded-2'
                    title={dashboardIcon?.iconId?.name}
                    style={{
                      zoom: (+rowDimension + 37) + '%',
                    }}
                    // style={{
                    //   width: rowDimension + 'px',
                    //   height: rowDimension + 'px',
                    //   // marginRight: rowDimension - 60 > 0 ? rowDimension - 60 : 0 + 'px',
                    // }}
                  >
                    <WorkspaceListing
                      dashboardIconTitle={dashboardIcon?.iconId?.name}
                      dashboardIconId={dashboardIcon?.iconId?._id}
                      moveDashboardIcons={moveDashboardIcons}
                      index={index}
                      spaceType={dashboardIcon.iconId?.spaceType}
                      dashboardIcons={dashboardIcons}
                      rowDimension={rowDimension}
                    />
                  </div>
                )
              }
            })}
          {(dashboardIcons?.length - viewMore > 0 ||
            noOfSpaces?.value < dashboardIcons?.length) && (
            <div
              className='mb-4'
              // style={{
              //   width: rowDimension + 'px',
              //   height: rowDimension + 'px',
              //   // marginRight: rowDimension - 60 > 0 ? rowDimension - 60 : 0 + 'px',
              // }}
              style={{
                zoom: (+rowDimension + 37) + '%',
              }}
            >
              {viewLess ? (
                <div
                  className='btn p-0'
                  title='View Less'
                  onClick={() => {
                    setViewMore(noOfSpaces?.value)
                    setViewLess(false)
                  }}
                >
                  <div
                    className='d-flex flex-column align-items-center view_all_dashboard'
                    style={{
                      // width: rowDimension + 'px',
                      // height: rowDimension + 'px',
                    }}
                  >
                    {' '}
                    <KTSVG path={'/media/icons/projects/upward.svg'} noClass={true} />
                  </div>
                </div>
              ) : (
                <div
                  className='btn p-0'
                  title='View All'
                  onClick={() => {
                    setViewMore(dashboardIcons?.length)
                    setViewLess(true)
                  }}
                >
                  <div
                    className='d-flex flex-column align-items-center view_all_dashboard'
                    style={{
                      // width: rowDimension + 'px',
                      // height: rowDimension + 'px',
                    }}
                  >
                    <p
                      className='color-grey-dark mb-1 text-center'
                      // style={{
                      //   fontSize: rowDimension / 5 + 'px',
                      // }}
                    >
                      {dashboardIcons?.length - viewMore}+
                    </p>
                    <span
                      className='color-grey-dark mb-1 '
                      // style={{
                      //   fontSize: rowDimension / 5 + 'px',
                      // }}
                    >
                      {' '}
                      View All
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Can>
    </>
  )
}

export {NewSpacesTileView}
