import * as serverRequest from './workspaceMembersCRUD'
import {workspaceMembersSlice, callTypes} from './workspaceMembersSlice'

const {actions} = workspaceMembersSlice

export const fetchWorkspaceMembers =
  ({
    search,
    page,
    sortby,
    orderby,
    currentStatus,
    updateFrequency,
    activity,
    workspace,
  }: {
    search: string
    page: number | string
    sortby: string
    orderby: string
    currentStatus: number
    updateFrequency: number
    activity: number
    workspace: string
  }) =>
  (dispatch: any) => {
    return serverRequest
      .getWorkspaceMembers({
        search,
        page,
        sortby,
        orderby,
        currentStatus,
        updateFrequency,
        activity,
        workspace,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data?.data

        // dispatch(
        //   actions.workspaceMembersFetched({
        //     totalCount,
        //     start,
        //     end,
        //     limitPerPage,
        //     entities: data,
        //   })
        // )
      })
  }
export const WorkspaceMemberAdd =
  (workspaceMembersToCreate: any, workspaceid: any) => (dispatch: any) => {
    return serverRequest
      .WorkspaceMemberAdd(
        workspaceMembersToCreate.title,
        workspaceMembersToCreate.description,
        workspaceMembersToCreate.currentStatus,
        workspaceMembersToCreate.updateFrequency,
        workspaceMembersToCreate.activity,
        workspaceMembersToCreate.assignee,
        workspaceMembersToCreate.whoWillUpdate,
        workspaceMembersToCreate.workspace
      )
      .then((response) => {
        const {data}: {data: any; totalCount: any} = response.data
        // dispatch(actions.workspaceMembersCreated({entities: data}))
        return serverRequest
          .getWorkspaceMembers({
            search: '',
            page: '',
            sortby: '',
            orderby: '',
            currentStatus: 0,
            updateFrequency: 0,
            activity: 0,

            workspace: workspaceid,
          })
          .then((response) => {
            const {data, totalCount, start, end, limitPerPage} = response?.data?.data
            // dispatch(
            //   actions.workspaceMembersFetched({
            //     totalCount,
            //     start,
            //     end,
            //     limitPerPage,
            //     entities: data,
            //   })
            // )
          })
      })
  }
export const updateWorkspaceMember =
  (workspaceMembersToUpdate: any, workspaceid: any) => (dispatch: any) => {
    return serverRequest
      .updateWorkspaceMember(
        workspaceMembersToUpdate._id,
        workspaceMembersToUpdate.title,
        workspaceMembersToUpdate.description,
        workspaceMembersToUpdate.currentStatus,
        workspaceMembersToUpdate.updateFrequency,
        workspaceMembersToUpdate.activity,
        workspaceMembersToUpdate.assignee,
        workspaceMembersToUpdate.whoWillUpdate,
        workspaceMembersToUpdate.workspace
      )
      .then((response) => {
        serverRequest
          .getWorkspaceMembers({
            search: '',
            page: '',
            sortby: '',
            orderby: '',
            currentStatus: 0,
            updateFrequency: 0,
            activity: 0,

            workspace: workspaceid,
          })
          .then((response) => {
            const {data, totalCount, start, end, limitPerPage} = response?.data?.data
            //   dispatch(
            //     actions.workspaceMembersFetched({
            //       totalCount,
            //       start,
            //       end,
            //       limitPerPage,
            //       entities: data,
            //     })
            //   )
          })
      })
  }

export const removeWorkspaceMember = (_id: string, workspaceid: any) => (dispatch: any) => {
  return serverRequest.removeWorkspaceMember(_id).then((response) => {
    return serverRequest
      .getWorkspaceMembers({
        search: '',
        page: '',
        sortby: '',
        orderby: '',
        currentStatus: 0,
        updateFrequency: 0,
        activity: 0,
        workspace: workspaceid,
      })
      .then((response) => {
        const {data, totalCount, start, end, limitPerPage} = response?.data?.data
      })
  })
}

export const fetchAssignees =
  ({workspaceid}: {workspaceid: any}) =>
  (dispatch: any) => {
    dispatch(actions.startCall())
    return serverRequest
      .fetchAssignees({
        workspaceid,
      })
      .then((response) => {
        const {data} = response

        dispatch(
          actions.assigneesFetched({
            assignees: data,
          })
        )
      })
  }

export const fetchWorkspaceMember = (_id: string, workspaceid: any) => (dispatch: any) => {
  return serverRequest.fetchWorkspaceMember(_id, workspaceid).then((response) => {
    const {data} = response.data

    dispatch(
      actions.workspaceMemberFetched({
        entities: data,
      })
    )
  })
}
