import {AbilityBuilder} from '@casl/ability'
import {defineAbility} from '@casl/ability'

export function updateAbility(ability: any, user: any) {
  const {can, rules} = new AbilityBuilder(user)

  const userRoles = user?.role
  const userId = user?._id

  userRoles?.map((data: any) => {
    data?.permission &&
      Object.entries(data.permission).forEach(([key, value]: any) => {
        Object.entries(value).forEach(([i, item]) => {
          let cond = ''
          if (key === 'spaces' && item === 'read') {
            can(item, key, '{owner:' + userId + '}')
            can(item, key, '{assignee:{$elemMatch:{_id:{$in:[' + userId + ']}}}}')
            can(item, key, '{maintainers:{$elemMatch:{_id:{$in:[' + userId + ']}}}}')
          } else if (key === 'spaces' && item === 'update') {
            can(item, key, '{owner:' + userId + '}')
            can(item, key, '{maintainers:{$elemMatch:{_id:{$in:[' + userId + ']}}}}')
          } else if (key === 'spaces' && item === 'delete') {
            can(item, key, '{owner:' + userId + '}')
          } else if (key === 'release-notes' && item === 'update') {
            can(item, key, '{createdBy.id:' + userId + '}')
          } else {
            can(item, key, cond)
          }
        })
      })
  })
  ability.update(rules)
}

export default defineAbility((can, cannot) => {
  can('read', 'Dashboard')
})
