import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {ReleaseNoteTable} from './components/ReleaseNoteTable'
import {ReleaseNoteAdd} from './components/ReleaseNoteAdd'
import {NotesDetails} from './components/NotesDetails'

const ReleaseNotes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route exact path='/release-notes/add'>
          {({match, history}) => (
            <ReleaseNoteAdd
              onHide={() => {
                history.push('/release-notes')
              }}
            />
          )}
        </Route>
        <Route exact path='/release-notes/:id/edit'>
          {({match, history}) => (
            <ReleaseNoteAdd
              id={match && match.params.id}
              onHide={() => {
                history.push(`/release-notes`)
              }}
            />
          )}
        </Route>
        <Route exact path='/release-notes/:id'>
          {({match, history}) => (
            <NotesDetails
              userId={match && match.params.id}
              onHide={() => {
                history.push(`/release-notes`)
              }}
            />
          )}
        </Route>
        <Route exact path='/release-notes'>
          <ReleaseNoteTable className='mb-5 mb-xl-8' />
        </Route>
      </Switch>
    </>
  )
}

export {ReleaseNotes}
