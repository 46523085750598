import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const TASK_URL = `${API_URL}/boards/task`
const COLUMNS_URL = `${API_URL}/boards/columns`
const TASK_ACTIVITY_URL = `${API_URL}/todo-activities`
const TASK_ATTACHMENT_DATA = `${API_URL}/attachments`

export function getTasksList({
  search,
  page,
  orderby,
  sortby,
  status,
  priority,
  createdAt,
  dueDate,
  assignee,
  workSpace,
  project,
}: {
  search: string
  page: number | string
  orderby: string
  sortby: string
  status: string
  priority: string
  createdAt: string
  dueDate: string
  assignee: any
  workSpace: string
  project: string
}) {
  assignee = JSON.stringify(assignee)

  return axios.get(TASK_URL, {
    params: {
      search,
      page,
      orderby,
      sortby,
      status,
      priority,
      createdAt,
      dueDate,
      assignee,
      // workSpace,
      project,
    },
  })
}
export function addTaskList({
  title,
  description,
  status,
  priority,
  dueDate,
  label,
  assignee,
  attachmentPaths,
  project,
  workSpace,
}: {
  title: string
  description: string
  status: string
  priority: string
  dueDate: string
  label: []
  assignee: [{}]
  attachmentPaths: any
  project: string
  workSpace: string
}) {
  return axios.post(TASK_URL, {
    title,
    description,
    status,
    priority,
    dueDate,
    label,
    assignee,
    attachmentPaths,
    project,
    workSpace,
  })
}
export function updateTaskList({
  _id,
  title,
  description,
  status,
  priority,
  dueDate,
  label,
  assignee,
  attachmentPaths,
  workSpace,
}: {
  _id: string
  title: string
  description: string
  status: number
  priority: number
  dueDate: string
  label: []
  assignee: [{}]
  attachmentPaths: any
  workSpace: string
}) {
  return axios.patch(
    `${TASK_URL}/${_id}`,
    {
      _id,
      title,
      description,
      status,
      priority,
      dueDate,
      label,
      assignee,
      attachmentPaths,
      itemType: 'Task',
    },
    {params: {workSpace}}
  )
}

export function updateSpecificTaskList({
  _id,
  updateTask,
  workSpace,
}: {
  _id: string
  updateTask: any
  workSpace: string
}) {
  return axios.patch(`${TASK_URL}/update`, updateTask, {params: {workSpace}})
}

export function deleteTaskList(_id: string) {
  return axios.delete(`${TASK_URL}/${_id}`)
}

export function markTaskAsDone(_id: string, projectid: any) {
  return axios.patch(`${TASK_URL}/status/done/${_id}?project=${projectid}`)
}
export function markTaskAsPinned(_id: string, pinned: boolean) {
  return axios.patch(`${TASK_URL}/pin/${_id}`, {pinned})
}

export function fetchTask(_id: string) {
  return axios.get(`${TASK_URL}/${_id}`)
}

export function addTaskActivityList({
  item,
  description,
  type,
  itemType,
}: {
  item: string | undefined | null
  description: string
  type: string
  itemType: string | undefined | null
}) {
  return axios.post(TASK_ACTIVITY_URL, {
    item,
    description,
    type,
    itemType,
  })
}

export function addTaskAttachmentData({_id, attachmentPaths}: {_id: any; attachmentPaths: any}) {
  return axios.post(TASK_ATTACHMENT_DATA, {
    item: _id,
    attachmentPaths,
    itemType: 'Task',
    type: 'Task',
  })
}
export function updateTaskAttachmentData({
  _id,
  attachmentPaths,
}: {
  _id: string
  attachmentPaths: any
}) {
  return axios.patch(`${TASK_ATTACHMENT_DATA}/${_id}`, {
    item: _id,
    attachmentPaths,
    itemType: 'Task',
    type: 'Task',
  })
}
export function deleteAttachment(_id: string) {
  return axios.delete(`${TASK_ATTACHMENT_DATA}/${_id}`)
}

export function getBoardColumns({project, workSpace}: {project: string; workSpace: string}) {
  return axios.get(COLUMNS_URL, {
    params: {
      project,
      workSpace,
    },
  })
}
