import React from 'react'
import {Redirect, Route, Switch, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {Private} from '../../Common/chat/Private'
import * as discussionActions from '../appChat/redux/DiscussionActions'
const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Chat',
    path: '/discussion',
    isSeparator: true,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const AppChatPage: React.FC = () => {
  return (
    <Switch>
      <Route exact path='/discussion'>
        <PageTitle breadcrumbs={chatBreadCrumbs}>Discussion</PageTitle>
        <Private discussionActions={discussionActions} workspaceid='' chatType={'apps'} />
      </Route>
      <Redirect from='/discussion' exact={true} to='/discussion' />
      <Redirect to='/discussion' />
    </Switch>
  )
}

export default AppChatPage
