/* eslint-disable jsx-a11y/anchor-is-valid */
import {memo, useState} from 'react'
import {useDispatch} from 'react-redux'
import * as actions from '../../redux/talentActions'
import {create} from './docx/DocumentCreator'
import {saveAs} from 'file-saver'
import {Packer} from 'docx'
import {Form} from 'react-bootstrap-v5'
import './templateContent.css'
interface Props {
  talendId: string
  handleClose: () => void
}

const TemplateContent = memo(function TemplateContent({talendId, handleClose}: Props) {
  const dispatch: any = useDispatch()
  const [templateName, setTemplateName] = useState<string>('nextbridge')

  const downloadItemHandler = () => {
    dispatch(actions.findOne(talendId)).then(async (res: any) => {
      const doc = create(res, templateName)
      Packer.toBlob(doc).then((blob) => {
        const fileName = `${res.data.name}-${templateName}`.toLowerCase().split(' ').join('-')
        saveAs(blob, fileName + '.docx')
      })
    })
  }

  const handleTeamChange = (event: any) => setTemplateName(event.target.value)

  return (
    <div className='flex-row-fluid px-lg-5'>
      <div className='current' data-kt-stepper-element='content'>
        <div className='w-100'>
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-4'>Select Template</label>
            <Form>
              <div className='d-flex flex-row'>
                <div key={'nextbridge'} className='mb-3 me-10 d-flex align-items-center'>
                  <Form.Check
                    type='radio'
                    label='Nextbridge'
                    name='team'
                    id='nextbridge'
                    value='nextbridge'
                    onChange={handleTeamChange}
                    checked={templateName === 'nextbridge'}
                    className='custom-radio'
                  />
                </div>
                <div key={'vteams'} className='mb-3 d-flex align-items-center'>
                  <Form.Check
                    type='radio'
                    label='vteams'
                    name='team'
                    id='vteams'
                    value='vteams'
                    onChange={handleTeamChange}
                    checked={templateName === 'vteams'}
                    className='custom-radio'
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className='d-flex flex-stack justify-content-center pt-'>
        <div className='me-2'>
          <button
            title='Cancel'
            type='button'
            className='btn btn-sm cancel_button me-3'
            onClick={() => {
              handleClose()
            }}
          >
            Cancel
          </button>
        </div>

        <div>
          <button
            title='Download docx'
            type='submit'
            className='btn btn-sm btn-blue me-3'
            onClick={downloadItemHandler}
          >
            <span className='indicator-label'>Download docx</span>
          </button>
        </div>
      </div>
    </div>
  )
})

export {TemplateContent}
