export const findAssigneeWithTeams = (teams: any, assignees: any) => {
  let assigneeWithTeams: any[] = assignees?.map((assignee: any) => {
    let assigneeObject: any
    teams?.map((team: any) => {
      team.members.map((member: any) => {
        if (assignee._id === member._id && !assigneeObject) {
          assigneeObject = {
            _id: assignee._id,
            fullName: assignee.fullName,
            email: assignee.email,
            team: [team.name],
          }
        } else if (assignee._id === member._id && assignee._id === assigneeObject._id) {
          assigneeObject.team.push(team.name)
        }
      })
    })
    return assigneeObject
  })

  assigneeWithTeams = assigneeWithTeams.filter(Boolean)
  return assigneeWithTeams
}
export const combineAssigneesAndTeams = (
  assignees: any,
  teams: any,
  assigneesId: any,
  teamsId: any
) => {
  let data: any = []
  if (assigneesId?.length > 0 && teamsId?.length > 0) {
    data = assignees?.filter((assignee: any) => {
      return assigneesId.includes(assignee._id)
    })
    data = [
      ...teams
        ?.filter((team: any) => {
          return teamsId.includes(team._id)
        })
        .map((team: any) => {
          return {...team, isTeam: true}
        }),
      ...data,
    ]
  } else if (assigneesId?.length > 0 && (teamsId?.length <= 0 || !teamsId)) {
    data = assignees?.filter((assignee: any) => {
      return assigneesId.includes(assignee._id)
    })
  } else if (assigneesId?.length <= 0 && teamsId?.length > 0) {
    data = teams
      ?.filter((team: any) => {
        return teamsId.includes(team._id)
      })
      .map((team: any) => {
        return {...team, isTeam: true}
      })
  }
  return data
}

export const combineAssigneesTeamsInArray = (data: any) => {
  let x = []
  let uniqueUsers = data.filter(
    (value: any, index: number, self: any) =>
      index === self.findIndex((t: any) => t._id === value._id)
  )
  // uniqueUsers.map((user:any)=> {
  //   data.map
  // })
  return uniqueUsers
}
