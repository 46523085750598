/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {ApprovalDeleteDialog} from './ApprovalDeleteDialog'
import * as actions from '../redux/ApprovalsActions'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {RootState} from '../../../../setup'
import moment from 'moment'
import {Pagination} from '../../../pagination/pagination'
import {TodoListSorting} from '../../../../_metronic/layout/sort/Sort'
import {Search} from '../../../../_metronic/layout/search/Search'
import {ApprovalStatuses} from './../ApprovalsUIHelper'
import {Link, useParams} from 'react-router-dom'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import {NotFound} from '../../../../_metronic/layout/NotFound'

type Props = {
  className: string
}
interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  page: number
}

const ApprovalTable: React.FC<Props> = ({className}) => {
  const {workspaceid}: {workspaceid: string} = useParams()

  const dispatch = useDispatch()
  const [showApprovalsDialog, setShowApprovalsDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [approvalss, setApprovalss] = useState([{}])
  const [spaceDetail, setSpaceDetail] = useState()
  const [fetchRecords, setFetchRecords] = useState(false)
  const [delApprovals, setDeleteApprovals] = useState('')
  const [toggleView, setToggleView] = useState(false)
  const [sortValues, setSortValues] = useState({
    orderby: 'desc',
    sortby: 'createdAt',
  })
  const [isFiltered, setIsFiltered] = useState(false)

  const {approvalsData, approvalsDetail, error}: any = useSelector<RootState>(
    (state) => ({
      approvalsData: state.approvals.entities,
      approvalsDetail: state.approvals.approvalsDetail,
      error: state.approvals.error,
    }),
    shallowEqual
  )

  const paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.approvals,
    shallowEqual
  ) as PaginationModel

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const {spaceData}: any = useSelector<RootState>(
    (state) => ({
      spaceData: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )

  useEffect(() => {
    if (Array.isArray(approvalsData)) {
      setApprovalss(approvalsData)
    }
  }, [approvalsData])

  useEffect(() => {
    if (approvalsDetail) {
      setSpaceDetail(approvalsDetail)
    }
  }, [approvalsDetail])
  const fetchApproval = (_id: string) => async (event: any) => {
    dispatch(actions.fetchApproval(_id))

    setShowApprovalsDialog(true)
  }
  const deleteApproval = (_id: string) => async (event: any) => {
    setDeleteApprovals(_id)
    setShowDeleteDialog(true)
  }

  const removeSortingClass = () => {
    const cells = document.querySelectorAll('th')
    cells.forEach(function (cell) {
      cell.classList.remove('table-sort-desc')
      cell.classList.remove('table-sort-asc')
    })
  }

  useEffect(() => {
    let tableHead: any
    tableHead = document.getElementById(`space-${sortValues.sortby}-head`)

    if (sortValues.orderby === 'desc' && tableHead && approvalss?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-asc')
      tableHead.classList.add('table-sort-desc')
    } else if (tableHead && approvalss?.length > 0) {
      removeSortingClass()
      tableHead.classList.remove('table-sort-desc')
      tableHead.classList.add('table-sort-asc')
    }
    dispatch(
      actions.fetchApprovals({
        search: '',
        page: paginationData?.page,

        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workSpace: workspaceid,
        // name: sortValues.name,
        // spaceType: sortValues.spaceType,
        // updatedAt: sortValues.updatedAt,
        // createdAt: sortValues.createdAt,
        // assignee: sortValues.assignee,
      })
    )
  }, [sortValues, fetchRecords])

  const sortColumn = (event: any) => {
    if (event.target.classList.contains('table-sort-desc')) {
      removeSortingClass()
      setSortValues({
        orderby: 'asc',
        sortby: event.target.abbr,
      })
      event.target.classList.remove('table-sort-desc')
      event.target.classList.add('table-sort-asc')
    } else {
      removeSortingClass()
      setSortValues({
        orderby: 'desc',
        sortby: event.target.abbr,
      })
      event.target.classList.remove('table-sort-asc')
      event.target.classList.add('table-sort-desc')
    }
  }

  const handlePagination = (event: any) => {
    dispatch(
      actions.fetchApprovals({
        search: '',
        page: event.selected + 1,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workSpace: workspaceid,
      })
    )
  }

  const handleSearch = (search: string) => {
    dispatch(
      actions.fetchApprovals({
        search: search,
        page: paginationData?.page,
        orderby: sortValues.orderby,
        sortby: sortValues.sortby,
        workSpace: workspaceid,
      })
    )
  }

  let crumbs
  if (spaceData) {
    crumbs = [
      {name: 'Dashboard', url: '/dashboard'},
      {name: 'Spaces', url: '/spaces'},
      {name: `${spaceData?.name}`, url: `/${spaceData?._id}/features`},
      {name: 'Approvals', url: `/${spaceData?._id}/approvals`},
    ]
  }

  return (
    <>
      <ApprovalDeleteDialog
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={delApprovals}
        setFetchRecords={setFetchRecords}
        fetchReocords={fetchRecords}
      />

      <div className={`card legend-card-main ${className}`}>
        {crumbs && <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 pt-5' />}
        <div className='card-header border-0'>
          {/* begin::Header */}
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Approvals</span>
          </h3>
          <Search handleSearch={handleSearch} />

          <div
            className='card-toolbar mt-0 pt-2'
            data-bs-toggle='tooltip'
            data-bs-placement='top'
            data-bs-trigger='hover'
            title='Create approval'
          >
            {/* <button
              type='button'
              className={`btn btn-sm btn-light-primary me-2 ${toggleView ? 'active' : ''}`}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              title='Filters'
              onClick={() => setToggleView(toggleView ? false : true)}
            >
              <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
              Filters
            </button> */}
            <Link
              to={`/${workspaceid}/approvals/add`}
              className='btn btn-sm btn-light-primary'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              onClick={() => {
                setSpaceDetail(undefined)

                setShowApprovalsDialog(true)
              }}
            >
              <KTSVG path='' className='svg-icon-3' />
              Create Approval
            </Link>
          </div>
        </div>
        {toggleView && (
          <TodoListSorting
            sortValues={sortValues}
            setSortValues={setSortValues}
            setIsFiltered={setIsFiltered}
          />
        )}
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body d-flex flex-column py-3'>
          {/* begin::Table container */}

          {approvalss?.length > 0 ? (
            <div className='table-responsive'>
              {/* begin::Table */}

              <table className='todo-table table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bolder text-muted'>
                    <th
                      className=''
                      style={{cursor: 'pointer', minWidth: '100px'}}
                      onClick={sortColumn}
                      abbr='title'
                      id='approval-title-head'
                    >
                      Title
                    </th>

                    <th
                      className='min-w-120px'
                      // style={{cursor: 'pointer'}}
                      // onClick={sortColumn}
                      abbr='assignee'
                      id='approval-assignee-head'
                    >
                      Assignees
                    </th>
                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='createdBy'
                      id='approval-createdBy-head'
                    >
                      Requester
                    </th>

                    <th
                      className='min-w-120px'
                      // style={{cursor: 'pointer'}}
                      // onClick={sortColumn}
                      abbr='approvedBy'
                      id='approval-approvedBy-head'
                    >
                      Approver
                    </th>

                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='status'
                      id='approval-status-head'
                    >
                      Status
                    </th>

                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='createdAt'
                      id='space-createdAt-head'
                    >
                      Requested Date
                    </th>

                    <th
                      className='min-w-120px'
                      style={{cursor: 'pointer'}}
                      onClick={sortColumn}
                      abbr='modifiedAt'
                      id='space-modifiedAt-head'
                    >
                      Modified Date
                    </th>

                    <th className='min-w-100px text-center'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {approvalss?.length > 0 &&
                    approvalss?.map((approvals: any, index: number) => {
                      const assigneeLength = approvals?.assignee?.length - 1

                      return (
                        <tr key={index}>
                          <td data-label='Title'>
                            {/* <Link className='d-flex' to={`/approvals/${approvals?._id}`}> */}
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                <span
                                  title={approvals?.title}
                                  style={{
                                    display: 'block',
                                    width: '250px',
                                    minWidth: '100px',
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                  }}
                                >
                                  {approvals?.title}
                                </span>
                              </div>
                            </div>
                            {/* </Link> */}
                          </td>

                          <td
                            data-label='Assignee'
                            title={approvals?.assignee?.map((assignee: any, index: number) => {
                              if (assigneeLength === index) {
                                return assignee.firstName
                              }
                              return assignee.firstName.concat(',')
                            })}
                          >
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {approvals._id
                                  ? approvals?.assignee?.map((assignee: any, index: number) => {
                                      if (assigneeLength === index) {
                                        return assignee.firstName
                                      }
                                      return assignee.firstName.concat(',')
                                    })
                                  : '-'}
                              </div>
                            </div>
                          </td>
                          {approvals.requestedBy ? (
                            <td data-label='Requester' title={approvals?.requestedBy?.firstName}>
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {approvals?.requestedBy?.firstName}
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td data-label='Requester'>
                              <div className='d-flex align-items-center '>
                                <div className='d-flex justify-content-start flex-column'>
                                  {'-'}
                                </div>
                              </div>
                            </td>
                          )}
                          <td
                            data-label='Approver'
                            title={approvals?.assignee?.map((assignee: any, index: number) => {
                              return assignee.firstName + ','
                            })}
                          >
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {approvals._id
                                  ? approvals?.assignee?.map((assignee: any, index: number) => {
                                      if (assigneeLength === index) {
                                        return assignee.firstName
                                      }
                                      return assignee.firstName.concat(',')
                                    })
                                  : '-'}
                              </div>
                            </div>
                          </td>
                          <td
                            data-label='Status'
                            title={
                              !approvals.status
                                ? '-'
                                : approvals.status === 0
                                ? approvals[0]
                                : approvals.status === '1'
                                ? approvals[1]
                                : approvals[2]
                            }
                          >
                            <div className='d-flex align-items-center'>
                              <div className='d-flex justify-content-start flex-column'>
                                {!approvals.status ? (
                                  '-'
                                ) : approvals.status === 1 ? (
                                  <span className='badge badge-light-info fs-7 '>
                                    {ApprovalStatuses[0]}
                                  </span>
                                ) : approvals.status === 2 ? (
                                  <span className='badge badge-light-primary fs-7 fw-bold'>
                                    {ApprovalStatuses[1]}{' '}
                                  </span>
                                ) : approvals.status === 3 ? (
                                  <span className='badge badge-light-success fs-7 fw-bold'>
                                    {ApprovalStatuses[2]}
                                  </span>
                                ) : (
                                  <span className='badge badge-light-success fs-7 fw-bold'>
                                    {ApprovalStatuses[3]}
                                  </span>
                                )}
                              </div>
                            </div>
                          </td>

                          <td
                            data-label='Requested Date'
                            title={
                              approvals.createdAt
                                ? moment(approvals.createdAt).format('MM/DD/YYYY')
                                : '-'
                            }
                          >
                            {approvals?.createdAt && (
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {moment(approvals.createdAt).format('MM/DD/YYYY')}
                                </div>
                              </div>
                            )}
                          </td>

                          <td
                            data-label='Modified Date'
                            title={
                              approvals?.modifiedDate
                                ? moment(approvals.modifiedDate).format('MM/DD/YYYY')
                                : '-'
                            }
                          >
                            {approvals?.updatedAt ? (
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {moment(approvals.updatedAt).format('MM/DD/YYYY')}
                                </div>
                              </div>
                            ) : (
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-start flex-column'>
                                  {'-'}
                                </div>
                              </div>
                            )}
                          </td>

                          <td data-label='Actions'>
                            <div className='d-flex justify-content-center flex-shrink-0'>
                              <Link
                                to={`/${workspaceid}/approvals/${approvals?._id}/edit`}
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 bg-light-blue-actions'
                                title='Edit'
                                onClick={fetchApproval(approvals?._id)}
                              >
                                <KTSVG
                                  path='/media/icons/duotune/art/art005.svg'
                                  className='svg-icon-3'
                                />
                              </Link>
                              <button
                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm bg-light-blue-actions'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_delete_app'
                                id='kt_toolbar_primary_button'
                                onClick={deleteApproval(approvals?._id)}
                                title='Delete'
                              >
                                <KTSVG
                                  path='/media/icons/duotune/general/gen027.svg'
                                  className='svg-icon-3'
                                />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>

              {/* end::Table */}
            </div>
          ) : (
            <NotFound />
          )}

          {/* end::Table container */}
        </div>

        <Pagination paginationData={paginationData} fetchData={handlePagination} />

        {/* begin::Body */}
      </div>
    </>
  )
}

export {ApprovalTable}
