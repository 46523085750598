/* eslint-disable jsx-a11y/anchor-is-valid */

import axios from 'axios'
import '../../../../_metronic/assets/sass/components/workspace-edit.scss'
import '../../../../_metronic/assets/sass/core/components/_pages.scss'
import {ErrorMessage, Field, Form, Formik} from 'formik'
import React, {FC, useEffect, useRef, useState} from 'react'
import SVG from 'react-inlinesvg'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import * as Yup from 'yup'
import {RootState} from '../../../../setup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {modifyDashboardIconsURL} from '../../../../_metronic/helpers/modifyDashboardIconsURL'
import {AssigneesList} from '../../../Common/assignee-teams/Assignee&TeamList'
import {Breadcrumbs} from '../../../pages/Breadcrumbs'
import * as actions from '../redux/WorkspaceActions'
import {showUserNames, showUserTeamNames} from '../WorkspaceUIHelper'
import {SpacePreview} from './SpacePreview'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {getNewSpaceMembers} from '../../../pages/UIHelper'
import {Companies} from '../Models/WorkspaceModel'
import Select from 'react-select'

interface ICreateAccount {
  createdAt: Date
  name: string
  company: any
  group: any
  description: string
  spaceKey: string
  spaceType: number | string
  owner: any
  assignee: any
  maintainers: any
  teams: any
  maintainerTeams: any
  features: string[]
}

const inits: ICreateAccount = {
  createdAt: new Date(),
  name: '',
  company: '',
  group: '',
  description: '',
  spaceKey: '',
  spaceType: '',
  owner: {},
  assignee: [],
  maintainers: [],
  teams: [],
  maintainerTeams: [],
  features: ['features'],
}

interface Props {
  onHide: () => void
  id?: string | null
}

const WorkspaceEdit: FC<Props> = ({onHide, id}) => {
  const API_URL = process.env.REACT_APP_API_URL
  const LIST_USERS = `${API_URL}/users`
  const LIST_TEAMS = `${API_URL}/teams`
  const SET_FEATURES_ICONS = `${API_URL}/apps/enabledfeatures/systemApp?systemApp=false`
  const history = useHistory()
  const path = history.location.pathname
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const dispatch = useDispatch<any>()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [response, setResponse] = useState<boolean>(false)
  const [dashIconType, setdashIconType] = useState('')
  const [workSpaceMembers, setWorkSpaceMembers] = useState()
  const [spacePreviewMember, setSpacePreviewMember] = useState()
  const [companies, setCompanies] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [selectedGroup, setSelectedGroup]: any = useState()
  const [selectedCompanies, setSelectedCompanies]: any = useState()
  const [allCompanyData, setAllCompanyData]: any = useState([])
  const [companyDetails, setCompanyDetails]: any = useState([])
  const {spaceDetail, error, userInfo, settings}: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.workspace.workspaceDetail,
      error: state.workspace.error,
      userInfo: state.auth.user,
      settings: state.settings.entities,
    }),
    shallowEqual
  )

  useEffect(() => {
    dispatch(actions.getAllCompanies()).then((companies: any) => {
      const allCompanies = companies.reduce((manageCompany: any, company: any) => {
        manageCompany.push({value: company?._id, label: company.name})
        return manageCompany
      }, [])

      setCompanies(allCompanies)
      setAllCompanyData(companies)
    })
  }, [])
  useEffect(() => {
    const iconType = settings.filter((data: any, index: any) => {
      return data.name === 'dashMediaIcons'
    })
    setdashIconType(iconType[0]?.value)
  }, [settings])

  const [usersId, setUsersId] = useState<string[]>([])
  const [teamsId, setTeamsId] = useState<string[]>([])
  const [assigneesId, setAssigneesId] = useState<string[]>([])
  const [assigneeTeamsId, setAssigneeTeamsId] = useState<string[]>([])
  const [maintainersId, setMaintainersId] = useState<string[]>([])

  const [assigneeAndTeams, setassigneeAndTeams]: any = useState()

  const [selectedFeatures, setSelectedFeatures] = useState<String[]>([])
  const [enabledFeatures, setEnabledFeatures] = useState<String[]>([])
  const [showAssigneesDialog, setShowAssigneesDialog] = useState(false)
  const [calledBy, setCalledBy] = useState('')
  const [popupTitle, setPopupTitle] = useState('')

  const assigneesMember = assigneeAndTeams?.assignee?.filter((assignee: any) =>
    assigneesId?.includes(assignee?._id)
  )
  const assineesTeams = assigneeAndTeams?.teams?.filter((assignee: any) =>
    assigneeTeamsId?.includes(assignee?._id)
  )
  const maintainersMember = assigneeAndTeams?.assignee?.filter((assignee: any) =>
    maintainersId?.includes(assignee?._id)
  )
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const fetchFeaturesData = async () => {
    const enabledFeatures = await axios.get(SET_FEATURES_ICONS)
    setEnabledFeatures(enabledFeatures.data.data)
  }

  const fetchAssigneesData = async () => {
    const usersData = await axios.get(LIST_USERS)
    const teams = await axios.get(LIST_TEAMS)

    setassigneeAndTeams({
      assignee: usersData.data,
      teams: teams.data.data,
    })
  }
  useEffect(() => {
    fetchFeaturesData()

    if (spaceDetail && id) {
      const workSpaceMember = getNewSpaceMembers(
        spaceDetail?.assignee,
        spaceDetail?.teams,
        spaceDetail?.maintainers,
        spaceDetail?.owner
      )
      setWorkSpaceMembers(workSpaceMember)

      setInitValues({
        createdAt: spaceDetail?.createdAt,
        name: spaceDetail?.name,
        company: spaceDetail.company,
        group: spaceDetail.group,
        spaceKey: spaceDetail?.spaceKey,
        spaceType: spaceDetail?.spaceType,
        description: spaceDetail?.description,
        owner: spaceDetail?.owner,
        assignee: spaceDetail?.assignee?.map((assignee: any) => {
          return {
            value: assignee._id,
            label:
              userInfo._id === assignee._id ? assignee.fullName + ' ' + '(Me)' : assignee.fullName,
            id: assignee._id,
            isTeam: false,
          }
        }),
        maintainerTeams: spaceDetail?.maintainerTeams?.map((maintainerTeam: any) => {
          return {
            value: maintainerTeam._id,
            label: maintainerTeam.name + ' ' + '(Team)',
            id: maintainerTeam._id,
            isTeam: true,
          }
        }),
        teams: spaceDetail?.teams?.map((team: any) => {
          return {
            value: team._id,
            label: team.name + ' ' + '(Team)',
            id: team._id,
            isTeam: true,
          }
        }),
        maintainers: spaceDetail?.maintainers?.map((maintainer: any) => {
          return {
            value: maintainer._id,
            label:
              userInfo._id === maintainer._id
                ? maintainer.fullName + ' ' + '(Me)'
                : maintainer.fullName,
            id: maintainer._id,
          }
        }),
        features: spaceDetail?.features,
      })
      setSelectedFeatures(spaceDetail?.features)
      setSelectedCompanies({
        value: spaceDetail?.company?.[0]?.id,
        label: spaceDetail?.company?.[0]?.name,
      })
      setSelectedGroup({value: spaceDetail?.group?.[0]?.id, label: spaceDetail?.group?.[0]?.name})
    }
    const savedCompany: any = allCompanyData.find((company: any, i: number) => {
      return company?._id === spaceDetail?.company?.[i]?._id
    })

    const groups = savedCompany?.charts?.reduce((groups: any, company: any) => {
      groups.push({value: company.id, label: company.name})
      return groups
    }, [])

    setAllGroups(groups)
  }, [spaceDetail, id, showAssigneesDialog])

  useEffect(() => {})

  useEffect(() => {
    fetchAssigneesData()
    setSelectedFeatures([])

    if (id) {
      dispatch(actions.fetchWorkspace(id))
    }
    dispatch(actions.removeErrorMessage(''))
  }, [dispatch])

  useEffect(() => {
    if (id) {
      setAssigneesId(spaceDetail?.assignee?.map((assignee: any) => assignee._id))
      setAssigneeTeamsId(spaceDetail?.teams.map((team: any) => team._id))
      setMaintainersId(spaceDetail?.maintainers.map((maintainer: any) => maintainer._id))
    }
  }, [id, spaceDetail])

  useEffect(() => {
    if (calledBy === 'workspace') {
      setAssigneesId(usersId)
      setAssigneeTeamsId(teamsId)
    } else if (calledBy === 'workspaceMaintainers') {
      setMaintainersId(usersId)
    }
  }, [usersId, teamsId])

  const createUpdateWorkspaceSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .label('name')
      .trim('The space name cannot include leading and trailing spaces')
      .min(1, 'Name must contain more than 3 characters'),
    spaceType: Yup.number().required('Space type is required'),
    spaceKey: Yup.string().nullable(true),
    description: Yup.string().nullable(true),
    features: Yup.array().min(1, 'Please select at least one feature'),
  })

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const handleFeatureChange = (feature: string, setFieldValue: any) => {
    if (selectedFeatures.includes(feature)) {
      setSelectedFeatures(selectedFeatures.filter((featured) => feature !== featured))
      setFieldValue(
        'features',
        selectedFeatures.filter((featured) => feature !== featured)
      )
    } else {
      setSelectedFeatures([...selectedFeatures, feature])
      setFieldValue('features', [...selectedFeatures, feature])
    }
  }

  const crumbs = [
    {name: 'Spaces', url: '/'},
    {name: id ? 'Edit Space' : 'Create Space', url: path},
  ]

  const changeCompanies = (value: any) => {
    if (value) {
      setSelectedCompanies(value)
      const singleSelectedCompany = allCompanyData.find((company: any) => {
        return company._id === value.value
      })
      const groups = singleSelectedCompany.charts.reduce((groups: any, chart: any) => {
        groups.push({value: chart._id, label: chart.name})
        return groups
      }, [])
      setSelectedGroup([])
      setAllGroups(groups)
    } else {
      setSelectedCompanies([])
      setAllGroups([])
    }
  }
  const groupChangeVal = (value: any) => {
    setSelectedGroup(value)
  }

  return (
    <>
      <AssigneesList
        show={showAssigneesDialog}
        handleClose={() => {
          setShowAssigneesDialog(false)
        }}
        assignee={assigneeAndTeams?.assignee}
        teams={[...(assigneeAndTeams?.teams || []), ...(spaceDetail?.teams || [])].filter(
          (v, i, a) => a.findIndex((v2) => v2._id === v._id) === i
        )}
        calledBy={calledBy}
        setAssigneesId={setUsersId}
        assigneesId={usersId}
        teamsId={teamsId}
        setTeamsId={setTeamsId}
        id={id}
        title={popupTitle}
      />

      <div className='mh-80 page-content'>
        <div className='px-6 px-sm-12'>
          <div className='row'>
            <div className='col-sm-6'>
              <h2 className='fw-500 fs-2 m-0 py-5'>{id ? 'Edit Space' : 'Create Space'}</h2>
            </div>
          </div>
        </div>
        <Breadcrumbs crumbs={crumbs} containerClass='row mx-10 ' />
        <div className='overflow_height_scroll'>
          <div className='page-body py-lg-10 px-lg-10 mobile_screen'>
            <div
              ref={stepperRef}
              className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid align-items-center'
              id='kt_modal_create_app_stepper'
            >
              <Formik
                validationSchema={createUpdateWorkspaceSchema}
                initialValues={id ? initValues : inits}
                enableReinitialize
                onSubmit={(values: any) => {
                  // const {value: companyId} = selectedCompanies.value
                  // const {value: groupId} = selectedGroup.value
                  let enableFeatureArr = enabledFeatures.map((eF: any) => eF?.appKey)
                  values.teams = assigneeTeamsId
                  values.features = values?.features?.filter((feature: any) =>
                    enableFeatureArr?.includes(feature)
                  )
                  values.assignee = values?.spaceType == 0 ? assigneesId : []
                  values.maintainers = values?.spaceType == 0 ? maintainersId : []
                  values.company = [selectedCompanies?.value]
                  values.group = [selectedGroup?.value]
                  if (id) {
                    values._id = id
                    values.workSpace = spaceDetail._id
                    setResponse(true)
                    dispatch(actions.updateWorkspace(values))
                      .then((response: any) => {
                        history.goBack()
                        // onHide()
                      })
                      .catch((error: string) => {
                        setResponse(false)
                      })
                  } else {
                    setResponse(true)
                    dispatch(actions.createWorkspace(values))
                      .then((response: any) => {
                        onHide()
                      })
                      .catch((error: string) => {
                        setResponse(false)
                      })
                  }
                }}
              >
                {({values, setFieldValue, errors}: any) => (
                  <div className='row pt-0 px-lg-15 px-sm-0 w-100'>
                    <div className='col-lg-9 col-md-12 py-lg-5'>
                      <Form className='form form_bg_light' noValidate id='kt_modal_create_app_form'>
                        <div className='current' data-kt-stepper-element='content'>
                          <div className='w-100'>
                            {error && (
                              <div className='mb-lg-15 alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{error}</div>
                              </div>
                            )}
                            <div className='fv-row mb-5'>
                              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className='required'>Space Name</span>
                              </label>

                              <Field
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='name'
                                placeholder='Space Name'
                              />
                              <div className='text-danger'>
                                <ErrorMessage name='name' />
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-md-6 fv-row mb-5'>
                                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                  <span>Space Key</span>
                                </label>

                                <Field
                                  type='text'
                                  className='form-control form-control-lg form-control-solid'
                                  name='spaceKey'
                                  placeholder='Space Key'
                                  disabled={id && true}
                                />
                                <div className='text-danger'>
                                  <ErrorMessage name='spaceKey' />
                                </div>
                              </div>

                              <div className='col-md-6 fv-row mb-5'>
                                <label className='fs-6 fw-bold form-label mb-2 required'>
                                  Space Type
                                </label>
                                <div className='row fv-row'>
                                  <div className='col-12'>
                                    <Field
                                      as='select'
                                      name='spaceType'
                                      className='form-select  form-select-solid selectpicker'
                                      onChange={(event: any) => {
                                        const toggleValue = event.target.value == 1 ? true : false
                                        setFieldValue('spaceType', event.target.value)

                                        if (toggleValue) {
                                          // setFieldValue('assignee', [])
                                          setMaintainersId([])
                                          setAssigneesId([])
                                        }
                                      }}
                                    >
                                      <option value=''>Select an option</option>
                                      <option value={0}>Group Space</option>
                                      <option value={1}>Personal Space</option>
                                    </Field>
                                    <div className='text-danger'>
                                      <ErrorMessage name='spaceType' />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className='col-md-6 fv-row mb-5'>
                                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                  <span>Organization</span>
                                </label>

                                <Select
                                  // isClearable
                                  options={companies}
                                  onChange={(e) => changeCompanies(e)}
                                  value={selectedCompanies}
                                  className='general_selector'
                                />
                                <div className='text-danger'>
                                  <ErrorMessage name='company' />
                                </div>
                              </div>

                              <div className='col-md-6 fv-row mb-5'>
                                <label className='fs-6 fw-bold form-label mb-2'>Group</label>
                                <div className='row fv-row'>
                                  <div className='col-12'>
                                    <Select
                                      className='general_selector'
                                      isDisabled={!selectedCompanies && !id}
                                      options={allGroups}
                                      value={
                                        allGroups?.length < 1 && !id
                                          ? {value: '', label: 'Select...'}
                                          : selectedGroup
                                      }
                                      onChange={(e) => groupChangeVal(e)}
                                    />

                                    <div className='text-danger'>
                                      <ErrorMessage name='groups' />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {values?.spaceType == 0 && (
                                <div className='col-md-6 fv-row mb-5'>
                                  <label className='fs-6 fw-bold mb-2 d-flex'>
                                    <span>Maintainers</span>{' '}
                                    {maintainersId?.length > 0 ? (
                                      <span className='text-style-count ms-2'>
                                        {maintainersId?.length}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </label>

                                  <div className='row fv-row'>
                                    <div className='col-12 multisel'>
                                      <div className='d-flex justify-content-center align-items-center bg-lightest'>
                                        <div
                                          style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {maintainersId?.length > 0 && (
                                            <span
                                              className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                              style={{borderRadius: '4px'}}
                                              title={showUserNames(
                                                maintainersId,
                                                assigneeAndTeams?.assignee
                                              )}
                                            >
                                              Users
                                              <span className='badge ms-2 text-danger badge text-danger pt-2 px-2 fs-9'>
                                                {maintainersId?.length}
                                              </span>
                                            </span>
                                          )}
                                        </div>
                                        <a
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_modal_create_app'
                                          id='kt_modal_assignees_list_app'
                                          onClick={() => {
                                            setUsersId(maintainersId)
                                            setTeamsId([])
                                            setCalledBy('workspaceMaintainers')
                                            setPopupTitle('Maintainers')
                                            setShowAssigneesDialog(true)
                                          }}
                                        >
                                          {/* <img src='media/icons/projects/add_icon.svg' /> */}
                                          <i
                                            className='fa fa-plus-circle text-blue'
                                            aria-hidden='true'
                                          ></i>
                                          <button
                                            title='Add'
                                            type='button'
                                            className='btn btn-sm bg-transparent text-primary fw-500 p-2 '
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_create_app'
                                            id='kt_modal_assignees_list_app'
                                            // onClick={() => {
                                            //   setUsersId(maintainersId)
                                            //   setTeamsId([])
                                            //   setCalledBy('workspaceMaintainers')
                                            //   setPopupTitle('Maintainers')
                                            //   setShowAssigneesDialog(true)
                                            // }}
                                          >
                                            add
                                          </button>
                                        </a>
                                      </div>
                                      <div className='text-danger'>
                                        <ErrorMessage name='assignee' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {values?.spaceType == 0 && (
                                <div className='col-md-6 fv-row mb-5'>
                                  <label className='fs-6 fw-bold mb-2 d-flex'>
                                    <span>Members</span>{' '}
                                    {assigneesId?.length + assigneeTeamsId?.length > 0 ? (
                                      <span className='text-style-count ms-2'>
                                        {assigneesId?.length + assigneeTeamsId?.length}
                                      </span>
                                    ) : (
                                      ''
                                    )}
                                  </label>

                                  <div className='row fv-row'>
                                    <div className='col-12 multisel'>
                                      <div className='d-flex justify-content-center align-items-center bg-lightest'>
                                        <div
                                          style={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {assigneesId?.length > 0 && (
                                            <span
                                              className='d-inline-flex align-items-center border border-light-blue text-blue border-runded-4 px-2 fs-8 m-2'
                                              style={{borderRadius: '4px'}}
                                              title={showUserNames(
                                                assigneesId,
                                                assigneeAndTeams?.assignee
                                              )}
                                            >
                                              Users
                                              <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                                {assigneesId?.length}
                                              </span>
                                            </span>
                                          )}
                                          {assigneeTeamsId?.length > 0 && (
                                            <span
                                              className='d-inline-flex align-items-center text-blue border border-light-blue border-runded-4 px-2 fs-8 m-2'
                                              style={{borderRadius: '4px'}}
                                              title={showUserTeamNames(
                                                assigneeTeamsId,
                                                assigneeAndTeams?.teams
                                              )}
                                            >
                                              Teams
                                              <span className='badge ms-2 text-danger py-1 px-2 fs-9 m-1'>
                                                {assigneeTeamsId?.length}
                                              </span>
                                            </span>
                                          )}
                                        </div>
                                        <a
                                          data-bs-toggle='modal'
                                          data-bs-target='#kt_modal_create_app'
                                          id='kt_modal_assignees_list_app'
                                          onClick={() => {
                                            setUsersId(assigneesId)
                                            setTeamsId(assigneeTeamsId)
                                            setCalledBy('workspace')
                                            setPopupTitle('Members')
                                            setShowAssigneesDialog(true)
                                          }}
                                        >
                                          {/* <img src='/media/icons/projects/add_icon.svg' /> */}
                                          <i
                                            className='fa fa-plus-circle text-blue'
                                            aria-hidden='true'
                                          ></i>
                                          <button
                                            title='Add'
                                            type='button'
                                            className='btn btn-sm  bg-transparent text-primary fw-500 p-2 '
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_create_app'
                                            id='kt_modal_assignees_list_app'
                                            // onClick={() => {
                                            //   setUsersId(assigneesId)
                                            //   setTeamsId(assigneeTeamsId)
                                            //   setCalledBy('workspace')
                                            //   setPopupTitle('Members')
                                            //   setShowAssigneesDialog(true)
                                            // }}
                                          >
                                            add
                                          </button>
                                        </a>
                                      </div>
                                      <div className='text-danger'>
                                        <ErrorMessage name='assignee' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className='fv-row mb-5'>
                              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                <span className=''>Description</span>
                              </label>

                              <Field
                                className='form-control form-control-lg form-control-solid'
                                name='description'
                                placeholder='Add description'
                                as='textarea'
                                rows='5'
                              />

                              <div className='text-danger'>
                                <ErrorMessage name='description' />
                              </div>
                            </div>
                            <div className='row m-0 '>
                              <label className='fs-5 fw-bold mb-2 px-0'>
                                <span className='required'>Features</span>
                              </label>
                              <div
                                className='col-md-12 fv-row mb-5 p-4'
                                style={{border: '1px solid #F5F5F7'}}
                              >
                                <div className='d-flex flex-wrap'>
                                  {enabledFeatures?.map((feature: any, index: number) => {
                                    return (
                                      <React.Fragment key={index}>
                                        {feature.isEnabled && (
                                          <div className='' key={index} title={feature.tooltip}>
                                            <div
                                              className={`card-item mx-2 my-2 rounded-2 feature-opacity ${
                                                selectedFeatures.includes(feature.appKey)
                                                  ? 'active'
                                                  : ''
                                              }`}
                                            >
                                              <div className='icon w-100 d-flex align-items-center justify-content-center position-relative mx-auto'>
                                                <div className='selected-feature position-absolute '>
                                                  <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                                </div>

                                                <a
                                                  className='icon d-flex flex-column align-items-center justify-content-start position-relative mx-auto'
                                                  href='#'
                                                  onClick={(e) => {
                                                    e.preventDefault()
                                                    handleFeatureChange(
                                                      feature.appKey,
                                                      setFieldValue
                                                    )
                                                  }}
                                                >
                                                  <div
                                                    className='icon'
                                                    style={{
                                                      width: '73px',
                                                      height: '67px',
                                                      borderRadius: '8px',
                                                      backgroundColor: feature.backgroundColor,
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                    }}
                                                  >
                                                    <SVG
                                                      style={{
                                                        width: '50%',
                                                        // margin: '0 auto',
                                                        // display: 'flex',
                                                        fill: '#fff',
                                                        // paddingTop: '12px',
                                                      }}
                                                      src={modifyDashboardIconsURL(
                                                        dashIconType,
                                                        toAbsoluteUrl(`/${feature.icon}`)
                                                      )}
                                                    />
                                                  </div>

                                                  <div
                                                    className='title mt-1 d-flex justify-content-center mx-auto align-items-center text-center'
                                                    style={{maxWidth: '90px'}}
                                                  >
                                                    <span style={{fontSize: '11px'}}>
                                                      {feature.name}
                                                    </span>
                                                  </div>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </React.Fragment>
                                    )
                                  })}
                                </div>
                                <div className='text-danger'>
                                  <ErrorMessage name='features' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='d-flex flex-stack pt-10'>
                          <div> </div>
                          <div className='me-2'>
                            <button
                              type='button'
                              className='btn btn-sm cancel_button fs-7  fw-bolder  me-3'
                              onClick={history.goBack}
                              title='Cancel'
                            >
                              Cancel
                            </button>
                            <button
                              disabled={response}
                              type='submit'
                              className='btn btn-lg btn-blue   fs-7'
                              title='Submit'
                            >
                              <span className='indicator-label'>{id ? 'Update' : 'Create'}</span>
                            </button>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className='col-lg-3 col-md-12 p-0'>
                      <h5 className='my-6 ms-3'> Space Preview</h5>
                      <SpacePreview
                        values={values}
                        userInfo={userInfo}
                        id={id}
                        assigneesMember={assigneesMember}
                        assineesTeams={assineesTeams}
                        maintainersMember={maintainersMember}
                      />
                    </div>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {WorkspaceEdit}
