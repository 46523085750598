import React from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'


export function SpacesDropdown() {
  return (
    <div className='px-7 py-5'>
      <div className='card card-xl-stretch'>
        <div className='card-body p-0'>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 1</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>
          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>
                Spaces 2
              </Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1 me-2'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 3</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center py-4 px-2'>
            <div className='flex-grow-1 me-2 d-flex justify-content-center'>
              <Link to='/dashboard9' className='fw-bolder text-primary fs-6'>View All</Link>
            </div>
            {/* <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link> */}


          </div>
          {/* <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 4</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>
          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 5</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' href='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 6</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1 me-2'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 7</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 8</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 9</Link>
            </div>
            <Link to='#' className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 10</Link>
            </div>
            <Link to='#'className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 11</Link>
            </div>
            <Link to='#'className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 12</Link>
            </div>
            <Link to='#'className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div>
          <div className='d-flex align-items-center border-bottom py-4 px-2'>
            <div className='flex-grow-1'>
              <Link to='#' className='fw-bolder text-gray-800 text-hover-primary fs-6'>Space 13</Link>
            </div>
            <Link to='#'className='svg-icon svg-icon-info ms-5'>
              <i className='fa fa-star text-hover-primary'></i>
            </Link>


          </div> */}
        </div>
      </div>
    </div>
  )
}
