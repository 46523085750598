/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useRef, useState} from 'react'
import {Modal} from 'react-bootstrap-v5'
import {KTSVG} from '../../../../_metronic/helpers'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import moment from 'moment'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import axios from 'axios'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/WorkspaceActions'
import {findAssigneesForSelect} from '../WorkspaceUIHelper'
import {RootState} from '../../../../setup'
import {Link} from 'react-router-dom'

interface ICreateAccount {
  name: string
  description: string
  spaceKey: string
  spaceType: number
  // assignee: []
}

const inits: ICreateAccount = {
  name: '',
  description: '',
  spaceKey: '',
  spaceType: 1,
  // assignee: [],
}

interface Props {
  show: boolean
  spaceDetail?: any
  handleClose: () => void
  error: string
  setFetchRecords: any
  fetchReocords: boolean
}

const CreateWorkspace: FC<Props> = ({
  show,
  spaceDetail,
  handleClose,
  error,
  setFetchRecords,
  fetchReocords,
}) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const dispatch = useDispatch<any>()
  const [initValues, setInitValues] = useState<ICreateAccount>(inits)
  const [response, setResponse] = useState<boolean>(false)

  const [_id, setID] = useState()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  useEffect(() => {}, [error])
  useEffect(() => {
    if (spaceDetail) {
      setInitValues({
        name: spaceDetail?.name,
        spaceKey: spaceDetail?.spaceKey,
        spaceType: spaceDetail?.spaceType,
        description: spaceDetail?.description,
      })
      setID(spaceDetail?._id)
    } else {
      setInitValues(inits)
    }
  }, [spaceDetail])

  const createUpdateWorkspaceSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .trim('The space name cannot include leading and trailing spaces')
      .label('name')
      .min(1, 'Name must contain more than 3 characters'),
    spaceType: Yup.number().required('Space type is required'),
    spaceKey: Yup.string().nullable(true),
    description: Yup.string().nullable(true),
  })

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog-centered modal-xl h-auto'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>{_id ? 'Edit Space' : 'Create a space'}</h2>

          <div
            className='btn btn-sm btn-icon btn-active-color-primary'
            data-bs-dismiss='modal'
            onClick={handleClose}
            title='Close'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061-1.svg' className='svg-icon-1' />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div
            ref={stepperRef}
            className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            <div className='flex-row-fluid py-lg-5 px-lg-15'>
              <Formik
                validationSchema={createUpdateWorkspaceSchema}
                initialValues={initValues}
                enableReinitialize
                onSubmit={(values: any) => {
                  if (spaceDetail) {
                    values._id = _id
                    setResponse(true)
                    dispatch(actions.updateWorkspace(values))
                      .then((response: any) => {
                        setFetchRecords(fetchReocords ? false : true)
                        setResponse(false)
                        handleClose()
                      })
                      .catch((error: string) => {
                        setResponse(false)
                      })
                  } else {
                    setResponse(true)
                    dispatch(actions.createWorkspace(values))
                      .then((response: any) => {
                        setFetchRecords(fetchReocords ? false : true)
                        setResponse(false)
                        handleClose()
                      })
                      .catch((error: string) => {
                        setResponse(false)
                      })
                  }
                }}
              >
                {({resetForm}) => (
                  <Form className='form' noValidate id='kt_modal_create_app_form'>
                    <div className='current' data-kt-stepper-element='content'>
                      <div className='w-100'>
                        {error && (
                          <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{error}</div>
                          </div>
                        )}
                        <div className='fv-row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className='required'>Space Name</span>
                          </label>

                          <Field
                            type='text'
                            className='form-control form-control-lg form-control-solid'
                            name='name'
                            placeholder='Space Name'
                          />
                          <div className='text-danger'>
                            <ErrorMessage name='name' />
                          </div>
                        </div>

                        {!_id && (
                          <div className={`fv-row mb-5`}>
                            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                              <span>Space Key</span>
                            </label>

                            <Field
                              type='text'
                              className='form-control form-control-lg form-control-solid'
                              name='spaceKey'
                              placeholder='Space Key'
                            />
                            <div className='text-danger'>
                              <ErrorMessage name='spaceKey' />
                            </div>
                          </div>
                        )}

                        <div className='row'>
                          <div className='col-md-12 fv-row mb-5'>
                            <label className='fs-5 fw-bold mb-2'>
                              <span className='required'> Space Type</span>
                            </label>
                            <div className='row fv-row'>
                              <div className='col-12'>
                                <Field
                                  as='select'
                                  name='spaceType'
                                  className='form-select form-select-solid'
                                >
                                  {/* <option value=''>Select an option</option> */}
                                  <option value={1}>Group Space</option>
                                  <option value={2}>Personal Space</option>
                                </Field>
                                <div className='text-danger'>
                                  <ErrorMessage name='spaceType' />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='row mb-5'>
                          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                            <span className=''>Description</span>
                          </label>

                          <Field
                            className='form-control form-control-lg form-control-solid'
                            name='description'
                            placeholder='Add description'
                            as='textarea'
                            rows='5'
                          />

                          <div className='text-danger'>
                            <ErrorMessage name='description' />
                          </div>
                        </div>

                        <div className='row'>
                          <div className='col-md-12 fv-row mb-5'>
                            <label className='fs-5 fw-bold mb-2'>
                              <span className='required'> Features</span>
                            </label>
                            <div className='row row-cols-xxl-6 row-cols-xl-5 row-cols-lg-4 row-cols-md-4 row-cols-sm-3 row-cols-2 mx-auto g-4 mt-4'>
                              <div className='col rounded-2 mt-0' title='Todo'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity active'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute '>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='icon h-auto'>
                                      <img
                                        src='./media/icons/list.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title px-lg-2 px-md-3 px-3 d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span style={{fontSize: '11px'}}>Todo</span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>

                              <div className='col rounded-2 mt-0' title='Customer Master'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity disable'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/custom-master.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Customer Master
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Customer Onboarding'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/cutomer-onboarding.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Customer Onboarding
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Resource Onboarding'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/resource-onborarding.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Resource Onboarding
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Customer Invoices'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/customer-invoices.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Customer Invoices
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Reports'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/reports.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Reports
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 1'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/list.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 1
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 2'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/custom-master.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 2
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 3'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/cutomer-onboarding.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 3
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 4'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/resource-onborarding.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 4
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 5'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/customer-invoices.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 5
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 6'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/reports.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 6
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 7'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/list.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 7
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 8'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/custom-master.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 8
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 9'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/cutomer-onboarding.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 9
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 10'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/resource-onborarding.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 10
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 11'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/customer-invoices.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 11
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className='col rounded-2 mt-0' title='Feature 12'>
                                <div className='card-item mx-2 my-2 rounded-2 feature-opacity'>
                                  <div className='icon w-50px h-50px d-flex align-items-center justify-content-center position-relative mx-auto'>
                                    <div className='selected-feature position-absolute'>
                                      <i className='fas fa-check bg-success rounded-circle text-white'></i>
                                    </div>
                                    <Link to='/todo' className='h-auto'>
                                      <img
                                        src='./media/icons/reports.png'
                                        alt='icon'
                                        className='w-40px h-40px'
                                      />
                                      <div
                                        className='title d-flex justify-content-center mx-auto align-items-center text-center'
                                        style={{maxWidth: '90px'}}
                                      >
                                        <span className='masonry-title' style={{fontSize: '11px'}}>
                                          Feature 12
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex flex-stack pt-10'>
                      <div> </div>
                      <div className='me-2'>
                        <button
                          type='button'
                          className='btn cancel_button fs-7 fw-bolder me-3'
                          onClick={handleClose}
                          title='Cancel'
                        >
                          Cancel
                        </button>
                        <button
                          disabled={response}
                          type='submit'
                          className='btn btn-lg btn-primary'
                          title='Submit'
                        >
                          <span className='indicator-label'>Submit</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export {CreateWorkspace}
