import {useField} from 'formik'
import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../../../setup'

interface TextAreaProps {
  label: string
  name: string
  placeholder?: string
  className: string
  rows?: number
  formAvailability?: boolean
}

export const TextArea: FC<TextAreaProps> = ({formAvailability, label, ...props}) => {
  const {userInfo}: any = useSelector<RootState>(
    (state) => ({
      userInfo: state.auth.user,
    }),
    shallowEqual
  )
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props)
  return <textarea {...field} {...props} readOnly={formAvailability} />
  // return <textarea {...field} {...props} />
}
