import React from 'react'

// Example items, to simulate fetching from another resources.

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  activityID?: string
}
type Props = {
  paginationData: PaginationModel
  fetchData: any
}

const PaginationLoadMore: React.FC<Props> = ({paginationData, fetchData}) => {
  const {start, end, limitPerPage, totalCount} = paginationData

  // Invoke when user click to request another page.
  const handleLoadMore = (event: any) => {
    fetchData(event)
  }

  return (
    <>
      {totalCount > 0 && (
        <div className='d-flex justify-content-center'>
          {end < totalCount && (
            <span className='btn load-more_btn' onClick={() => handleLoadMore(Math.ceil(start/limitPerPage))}>Load More</span>
          )}
        </div>
      )}
    </>
  )
}

export {PaginationLoadMore}
