import {FC} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {Link, useParams} from 'react-router-dom'
import {RootState} from '../../../../setup'
import {getImageURL, KTSVG} from '../../../../_metronic/helpers'
import {SearchBox} from './headerPartially/SearchBox'
import * as actions from '../redux/BoardActions'

const BoardHeader: FC = () => {
  const {workspaceid, id}: {workspaceid: string; id: string; filter: any} = useParams()
  const {currentSpaceUsers, accessToken, filter}: any = useSelector<RootState>(
    (state) => ({
      currentSpaceUsers: state.board.currentSpaceUsers,
      accessToken: state.auth.accessToken,
      filter: state.board.filter,
    }),
    shallowEqual
  )
  const dispatch = useDispatch()

  const filterUser = (userId: string[]) => {
    if (!filter?.users?.includes(userId)) {
      dispatch(actions.filterProject({...filter, users: [...filter.users, userId]}))
    } else {
      dispatch(
        actions.filterProject({
          ...filter,
          users: filter.users.filter((user: any) => user !== userId),
        })
      )
    }
  }
  return (
    <div className='row mx-10 mt-5'>
      <div className='kanban-board-header-main px-0'>
        <div className='left d-flex align-items-center w-100'>
          <div className='board-views-btn d-none'></div>
          {/* <SearchBox /> */}
          <div className='mx-4 ms-0 side-margins-responsive'>
            <Link
              className='btn btn-blue position-relative  fw-bolder fs-7 d-flex align-items-center ml-2'
              to={`/${workspaceid}/project/${id}/board/summary`}
              title={'Summary'}
              style={{height: '31px', minWidth: '72px'}}
            >
              Summary
            </Link>
          </div>
          <div className='side-margins-responsive'>
            <Link
              to={`/${workspaceid}/project/${id}/task`}
              className='btn btn-sm btn-light-primary text-blue ml-2 fw-bolder fs-7 d-flex align-items-center'              
              title={'Tasks'}
              style={{height: '31px', minWidth: '72px'}}
            >
              <KTSVG path='' className='svg-icon svg-icon-3' />
              Tasks
            </Link>
          </div>

          <div className='kanban-header-facepile header_kanban_ui mx-4'>
            <ul>
              {currentSpaceUsers.map((user: any, i: any) => {
                return (
                  <li title={user?.fullName} key={i}>
                    <Link to='#'>
                      <img
                        className={`member-avatar ${
                          filter.users.includes(user._id) ? 'border border-primary' : ''
                        }`}
                        src={getImageURL(user?.image?.[0]?.thumbnailUrl, accessToken)}
                        alt={user?.fullName}
                        onClick={() => {
                          filterUser(user._id)
                        }}
                      />
                    </Link>
                  </li>
                )
              })}
              {/* {currentSpaceUsers.length > 3 && (
                <li>
                  <span className='d-flex justify-content-center align-items-center badge rounded-pill  rounded-pill-radius bg-primary'>
                    {currentSpaceUsers.length - 3}+
                  </span>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export {BoardHeader}
