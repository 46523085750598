import {Field, Form, Formik} from 'formik'
import {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'

import {FormikOnChangeSubmit} from '../FormikOnChangeSubmit'
export function Notifications({notificationsSettings, actions, actionsID, settings}: any) {
  const [disabled, setDisabled] = useState(false)

  const dispatch = useDispatch()
  const onSubmit = (notificationValues: any) => {
    dispatch(actions.updateNotification(actionsID, notificationValues))
  }

  const {workspaceDetail}: any = useSelector<any>(
    (state) => ({
      workspaceDetail: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )
  useEffect(() => {
    dispatch(actions.fetchNotification(actionsID))
  }, [actionsID])

  useEffect(() => {
    markeDisable()
  }, [settings.data?.app, settings.data?.email])

  const markeDisable = () => {
    if (settings.data?.app === false && settings.data?.email === false) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  return (
    <Formik initialValues={settings.data} onSubmit={onSubmit} enableReinitialize={true}>
      {(values: any) => (
        <Form>
          <div className='card-body'>
            <div className='row  w-100 d-flex flex-column  pb-6'>
              <div className='col-md-12'>
                <h5 className='pb-5'>{notificationsSettings.typeHeading}</h5>
              </div>
              {notificationsSettings.setting.map((getSetting: any, key: any) => {
                return (
                  <div className='col-md-3 pb-5' key={key}>
                    <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                      <label className=' fs-6 text-dark' title={getSetting.name}>
                        {getSetting.name}
                      </label>
                      <Field
                        title={getSetting.name}
                        className='form-check-input mx-3'
                        type='checkbox'
                        name={getSetting.key}
                      />
                    </div>
                  </div>
                )
              })}

              <div className='row mb-10 w-100  pt-10 mt-10'>
                <div className='col-md-12'>
                  <h5 className='pb-5'>{notificationsSettings.featureHeading}</h5>
                </div>
                {notificationsSettings.data.map((singleData: any) => {
                  return singleData.module.map((singleModule: any, key: any) => {
                    return workspaceDetail?.features.includes(singleModule.key) && actionsID ? (
                      <div className='col-md-3 pb-10' key={key}>
                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                          <label className=' fs-6 text-dark' title={singleModule.name}>
                            {singleModule.name}
                          </label>
                          <Field
                            title={singleModule.name}
                            className='form-check-input mx-3'
                            type='checkbox'
                            name={singleModule.key}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    ) : !actionsID ? (
                      <div className='col-md-3 pb-10' key={key}>
                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                          <label className=' fs-6 text-dark' title={singleModule.name}>
                            {singleModule.name}
                          </label>
                          <Field
                            title={singleModule.name}
                            className='form-check-input mx-3'
                            type='checkbox'
                            name={singleModule.key}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    ) : null
                  })
                })}
              </div>
            </div>
          </div>
          <FormikOnChangeSubmit />
        </Form>
      )}
    </Formik>
  )
}
