import {ErrorMessage, Field, useField} from 'formik'
import {FC, useEffect, useState} from 'react'
import {statuses, approvedStatuses} from '../talentUIHelper'
import {TextArea} from './TextArea'
import {useDispatch} from 'react-redux'
import * as actions from '../redux/talentActions'
interface TalentRecruiterFieldsProps {
  saveData: boolean
  id: string
  widgetId: string
}

export const TalentRecruiterFields: FC<TalentRecruiterFieldsProps> = ({saveData, id, widgetId}) => {
  const dispatch: any = useDispatch()
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    if (!widgetId)
      dispatch(actions.getAllWidgets()).then((res: any) => {
        setWidgets(res.data)
      })
  }, [])
  return (
    <>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Status</span>
          </label>

          <div className='row'>
            <div className='col-md-6 py-2 py-sm-0'>
              <Field
                as='select'
                name='initialStatus'
                className='form-select form-select-md form-select-solid'
              >
                <option value=''>Initial status</option>
                {statuses.map((status: any, i: any) => {
                  return (
                    <option key={i} value={status.value}>
                      {status.label}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger'>{saveData && <ErrorMessage name='initialStatus' />}</div>
            </div>
            <div className='col-md-6 py-2 py-sm-0'>
              <Field
                as='select'
                name='resumeStatus'
                className='form-select form-select-md form-select-solid'
              >
                <option value=''>Resume Status</option>
                {approvedStatuses.map((status: any, i: any) => {
                  return (
                    <option key={i} value={status.value}>
                      {status.label}
                    </option>
                  )
                })}
              </Field>
              <div className='text-danger'>
                {saveData && <ErrorMessage name='approvedStatus' />}
              </div>
            </div>
          </div>
        </div>

        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Shared With</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='sharedWith'
            placeholder='Shared With'
          />
          <div className='text-danger'>{saveData && <ErrorMessage name='sharedWith' />}</div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Calling Name</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='callingName'
            placeholder='Calling Name'
          />
          <div className='text-danger'>{saveData && <ErrorMessage name='callingName' />}</div>
        </div>

        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Offered As</span>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='offeredAs'
            placeholder='Offered As'
          />
          <div className='text-danger'>{saveData && <ErrorMessage name='offeredAs' />}</div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Remarks</span>
          </label>

          <TextArea
            className='form-control form-control-lg form-control-solid'
            name='remarks'
            placeholder='Remarks'
            label='Remarks'
            rows={5}
          />
          <div className='text-danger'>{saveData && <ErrorMessage name='remarks' />}</div>
        </div>

        <div className='col-md-6 fv-row mb-5'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Client Decision</span>
          </label>
          <TextArea
            name='clientDecision'
            className='form-control form-control-lg form-control-solid'
            placeholder='Client Decision'
            label='Client Decision'
            rows={5}
          />
          <div className='text-danger'>{saveData && <ErrorMessage name='clientDecision' />}</div>
        </div>
        {id && !widgetId && (
          <div className='col-md-6 fv-row mb-5'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Widget</span>
            </label>
            <div className='py-2 py-sm-0'>
              <Field
                as='select'
                name='widget'
                className='form-select form-select-md form-select-solid'
              >
                <option value={''}>Select Widget</option>
                {widgets.map((widget: any, i: any) => {
                  return (
                    <option key={i} value={widget._id}>
                      {widget.name}
                    </option>
                  )
                })}
              </Field>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
