import {AlignmentType, HeadingLevel, Paragraph, TableCell, TableRow, TextRun, WidthType} from 'docx'
import {constants} from './Constants'
import {docxTable, indentSpace, showImage, docxBorder, addLineBreak} from './DocxFunctions'

export const careerProfile = (profile: any) => {
  const tableRows: any = []
  tableRows.push(
    new TableRow({
      children: [
        new TableCell({
          children: [showImage('/media/talent/career-profile.png', 20, 20)],
          width: {
            size: 6,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),

        new TableCell({
          children: [
            new Paragraph({
              indent: indentSpace(),
              spacing: {
                line: 400,
              },
              children: [
                new TextRun({
                  text: constants.Titles.CAREER_PROFILE,
                  size: 24,
                  font: {
                    name: constants.CONTENT_FONT_FAMILY,
                  },
                }),
              ],
            }),
          ],
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    }),
    new TableRow({
      children: [
        new TableCell({
          children: [
            new Paragraph({
              style: '',
              text: '',
            }),
          ],
          width: {
            size: 5,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
        new TableCell({
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  children: addLineBreak(profile?.professionalSummary?.split('\n')),
                }),
              ],
            }),
          ],

          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          borders: docxBorder(),
        }),
      ],
    })
  )
  return docxTable(tableRows)
}
