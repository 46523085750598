import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL

export const VERIFY_ACCESS_TOKEN_URL = `${API_URL}/auth/verify-token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const LDAP_LOGIN_URL = `${API_URL}/ldap/login`
export const REGISTER_URL = `${API_URL}/auth/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot/password`
export const UPDATE_URL = `${API_URL}/users`
export const USER_ATTACHMENT_DATA = `${API_URL}/attachments`
export const RESET_PASSWORD_URL = `${API_URL}/auth/reset/password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    email,
    password,
    roleType: 2,
  })
}

// Server should return AuthModel
export function ldaplogin(username: string, password: string) {
  return axios.post<AuthModel>(LDAP_LOGIN_URL, {
    username,
    password,
  })
}
// Server should return AuthModel
export function register(email: string, firstName: string, lastName: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstName,
    lastName,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{success: boolean; message: string}>(REQUEST_PASSWORD_URL, {
    email: email,
  })
}
export function resetPassword(password: string, token: string) {
  return axios.post<{success: boolean; message: string}>(`${RESET_PASSWORD_URL}`, {
    password,
    token,
  })
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.post<UserModel>(VERIFY_ACCESS_TOKEN_URL, {roleType: 2}).then((res: any) => {
    return res.data.userSettings
  })
}

export function updateUser(
  _id: string,
  email: string,
  firstName: string,
  lastName: string,
  password: string,
  newPassword: string,
  attachmentPaths: any
) {
  return axios.patch<UserModel>(UPDATE_URL, {
    _id,
    email,
    firstName,
    lastName,
    password,
    newPassword,
    attachmentPaths,
    itemType: 'User',
  })
}

export function addUserAttachmentData({_id, attachmentPaths}: {_id: any; attachmentPaths: any}) {
  return axios.post(USER_ATTACHMENT_DATA, {
    item: _id,
    attachmentPaths,
    itemType: 'User',
    type: 'User',
  })
}

export function updateUserAttachmentData({
  _id,
  attachmentPaths,
}: {
  _id: string
  attachmentPaths: any
}) {
  return axios.patch(`${USER_ATTACHMENT_DATA}/${_id}`, {
    item: _id,
    attachmentPaths,
    itemType: 'User',
    type: 'User',
  })
}

export function deleteAttachment(_id: string) {
  return axios.delete(`${USER_ATTACHMENT_DATA}/${_id}`)
}
