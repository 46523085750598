import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const WORKSPACE_URL = `${API_URL}/workspace`
export const UPDATE_NOTIFICATION_URL = `${API_URL}/users/update-notification`
export const GET_UPDATED_NOTIFICATION_URL = `${API_URL}/users/updated-notification`
const DISCUSSION_URL = `${API_URL}/discussions`
const ALL_COMPANIES_AND_GROUPS = `${API_URL}/users/companies/groups`

export function getAllWorkspaces({
  search,
  page,
  sortby,
  orderby,
  createdAt,
  spaceType,
  assignee,
  maintainer,
}: {
  search: string
  page: number | string
  sortby: string
  orderby: string
  createdAt: string
  spaceType: number | string
  assignee: any
  maintainer: any
}) {
  return axios.get(WORKSPACE_URL, {
    params: {search, page, sortby, orderby, createdAt, spaceType, assignee, maintainer},
  })
}
export function createWorkspace({
  name,
  spaceKey,
  spaceType,
  description,
  assignee,
  maintainers,
  teams,
  maintainerTeams,
  features,
  company,
  group,
}: {
  name: string
  spaceKey: string
  spaceType: number
  description: string
  assignee: []
  maintainers: []
  teams: []
  maintainerTeams: []
  features: []
  company: []
  group: []
}) {
  return axios.post(WORKSPACE_URL, {
    name,
    spaceKey,
    spaceType,
    description,
    assignee,
    maintainers,
    teams,
    maintainerTeams,
    features,
    company,
    group,
  })
}
export function updateWorkspace({
  _id,
  name,
  spaceType,
  description,
  assignee,
  maintainers,
  teams,
  maintainerTeams,
  features,
  workSpace,
  company,
  group,
}: {
  _id: string
  name: string
  spaceType: number
  description: string
  assignee: []
  maintainers: []
  teams: []
  maintainerTeams: []
  features: []
  workSpace: string
  company: []
  group: []
}) {
  return axios.patch(`${WORKSPACE_URL}/${_id}`, {
    name,
    spaceType,
    description,
    assignee,
    maintainers,
    teams,
    maintainerTeams,
    features,
    workSpace,
    company,
    group,
  })
}

export function deleteWorkspace(_id: string) {
  return axios.delete(`${WORKSPACE_URL}/${_id}`)
}

export function fetchWorkspace(_id: string) {
  return axios.get(`${WORKSPACE_URL}/${_id}`)
}

export function updateUserSettings(notificationSettings: object) {
  return axios.patch(UPDATE_NOTIFICATION_URL, {
    notificationSettings,
  })
}

export function getUpdatedNotification() {
  return axios.get(GET_UPDATED_NOTIFICATION_URL)
}

export function getAllWorkspaceUsers(workSpaceId: string) {
  return axios.get(`${DISCUSSION_URL}?workSpace=${workSpaceId}`)
}

export function getAllCompanies() {
  return axios.get(`${ALL_COMPANIES_AND_GROUPS}`)
}
