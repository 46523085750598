import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {getImageURL, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {useParams, Link} from 'react-router-dom'
import moment from 'moment'
import * as actions from '../redux/activityLogsAction'
import * as workspaceActions from '../../workspace/redux/WorkspaceActions'
import {ActivityDelete} from './ActivityDelete'
import {RootState} from '../../../../setup'
import {UserModel} from '../../auth/models/UserModel'
import {Pagination} from '../../../pagination/pagination'
import SimpleDropZone from './DropZone'
import {ActivityCommentForm} from './ActivityCommentForm'
import '../../../../_metronic/assets/sass/components/comments.scss'
// import {Editor} from '@tinymce/tinymce-react'
import {Attachment} from '../../../Common/attachments/Attachment'
import {Can} from '../../../Common/RBAC/Can'
import Editor from '../../../Common/Editor/Editor'
import {PaginationLoadMore} from '../../../pagination/paginationLoadMore'
import {CommentForm} from './CommentForm'

interface ActivityComment {
  comment: String
  // files: []
  attachments?: []
}
const filesExtensions = ['csv', 'pdf', 'doc', 'docx', 'csv', 'xls', 'xlsx', 'txt', 'ppt', 'pptx']

const inits: ActivityComment = {
  comment: '',
  // files: [],
  attachments: [],
  // attachments:[]
}

type ActivitiesModel = [
  {
    _id: string
    itemType: string
    description: string
    type: string
    assigneeChanged: string
    attachments?: []
    createdBy: string
    createdAt: string
  }
]

interface PaginationModel {
  start: number
  end: number
  limitPerPage: number
  entities: []
  totalCount: number
  activityID?: string
}

interface Props {
  itemType: string
  activityItemId: string
  isPopupActivity?: boolean
}

const Activity: React.FC<Props> = ({itemType, activityItemId, isPopupActivity = false}) => {
  const dispatch: any = useDispatch()
  const {workspaceid}: {workspaceid: string} = useParams()
  var {id}: {id: any} = useParams()

  id = activityItemId ? activityItemId : id

  const [initValues] = useState<ActivityComment>(inits)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [editComment, setEditComment] = useState<any>({})
  const [imagePaths, setImagePaths]: any = useState([])
  const [show, setShow] = useState(false)
  const [showEditComment, setShowEditComment] = useState(false)

  const [itemToDelete, setItemToDelete] = useState('')
  const [itemTypeActivity, setItemTypeActivity] = useState('')
  const [itemId, setItemId] = useState('')

  const [activitiesData, setActivitiesData] = useState<any>()
  // const [selectedfiles, setSelectedFiles] = useState<any>()

  const {spaceDetail}: any = useSelector<RootState>(
    (state) => ({
      spaceDetail: state.workspace.workspaceDetail,
    }),
    shallowEqual
  )
  const accessToken: any = useSelector<RootState>(
    (state) => state.auth.accessToken,
    shallowEqual
  ) as any

  const activites: ActivitiesModel = useSelector<RootState>(
    (state) => state.activities.entities,
    shallowEqual
  ) as ActivitiesModel
  const listLoading: ActivitiesModel = useSelector<RootState>(
    (state) => state.activities.listLoading,
    shallowEqual
  ) as ActivitiesModel
  const attachments: ActivitiesModel = useSelector<RootState>(
    (state) => state.activities.attachments,
    shallowEqual
  ) as ActivitiesModel

  let paginationData: PaginationModel = useSelector<RootState>(
    (state) => state.activities,
    shallowEqual
  ) as PaginationModel

  paginationData = {...paginationData, activityID: id}

  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const deleteActivityTask = (_id: string) => async (event: any) => {
    setItemToDelete(_id)
    setShowDeleteDialog(true)
    setItemId(id)
  }

  useEffect(() => {
    if (id) {
      dispatch(actions.fetchActivites({itemId: id, page: '', itemType}))
    }
  }, [id])

  useEffect(() => {
    if (Array.isArray(attachments)) {
      setImagePaths(attachments.map((attachment: any) => attachment.path))
    } else {
      setImagePaths([])
    }
  }, [attachments])

  useEffect(() => {
    if (activites.length > 0) {
      setActivitiesData(activites)
    } else {
      setActivitiesData([])
    }
  }, [activites])

  useEffect(() => {
    if (workspaceid) {
      dispatch(workspaceActions.fetchWorkspace(workspaceid))
    }
  }, [dispatch])

  const resetFields = () => {
    setImagePaths([])
    setEditComment({})
    dispatch(actions.removeAttachments())
  }

  const handlePageClick = (event: any) => {
    dispatch(actions.fetchActivites({itemId: id, page: event + 1, itemType}))
  }

  const createAppSchema = Yup.object().shape({
    comment: Yup.string().min(1, 'Minimum 1 alphabets allowed').required('Comment is required'),
  })
  return (
    <>
      <ActivityDelete
        show={showDeleteDialog}
        handleClose={() => {
          setShowDeleteDialog(false)
        }}
        _id={itemToDelete}
        itemId={itemId}
        itemType={itemType}
      />

      <div className='card my-10'>
        <div className='collapse show'>
          <Formik
            validationSchema={createAppSchema}
            initialValues={initValues}
            onSubmit={(values: any, {resetForm}) => {
              values.attachments = imagePaths
              dispatch(actions.createActivites(values.comment, id, itemType, values.attachments))
              resetFields()
              resetForm()
              setShow(false)
              window.scrollTo(0, 0)
            }}
          >
            {({setFieldValue, errors, values, resetForm}) =>
              !listLoading && (
                <div className='card-body  border_grey_info mx-2 mx-sm-10'>
                  <span title='Activity' className='me-6  mb-6 active fw-500'>
                    Activity Log
                  </span>
                  {isPopupActivity && (
                    <CommentForm
                      values={values}
                      setFieldValue={setFieldValue}
                      resetFields={resetFields}
                      resetForm={resetForm}
                      show={show}
                      setShow={setShow}
                    />
                  )}
                  <div className='row-mb-6 mt-14'>
                    <div className='timeline timeline-3'>
                      <div className='timeline-items'>
                        {activitiesData &&
                          activitiesData.length > 0 &&
                          activitiesData.map((activityData: any, index: number) => {
                            return (
                              <div
                                key={index}
                                className='timeline-item'
                                title={
                                  activityData?.createdBy
                                    ? activityData?.createdBy?.firstName +
                                      ' ' +
                                      activityData?.createdBy?.lastName +
                                      ' posted on ' +
                                      moment(activityData?.createdAt).format('MM/DD/YYYY')
                                    : ''
                                }
                              >
                                <div className='timeline-media'>
                                  <img
                                    alt={
                                      activityData?.createdBy?.firstName +
                                      ' ' +
                                      activityData?.createdBy?.lastName
                                    }
                                    src={getImageURL(
                                      activityData?.createdBy?.image?.[0]?.thumbnailUrl,
                                      accessToken
                                    )}
                                  />
                                </div>
                                <div className='timeline-content'>
                                  <div className='mb-3'>
                                    <div className='mr-2'>
                                      <span className='text-dark-75'>
                                        {activityData.type === 'activity' ? (
                                          <span
                                            dangerouslySetInnerHTML={{
                                              __html: activityData.template
                                                .replace(
                                                  '{user}',
                                                  `<span class="font-weight-bold">${activityData?.createdBy?.['firstName']}</span>`
                                                )
                                                .replace(
                                                  '{field}',
                                                  activityData?.data?.field
                                                    ? activityData?.data?.field
                                                    : null
                                                )
                                                .replace(
                                                  '{value}',
                                                  activityData.assigneeChanged
                                                    ? `<span class="font-weight-bold">${activityData?.assigneeChanged?.firstName}</span>`
                                                    : activityData?.data?.field === 'due date'
                                                    ? moment(activityData?.data?.value).format(
                                                        'MM/DD/YYYY'
                                                      )
                                                    : activityData?.data?.value
                                                    ? activityData?.data?.value
                                                    : null
                                                ),
                                            }}
                                          />
                                        ) : (
                                          <span className='font-weight-bold'>
                                            {activityData?.createdBy?.['firstName']}
                                          </span>
                                        )}
                                      </span>
                                      <span className='text-muted ml-2'>
                                        {moment(activityData.createdAt).fromNow()}
                                      </span>
                                    </div>

                                    {activityData.type === 'comment' &&
                                      (spaceDetail?.maintainers?.find(
                                        (m: any) => m?._id == user?._id
                                      ) ||
                                        spaceDetail?.owner?._id === user?._id ||
                                        activityData?.createdBy?._id === user?._id) && (
                                        // <Can I='update' this='spaces'>
                                        //   <Can I='update' this='opportunity-watch'>
                                        <div className='card-toolbar m-0'>
                                          <button
                                            type='button'
                                            className='btn btn-active-color-primary btn-sm d-inline-flex align-items-center justify-content-end p-1'
                                            onClick={(e) => {
                                              setEditComment({
                                                _id: activityData._id,
                                                description: activityData.description,
                                                attachments: activityData.attachments,
                                              })
                                              dispatch(
                                                actions.loadAttachments(activityData.attachments)
                                              )
                                              setImagePaths(activityData.attachmentPaths)
                                              if (editComment?._id === activityData._id) {
                                                setShowEditComment((show) => !show)
                                              } else {
                                                setShowEditComment(true)
                                              }
                                              // setFieldValue('comment', activityData.description)
                                              // window.scrollTo(0, document.body.scrollHeight)
                                            }}
                                            title='Edit'
                                            data-bs-target='#editComment'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/art/art005.svg'
                                              className='m-0'
                                            />
                                          </button>
                                          <button
                                            type='button'
                                            className='btn btn-active-color-primary btn-sm d-inline-flex align-items-center justify-content-end p-1'
                                            data-bs-toggle='modal'
                                            data-bs-target='#kt_modal_delete_app'
                                            id='kt_toolbar_primary_button'
                                            onClick={deleteActivityTask(activityData._id)}
                                            title='Delete'
                                          >
                                            <KTSVG
                                              path='/media/icons/duotune/general/gen027.svg'
                                              className='m-0'
                                            />
                                          </button>
                                        </div>
                                        //   </Can>
                                        // </Can>
                                      )}
                                  </div>
                                  {activityData.type === 'comment' && (
                                    <>
                                      {showEditComment && editComment._id === activityData._id ? (
                                        <ActivityCommentForm
                                          comment={editComment}
                                          show={show}
                                          setShow={setShowEditComment}
                                          itemType={itemType}
                                          item={activityData?.item}
                                        />
                                      ) : (
                                        <>
                                          <div
                                            title={`${itemType} Comment`}
                                            className='d-flex flex-column'
                                            dangerouslySetInnerHTML={{
                                              __html: activityData?.description,
                                            }}
                                          />

                                          {activityData?.attachments?.length > 0 && (
                                            <Attachment
                                              attachments={activityData.attachments}
                                              loadAttachments={actions.loadAttachments}
                                              attachmentType='listing'
                                              id={id}
                                              itemType='activity'
                                            />
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    </div>
                    <PaginationLoadMore
                      paginationData={paginationData}
                      fetchData={handlePageClick}
                    />
                  </div>
                  {!isPopupActivity && (
                    <CommentForm
                      values={values}
                      setFieldValue={setFieldValue}
                      resetFields={resetFields}
                      resetForm={resetForm}
                      show={show}
                      setShow={setShow}
                    />
                  )}
                </div>
              )
            }
          </Formik>
        </div>
      </div>
    </>
  )
}

export {Activity}
