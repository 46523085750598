import React, {useState, useEffect} from 'react'
import * as Yup from 'yup'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import {useDispatch, useSelector, shallowEqual} from 'react-redux'
import {useParams} from 'react-router-dom'
import * as actions from '../redux/activityLogsAction'
import {RootState} from '../../../../setup'
import '../../../../_metronic/assets/sass/components/comments.scss'
import {Attachment} from '../../../Common/attachments/Attachment'
import {Can} from '../../../Common/RBAC/Can'
import Editor from '../../../Common/Editor/Editor'

interface ActivityComment {
  comment: String
  // files: []
  attachments?: []
}

const inits: ActivityComment = {
  comment: '',
  // files: [],
  attachments: [],
  // attachments:[]
}

type TodoActivitesModel = [
  {
    _id: string
    todo: string
    description: string
    type: string
    assigneeChanged: string
    attachments?: []
    createdBy: string
    createdAt: string
  }
]
interface Props {
  show: boolean
  setShow: any
  comment: any
  itemType: string
  item: string
}

const ActivityCommentForm: React.FC<Props> = ({comment, show, setShow, itemType, item}) => {
  const dispatch = useDispatch()
  const {id}: {id: any} = useParams()
  const [initValues] = useState<ActivityComment>(comment || inits)
  const [editComment, setEditComment] = useState<any>(comment)
  const [imagePaths, setImagePaths]: any = useState([])
  const [showUploader, setShowUploader] = useState(false)

  const attachments: TodoActivitesModel = useSelector<RootState>(
    (state) => state.activities.attachments,
    shallowEqual
  ) as TodoActivitesModel

  useEffect(() => {
    if (Array.isArray(attachments)) {
      setImagePaths(
        attachments.map((attachment: any) => {
          const staticURL = attachment.path.split('/')[0]
          if (staticURL === 'public') {
            return attachment.path
          }
          return attachment.type ? attachment.path + '.' + attachment.type : attachment.path
        })
      )
    }
  }, [attachments])

  const resetFields = () => {
    setImagePaths([])
    setEditComment({})
    dispatch(actions.removeAttachments())
  }

  const createAppSchema = Yup.object().shape({
    description: Yup.string().min(1, 'Minimum 1 alphabets allowed').required('Comment is required'),
  })
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='collapse show'>
          <Formik
            validationSchema={createAppSchema}
            initialValues={initValues}
            onSubmit={(values: any, {resetForm}) => {
              values.attachments = imagePaths
              if (editComment?._id) {
                dispatch(
                  actions.UpdateActivites(
                    editComment?._id,
                    item,
                    values.description,
                    imagePaths,
                    itemType
                  )
                )

                setEditComment({})
              }
              resetFields()
              resetForm()
              setShow(false)
            }}
          >
            {({setFieldValue, errors, values, resetForm}) => (
              <Form className='Form'>
                <div className='card-body border-top p-9'>
                  <div className='fv-row'>
                    <Field
                      className='form-control form-control-lg form-control-solid'
                      name='description'
                      placeholder='Write a comment'
                      id='editComment'
                      type='text'
                    >
                      {({field, form, meta}: any) => (
                        <Editor
                          value={values?.description || ''}
                          field='description'
                          setFieldValue={setFieldValue}
                        />
                      )}
                    </Field>

                    <div className='text-danger'>
                      <ErrorMessage name='comment' />
                    </div>
                  </div>
                  <Attachment
                    attachments={attachments}
                    loadAttachments={actions.loadAttachments}
                    attachmentType='attachmentUpload'
                    id={id}
                    itemType='activity'
                  />
                </div>

                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button
                    type='button'
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    onClick={() => {
                      resetFields()
                      resetForm()
                      setShow(false)
                    }}
                    title='Cancel'
                  >
                    Cancel
                  </button>

                  <button
                    type='submit'
                    className='btn btn-primary'
                    title={editComment?._id ? 'Update' : 'Comment'}
                  >
                    {editComment?._id ? 'Update' : 'Comment'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export {ActivityCommentForm}
